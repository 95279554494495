/**
 * ****************************************************************************************************
 * 关于我们模块相关接口
 * ****************************************************************************************************
 */
import HiRequest from "@/request/index.js";
export const hr = new HiRequest();

// 获取关于我们内容
export const apiGetAboutUs = (data = {}, options = {}) => hr.get("api/v1/about_us/index_pc", data, options);

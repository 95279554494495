<!-- 标的详情页 - 标的介绍模块 -->
<template>
	<div class="component-target-detail-intro">
		<!-- #S 左侧内容 -->
		<div class="component-target-detail-intro__left">
			<div class="component-target-detail-intro__left__image-preview-box">
				<img :src="info?.images?.[current]" class="image" alt="" />
			</div>
			<div class="component-target-detail-intro__left__swiper" v-show="swiperInstance">
				<i class="component-target-detail-intro__left__swiper-arrow arrow-prev zn-iconfont zn-iconfont-zuo"></i>
				<div class="swiper-container component-target-detail-intro__left__swiper-container">
					<div class="swiper-wrapper">
						<div class="swiper-slide" :class="{ 'is-selected': current == index }"
							v-for="(item, index) in info?.images || []" :key="item" @click="handleSlideClick(index)">
							<div class="image-box">
								<img :src="item" class="image" alt="" />
							</div>
						</div>
					</div>
				</div>
				<i class="component-target-detail-intro__left__swiper-arrow arrow-next zn-iconfont zn-iconfont-you"></i>
			</div>

			<!-- 操作+数据展示 -->
			<div class="component-target-detail-intro__left__datas">
				<div class="component-target-detail-intro__left__datas__group group-collection collection"
					@click="$emit('uncollection')" v-if="info?.is_collect">
					<i
						class="component-target-detail-intro__left__datas__group__icon zn-iconfont zn-iconfont-shoucangxuanzhong"></i>
					<span>已收藏</span>
				</div>
				<div class="component-target-detail-intro__left__datas__group group-collection"
					@click="$emit('collection')" v-else>
					<i
						class="component-target-detail-intro__left__datas__group__icon zn-iconfont zn-iconfont-shoucangxuanzhong"></i>
					<span>未收藏</span>
				</div>
				<div class="component-target-detail-intro__left__datas__group">
					<i
						class="component-target-detail-intro__left__datas__group__icon zn-iconfont zn-iconfont-chakan"></i>
					<span>{{ info?.read_count }}次围观</span>
				</div>
				<div class="component-target-detail-intro__left__datas__group" @click="clickHandlers">
					<img src="../../public/images/wx.png" alt="分享到微信" class="img-share">
				</div>
			</div>
		</div>
		<!-- #E 左侧内容 -->

		<!-- #S 右侧内容 -->
		<div class="component-target-detail-intro__right">
			<!-- #S 右侧内容 - TOP -->
			<div class="component-target-detail-intro__right__top">
				<!-- Head -->
				<div class="component-target-detail-intro__right__top__head"
					:class="[{ 'fix-zt': info?.pause_status == 10 || info?.status == 80 || info?.status == 70 }, { 'fix-jx': info?.pause_status != 10 && info?.status != 80 && info?.status != 70 }]">
					<!-- 标的状态 -->
					<div class="component-target-detail-intro__right__top__head__status status-in-progress is-pause "
						:class="{ fixBgs: info?.pause_status == 10 }" v-if="info?.pause_status == 10">
						<div class="fixImgBox"></div>
						已暂停
					</div>

					<TargetStatus :status="info?.status" :showFlag="info?.status"
						class="component-target-detail-intro__right__top__head__status" v-else></TargetStatus>

					<div class="component-target-detail-intro__right__top__head__countdown" v-if="info?.status == 30">
						<span>拍卖会预计{{ dayjs.unix(info?.auction_start_time).format("YYYY年M月D日 HH:mm") }}开始</span>
					</div>
					<!-- 倒计时 -->
					<div class="component-target-detail-intro__right__top__head__countdown" v-if="info?.status == 40">
						<span>自由竞拍结束还有 {{ `${countdown.DD}天${countdown.hh}时${countdown.mm}分${countdown.ss}秒` }}</span>
					</div>
					<div class="component-target-detail-intro__right__top__head__countdown"
						v-else-if="info?.status == 50">
						<span>限时竞拍结束还有 {{ `${countdown.DD}天${countdown.hh}时${countdown.mm}分${countdown.ss}秒` }}</span>
					</div>
					<div class="component-target-detail-intro__right__top__head__countdown"
						v-else-if="info?.status == 60">
						<span>标的等待拍卖师确认</span>
					</div>
					<div class="component-target-detail-intro__right__top__head__countdown"
						v-else-if="[70, 80, 90].includes(info?.status)">
						<span>标的于{{ dayjs.unix(info?.item_end_time).format("YYYY年M月D日 HH:mm") }}结束</span>
					</div>

				</div>

				<!-- #S 竞拍操作区域 -->
				<div class="component-target-detail-intro__right__top__content">
					<!-- 已撤拍，已流拍 -->
					<div class="component-target-detail-intro__right__top__content__status"
						v-if="[70, 80].includes(info?.status)">
						<div class="component-target-detail-intro__right__top__content__status__row" v-if="canSeeInfo">
							<label
								class="component-target-detail-intro__right__top__content__status__row__label">起拍价格：</label>
							<span class="component-target-detail-intro__right__top__content__status__row__price">¥{{
					formatPrice(info?.start_price) }}</span>
							<span class="component-target-detail-intro__right__top__content__status__row__price"
								v-if="info.auction_type == 20">/{{ info.auction_count_unit }}</span>

						</div>
						<div class="component-target-detail-intro__right__top__content__status__row" v-else>
							<label
								class="component-target-detail-intro__right__top__content__status__row__label">起拍价格：</label>
							<span
								class="component-target-detail-intro__right__top__content__status__row__price">--</span>
						</div>
						<div class="component-target-detail-intro__right__top__content__status__row">
							<el-button type="primary"
								class="component-target-detail-intro__right__top__content__button center" disabled>{{
					targetStatus[info?.status]?.name
				}}</el-button>
						</div>
					</div>

					<!-- 已成交 - 成交人是我自己 -->
					<div class="component-target-detail-intro__right__top__content__status"
						v-else-if="info?.status == 90 && info?.nickname == info?.quote_data?.[0]?.nickname">
						<div class="component-target-detail-intro__right__top__content__status__row" v-if="canSeeInfo">
							<label
								class="component-target-detail-intro__right__top__content__status__row__label">成交价格：</label>
							<span class="component-target-detail-intro__right__top__content__status__row__price">¥{{
					formatPrice(info?.quote_data?.[0]?.price) }}元</span>
							<span class="component-target-detail-intro__right__top__content__status__row__price"
								v-if="info.auction_type == 20">/{{ info.auction_count_unit }}</span>

							<span class="component-target-detail-intro__right__top__content__status__row__code">出价人：{{
					info?.quote_data?.[0]?.nickname }}</span>
						</div>
						<div class="component-target-detail-intro__right__top__content__status__row" v-else>
							<label
								class="component-target-detail-intro__right__top__content__status__row__label">成交价格：</label>
							<span
								class="component-target-detail-intro__right__top__content__status__row__price">--</span>
							<span
								class="component-target-detail-intro__right__top__content__status__row__code">出价人：--</span>
						</div>
						<div class="component-target-detail-intro__right__top__content__status__row">
							<el-button type="primary"
								class="component-target-detail-intro__right__top__content__button center" disabled>恭喜您
								已成交</el-button>
						</div>
					</div>

					<!-- 已成交 - 成交人是其他用户 -->
					<div class="component-target-detail-intro__right__top__content__status"
						v-else-if="info?.status == 90 && info?.nickname != info?.quote_data?.[0]?.nickname">
						<div class="component-target-detail-intro__right__top__content__status__row" v-if="canSeeInfo">
							<label
								class="component-target-detail-intro__right__top__content__status__row__label">成交价格：</label>
							<span class="component-target-detail-intro__right__top__content__status__row__price">¥{{
					formatPrice(info?.quote_data?.[0]?.price) }}元</span>
							<span class="component-target-detail-intro__right__top__content__status__row__price"
								v-if="info.auction_type == 20">/{{ info.auction_count_unit }}</span>

							<span class="component-target-detail-intro__right__top__content__status__row__code">出价人：{{
					info?.quote_data?.[0]?.nickname }}</span>
						</div>
						<div class="component-target-detail-intro__right__top__content__status__row" v-else>
							<label
								class="component-target-detail-intro__right__top__content__status__row__label">成交价格：</label>
							<span
								class="component-target-detail-intro__right__top__content__status__row__price">--</span>
							<span
								class="component-target-detail-intro__right__top__content__status__row__code">出价人：--</span>
						</div>
						<div class="component-target-detail-intro__right__top__content__status__row">
							<el-button type="primary"
								class="component-target-detail-intro__right__top__content__button center"
								disabled>已成交</el-button>
						</div>
					</div>

					<!-- 用户未登录 -->
					<div class="component-target-detail-intro__right__top__content__status" v-else-if="!userinfo">
						<div class="component-target-detail-intro__right__top__content__status__row" v-if="canSeeInfo">
							<label
								class="component-target-detail-intro__right__top__content__status__row__label">当前价格：</label>
							<span class="component-target-detail-intro__right__top__content__status__row__price">¥{{
					formatPrice(info?.quote_data?.[0]?.price ?? info?.start_price) }}元</span>
							<span class="component-target-detail-intro__right__top__content__status__row__price"
								v-if="info.auction_type == 20">/{{ info.auction_count_unit }}</span>

						</div>
						<div class="component-target-detail-intro__right__top__content__status__row" v-else>
							<label
								class="component-target-detail-intro__right__top__content__status__row__label">当前价格：</label>
							<span
								class="component-target-detail-intro__right__top__content__status__row__price">--</span>
						</div>
						<div class="component-target-detail-intro__right__top__content__status__row">
							<el-button type="primary"
								class="component-target-detail-intro__right__top__content__button center"
								@click="$router.push({ name: 'Login' })">登录后参拍</el-button>
						</div>
					</div>

					<!-- 用户在报名黑名单 -->
					<div class="component-target-detail-intro__right__top__content__status"
						v-else-if="info?.enroll_status == 30">
						<div class="component-target-detail-intro__right__top__content__status__row" v-if="canSeeInfo">
							<label
								class="component-target-detail-intro__right__top__content__status__row__label">当前价格：</label>
							<span class="component-target-detail-intro__right__top__content__status__row__price">¥{{
					formatPrice(info?.quote_data?.[0]?.price ?? info?.start_price) }}元</span>
							<span class="component-target-detail-intro__right__top__content__status__row__price"
								v-if="info.auction_type == 20">/{{ info.auction_count_unit }}</span>

						</div>
						<div class="component-target-detail-intro__right__top__content__status__row" v-else>
							<label
								class="component-target-detail-intro__right__top__content__status__row__label">当前价格：</label>
							<span
								class="component-target-detail-intro__right__top__content__status__row__price">--</span>
						</div>
						<div class="component-target-detail-intro__right__top__content__status__row">
							<el-button type="primary"
								class="component-target-detail-intro__right__top__content__button center"
								disabled>暂时无法报名</el-button>
						</div>
					</div>

					<!-- 用户未报名 && 报名时间已截止 -->
					<div class="component-target-detail-intro__right__top__content__status"
						v-else-if="info?.enroll_status == 0 && info?.now_time >= info?.enroll_stop_time && info?.enroll_stop_time != 0">
						<div class="component-target-detail-intro__right__top__content__status__row" v-if="canSeeInfo">
							<label
								class="component-target-detail-intro__right__top__content__status__row__label">当前价格：</label>
							<span class="component-target-detail-intro__right__top__content__status__row__price">¥{{
					formatPrice(info?.quote_data?.[0]?.price ?? info?.start_price) }}元</span>
							<span class="component-target-detail-intro__right__top__content__status__row__price"
								v-if="info.auction_type == 20">/{{ info.auction_count_unit }}</span>

						</div>
						<div class="component-target-detail-intro__right__top__content__status__row" v-else>
							<label
								class="component-target-detail-intro__right__top__content__status__row__label">当前价格：</label>
							<span
								class="component-target-detail-intro__right__top__content__status__row__price">--</span>
						</div>
						<div class="component-target-detail-intro__right__top__content__status__row">
							<el-button type="primary"
								class="component-target-detail-intro__right__top__content__button center"
								disabled>报名时间已截止</el-button>
						</div>
					</div>

					<!-- 用户未报名 && 没报名过同拍卖会下的其它标的 && 还未截止报名 -->
					<div class="component-target-detail-intro__right__top__content__status"
						v-else-if="info?.enroll_status == 0 && !info?.auction_enroll_exists && (info?.now_time < info?.enroll_stop_time || info?.enroll_stop_time == 0)">
						<div class="component-target-detail-intro__right__top__content__status__row" v-if="canSeeInfo">
							<label
								class="component-target-detail-intro__right__top__content__status__row__label">当前价格：</label>
							<span class="component-target-detail-intro__right__top__content__status__row__price">¥{{
					formatPrice(info?.quote_data?.[0]?.price ?? info?.start_price) }}元</span>
							<span class="component-target-detail-intro__right__top__content__status__row__price"
								v-if="info.auction_type == 20">/{{ info.auction_count_unit }}</span>
						</div>
						<div class="component-target-detail-intro__right__top__content__status__row" v-else>
							<label
								class="component-target-detail-intro__right__top__content__status__row__label">当前价格：</label>
							<span
								class="component-target-detail-intro__right__top__content__status__row__price">--</span>
						</div>
						<div class="component-target-detail-intro__right__top__content__status__row">
							<el-button type="primary"
								class="component-target-detail-intro__right__top__content__button center"
								@click="handleEnroll">立即报名</el-button>
						</div>
					</div>

					<!-- 用户未报名 && 报名过同拍卖会下的其它标的 && 还未截止报名 -->
					<div class="component-target-detail-intro__right__top__content__status"
						v-else-if="info?.enroll_status == 0 && info?.auction_enroll_exists && (info?.now_time < info?.enroll_stop_time || info?.enroll_stop_time == 0)">
						<div class="component-target-detail-intro__right__top__content__status__row" v-if="canSeeInfo">
							<label
								class="component-target-detail-intro__right__top__content__status__row__label">当前价格：</label>
							<span class="component-target-detail-intro__right__top__content__status__row__price">¥{{
					formatPrice(info?.quote_data?.[0]?.price ?? info?.start_price) }}元</span>
							<span class="component-target-detail-intro__right__top__content__status__row__price"
								v-if="info.auction_type == 20">/{{ info.auction_count_unit }}</span>

						</div>
						<div class="component-target-detail-intro__right__top__content__status__row" v-else>
							<label
								class="component-target-detail-intro__right__top__content__status__row__label">当前价格：</label>
							<span
								class="component-target-detail-intro__right__top__content__status__row__price">--</span>
						</div>
						<div class="component-target-detail-intro__right__top__content__status__row">
							<el-button type="primary"
								class="component-target-detail-intro__right__top__content__button center"
								disabled>立即报名</el-button>
						</div>
					</div>

					<!-- 报名审核中 -->
					<div class="component-target-detail-intro__right__top__content__status"
						v-else-if="info?.enroll_status == 10">
						<div class="component-target-detail-intro__right__top__content__status__row" v-if="canSeeInfo">
							<label
								class="component-target-detail-intro__right__top__content__status__row__label">当前价格：</label>
							<span class="component-target-detail-intro__right__top__content__status__row__price">¥{{
					formatPrice(info?.quote_data?.[0]?.price ?? info?.start_price) }}元</span>
							<span class="component-target-detail-intro__right__top__content__status__row__price"
								v-if="info.auction_type == 20">/{{ info.auction_count_unit }}</span>

						</div>
						<div class="component-target-detail-intro__right__top__content__status__row" v-else>
							<label
								class="component-target-detail-intro__right__top__content__status__row__label">当前价格：</label>
							<span
								class="component-target-detail-intro__right__top__content__status__row__price">--</span>
						</div>
						<div class="component-target-detail-intro__right__top__content__status__row">
							<el-button type="primary"
								class="component-target-detail-intro__right__top__content__button center"
								disabled>报名审核中</el-button>
						</div>
					</div>

					<!-- 标的未开始 && 报名审核已通过 -->
					<div class="component-target-detail-intro__right__top__content__status"
						v-else-if="info?.status == 30 && info?.enroll_status == 20">
						<div class="component-target-detail-intro__right__top__content__status__row" v-if="canSeeInfo">
							<label
								class="component-target-detail-intro__right__top__content__status__row__label">当前价格：</label>
							<span class="component-target-detail-intro__right__top__content__status__row__price">¥{{
					formatPrice(info?.quote_data?.[0]?.price ?? info?.start_price) }}元</span>
							<span class="component-target-detail-intro__right__top__content__status__row__price"
								v-if="info.auction_type == 20">/{{ info.auction_count_unit }}</span>

						</div>
						<div class="component-target-detail-intro__right__top__content__status__row" v-else>
							<label
								class="component-target-detail-intro__right__top__content__status__row__label">当前价格：</label>
							<span
								class="component-target-detail-intro__right__top__content__status__row__price">--</span>
						</div>
						<div class="component-target-detail-intro__right__top__content__status__row">
							<el-button type="primary"
								class="component-target-detail-intro__right__top__content__button center"
								disabled>等待拍卖开始</el-button>
						</div>
					</div>

					<!-- 可以出价: 拍卖会自由竞拍中或限时竞拍中 && 报名成功并且已通过 -->
					<div class="component-target-detail-intro__right__top__content__status"
						v-else-if="[40, 50].includes(info?.status) && info?.enroll_status == 20">
						<!-- 有人出价 -->
						<div class="component-target-detail-intro__right__top__content__status__row"
							v-if="info?.quote_data?.[0]">
							<label
								class="component-target-detail-intro__right__top__content__status__row__label">当前价格：</label>
							<span class="component-target-detail-intro__right__top__content__status__row__price">¥{{
					formatPrice(info?.quote_data?.[0].price) }}元</span>
							<span class="component-target-detail-intro__right__top__content__status__row__price"
								v-if="info.auction_type == 20">/{{ info.auction_count_unit }}</span>

							<span class="component-target-detail-intro__right__top__content__status__row__code">出价人：{{
					info?.quote_data?.[0]?.nickname }}</span>
						</div>

						<!-- 无人出价 -->
						<div class="component-target-detail-intro__right__top__content__status__row" v-else>
							<label
								class="component-target-detail-intro__right__top__content__status__row__label">当前价格：</label>
							<span class="component-target-detail-intro__right__top__content__status__row__price">¥{{
					formatPrice(info?.start_price) }}元</span>
							<span class="component-target-detail-intro__right__top__content__status__row__price"
								v-if="info.auction_type == 20">/{{ info.auction_count_unit }}</span>

							<span
								class="component-target-detail-intro__right__top__content__status__row__code">出价人：无</span>
						</div>

						<!-- 出价 -->
						<div class="component-target-detail-intro__right__top__content__status__row">
							<label class="component-target-detail-intro__right__top__content__status__row__label">出价
								：</label>
							<span class="component-target-detail-intro__right__top__content__status__row__price">¥{{
					formatPrice(myPrice) }}</span>
							<div class="component-target-detail-intro__right__top__content__status__row__operate">
								<span
									class="component-target-detail-intro__right__top__content__status__row__operate__item plus zn-iconfont zn-iconfont-jian"
									:class="{ 'is-disabled': isDisabledJianButton }" @click="handleJian"></span>
								<span
									class="component-target-detail-intro__right__top__content__status__row__operate__item subtract zn-iconfont zn-iconfont-jia"
									:class="{ 'is-disabled': isDisabledJiaButton }" @click="handleJia"></span>
							</div>
						</div>

						<!-- 身份拍号 -->
						<div class="component-target-detail-intro__right__top__content__status__row idcode">
							<label
								class="component-target-detail-intro__right__top__content__status__row__label"></label>
							<span
								class="component-target-detail-intro__right__top__content__status__row__value">您的竞拍号码：{{
					info?.nickname }}</span>
						</div>

						<!-- 确定出价 -->
						<div class="component-target-detail-intro__right__top__content__status__row">
							<label
								class="component-target-detail-intro__right__top__content__status__row__label"></label>
							<div class="component-target-detail-intro__right__top__content__status__row__value">
								<el-button type="primary"
									class="component-target-detail-intro__right__top__content__button"
									@click="$emit('confirmOfferPrice', myPrice)" :disabled="info?.pause_status == 10"
									v-debounce="'报价中'">确定出价</el-button>
							</div>
						</div>
					</div>

					<!-- 等待拍卖师确认 && 报名审核已通过 -->
					<div class="component-target-detail-intro__right__top__content__status"
						v-else-if="info?.status == 60 && info?.enroll_status == 20">
						<div class="component-target-detail-intro__right__top__content__status__row">
							<label
								class="component-target-detail-intro__right__top__content__status__row__label">当前价格：</label>
							<span class="component-target-detail-intro__right__top__content__status__row__price">¥{{
					formatPrice(info?.quote_data?.[0]?.price ?? info?.start_price) }}</span>
						</div>
						<div class="component-target-detail-intro__right__top__content__status__row">
							<el-button type="primary"
								class="component-target-detail-intro__right__top__content__button center"
								disabled>等待拍卖师确认</el-button>
						</div>
					</div>
				</div>
				<!-- #E 竞拍操作区域 -->

				<!-- 水印图 -->
				<img class="watermark" :src="require('@/assets/images/state-closed.png')" alt=""
					v-if="targetStatus[info?.status]?.name === '已成交'" />
			</div>
			<!-- #E 右侧内容 - TOP -->

			<!-- #S 中间内容 - MIDDLE -->
			<template v-if="info?.commission_type == 40">
				<div class="component-target-detail-intro__right__tips mb5">
					<i class="component-target-detail-intro__right__tips__icon zn-iconfont zn-iconfont-xinxi-quan"></i>
					<p class="component-target-detail-intro__right__tips__value">成交后，需向拍卖企业另付拍卖佣金，具体规则请联系拍卖企业。</p>
				</div>
			</template>
			<div class="component-target-detail-intro__right__tips"
				v-if="info?.commission_type != 20 && (info?.commission_scale_1 || info?.commission_price)">
				<i class="component-target-detail-intro__right__tips__icon zn-iconfont zn-iconfont-xinxi-quan"></i>
				<p class="component-target-detail-intro__right__tips__value" v-if="info?.commission_type == 10">
					<span v-if="info?.commission_scale_1">成交后，需向拍卖企业另付{{ info?.commission_scale_1 }}%的佣金</span>
					<span v-if="info?.commission_scale_1 && info?.commission_price">，</span>
					<span v-if="info?.commission_price">佣金上限{{ info?.commission_price }}元。</span>
				</p>
				<!-- <p class="component-target-detail-intro__right__tips__value" v-if="info?.commission_type == 20">
					<p v-for="item in info.commission_scale_2" :key="item.scale">
						成交后，需向拍卖企业另付{{ info?.commission_scale_1 }}%-{{ info?.commission_scale_2 }}%的佣金
					</p>
				</p> -->
				<p class="component-target-detail-intro__right__tips__value"
					v-if="info?.commission_type == 30 && info?.commission_price">
					成交后，需向拍卖企业另付¥{{ formatPrice(info?.commission_price) }}元的佣金
				</p>
				<p class="component-target-detail-intro__right__tips__value" v-if="info?.commission_type == 40">
					成交后，需向拍卖企业另付拍卖佣金，具体规则请联系拍卖企业
				</p>
			</div>
			<template v-if="info?.commission_type == 20">
				<div class="component-target-detail-intro__right__tips mb5" v-for="item in info.commission_scale_2"
					:key="item.scale">
					<i class="component-target-detail-intro__right__tips__icon zn-iconfont zn-iconfont-xinxi-quan"></i>
					<p class="component-target-detail-intro__right__tips__value">
						<!-- 成交后，需向拍卖企业另付{{ info?.commission_scale_1 }}%-{{ info?.commission_scale_2 }}%的佣金 -->
						&gt;{{ item.money_start }}元，&lt;{{ item.money_end }}元，佣金比例为{{ item.scale }}%<span
							v-if="info?.commission_price">，佣金上限为{{ info?.commission_price }}元</span>。
					</p>
				</div>
			</template>

			<!-- #E 中间内容 - MIDDLE -->

			<!-- #S 右侧内容 - BOTTOM -->

			<!-- 可以查看信息: (已登录&&未禁止查看信息) || (已登录&&报名审核通过&&未禁止查看信息) -->
			<div class="component-target-detail-intro__right__bottom" v-if="canSeeInfo">
				<div class="component-target-detail-intro__right__bottom__row">
					<div class="component-target-detail-intro__right__bottom__row__item" style="min-width: 130px">
						<label class="component-target-detail-intro__right__bottom__row__item__label">起拍价：</label>
						<span class="component-target-detail-intro__right__bottom__row__item__value">{{
					formatPrice(info?.start_price) }}元</span>
						<el-popover trigger="hover" :content="cashToChinese(info?.start_price)" placement="top"
							width="160" popper-class="popover-center">
							<span class="popover-block" slot="reference"></span>
						</el-popover>
					</div>
					<div class="component-target-detail-intro__right__bottom__row__item" style="min-width: 140px">
						<label class="component-target-detail-intro__right__bottom__row__item__label">评估价：</label>
						<span class="component-target-detail-intro__right__bottom__row__item__value"
							v-if="info?.valuation_price != 0">{{ formatPrice(info?.valuation_price) }}元</span>
						<span class="component-target-detail-intro__right__bottom__row__item__value" v-else>无</span>
						<el-popover trigger="hover" :content="cashToChinese(info?.valuation_price)" placement="top"
							v-if="info?.valuation_price != 0" width="160" popper-class="popover-center">
							<span class="popover-block" slot="reference"></span>
						</el-popover>
					</div>
					<div class="component-target-detail-intro__right__bottom__row__item">
						<label class="component-target-detail-intro__right__bottom__row__item__label">自由竞拍时间：</label>
						<span class="component-target-detail-intro__right__bottom__row__item__value">{{
					secondsToHoursMinutesSeconds(info?.free_bidding_time) }}</span>
					</div>
				</div>
				<div class="component-target-detail-intro__right__bottom__row">
					<div class="component-target-detail-intro__right__bottom__row__item" style="min-width: 130px">
						<label class="component-target-detail-intro__right__bottom__row__item__label">保证金：</label>
						<span class="component-target-detail-intro__right__bottom__row__item__value">{{
					formatPrice(info?.bond) }}元</span>
						<el-popover trigger="hover" :content="cashToChinese(info?.bond)" placement="top" width="160"
							popper-class="popover-center">
							<span class="popover-block" slot="reference"></span>
						</el-popover>
					</div>
					<div class="component-target-detail-intro__right__bottom__row__item" style="min-width: 140px">
						<label class="component-target-detail-intro__right__bottom__row__item__label">保留价：</label>
						<span class="component-target-detail-intro__right__bottom__row__item__value"
							v-if="info?.has_reserve_price == 10">有</span>
						<span class="component-target-detail-intro__right__bottom__row__item__value"
							v-else-if="info?.has_reserve_price == 20">无</span>
						<span class="component-target-detail-intro__right__bottom__row__item__value" v-else>--</span>
						<el-popover trigger="hover" content="拍卖企业设定的标的最低成交价格，最终的价格低于该价格即流拍" placement="top" width="160">
							<span class="popover-block" slot="reference"></span>
						</el-popover>
					</div>

					<div class="component-target-detail-intro__right__bottom__row__item">
						<label class="component-target-detail-intro__right__bottom__row__item__label">限时竞拍时间：</label>
						<span class="component-target-detail-intro__right__bottom__row__item__value">{{
					secondsToHoursMinutesSeconds(info?.limited_bidding_time)
				}}</span>
					</div>
				</div>
				<div class="component-target-detail-intro__right__bottom__row">
					<div class="component-target-detail-intro__right__bottom__row__item" style="min-width: 270px">
						<label class="component-target-detail-intro__right__bottom__row__item__label"
							v-if="info?.auction_mode === 10">加价幅度：</label>
						<label class="component-target-detail-intro__right__bottom__row__item__label"
							v-if="info?.auction_mode === 20">减价幅度：</label>
						<span class="component-target-detail-intro__right__bottom__row__item__value">{{
					formatPrice(info?.price_range) }}元</span>
						<el-popover trigger="hover" :content="cashToChinese(info?.price_range)" placement="top"
							width="160" popper-class="popover-center">
							<span class="popover-block" slot="reference"></span>
						</el-popover>
					</div>
					<div class="component-target-detail-intro__right__bottom__row__item">
						<label class="component-target-detail-intro__right__bottom__row__item__label">报名限制：</label>
						<span class="component-target-detail-intro__right__bottom__row__item__value"
							v-if="info?.enroll_limit == 10">不限制</span>
						<span class="component-target-detail-intro__right__bottom__row__item__value"
							v-else-if="info?.enroll_limit == 20">仅限个人报名</span>
						<span class="component-target-detail-intro__right__bottom__row__item__value"
							v-else-if="info?.enroll_limit == 30">仅限机构报名</span>
						<span class="component-target-detail-intro__right__bottom__row__item__value" v-else>不限制</span>
					</div>
				</div>
				<div class="component-target-detail-intro__right__bottom__row"
					v-if="info?.auction_type == 20 || info?.enroll_stop_time">
					<!-- 按单价拍卖时才展示此字段 -->
					<div class="component-target-detail-intro__right__bottom__row__item" style="min-width: 150px"
						v-if="info?.auction_type == 20">
						<label class="component-target-detail-intro__right__bottom__row__item__label">数量：</label>
						<span class="component-target-detail-intro__right__bottom__row__item__value"
							v-if="info?.auction_count_type == 10">{{
					info?.auction_count
				}}</span>
						<span class="component-target-detail-intro__right__bottom__row__item__value"
							v-else-if="info?.auction_count_type == 20">以实际数量为准</span>
						<span class="component-target-detail-intro__right__bottom__row__item__value" v-else>--</span>
					</div>
					<!-- 按单价拍卖时才展示此字段 -->
					<div class="component-target-detail-intro__right__bottom__row__item" style="min-width: 70px"
						v-if="info?.auction_type == 20">
						<label class="component-target-detail-intro__right__bottom__row__item__label">单位：</label>
						<span class="component-target-detail-intro__right__bottom__row__item__value">{{
					info?.auction_count_unit }}</span>
					</div>
					<div class="component-target-detail-intro__right__bottom__row__item" v-if="info?.enroll_stop_time">
						<label class="component-target-detail-intro__right__bottom__row__item__label">报名截止日期：</label>
						<span class="component-target-detail-intro__right__bottom__row__item__value">{{
					dayjs.unix(info?.enroll_stop_time).format("YYYY-MM-DD HH:mm:ss")
				}}</span>
					</div>
				</div>
				<!-- 网络拍：没有拍卖师，隐藏此行内容 -->
				<div class="component-target-detail-intro__right__bottom__row" v-if="info?.auction_type_1 == 10">
					<div class="component-target-detail-intro__right__bottom__row__item" style="min-width: 110px">
						<label class="component-target-detail-intro__right__bottom__row__item__label">拍卖师：</label>
						<span class="component-target-detail-intro__right__bottom__row__item__value">{{
					info?.auctioneer_name }}</span>
					</div>
					<div class="component-target-detail-intro__right__bottom__row__item" style="min-width: 120px">
						<label class="component-target-detail-intro__right__bottom__row__item__label">编号：</label>
						<span class="component-target-detail-intro__right__bottom__row__item__value">{{
					info?.auctioneer_cert_no }}</span>
					</div>
					<div class="component-target-detail-intro__right__bottom__row__item">
						<div class="component-target-detail-intro__right__bottom__row__item__value link-button">
							点击查看证件
							<el-image class="image" :src="info?.auctioneer_cert_img"
								:preview-src-list="[info?.auctioneer_cert_img]"> </el-image>
						</div>
					</div>
				</div>
				<div class="component-target-detail-intro__right__bottom__row">
					<div class="component-target-detail-intro__right__bottom__row__item">
						<label class="component-target-detail-intro__right__bottom__row__item__label">所在地区：</label>
						<span class="component-target-detail-intro__right__bottom__row__item__value">{{ info?.address
							}}</span>
					</div>
				</div>
				<!-- 2024-07-25新增字段 -->
				<div class="component-target-detail-intro__right__bottom__row" v-if="info?.enroll_material">
					<div class="component-target-detail-intro__right__bottom__row__item">
						<label class="component-target-detail-intro__right__bottom__row__item__label">报名材料名称：</label>
						<span class="component-target-detail-intro__right__bottom__row__item__value">{{ info?.enroll_material }}</span>
					</div>
				</div>
			</div>
			<!-- 不可查看信息 -->
			<div class="component-target-detail-intro__right__bottom" v-else>
				<div class="component-target-detail-intro__right__bottom__row">
					<div class="component-target-detail-intro__right__bottom__row__item" style="min-width: 130px">
						<label class="component-target-detail-intro__right__bottom__row__item__label">起拍价：</label>
						<span class="component-target-detail-intro__right__bottom__row__item__value">--</span>
						<el-popover trigger="hover" content="报名审核通过后查看" placement="top" width="160"
							popper-class="popover-center">
							<span class="popover-block" slot="reference"></span>
						</el-popover>
					</div>
					<div class="component-target-detail-intro__right__bottom__row__item" style="min-width: 140px">
						<label class="component-target-detail-intro__right__bottom__row__item__label">评估价：</label>
						<span class="component-target-detail-intro__right__bottom__row__item__value">--</span>
						<el-popover trigger="hover" content="报名审核通过后查看" placement="top" width="160"
							popper-class="popover-center">
							<span class="popover-block" slot="reference"></span>
						</el-popover>
					</div>
					<div class="component-target-detail-intro__right__bottom__row__item">
						<label class="component-target-detail-intro__right__bottom__row__item__label">自由竞拍时间：</label>
						<span class="component-target-detail-intro__right__bottom__row__item__value">--</span>
					</div>
				</div>
				<div class="component-target-detail-intro__right__bottom__row">
					<div class="component-target-detail-intro__right__bottom__row__item" style="min-width: 130px">
						<label class="component-target-detail-intro__right__bottom__row__item__label">保证金：</label>
						<span class="component-target-detail-intro__right__bottom__row__item__value">--</span>
						<el-popover trigger="hover" content="报名审核通过后查看" placement="top" width="160"
							popper-class="popover-center">
							<span class="popover-block" slot="reference"></span>
						</el-popover>
					</div>
					<div class="component-target-detail-intro__right__bottom__row__item" style="min-width: 140px">
						<label class="component-target-detail-intro__right__bottom__row__item__label">保留价：</label>
						<span class="component-target-detail-intro__right__bottom__row__item__value">--</span>
						<el-popover trigger="hover" content="拍卖企业设定的标的最低成交价格，最终的价格低于该价格即流拍" placement="top" width="160">
							<span class="popover-block" slot="reference"></span>
						</el-popover>
					</div>
					<div class="component-target-detail-intro__right__bottom__row__item">
						<label class="component-target-detail-intro__right__bottom__row__item__label">限时竞拍时间：</label>
						<span class="component-target-detail-intro__right__bottom__row__item__value">--</span>
					</div>
				</div>
				<div class="component-target-detail-intro__right__bottom__row">
					<div class="component-target-detail-intro__right__bottom__row__item" style="min-width: 270px">
						<label class="component-target-detail-intro__right__bottom__row__item__label">加价幅度：</label>
						<span class="component-target-detail-intro__right__bottom__row__item__value">--</span>
						<el-popover trigger="hover" content="报名审核通过后查看" placement="top" width="160"
							popper-class="popover-center">
							<span class="popover-block" slot="reference"></span>
						</el-popover>
					</div>
					<div class="component-target-detail-intro__right__bottom__row__item">
						<label class="component-target-detail-intro__right__bottom__row__item__label">报名限制：</label>
						<span class="component-target-detail-intro__right__bottom__row__item__value">--</span>
					</div>
				</div>
				<div class="component-target-detail-intro__right__bottom__row">
					<!-- 按单价拍卖时才展示此字段 -->
					<div class="component-target-detail-intro__right__bottom__row__item" style="min-width: 150px"
						v-if="info?.auction_type == 20">
						<label class="component-target-detail-intro__right__bottom__row__item__label">数量：</label>
						<span class="component-target-detail-intro__right__bottom__row__item__value">--</span>
					</div>
					<!-- 按单价拍卖时才展示此字段 -->
					<div class="component-target-detail-intro__right__bottom__row__item" style="min-width: 70px"
						v-if="info?.auction_type == 20">
						<label class="component-target-detail-intro__right__bottom__row__item__label">单位：</label>
						<span class="component-target-detail-intro__right__bottom__row__item__value">--</span>
					</div>
					<div class="component-target-detail-intro__right__bottom__row__item" v-if="info?.enroll_stop_time">
						<label class="component-target-detail-intro__right__bottom__row__item__label">报名截止日期：</label>
						<span class="component-target-detail-intro__right__bottom__row__item__value">{{
					dayjs.unix(info?.enroll_stop_time).format("YYYY-MM-DD HH:mm:ss")
				}}</span>
					</div>
				</div>
				<!-- 网络拍：没有拍卖师，隐藏此行内容 -->
				<div class="component-target-detail-intro__right__bottom__row" v-if="info?.auction_type_1 == 10">
					<div class="component-target-detail-intro__right__bottom__row__item" style="min-width: 110px">
						<label class="component-target-detail-intro__right__bottom__row__item__label">拍卖师：</label>
						<span class="component-target-detail-intro__right__bottom__row__item__value">--</span>
					</div>
					<div class="component-target-detail-intro__right__bottom__row__item" style="min-width: 120px">
						<label class="component-target-detail-intro__right__bottom__row__item__label">编号：</label>
						<span class="component-target-detail-intro__right__bottom__row__item__value">--</span>
					</div>
					<!-- <div class="component-target-detail-intro__right__bottom__row__item">
						<div class="component-target-detail-intro__right__bottom__row__item__value link-button">
							点击查看证件
							<el-image class="image" :src="info?.auctioneer_cert_img" :preview-src-list="[info?.auctioneer_cert_img]"> </el-image>
						</div>
					</div> -->
				</div>
				<div class="component-target-detail-intro__right__bottom__row">
					<div class="component-target-detail-intro__right__bottom__row__item">
						<label class="component-target-detail-intro__right__bottom__row__item__label">所在地区：</label>
						<span class="component-target-detail-intro__right__bottom__row__item__value">--</span>
					</div>
				</div>
			</div>
			<!-- #E 右侧内容 - BOTTOM -->
		</div>
		<!-- #E 右侧内容 -->
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import { dealBigMoney, calcDateDiff } from "@/utils/func.js";
import { EnumTargetStatus } from "@/const.js";
import TargetStatus from "@/components/TargetStatus.vue";
export default {
	name: "TargetDetailIntro",
	components: { TargetStatus },
	props: {
		// 标的详情
		info: { type: Object, default: () => ({}) },
		// 是否可以查看信息
		canSeeInfo: { type: Boolean, default: false },
	},
	data() {
		return {
			swiperInstance: null, // swiper实例
			current: 0, // 当前选中的slide下标
			targetStatus: EnumTargetStatus,
			// 倒计时显示内容
			countdown: {
				DD: "00",
				D: 0,
				hh: "00",
				h: 0,
				mm: "00",
				m: 0,
				ss: "00",
				s: 0,
			},
			myPrice: 0, // 我的预出价价格
			nowTime: 0, // 服务器当前时间
			timer: null, // 倒计时定时器
		};
	},
	computed: {
		...mapGetters(["userinfo"]),

		// 计算是否禁用减价按钮
		isDisabledJianButton() {
			// 如果是降价拍形式,减号按钮一直可用
			if (this.info.auction_mode == 20) return false;

			// 该标的为增价拍类型
			// 如果有最后一次出价数据
			if (this.info?.quote_data?.[0]) {
				// 如果我减去1次价格后比最后一次价格低,就禁用减按钮
				if (this.myPrice - this.info.price_range <= this.info?.quote_data[0].price) return true;
				else return false;
			}
			// 否则,如果没有最后一次出价数据
			else {
				// 如果我减去1次价格后比起拍价还低,就禁用减按钮
				if (this.myPrice - this.info.price_range < this.info.start_price) return true;
				else return false;
			}
		},

		// 计算是否禁用加价按钮
		isDisabledJiaButton() {
			// 如果是增价拍形式,加号按钮一直可用
			if (this.info.auction_mode == 10) return false;

			// 该标的为降价拍类型
			// 如果有最后一次出价数据
			if (this.info?.quote_data?.[0]) {
				// 如果我加上1次价格后比最后一次价格高,就禁用减按钮
				if (this.myPrice + this.info.price_range >= this.info?.quote_data[0].price) return true;
				else return false;
			}
			// 否则,如果没有最后一次出价数据
			else {
				// 如果我加上1次价格后比起拍价还高,就禁用减按钮
				if (this.myPrice + this.info.price_range > this.info.start_price) return true;
				else return false;
			}
		},
	},
	watch: {
		// 监听轮播图数据
		"info.images": {
			handler() {
				this.initOrUpdateSwiper();
			},
			deep: true,
			immediate: true,
		},

		// 监听标的详情数据
		"info": {
			handler(matter) {
				// 服务器当前时间更新了
				this.nowTime = matter?.now_time;

				// 标的正在自由竞拍中或限时竞拍中
				if (matter?.status == 40 || matter?.status == 50) {
					// 标的已暂停
					if (matter?.pause_status == 10) {
						console.log("计算一次倒计时数据1", this.nowTime, matter?.count_down_time);
						// 计算一次倒计时数据
						this.countdown = calcDateDiff(this.dayjs.unix(this.nowTime), this.dayjs.unix(matter?.count_down_time));
						console.log("计算一次倒计时数据2", this.countdown);
						// 暂停倒计时
						this.clearTimer();
					}
					// 标的未暂停
					else {
						// 初始化倒计时
						this.setTimer(matter?.count_down_time);
					}
				}

				// 重新计算我的出价
				this.calcMyPrice();
			},
			deep: true,
			immediate: true,
		},

		// 监听最后出价数据
		"info.quote_data": {
			handler(newList) {
				// 如果有最后一次出价数据
				if (newList?.[0]) {
					console.log("有最后一次出价数据", newList[0]);
					// 当前标的为增价拍类型
					if (this.info?.auction_mode == 10) {
						// 如果最后一次出价 > 我当前的预出价
						if (newList[0].price >= this.myPrice) {
							// 更新我的预出价 = 最后一次出价 + 加价幅度
							this.myPrice = newList[0].price + this.info.price_range;
						}
					}

					// 当前标的为降价拍类型
					else if (this.info?.auction_mode == 20) {
						console.log("当前标的为降价拍类型");
						// 如果最后一次出价 < 我当前的预出价
						if (newList[0].price <= this.myPrice) {
							// 更新我的预出价 = 最后一次出价 - 加价幅度
							this.myPrice = newList[0].price - this.info.price_range;
						}
					}
				}
				// 否则,如果没有最后一次出价数据
				else {
					// 当前标的为增价拍类型
					if (this.info?.auction_mode == 10) {
						// 我的当前预出价 >= 起拍价 == 不用修改我的预出价
						if (this.myPrice >= this.info?.start_price) return;
						// 我的预出价价格 = 起拍价格
						this.myPrice = this.info?.start_price;
					}

					// 当前标的为降价拍类型
					else if (this.info?.auction_mode == 20) {
						// 我的当前预出价 <= 起拍价 == 不用修改我的预出价
						if (this.myPrice <= this.info?.start_price) return;
						// 我的预出价价格 = 起拍价格
						this.myPrice = this.info?.start_price;
					}
				}
			},
			deep: true,
			immediate: true,
		},
	},
	mounted() {
		// 计算我的出价
		this.calcMyPrice();
	},
	beforeDestroy() {
		this.clearTimer();
	},
	methods: {
		clickHandlers(){
			this.$emit("share")
		},
		/**
		 * @description: 计算我的出价
		 * @return {*}
		 */
		calcMyPrice() {
			// 计算我的出价默认值
			// 1. 有人出过价
			if (this.info?.quote_data?.length) {
				// 当前标的为增价拍
				if (this.info?.auction_mode == 10) {
					// 我的当前预出价 > 最后一次出价
					if (this.myPrice > this.info.quote_data[0].price) {
						// （计算我的当前预出价 - 最后一次出价） / 加价幅度 = 的整数倍数
						let multiple = Math.floor((this.myPrice - this.info.quote_data[0].price) / this.info.price_range);
						multiple = multiple <= 0 ? 1 : multiple;
						this.myPrice = this.info.quote_data[0].price + multiple * this.info.price_range;
					}
					// 否则，将我的预出价设置为最后一次出价 + 加价幅度
					else {
						this.myPrice = this.info.quote_data[0].price + this.info.price_range;
					}
				}
				// 当前标的为降价拍
				else if (this.info?.auction_mode == 20) {
					// 我的当前预出价 < 最后一次出价
					if (this.myPrice < this.info.quote_data[0].price) {
						// （计算我的当前预出价 - 最后一次出价） / 加价幅度 = 的整数倍数
						let multiple = Math.floor((this.myPrice - this.info.quote_data[0].price) / this.info.price_range);
						multiple = multiple <= 0 ? 1 : multiple;
						this.myPrice = this.info.quote_data[0].price - multiple * this.info.price_range;
					}
					// 否则，将我的预出价设置为最后一次出价 - 加价幅度
					else {
						this.myPrice = this.info.quote_data[0].price - this.info.price_range;
					}
				}
			}
			// 2. 无人出过价
			else {
				// 将我的预出价设置为起拍价
				this.myPrice = this.info?.start_price;
			}
		},

		/**
		 * !初始化或更新轮播图
		 */
		initOrUpdateSwiper() {
			this.$nextTick(() => {
				let timer = setTimeout(() => {
					clearTimeout(timer);
					if (!this.swiperInstance) this.initSwiper();
					else this.swiperInstance?.update();
				}, 200);
			});
		},

		/**
		 * !实例化swiper
		 */
		initSwiper() {
			this.swiperInstance = new this.Swiper(".component-target-detail-intro__left__swiper-container", {
				slidesPerView: 4,
				spaceBetween: 7,
				loop: false,
				loopPreventsSlide: true,
				slideToClickedSlide: false,
				navigation: {
					nextEl: ".component-target-detail-intro__left__swiper-arrow.arrow-next",
					prevEl: ".component-target-detail-intro__left__swiper-arrow.arrow-prev",
				},
			});
		},

		/**
		 * !轮播图slide点击事件
		 * @param {Number} index 被点击slide的下标
		 */
		handleSlideClick(index) {
			this.swiperInstance?.slideTo(index);
			this.current = index;
		},

		/**
		 * !设置倒计时定时器
		 * @param {Number} endTime 倒计时结束时间,秒
		 */
		setTimer(endTime) {
			console.log("初始化倒计时", endTime);
			// 以防倒计时定时器清除不干净,每次都清除一下上次创建的倒计时定时器
			this.clearTimer();

			// 无效的开始或结束时间
			if (!this.nowTime || !endTime) {
				console.warn("无效的开始或结束时间");
				return;
			}

			// 设置倒计时定时器
			this.timer = setInterval(() => {
				// 倒计时计算结果
				this.countdown = calcDateDiff(this.dayjs.unix(this.nowTime + 1), this.dayjs.unix(this.info?.count_down_time));

				// 倒计时结束
				if (this.countdown?.seconds <= 0) {
					// 清除定时器
					this.clearTimer();
				}
				// 倒计时未结束
				else {
					// 开始时间加一秒
					this.nowTime = this.nowTime + 1;
				}
			}, 1000);
		},

		/**
		 * !清除倒计时定时器
		 */
		clearTimer() {
			if (this.timer) {
				clearInterval(this.timer);
				this.timer = null;
			}
		},

		/**
		 * !立即报名
		 */
		handleEnroll() {
			// 只有认证审核通过后才能报名
			if (this.info?.user_status == 40) {
				// 仅限个人报名
				if (this.info?.enroll_limit == 20) {
					// 当前用户为个人认证用户
					if (this.info?.user_auth_type == 1) {
						// 跳转到报名页面
						this.$router.push({ name: "TargetEnroll", query: { no: this.info?.no, title: this.info?.title, auction_no: this.info?.auction_no } });
					}
					// 否则,当前用户非个人认证用户
					else {
						this.$message.warning("当前标的仅限个人认证用户报名!");
					}
				}
				// 仅限企业报名
				else if (this.info?.enroll_limit == 30) {
					// 当前用户为企业认证用户
					if (this.info?.user_auth_type == 2) {
						// 跳转到报名页面
						this.$router.push({ name: "TargetEnroll", query: { no: this.info?.no, title: this.info?.title, auction_no: this.info?.auction_no } });
					}
					// 否则,当前用户非企业认证用户
					else {
						this.$message.warning("当前标的仅限企业认证用户报名!");
					}
				}
				// 不限制认证类型
				else if (this.info?.enroll_limit == 10) {
					// 跳转到报名页面
					this.$router.push({ name: "TargetEnroll", query: { no: this.info?.no, title: this.info?.title, auction_no: this.info?.auction_no } });
				}
			}

			// 认证审核失败
			else if (this.info?.user_status == 30) {
				this.$message.warning("您的认证审核失败,不可进行报名!");
			}

			// 认证审核中
			else if (this.info?.user_status == 20) {
				this.$message.warning("您的认证审核中,请审核通过后再进行报名!");
			}

			// 未认证
			else if (this.info?.user_status == 10) {
				this.$message.warning("您还未进行认证,请认证后再进行报名!");
			}
		},

		/**
		 * !减出价
		 */
		handleJian() {
			this.myPrice = this.myPrice - this.info?.price_range;
		},

		/**
		 * !加出价
		 */
		handleJia() {
			this.myPrice = this.myPrice + this.info?.price_range;
		},

		/**
		 * !金额转大写
		 */
		cashToChinese(price) {
			return dealBigMoney(price);
		},
	},
};
</script>

<style lang="scss" scoped>
.img-share {
	cursor: pointer;
	width: 40px;
	height: 40px;
	margin-left: 10px;
}

.component-target-detail-intro {
	background-color: #ffffff;
	padding: 30px 43px 28px 49px;
	display: flex;

	&__left {
		width: 294px;
		flex-shrink: 0;
		margin-right: 60px;
		position: relative;

		&__image-preview-box {
			padding-bottom: 100%;
			position: relative;
			background-color: #f5f5f5;
			overflow: hidden;
			margin-bottom: 16px;

			.image {
				position: absolute;
				width: 100%;
				height: 100%;
				display: block;
				left: 0;
				top: 0;
				object-fit: contain;
			}
		}

		&__swiper {
			display: flex;
			align-items: center;
			margin-bottom: 30px;
		}

		&__swiper-arrow {
			flex-shrink: 0;
			color: #c7c7c7;
			font-size: 26px;
			cursor: pointer;
			transition: 0.2s;

			&.arrow-prev {
				margin-right: 6px;
			}

			&.arrow-next {
				margin-left: 6px;
			}

			&.swiper-button-disabled {
				color: #999999;
			}

			&:not(.swiper-button-disabled):hover {
				color: var(--theme-color);
			}
		}

		&__swiper-container {
			flex: 1;
			overflow: hidden;

			.image-box {
				padding-bottom: 100%;
				overflow: hidden;
				position: relative;
				cursor: pointer;

				&::after {
					content: "";
					width: 100%;
					height: 100%;
					left: 0;
					top: 0;
					z-index: 2;
					position: absolute;
					border: 2px solid transparent;
					transition: 0.2s;
				}

				.image {
					position: absolute;
					width: 100%;
					height: 100%;
					display: block;
					left: 0;
					top: 0;
					object-fit: cover;
				}
			}

			.swiper-slide {
				overflow: hidden;
				max-width: 25%;
			}

			.swiper-slide.is-selected {
				.image-box {
					&::after {
						border-color: var(--theme-color);
					}
				}
			}
		}

		&__datas {
			display: flex;

			&__group {
				display: flex;
				align-items: center;
				line-height: 1;
				margin-right: 20px;

				&__icon {
					color: #c1c1c1;
					margin-right: 4px;
				}

				&.group-collection {
					cursor: pointer;
					user-select: none;
				}

				&.collection {
					color: var(--theme-color);

					.component-target-detail-intro__left__datas__group__icon {
						color: var(--theme-color);
					}
				}
			}
		}
	}

	&__right {
		flex: 1;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		position: relative;

		&__top {
			background-color: #f5f5f5;
			margin-bottom: 8px;
			position: relative;

			&__head {
				height: 33px;
				line-height: 33px;
				background-color: rgba(0, 0, 0, 0.8);
				color: #ffffff;
				display: flex;


				&__status {
					width: 80px;
					flex-shrink: 0;
					text-align: center;

					&.is-pause {
						/* background-color: var(--theme-color6); */
						background-color: #ed8726;
						position: relative;
						padding-left: 32px;
					}
				}

				&__countdown {
					flex: 1;
					padding-left: 35px;
					/* TODO：css */

				}
			}

			&__content {
				padding: 24px 36px;
				height: 200px;

				&__status__row {
					display: flex;
					line-height: 1;
					align-items: center;
					margin-bottom: 12px;

					&.idcode {
						margin-top: 18px;
						margin-bottom: 14px;
					}

					&__label {
						width: 70px;
						margin-right: 5px;
						flex-shrink: 0;
						text-align: right;
						display: inline-block;
					}

					&__value {
						color: #666666;
					}

					&__price {
						font-size: 24px;
						color: var(--theme-color);
						font-family: "MarkPro-Medium";
					}

					&__code {
						height: 24px;
						line-height: 24px;
						flex-shrink: 0;
						font-size: 12px;
						color: var(--theme-color);
						background-color: #ffffff;
						padding: 0 12px;
						border: 1px solid #e0e0e0;
						margin-left: 14px;
					}

					&__operate {
						margin-left: 14px;
						display: flex;
						background-color: #ffffff;
						border: 1px solid #e0e0e0;
						font-size: 12px;

						&__item {
							width: 24px;
							height: 24px;
							line-height: 24px;
							text-align: center;
							cursor: pointer;
							transition: 0.2s;

							&:first-child {
								width: 25px;
								border-right: 1px solid #e0e0e0;
							}

							&:hover {
								background-color: #f2f3f4;
							}

							&.is-disabled {
								pointer-events: none;
								background-color: var(--theme-color6);
							}
						}
					}
				}

				&__button {
					min-width: 125px;
					height: 42px;
					font-size: 16px;
					border-radius: 0;

					&.center {
						margin-left: auto;
						margin-right: auto;
						margin-top: 22px;
					}

					&.is-disabled {
						background-color: var(--theme-color6);
						border-color: var(--theme-color6);
					}
				}
			}

			.watermark {
				position: absolute;
				display: block;
				width: 81px;
				height: 81px;
				z-index: 3;
				right: 9px;
				bottom: 8px;
			}
		}

		&__tips {
			font-size: 12px;
			padding: 6px 16px;
			background-color: var(--theme-color-light);
			display: flex;

			color: var(--theme-color);

			&__icon {
				flex-shrink: 0;
				font-size: 14px;
				flex-shrink: 0;
				margin-top: 1.5px;
				margin-right: 5px;
			}

			&__value {
				flex: 1;
			}
		}

		&__bottom {
			padding-top: 12px;

			&__row {
				display: flex;
				line-height: 1;
				margin-bottom: 15px;

				&__item {
					display: flex;
					align-items: center;
					position: relative;

					&__label {
						color: #999999;
						flex-shrink: 0;
					}

					&__value.link-button {
						font-size: 12px;
						line-height: 1;
						height: 23px;
						line-height: 23px;
						padding: 0 9px;
						color: var(--theme-color);
						border: 1px solid var(--theme-color);
						transition: 0.2s;
						border-radius: 24px;
						cursor: pointer;
						user-select: none;
						position: relative;
						overflow: hidden;
						margin-left: 5px;

						&:hover {
							background-color: var(--theme-color-light);
						}

						.image {
							position: absolute;
							width: 100%;
							height: 100%;
							left: 0;
							top: 0;
							opacity: 0;
						}
					}

					.popover-block {
						display: block;
						position: absolute;
						width: 100%;
						height: 100%;
						left: 0;
						top: 0;
						z-index: 10;
						background-color: transparent;
						opacity: 0;
					}
				}
			}
		}
	}
}

.mb5 {
	margin-bottom: 5px;
}

.fix-zt {
	background: url(../assets/images/zt-img.png);
}

.fix-jx {
	background: url(../assets/images/jx-img.png);
}

.fixImgBox {
	background: url(../assets/images/zt-icon.png);
	width: 18px;
	height: 18px;
	position: absolute;
	top: 7px;
	left: 11px;
}

.fixBgs {
	/* background-color: transparent !important; */
	position: relative;
	/* background-color: red; */
	width: 90px !important;
}

.fixBgs::after {
	content: '';
	display: block;
	/* width: 10px;
	height: 10px; */
	position: absolute;
	top: 0;
	right: -22px;
	border-left: 13px solid #ed8726;
	border-right: 10px solid transparent;
	border-bottom: 0px solid #ed8726;
	border-top: 33px solid transparent;
}
</style>

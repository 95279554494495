/**
 * ****************************************************************************************************
 * Request Promise.catch 的回调函数
 * @param {Object} config 合并后的请求配置项
 * @param {Object} error 错误信息对象
 * @param {Function} reject Promise.reject回调函数
 * @return {Boolean} 该函数返回 true 则请求继续，否则，中断请求
 * ****************************************************************************************************
 */
import { handleCodes } from "./codes.js";
import { closeLoading, showFail } from "./functions.js";

export default function (config, error, reject) {
	console.error("接口请求失败:-> ", error);

	// 关闭loading
	if (config.loadingShow) closeLoading(config);

	// 是否需要显示fail提示
	if (config.failShow) showFail(config, error);

	// 状态码处理函数
	handleCodes(config, error?.response?.status);

	reject({
		code: error?.response?.status || -110,
		msg: config.failMsg || error?.response?.data?.errMsg || error?.response?.statusText || "服务器错误，请稍后再试!",
	});
}

<!-- 帮助中心左侧导航菜单组件 -->
<template>
	<div class="component-help-center-navs">
		<div class="content">
			<el-menu class="menus" :default-active="activeIndex">
				<template v-for="submenu in navs">
					<el-submenu :index="'_' + submenu.id" class="submenu" :key="'_' + submenu.id" v-if="submenu.children && submenu.children.length">
						<template slot="title">
							<div class="submenu-title">
								<span class="submenu-name">{{ submenu.name }}</span>
							</div>
						</template>
						<el-menu-item
							:index="'_' + submenu.id + '_' + item.id"
							class="item"
							v-for="item in submenu.children"
							:key="'_' + submenu.id + '_' + item.id"
							@click="$emit('click', submenu, item)"
							>{{ item.name }}</el-menu-item
						>
					</el-submenu>
					<el-menu-item :index="'_' + submenu.id" class="submenu submenu-not-children" :key="'_' + submenu.id" v-else @click="$emit('click', submenu, null)">{{ submenu.name }}</el-menu-item>
				</template>
			</el-menu>
		</div>
	</div>
</template>

<script>
export default {
	name: "HelpCenterNavs",
	props: {
		activeIndex: [String, Number], // 默认激活的菜单值
		defaultOpeneds: Array, // 默认展开项
		navs: Array, // 菜单列表
	},
	data() {
		return {};
	},
	mounted() {},
	methods: {},
};
</script>

<style lang="scss" scoped>
.component-help-center-navs {
	width: 190px;
	background-color: #ffffff;

	::v-deep {
		.el-menu {
			border-right: none;
		}

		.el-submenu__title {
			height: auto;
			line-height: 0;
			padding: 0;
		}

		.el-submenu.is-active .el-submenu__title {
			background-color: var(--theme-color);
			font-weight: bold;
		}

		.el-submenu.is-active .submenu-title {
			color: #ffffff;
		}

		.el-submenu__icon-arrow {
			font-size: 16px;
		}

		.el-submenu.is-active .el-submenu__icon-arrow {
			color: #ffffff;
		}

		.el-menu-item {
			font-size: 16px;
			padding: 6px 5px 6px 59px;
			padding-left: 59px !important;
			line-height: 24px;
			height: auto;
			min-width: 100px;

			&:first-child {
				margin-top: 10px;
			}

			&:last-child {
				margin-bottom: 10px;
			}

			.submenu-name {
				font-size: 16px;
				color: #666666;
			}
		}

		.el-menu.el-menu--inline {
			background-color: #f0f0f0;
		}
	}

	.submenu-title,
	.submenu-not-children {
		height: auto;
		font-size: 18px;
		line-height: 28px;
		padding: 14px 5px 14px 39px;
		color: #666666;
		display: flex;
	}

	.submenu-not-children.is-active {
		background-color: var(--theme-color);
		font-weight: bold;
		color: #ffffff;
	}

	.item {
		background-color: transparent;
	}
}
</style>

/**
 * @Description 常量
 */
// WebSocket基准路径
// export const WsBaseURL = "ws://zhongnuopaimai.biaofun.com.cn/websocket/";
export const WsBaseURL = "wss://www.qipai-wang.com/websocket/";


// 拍卖会状态
// 不要修改 name 属性值，很多组件都是通过 name 属性值来判断的状态
export const EnumAuctionStatus = {
	30: { status: 30, name: "即将开始", text: "未开始" },
	40: { status: 40, name: "正在进行", text: "进行中" },
	50: { status: 50, name: "已结束", text: "已结束" },
};

// 标的状态
// 不要修改 name 属性值，很多组件都是通过 name 属性值来判断的状态
export const EnumTargetStatus = {
	30: { status: 30, name: "即将开始", text: "即将开始" },
	40: { status: 40, name: "自由竞拍中", text: "进行中" },
	50: { status: 50, name: "限时竞拍中", text: "进行中" },
	60: { status: 60, name: "待拍卖师确认", text: "进行中" },
	70: { status: 70, name: "已撤拍", text: "已撤拍" },
	80: { status: 80, name: "已流拍", text: "已流拍" },
	90: { status: 90, name: "已成交", text: "已成交" },
};

// 求购、租赁、采购
// 不要修改 name 属性值，很多组件都是通过 name 属性值来判断的状态
export const EnmuBuyLeaseTypes = {
	1: { type: 1, name: "求购", text: "求购" },
	2: { type: 2, name: "租赁", text: "租赁" },
	3: { type: 3, name: "采购", text: "采购" },
	4: { type: 4, name: "置换", text: "置换" },
};

// 搜索条件 - 时间可选项
export const EnumDateItems = [
	{ id: 1, name: "今天" },
	{ id: 2, name: "未来3天" },
	{ id: 3, name: "未来一周" },
];

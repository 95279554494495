<!--  -->
<template>
	<div class="component-new-target-start-notice" v-if="isShow">
		<div class="layout-main wrapper">
			<h1 class="title">{{ info?.title }}标的已经开始竞拍，是否切换？</h1>
			<div class="btns">
				<span class="btn confirm" @click="handleConfirm">立即切换</span>
				<span class="btn cancel" @click="handleCancel">暂不切换</span>
			</div>
		</div>
		<i class="zn-iconfont zn-iconfont-guanbi1 close" @click="handleCancel"></i>
	</div>
</template>

<script>
	export default {
		name: "",
		props: {
			show: {
				type: Boolean,
				default: false,
			},
			// 新（下一个）标的信息
			info: {
				type: Object,
				default: () => ({}),
			},
		},
		data() {
			return {
				isShow: false,
			};
		},
		watch: {
			show: {
				handler(newVal) {
					this.isShow = newVal;
				},
				immediate: true,
			},
		},
		mounted() {},
		methods: {
			/**
			 * @description: 确认
			 * @return {*}
			 */
			handleConfirm() {
				this.$router.push({
					name: "TargetDetails",
					query: {
						no: this.info?.item_no,
						t: new Date().getTime(),
					},
				});
				this.$emit("confirm");
			},

			/**
			 * @description: 取消
			 * @return {*}
			 */
			handleCancel() {
				this.isShow = false;
				this.$emit("cancel");
			},
		},
	};
</script>

<style lang="scss" scoped>
	.component-new-target-start-notice {
		padding: 40px 20px;
		text-align: center;
		position: sticky;
		top: 0;
		left: 0;
		width: 100%;
		background-color: #ffffff;
		z-index: 2000;
		box-shadow: 0 10px 20px 20px rgba(0, 0, 0, 0.2);

		.title {
			font-size: 24px;
			margin-bottom: 20px;
			font-weight: 500;
		}

		.btns {
			display: flex;
			justify-content: center;

			.btn {
				width: 120px;
				height: 40px;
				font-size: 16px;
				color: #ffffff;
				display: flex;
				align-items: center;
				justify-content: center;
				border: 1px solid transparent;
				margin: 3px;
				transition: opacity 0.1s;
				cursor: pointer;
				user-select: none;

				&:hover {
					opacity: 0.8;
				}
			}

			.confirm {
				background: var(--theme-color);
			}

			.cancel {
				background: #ffffff;
				color: var(--theme-color);
				border-color: var(--theme-color);
			}
		}

		.close {
			position: absolute;
			right: 10px;
			top: 10px;
			color: #666666;
			font-size: 26px;
			cursor: pointer;
			transition: color 0.1s;

			&:hover {
				color: var(--theme-color);
			}
		}
	}
</style>

/**
 * ****************************************************************************************************
 * 拍卖会模块相关接口
 * ****************************************************************************************************
 */
import HiRequest from "@/request/index.js";
export const hr = new HiRequest();

// 获取搜索数据
export const apiGetSearchItems = (data = {}, options = {}) => hr.get("api/v1/auction/search_data", data, options);

// 获取拍卖会列表
export const apiGetAuctionList = (data = {}, options = {}) => hr.get("api/v1/auction/lists", data, options);

// 获取拍卖会详情
export const apiGetAuctionDetails = (data = {}, options = {}) => hr.get("api/v1/auction/detail", data, options);

// 获取拍卖会公告列表
export const apiGetAuctionNoticeList = (data = {}, options = {}) => hr.get("api/v1/auction_notice/lists", data, options);

// 获取拍卖会公告详情
export const apiGetAuctionNoticeDetails = (data = {}, options = {}) => hr.get("api/v1/auction_notice/detail", data, options);

<!-- 求购租赁 - 详情 -->
<template>
	<div class="page-container page-buy-lease-details-container">
		<WebHeader></WebHeader>
		<div class="page-buy-lease-details-content">
			<!-- 面包屑 -->
			<Breadcrumbs :list="breadcrumbs"></Breadcrumbs>

			<!-- 主内容区域 -->
			<div class="layout-main">
				<!-- 基础简介 -->
				<BuyLeaseDetailIntro class="buy-lease-detail-intro" :intro="intro"></BuyLeaseDetailIntro>

				<!-- Tabs && Contents -->
				<div class="tabs-and-contents">
					<el-tabs type="border-card" v-model="tabsValue">
						<el-tab-pane :label="tab.label" :name="key" v-for="(tab, key) in tabs" :key="key">
							<div class="tab-content">
								<TargetRichText v-if="key == 'detail'" :content="tab.content"></TargetRichText>
							</div>
						</el-tab-pane>
					</el-tabs>
				</div>
			</div>
		</div>
		<WebFooter></WebFooter>
	</div>
</template>

<script>
	import WebHeader from "@/components/WebHeader.vue"; // 页面Header
	import WebFooter from "@/components/WebFooter.vue"; // 页面Footer
	import Breadcrumbs from "@/components/Breadcrumbs.vue"; // 面包屑
	import BuyLeaseDetailIntro from "@/components/BuyLeaseDetailIntro.vue"; // 求购租赁介绍
	import TargetRichText from "@/components/TargetRichText.vue"; // 富文本模块
	import { breadcrumbsBuyLeaseDetails } from "@/breadcrumbs/index.js"; // 面包屑数据
	import { apiGetBuyLeaseDetails } from "@/apis/buyLease.js";

	export default {
		name: "PageBuyLeaseDetails",
		components: {
			WebHeader,
			WebFooter,
			Breadcrumbs,
			BuyLeaseDetailIntro,
			TargetRichText,
		},
		data() {
			return {
				breadcrumbs: breadcrumbsBuyLeaseDetails, // 面包屑数据
				id: "", // 求购租赁id
				intro: null, // 简介,介绍
				tabs: {
					// 商品详情
					detail: {
						label: "商品详情",
						content: "",
					},
				}, // tabs
				tabsValue: "detail", // 当前选中的tab名称
			};
		},
		mounted() {
			// 获取求购租赁id
			this.id = this.$route.query?.id;

			// 查询详情
			this.queryDetails();
		},
		methods: {
			/**
			 * !查询详情
			 * @return {*}
			 */
			queryDetails() {
				apiGetBuyLeaseDetails({
					id: this.id,
				})
					.then((res) => {
						console.log("详情->", res);
						// 简介,介绍
						this.intro = res?.finalData;

						// 商品详情
						this.tabs.detail.content = res?.finalData?.content;
					})
					.catch((err) => console.error(err));
			},
		},
	};
</script>

<style scoped lang="scss">
	.page-buy-lease-details-container {
		.buy-lease-detail-intro {
			margin-bottom: 19px;
		}

		.tabs-and-contents {
			margin-bottom: 72px;

			.tab-content {
				padding: 60px 100px;
			}
		}
	}
</style>

import { formatPrice } from "@/utils/func.js";
import dayjs from "dayjs";

/**
 * @description: 根据标的状态获取展示的价格相关数据
 *               标的在所有页面显示的价格字段：
 *                   - 未开始【起拍价格：xxx】
 *                   - 进行中【当前价格：xxx】
 *                   - 已成交【成交价格：xxx】
 *                   - 撤拍【起拍价格：xxx】
 *                   - 流拍【起拍价格：xxx】
 *                   - 补充：若进行中的标的，当前无人出价，则显示起拍价
 *
 * @param {Object} item 标的数据
 * @return {Object} {label: '展示的价格标签', value: '展示的价格'}
 */
export const getTargetShowPriceDataByStatus = (item) => {
	let result = {
		label: "起拍价格",
		value: formatPrice(item?.start_price),
	};
	if ((item?.status == 40 && item?.is_quote) || (item?.status == 50 && item?.is_quote) || (item?.status == 60 && item?.is_quote)) {
		result.label = "当前价格";
		result.value = formatPrice(item?.now_price);
	}
	if (item?.status == 90) {
		result.label = "成交价格";
		result.value = formatPrice(item?.final_price);
	}
	return result;
};

/**
 * @description: 根据标的状态获取展示的日期时间相关数据
 *               所有标的列表页。标的状态不同，显示的时间也不同
 *                   - 30：未开始。40：自由竞拍中。50：限时竞拍中。60：待拍卖师确认
 *                         显示为：开始时间（拍卖会的开始时间）
 *                   - 70：已撤拍。80：已流拍。90：已成交
 *                         显示为：结束时间（标的结束时间）
 *
 * @param {Object} item 标的数据
 * @param {String} format 时间格式
 * @return {Object} {label: '展示的价格标签', value: '展示的价格'}
 */
export const getTargetShowDateDataByStatus = (item, format = "YYYY-MM-DD HH:mm:ss") => {
	let result = {
		label: "开始时间",
		value: dayjs.unix(item?.start_time).format(format),
	};
	if (item?.status == 70 || item?.status == 80 || item?.status == 90) {
		result.label = "结束时间";
		result.value = dayjs.unix(item?.end_time).format(format);
	}
	return result;
};

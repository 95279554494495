<!-- 内页Banner -->
<template>
	<div class="component-banner-inner">
		<img class="banner" :src="banner" alt="" />
		<div class="content">
			<div class="layout-main">
				<h1 class="title">{{ title }}</h1>
				<h2 class="subtitle" v-html="subtitle"></h2>
				<slot></slot>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "BannerInner",
	props: {
		banner: String, // Banner图片地址
		title: String, // 标题,中文
		subtitle: String, // 副标题,英文
	},
	data() {
		return {};
	},
	mounted() {},
	methods: {},
};
</script>

<style lang="scss" scoped>
.component-banner-inner {
	position: relative;

	.banner {
		display: block;
		width: 100%;
		height: 300px;
		object-fit: cover;
	}

	.content {
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		z-index: 0;
	}

	.layout-main {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		line-height: 1;
		color: #ffffff;
		height: 100%;
		position: relative;
	}

	.title {
		font-size: 42px;
		font-weight: normal;
		text-align: center;
	}

	.subtitle {
		font-size: 20px;
		font-family: "MarkPro";
		margin-top: 15px;
		font-weight: normal;
		text-align: center;
	}
}
</style>

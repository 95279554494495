<!-- 个人中心 - 委托竞拍 -->
<template>
	<div class="subpage-container subpage-mine-entrusted-bidding-container">
		<h1 class="module-title">委托竞拍</h1>

		<!-- Tabs && Contents -->
		<div class="tabs-and-contents">
			<el-tabs type="border-card" v-model="tabsValue" @tab-click="handleTabClick">
				<el-tab-pane :label="tab.label" :name="key" v-for="(tab, key) in tabs" :key="key">
					<div class="list-wrapper">
						<MineEntrustedBiddingList :list="tab.list" v-if="tab.list.length"></MineEntrustedBiddingList>
						<el-empty description="数据为空" v-else></el-empty>
						<div class="common-pagination">
							<el-pagination
								background
								:page-size="tab.pageSize"
								:total="tab.total"
								:pager-count="5"
								:current-page="tab.pageIndex"
								hide-on-single-page
								@current-change="onChangePageIndex"
							></el-pagination>
						</div>
					</div>
				</el-tab-pane>
			</el-tabs>
		</div>
	</div>
</template>

<script>
	import MineEntrustedBiddingList from "@/components/MineEntrustedBiddingList.vue"; // 我的委托竞拍列表组件
	import { apiGetMyTargetList } from "@/apis/user.js";

	export default {
		name: "PageMineEntrustedBidding",
		components: { MineEntrustedBiddingList },
		data() {
			return {
				tabs: {
					// 参拍中
					inHand: {
						label: "参拍中",
						state: 30,
						list: [],
						pageIndex: 1, // 当前页码
						pageSize: 20, // 每页数据长度
						total: 0, // 总数据条数
					},
					// 已拍下
					captured: {
						label: "已拍下",
						state: 100,
						list: [],
						pageIndex: 1, // 当前页码
						pageSize: 20, // 每页数据长度
						total: 0, // 总数据条数
					},
					// 已结束
					completed: {
						label: "已结束",
						state: 90,
						list: [],
						pageIndex: 1, // 当前页码
						pageSize: 20, // 每页数据长度
						total: 0, // 总数据条数
					},
				}, // tabs
				tabsValue: "inHand", // 当前选中的tab名称
			};
		},
		mounted() {
			this.init();
		},
		methods: {
			init() {
				// 查询默认tab下的列表数据
				this.queryList();
			},
			/**
			 * !查询列表
			 */
			queryList() {
				apiGetMyTargetList({
					page: this.tabs[this.tabsValue].pageIndex,
					limit: this.tabs[this.tabsValue].pageSize,
					need_count: this.tabs[this.tabsValue].pageIndex == 1 ? 1 : 2,
					type: 2,
					status: this.tabs[this.tabsValue].state,
				})
					.then((res) => {
						// 数据总条数
						if (res?.finalData?.count) this.tabs[this.tabsValue].total = res?.finalData?.count;

						// 列表
						res?.finalData?.items?.forEach((item) => (item.state = this.tabs[this.tabsValue].state));
						this.tabs[this.tabsValue].list = res?.finalData?.items || [];
					})
					.catch((err) => console.error(err));
			},

			/**
			 * !页码切换事件
			 * @param {Number} current 当前页
			 */
			onChangePageIndex(current) {
				// 清空现有列表
				this.tabs[this.tabsValue].list = [];

				// 更新页码
				this.tabs[this.tabsValue].pageIndex = current;

				// 查询新的列表数据
				this.queryList();
			},

			/**
			 * !tab 被选中时触发
			 * @param {Object} tab 被选中的标签 tab 实例
			 *
			 */
			handleTabClick(tab) {
				// 如果tab下的数据列表为空,就查询一下数据,否则就不用查询
				if (!this.tabs[this.tabsValue].list.length) this.queryList();
			},
		},
	};
</script>

<style scoped lang="scss">
	.subpage-mine-entrusted-bidding-container {
		background-color: #ffffff;
		min-height: 100%;
		padding: 0 44px 30px 44px;

		.module-title {
			font-size: 24px;
			line-height: 1;
			font-weight: normal;
			padding: 39px 0 21px;
			border-bottom: 1px solid #fafafa;
			margin-bottom: 24px;
		}

		::v-deep {
			.el-tabs .el-tabs__header {
				background-color: #f5f5f5;
			}

			.el-tabs .el-tabs__header::after {
				display: none;
			}

			.el-tabs .el-tabs__header .el-tabs__item {
				height: 48px;
				line-height: 48px;
				font-size: 16px;
				color: #333333;
				width: 150px;
				text-align: center;
				border: none;
			}

			.el-tabs .el-tabs__header .el-tabs__item.is-active {
				background-color: transparent;
				color: var(--theme-color);
				position: relative;

				&::after {
					content: "";
					width: 100%;
					height: 4px;
					background-color: var(--theme-color);
					left: 0;
					bottom: 0;
					z-index: 8;
					position: absolute;
				}
			}

			.el-tabs__content {
				padding: 8px 0 0 0;
			}
		}
	}
</style>

<!-- 求购租赁列表 -->
<template>
	<div class="buy-lease-list-container">
		<div class="wrapper">
			<div class="item" v-for="(item, index) in list" :key="index" @click="onClickItem(item)">
				<BuyLeaseListItem :item="item"></BuyLeaseListItem>
			</div>
		</div>
	</div>
</template>

<script>
	import BuyLeaseListItem from "@/components/BuyLeaseListItem.vue";
	export default {
		name: "BuyLeaseList",
		components: {
			BuyLeaseListItem,
		},
		props: {
			list: {
				type: Array,
				default: () => {
					return [];
				},
			},
		},
		methods: {
			/**
			 * !item点击事件
			 * @param {Object} item item数据
			 */
			onClickItem(item) {
				// 跳转到求购租赁详情页
				this.$router.push({ name: "BuyLeaseDetails", query: { id: item.id } });
			},
		},
	};
</script>

<style lang="scss" scoped>
	.buy-lease-list-container {
		.wrapper {
			margin: 0 -6px;
			display: flex;
			flex-wrap: wrap;
		}

		.item {
			width: 25%;
			padding: 0 6px;
			margin-bottom: 19px;
		}
	}
</style>

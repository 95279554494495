<!-- 成交案例 - 列表 -->
<template>
	<div class="page-container page-transaction-cases-list-container">
		<WebHeader></WebHeader>
		<div class="page-transaction-cases-list-content">
			<!-- 面包屑 -->
			<Breadcrumbs :list="breadcrumbs"></Breadcrumbs>

			<!-- 主内容区域 -->
			<div class="layout-main">
				<!-- 搜索 -->
				<SearchFilter class="search-filter" :region="region" :targetTypes="targetTypes" @change="handleSearch"></SearchFilter>

				<!-- 列表 -->
				<TargetList class="target-list" :list="list" v-if="list && list.length" isCase></TargetList>

				<!-- 数据为空 -->
				<el-empty description="数据为空" v-else></el-empty>

				<!-- 分页器 -->
				<div class="common-pagination">
					<el-pagination background :page-size="pageSize" :total="total" :pager-count="5" :current-page="pageIndex" hide-on-single-page @current-change="onChangePageIndex"></el-pagination>
				</div>
			</div>
		</div>
		<WebFooter></WebFooter>
	</div>
</template>

<script>
import WebHeader from "@/components/WebHeader.vue"; // 页面Header
import WebFooter from "@/components/WebFooter.vue"; // 页面Footer
import Breadcrumbs from "@/components/Breadcrumbs.vue"; // 面包屑
import SearchFilter from "@/components/SearchFilter.vue"; // 搜索过滤
import TargetList from "@/components/TargetList.vue"; // 标的列表
import { breadcrumbsTransactionCasesList } from "@/breadcrumbs/index.js"; // 面包屑数据
import { apiGetSearchItems, apiGetTargetList } from "@/apis/target.js";

export default {
	name: "PageTransactionCasesList",
	components: {
		WebHeader,
		WebFooter,
		Breadcrumbs,
		SearchFilter,
		TargetList,
	},
	data() {
		return {
			// 面包屑数据
			breadcrumbs: breadcrumbsTransactionCasesList,
			region: [], // 地区数据
			targetTypes: [], // 标的类型数据
			list: [], // 标的列表
			loading: false, // loading状态
			pageIndex: 1, // 当前页码
			pageSize: 20, // 每页数据长度
			total: 0, // 总数据条数
			// 搜索条件数据
			searchParams: {
				provinceId: "", // 省份id
				cityId: "", // 城市id
				targetParentId: "", // 标的类型一级分类id
				targetChildrenId: "", // 标的类型二级分类id
			},
		};
	},
	async mounted() {
		// 查询搜索数据
		await this.querySearchItems().then().catch();

		// 查询列表数据
		this.queryList();
	},
	methods: {
		/**
		 * !查询搜索数据
		 */
		querySearchItems() {
			return new Promise((resolve, reject) => {
				apiGetSearchItems()
					.then((res) => {
						this.region = res?.finalData?.region_data || [];
						this.targetTypes = res?.finalData?.type_data || [];
						resolve();
					})
					.catch((err) => {
						console.error(err);
						reject();
					});
			});
		},

		/**
		 * !查询列表数据
		 */
		queryList() {
			apiGetTargetList({
				page: this.pageIndex,
				limit: this.pageSize,
				need_count: this.pageIndex == 1 ? 1 : 2,
				province_id: this.searchParams?.provinceId,
				city_id: this.searchParams?.cityId,
				ptype_id: this.searchParams?.targetParentId,
				stype_id: this.searchParams?.targetChildrenId,
				status_id: 90,
			})
				.then((res) => {
					// 数据总条数
					if (res?.finalData?.count) this.total = res?.finalData?.count;

					// 列表
					res?.finalData?.lists?.forEach((item) => (item.now_time = res?.finalData?.now_time));
					this.list = res?.finalData?.lists || [];
				})
				.catch((err) => console.error(err));
		},

		/**
		 * !搜索事件
		 * @param {Object} params 搜索条件数据
		 */
		handleSearch(params) {
			// 更新搜索条件
			this.searchParams = params;

			// 清空当前列表
			this.list = [];

			// 重置页码
			this.pageIndex = 1;

			// 重置总数据条数
			this.total = 0;

			// 查询列表数据
			this.queryList();
		},

		/**
		 * !页码切换事件
		 * @param {Number} current 当前页
		 */
		onChangePageIndex(current) {
			// 清空当前列表
			this.list = [];

			// 更新页码
			this.pageIndex = current;

			// 查询列表数据
			this.queryList();
		},
	},
};
</script>

<style scoped lang="scss">
.page-transaction-cases-list-content {
	.search-filter {
		margin-bottom: 20px;
	}
}
</style>

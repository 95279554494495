<!-- Header Two -->
<template>
	<div class="component-web-header-two">
		<div class="wrapper layout-main">
			<router-link to="/" class="logo-link">
				<img class="logo" :src="companyInfo?.view_logo_top" alt="" />
			</router-link>
			<img class="slogan" :src="companyInfo?.slogan_img" alt="" />
		</div>
	</div>
</template>

<script>
import { mapState } from "vuex";
export default {
	name: "WebHeaderTwo",
	props: {},
	computed: mapState(["companyInfo"]),
	data() {
		return {};
	},
	mounted() {},
	methods: {},
};
</script>

<style lang="scss" scoped>
.component-web-header-two {
	background-color: #ffffff;
	overflow-x: hidden;

	.wrapper {
		height: 100px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.logo-link {
		display: block;
		/* background-image: url("@/assets/images/logo.png"); */
		width: 207px;
		height: 57px;
		animation: logoAnimation 4.04s steps(1) infinite;
	}

	.logo {
		display: block;
		height: 56px;
		width: auto;
		/* display: none; */
	}

	.slogan {
		display: block;
		height: 36px;
		width: auto;
	}

	@keyframes logoAnimation {
		0% {
			background-position: 0px 0px;
		}
		1.00% {
			background-position: -207px 0px;
		}
		2.00% {
			background-position: -414px 0px;
		}
		3.00% {
			background-position: -621px 0px;
		}
		4.00% {
			background-position: -828px 0px;
		}
		5.00% {
			background-position: -1035px 0px;
		}
		6.00% {
			background-position: -1242px 0px;
		}
		7.00% {
			background-position: -1449px 0px;
		}
		8.00% {
			background-position: -1656px 0px;
		}
		9.00% {
			background-position: -1863px 0px;
		}
		10.00% {
			background-position: -2070px 0px;
		}
		11.00% {
			background-position: -2277px 0px;
		}
		12.00% {
			background-position: -2484px 0px;
		}
		13.00% {
			background-position: -2691px 0px;
		}
		14.00% {
			background-position: -2898px 0px;
		}
		15.00% {
			background-position: -3105px 0px;
		}
		16.00% {
			background-position: -3312px 0px;
		}
		17.00% {
			background-position: -3519px 0px;
		}
		18.00% {
			background-position: -3726px 0px;
		}
		19.00% {
			background-position: -3933px 0px;
		}
		20.00% {
			background-position: -4140px 0px;
		}
		21.00% {
			background-position: -4347px 0px;
		}
		22.00% {
			background-position: -4554px 0px;
		}
		23.00% {
			background-position: -4761px 0px;
		}
		24.00% {
			background-position: 0px -57px;
		}
		25.00% {
			background-position: -207px -57px;
		}
		26.00% {
			background-position: -414px -57px;
		}
		27.00% {
			background-position: -621px -57px;
		}
		28.00% {
			background-position: -828px -57px;
		}
		29.00% {
			background-position: -1035px -57px;
		}
		30.00% {
			background-position: -1242px -57px;
		}
		31.00% {
			background-position: -1449px -57px;
		}
		32.00% {
			background-position: -1656px -57px;
		}
		33.00% {
			background-position: -1863px -57px;
		}
		34.00% {
			background-position: -2070px -57px;
		}
		35.00% {
			background-position: -2277px -57px;
		}
		36.00% {
			background-position: -2484px -57px;
		}
		37.00% {
			background-position: -2691px -57px;
		}
		38.00% {
			background-position: -2898px -57px;
		}
		39.00% {
			background-position: -3105px -57px;
		}
		40.00% {
			background-position: -3312px -57px;
		}
		41.00% {
			background-position: -3519px -57px;
		}
		42.00% {
			background-position: -3726px -57px;
		}
		43.00% {
			background-position: -3933px -57px;
		}
		44.00% {
			background-position: -4140px -57px;
		}
		45.00% {
			background-position: -4347px -57px;
		}
		46.00% {
			background-position: -4554px -57px;
		}
		47.00% {
			background-position: -4761px -57px;
		}
		48.00% {
			background-position: 0px -114px;
		}
		49.00% {
			background-position: -207px -114px;
		}
		50.00% {
			background-position: -414px -114px;
		}
		51.00% {
			background-position: -621px -114px;
		}
		52.00% {
			background-position: -828px -114px;
		}
		53.00% {
			background-position: -1035px -114px;
		}
		54.00% {
			background-position: -1242px -114px;
		}
		55.00% {
			background-position: -1449px -114px;
		}
		56.00% {
			background-position: -1656px -114px;
		}
		57.00% {
			background-position: -1863px -114px;
		}
		58.00% {
			background-position: -2070px -114px;
		}
		59.00% {
			background-position: -2277px -114px;
		}
		60.00% {
			background-position: -2484px -114px;
		}
		61.00% {
			background-position: -2691px -114px;
		}
		62.00% {
			background-position: -2898px -114px;
		}
		63.00% {
			background-position: -3105px -114px;
		}
		64.00% {
			background-position: -3312px -114px;
		}
		65.00% {
			background-position: -3519px -114px;
		}
		66.00% {
			background-position: -3726px -114px;
		}
		67.00% {
			background-position: -3933px -114px;
		}
		68.00% {
			background-position: -4140px -114px;
		}
		69.00% {
			background-position: -4347px -114px;
		}
		70.00% {
			background-position: -4554px -114px;
		}
		71.00% {
			background-position: -4761px -114px;
		}
		72.00% {
			background-position: 0px -171px;
		}
		73.00% {
			background-position: -207px -171px;
		}
		74.00% {
			background-position: -414px -171px;
		}
		75.00% {
			background-position: -621px -171px;
		}
		76.00% {
			background-position: -828px -171px;
		}
		77.00% {
			background-position: -1035px -171px;
		}
		78.00% {
			background-position: -1242px -171px;
		}
		79.00% {
			background-position: -1449px -171px;
		}
		80.00% {
			background-position: -1656px -171px;
		}
		81.00% {
			background-position: -1863px -171px;
		}
		82.00% {
			background-position: -2070px -171px;
		}
		83.00% {
			background-position: -2277px -171px;
		}
		84.00% {
			background-position: -2484px -171px;
		}
		85.00% {
			background-position: -2691px -171px;
		}
		86.00% {
			background-position: -2898px -171px;
		}
		87.00% {
			background-position: -3105px -171px;
		}
		88.00% {
			background-position: -3312px -171px;
		}
		89.00% {
			background-position: -3519px -171px;
		}
		90.00% {
			background-position: -3726px -171px;
		}
		91.00% {
			background-position: -3933px -171px;
		}
		92.00% {
			background-position: -4140px -171px;
		}
		93.00% {
			background-position: -4347px -171px;
		}
		94.00% {
			background-position: -4554px -171px;
		}
		95.00% {
			background-position: -4761px -171px;
		}
		96.00% {
			background-position: 0px -228px;
		}
		97.00% {
			background-position: -207px -228px;
		}
		98.00% {
			background-position: -414px -228px;
		}
		99.00% {
			background-position: -621px -228px;
		}
		100.00% {
			background-position: -828px -228px;
		}
	}
}
</style>

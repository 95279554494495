<!-- 企业认证 -->
<template>
	<div class="subpage-container subpage-attestation-enterprise-container">
		<div class="top-tips">
			<label class="label">注：</label>
			<span class="value">您填写的资料仅用于认证，认证通过后不可更改</span>
		</div>
		<div class="form-wrapper">
			<el-form class="form" :model="form" ref="form" :rules="rules" label-width="160px">
				<el-form-item prop="companyName" label="公司名称：">
					<el-input class="input" v-model="form.companyName" placeholder="请输入您的公司名称" maxlength="80"></el-input>
				</el-form-item>
				<el-form-item prop="unifiedCode" label="统一社会信用代码：">
					<el-input class="input" v-model="form.unifiedCode" placeholder="请输入统一社会信用代码" maxlength="18"></el-input>
				</el-form-item>
				<el-form-item prop="legalRepresentativeName" label="法定代表人：">
					<el-input class="input" v-model="form.legalRepresentativeName" placeholder="请输入法定代表人姓名"
						maxlength="80"></el-input>
				</el-form-item>
				<el-form-item prop="idno" label="法人身份证号：">
					<el-input class="input" v-model="form.idno" placeholder="请输入法定代表人身份证号" maxlength="18"></el-input>
				</el-form-item>
				<el-form-item prop="businessLicensePic" label="营业执照照片：">
					<el-upload class="pic-uploader pic3-uploader" :action="action" :show-file-list="false"
						:on-success="(res, file) => handleUploadPicSuccess(res, file, 'businessLicensePic')"
						:before-upload="beforePicUpload" :data="postData">
						<div class="pic-box" v-if="form.businessLicensePic">
							<img :src="form.businessLicensePic" class="pic" />
						</div>
						<div class="upload-tips" v-else>
							<i class="icon el-icon-plus avatar-uploader-icon"></i>
							<span class="text">上传照片</span>
						</div>
					</el-upload>
					<div class="upload-pic-tips">请确保照片上的信息清晰真实，亮度均匀</div>
				</el-form-item>
				<el-form-item prop="idPic" label="法人身份证照片：" class="pics-items">
					<div class="id-pics">
						<el-form-item prop="idPic1">
							<el-upload class="pic-uploader pic1-uploader" :action="action" :show-file-list="false"
								:on-success="(res, file) => handleUploadPicSuccess(res, file, 'idPic1')"
								:before-upload="beforePicUpload" :data="postData">
								<div class="pic-box" v-if="form.idPic1">
									<img :src="form.idPic1" class="pic" />
								</div>
								<div class="upload-tips" v-else>
									<i class="icon el-icon-plus avatar-uploader-icon"></i>
									<!-- <span class="text">上传正面照片</span> -->
								</div>
							</el-upload>
							<div style="text-align: center;color: #999;">上传身份证人像页</div>
						</el-form-item>
						<el-form-item prop="idPic2">
							<el-upload class="pic-uploader pic2-uploader" :action="action" :show-file-list="false"
								:on-success="(res, file) => handleUploadPicSuccess(res, file, 'idPic2')"
								:before-upload="beforePicUpload" :data="postData">
								<div class="pic-box" v-if="form.idPic2">
									<img :src="form.idPic2" class="pic" />
								</div>
								<div class="upload-tips" v-else>
									<i class="icon el-icon-plus avatar-uploader-icon"></i>
									<!-- <span class="text">上传背面照片</span> -->
								</div>
							</el-upload>
							<div style="text-align: center;color: #999;">上传身份证国徽页</div>

						</el-form-item>
					</div>
					<div class="id-pics-tips">
						1.身份证照片不得有遮挡，保证字迹清晰<br />
						2.请在白色背景下拍摄，保证身份证边缘清晰<br />
						3.请从证件的正上方拍摄，防止画面变形
					</div>
				</el-form-item>
				<el-form-item prop="supplementPic" label="补充证件资料（非必传）：">
					<el-upload class="pic-uploader pic4-uploader" :action="action" :show-file-list="false"
						:on-success="(res, file) => handleUploadPicSuccess(res, file, 'supplementPic')"
						:before-upload="beforePicUpload" :data="postData">
						<div class="pic-box" v-if="form.supplementPic">
							<img :src="form.supplementPic" class="pic" />
						</div>
						<div class="upload-tips" v-else>
							<i class="icon el-icon-plus avatar-uploader-icon"></i>
							<span class="text">上传照片</span>
						</div>
					</el-upload>
				</el-form-item>
				<el-form-item>
					<el-button class="submit-button" type="primary" @click="handleSubmit">立即认证</el-button>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
import { validateIdNo, validateUnifiedSocialCreditCode, validateBeforeUploadImage, createFileName } from "@/utils/func.js";
import { apiGetQiniuConfig } from "@/apis/common.js";
import { apiUserAttestation, apiGetUserAttestationInfo } from "@/apis/user.js";

export default {
	name: "PageAttestationEnterprise",
	components: {},
	data() {
		return {
			// 表单数据
			form: {
				companyName: "", // 公司名称
				unifiedCode: "", // 统一社会信用代码
				legalRepresentativeName: "", // 法定代表人姓名
				idno: "", // 法人身份证号
				businessLicensePic: "", // 营业执照照片
				idPic1: "", // 身份证照片 - 正面
				idPic2: "", // 身份证照片 - 背面
				supplementPic: "", // 补充证件资料
				idPic: '1'
			},
			// 表单验证规则
			rules: {
				companyName: [{ required: true, message: "请输入您的公司名称" }],
				unifiedCode: [{ required: true, message: "请输入统一社会信用代码" }, { validator: validateUnifiedSocialCreditCode }],
				legalRepresentativeName: [{ required: true, message: "请输入法定代表人姓名" }],
				idno: [
					{ required: true, message: "请输入法定代表人身份证号" },
					{ validator: (rule, value, callback) => validateIdNo(rule, value, callback, "请输入有效的法定代表人身份证号") },
				],
				businessLicensePic: [{ required: true, message: "请上传营业执照照片" }],
				idPic: [{ required: true, message: "1 " }],
				idPic1: [{ required: true, message: "请上传身份证正面照片" }],
				idPic2: [{ required: true, message: "请上传身份证背面照片" }],
			},
			action: "", // 七牛直传地址
			prefixUrl: "", // 上传到的七牛空间地址
			postData: {
				key: "", // 文件名
				token: "", // 七牛上传token
			},
		};
	},
	mounted() {
		// 是否重新认证. 0: 否; 1: 是;
		// 是重新认证,查询用户认证信息
		if (this.$route.query?.again == 1) this.queryUserAttestationInfo();
	},
	methods: {
		/**
		 * !查询七牛配置信息
		 */
		queryQiniuConfig() {
			return new Promise((resolve, reject) => {
				apiGetQiniuConfig()
					.then((res) => {
						// 七牛token
						this.postData.token = res?.finalData?.token;

						// 七牛直传地址
						this.action = res?.finalData?.endpoint_url;

						// 上传到的七牛空间地址
						this.prefixUrl = res?.finalData?.prefix_url;

						resolve();
					})
					.catch((err) => {
						console.error(err);
						reject();
					});
			});
		},

		/**
		 * !图片上传成功的回调函数
		 * @param {Response} res 上传成功后返回的Response对象
		 * @param {File} file 文件对象
		 * @param {String} picFieldKey 图片字段的key
		 */
		handleUploadPicSuccess(res, file, picFieldKey) {
			// 更新图片路径
			this.form[picFieldKey] = this.prefixUrl + "/" + res?.key;
		},

		/**
		 * !图片上传之前的回调函数
		 * @param {File} file 文件对象
		 */
		async beforePicUpload(file) {
			// 保证文件名唯一性
			this.postData.key = createFileName(file);

			// 校验文件类型和文件大小的结果
			let result = validateBeforeUploadImage(file);

			// 校验文件类型和文件大小通过
			if (result) {
				try {
					// 查询七牛配置信息
					await this.queryQiniuConfig();

					// 获取七牛配置信息成功,可以上传
					result = true;
				} catch (error) {
					// 获取七牛配置信息失败,无法上传
					result = false;
				}
			}

			// 返回最终结果
			return result;
		},

		/**
		 * !点击了"立即认证"按钮
		 */
		handleSubmit() {
			this.$emit("提交认证::Form::", this.form);
			this.$refs.form.validate((valid) => {
				// 表单验证通过
				if (valid) {
					// 请求接口提交认证
					this.submitAttestation();
				} else {
					console.error("表单验证失败");
					return false;
				}
			});
		},

		/**
		 * !请求接口提交认证
		 */
		submitAttestation() {
			apiUserAttestation(
				{
					type: 2,
					name: this.form.legalRepresentativeName,
					id_no: this.form.idno,
					id_front_img: this.form.idPic1,
					id_back_img: this.form.idPic2,
					company_name: this.form.companyName,
					credit_code: this.form.unifiedCode,
					business_license_img: this.form.businessLicensePic,
					replenish_img: this.form.supplementPic,
				},
				{
					loadingMsg: "提交认证中",
				}
			)
				.then((res) => {
					// 待审核
					if (res?.finalData?.status == 20) {
						this.$emit("pending");
						return false;
					}

					// 认证通过
					if (res?.finalData?.status == 40) {
						this.$emit("pass");
						return false;
					}
				})
				.catch((err) => console.error(err));
		},

		/**
		 * !查询用户认证信息
		 */
		queryUserAttestationInfo() {
			apiGetUserAttestationInfo()
				.then((res) => {
					// 设置表单默认数据
					this.form.companyName = res?.finalData?.company_name || "";
					this.form.unifiedCode = res?.finalData?.credit_code || "";
					this.form.legalRepresentativeName = res?.finalData?.name || "";
					this.form.idno = res?.finalData?.id_no || "";
					this.form.businessLicensePic = res?.finalData?.business_license_img || "";
					this.form.idPic1 = res?.finalData?.id_front_img || "";
					this.form.idPic2 = res?.finalData?.id_back_img || "";
					this.form.supplementPic = res?.finalData?.replenish_img || "";
				})
				.catch((err) => console.error(err));
		},
	},
};
</script>

<style scoped lang="scss">
.subpage-attestation-enterprise-container {
	padding: 35px 0 30px;
	background-color: #ffffff;

	.top-tips {
		font-size: 12px;
		line-height: 20px;
		text-align: center;

		.label {
			color: var(--theme-color);
		}
	}

	.form-wrapper {
		max-width: 500px;
		margin: 27px auto 0;
	}

	.pic-uploader {
		width: 167px;
		height: 101px;
		border: 1px solid #f2f2f2;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: 81.44% 81.19%;
	}

	.pic1-uploader {
		background-image: url("@/assets/images/idcard-front.png");
	}

	.pic2-uploader {
		background-image: url("@/assets/images/idcard-back.png");
	}

	.pic3-uploader {
		background-image: url("@/assets/images/business-license.png");
	}

	.pic4-uploader {
		background-image: url("@/assets/images/supplement.png");
	}

	.upload-tips {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		font-size: 12px;
		color: #999999;

		.icon {
			font-size: 16px;
			color: #999999;
		}

		.text {
			line-height: 1;
			margin-top: 8px;
		}
	}

	.pic-box {
		display: block;
		width: 100%;
		height: 100%;
		background-color: #dedede;

		.pic {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}

	.id-pics {
		display: flex;
		align-items: flex-start;
	}

	.upload-pic-tips {
		line-height: 20px;
		color: #999999;
		margin-top: 14px;
	}

	.id-pics-tips {
		line-height: 20px;
		color: #999999;
		margin-top: -10px;
		font-size: 14px;
		margin-bottom: 31px;
	}

	.submit-button {
		width: 200px;
		height: 48px;
		margin-top: 28px;
		margin-left: 28px;
		padding: 0;
		border-radius: 0;
	}

	::v-deep {
		.el-form-item {
			margin-bottom: 31px;
		}

		.el-input__inner {
			border-radius: 0;
		}

		.el-upload {
			width: 100%;
			height: 100%;
		}

		.pics-items {
			margin-bottom: 0;
		}

		.pics-items .el-form-item {
			margin-right: 6px;
		}
	}
}
</style>

<style>
.subpage-attestation-enterprise-container .el-input__inner {
	border-color: #f3f3f3;
}
</style>

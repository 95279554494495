<!-- 标的状态 -->
<template>
	<div class="component-target-status" :class="[`component-target-status--${status}`, { fixBg: showFlag == 60 || showFlag == 40 },{ fixBg1: showFlag == 30 },{ fixBg2: showFlag == 50 }]"
		:style="`--target-status-bg: var(--target-status-bg-${status}); --target-status-text-color: var(--target-status-text-color-${status});`">
		<div class="fixJxBox"
			v-if="showFlag && status != 30 && status != 60 && status != 70 && status != 80 && status != 90"></div>
		<div :class="{ 'pl32': showFlag && status != 30 && status != 60 && status != 70 && status != 80 && status != 90 }">{{
			targetStatus[status]?.text || "" }}</div>
	</div>
</template>

<script>
import { EnumTargetStatus } from "@/const.js";
export default {
	name: "",
	props: {
		status: {
			type: [String, Number],
			default: "",
		},
		showFlag: {
			type: Number,
			default: 0,
		},
		type:{
			type:[String, Number],
			default:'0'
		}
	},
	data() {
		return {
			targetStatus: EnumTargetStatus,
		};
	},
	mounted() { },
	methods: {},
};
</script>

<style lang="scss" scoped>
.component-target-status {
	background: var(--target-status-bg);
	color: var(--target-status-text-color);
	font-size: 12px;
	position: relative;
}

.fixJxBox {
	background: url(../assets/images/jx-icon.png);
	width: 18px;
	height: 18px;
	position: absolute;
	top: 7px;
	left: 11px;
}

.pl32 {
	padding-left: 32px;
}

.fixBg,.fixBg1,.fixBg2 {
	/* background-color: transparent !important; */
	position: relative;
	/* background-color: red; */
	width: 90px !important;
}

.fixBg::after {
	content: '';
	display: block;
	/* width: 10px;
	height: 10px; */
	position: absolute;
	top: 0;
	right: -22px;
	border-left: 13px solid #20b79a;
	border-right: 10px solid transparent;
	border-bottom: 0px solid #20b79a;
	border-top: 33px solid transparent;
}
.fixBg1::after {
	content: '';
	display: block;
	/* width: 10px;
	height: 10px; */
	position: absolute;
	top: 0;
	right: -22px;
	border-left: 13px solid orange;
	border-right: 10px solid transparent;
	border-bottom: 0px solid orange;
	border-top: 33px solid transparent;
}
.fixBg2::after {
	content: '';
	display: block;
	/* width: 10px;
	height: 10px; */
	position: absolute;
	top: 0;
	right: -22px;
	border-left: 13px solid #20b79a;
	border-right: 10px solid transparent;
	border-bottom: 0px solid #20b79a;
	border-top: 33px solid transparent;
}
</style>

<!-- 资讯列表 -->
<template>
	<div class="component-news-list">
		<div class="item" v-for="(item, index) in list" :key="index" @click="handleClickItem(item)">
			<NewsListItem :item="item"></NewsListItem>
		</div>
	</div>
</template>

<script>
import NewsListItem from "@/components/NewsListItem.vue";
export default {
	name: "NewsList",
	components: {
		NewsListItem,
	},
	props: {
		list: Array,
	},
	methods: {
		/**
		 * !item点击事件
		 * @param {Object} item item数据
		 */
		handleClickItem(item) {
			// 跳转到新闻详情页
			this.$router.push({ name: "NewsCenterDetails", query: { no: item?.no } });
		},
	},
};
</script>

<style lang="scss" scoped>
.component-news-list {
	display: flex;
	flex-wrap: wrap;

	.item {
		width: 50%;
		border-top: 1px solid #f6f6f6;
		border-right: 1px solid #f6f6f6;

		&:nth-child(1),
		&:nth-child(2) {
			border-top: none;
		}

		&:nth-child(odd) {
			padding-right: 38px;
		}

		&:nth-child(even) {
			border-right: none;
		}
	}
}
</style>

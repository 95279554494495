<!-- 个人中心 - 账户认证 -->
<template>
	<div class="subpage-container subpage-mine-account-auth-container">
		<h1 class="module-title">账户认证</h1>

		<!-- 10：注册成功未认证; -->
		<div class="status-wrapper not-certified" v-if="authInfo?.user_status == 10">
			<i class="icon zn-iconfont zn-iconfont-chenggong"></i>
			<span class="tip">您尚未完成认证</span>
			<span class="subtips">完成认证后方可报名参拍</span>
			<el-button type="primary" class="button" @click="$router.push({ name: 'Attestation' })">去认证</el-button>
		</div>

		<!-- 40：认证审核通过; -->
		<div class="status-wrapper complete" v-else-if="authInfo?.user_status == 40">
			<i class="icon zn-iconfont zn-iconfont-chenggong"></i>
			<span class="tip">您已完成认证</span>
		</div>

		<!-- 20：认证审核中; -->
		<div class="status-wrapper in-review" v-else-if="authInfo?.user_status == 20">
			<i class="icon zn-iconfont zn-iconfont-dengdailoukong"></i>
			<span class="tip">认证审核中</span>
		</div>

		<!-- 30：认证审核失败; -->
		<div class="status-wrapper reject" v-else-if="authInfo?.user_status == 30">
			<i class="icon zn-iconfont zn-iconfont-dituweizhixinxi_chahao"></i>
			<span class="tip">您的资料认证被驳回</span>
			<span class="subtips">原因：{{ authInfo?.err_msg }}</span>
			<el-button type="primary" class="button" @click="$router.push({ name: 'Attestation', query: { type: authInfo?.type || 1, again: 1 } })">重新认证</el-button>
		</div>

		<!-- 个人认证信息 -->
		<div class="info-wrapper" v-if="(authInfo?.user_status == 20 || authInfo?.user_status == 40) && authInfo?.type == 1">
			<div class="row">
				<label class="label">您的认证方式：</label>
				<span class="value">个人认证</span>
			</div>
			<div class="row">
				<label class="label">认证资料（身份证）：</label>
				<span class="value">{{ authInfo?.id_no ? "有" : "无" }}</span>
			</div>
			<div class="rows">
				<div class="row" style="width: 224px">
					<label class="label">真实姓名：</label>
					<span class="value">{{ authInfo?.name }}</span>
				</div>
				<div class="row">
					<label class="label">身份证：</label>
					<span class="value">{{ authInfo?.id_no }}</span>
				</div>
			</div>
		</div>

		<!-- 企业认证信息 -->
		<div class="info-wrapper" v-else-if="(authInfo?.user_status == 20 || authInfo?.user_status == 40) && authInfo?.type == 2">
			<div class="row">
				<label class="label">您的认证方式：</label>
				<span class="value">企业认证</span>
			</div>
			<div class="rows">
				<div class="row" style="width: 255px">
					<label class="label">认证资料（身份证）：</label>
					<span class="value">{{ authInfo?.id_no ? "有" : "无" }}</span>
				</div>
				<div class="row">
					<label class="label">认证资料（营业执照）：</label>
					<span class="value">{{ authInfo?.business_license_img ? "有" : "无" }}</span>
				</div>
			</div>
			<div class="rows">
				<div class="row" style="width: 255px">
					<label class="label">真实姓名：</label>
					<span class="value">{{ authInfo?.name }}</span>
				</div>
				<div class="row">
					<label class="label">身份证：</label>
					<span class="value">{{ authInfo?.id_no }}</span>
				</div>
			</div>
			<div class="rows">
				<div class="row" style="margin-right: 110px">
					<label class="label">企业名称：</label>
					<span class="value">{{ authInfo?.company_name }}</span>
				</div>
				<div class="row">
					<label class="label">统一社会信用代码：</label>
					<span class="value">{{ authInfo?.credit_code }}</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { apiGetUserAttestationInfo } from "@/apis/user.js";

export default {
	name: "PageMineAccountAuth",
	components: {},
	data() {
		return {
			authInfo: {}, // 用户认证信息
		};
	},
	mounted() {
		// 查询用户认证信息
		this.queryUserAttestationInfo();
	},
	methods: {
		/**
		 * !查询用户认证信息
		 */
		queryUserAttestationInfo() {
			apiGetUserAttestationInfo()
				.then((res) => {
					// 用户认证状态
					this.authInfo = res?.finalData;
					console.log('about',res);
				})
				.catch((err) => console.error(err));
		},
	},
};
</script>

<style scoped lang="scss">
.subpage-mine-account-auth-container {
	background-color: #ffffff;
	min-height: 100%;
	padding: 0 60px 46px 42px;

	.module-title {
		font-size: 24px;
		line-height: 1;
		font-weight: normal;
		padding: 39px 0 21px;
		border-bottom: 1px solid #fafafa;
	}

	.status-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		font-size: 20px;
		color: #000000;
		padding: 35px 0;
		border-bottom: 1px solid #fafafa;

		.icon {
			font-size: 54px;
			color: var(--theme-color);
			margin-bottom: 15px;
		}

		.subtips {
			font-size: 16px;
			color: #999999;
			margin-top: 20px;
			margin-bottom: 20px;
		}

		.button {
			width: 140px;
			height: 42px;
			padding: 0;
		}

		&.not-certified {
			border-bottom: none;

			.icon {
				color: #999999;
			}

			.subtips {
				margin-top: 6px;
			}
		}

		&.reject {
			border-bottom: none;

			.icon {
				color: #999999;
			}
		}
	}

	.info-wrapper {
		line-height: 1;
		margin-top: 15px;
		font-size: 16px;

		.rows {
			display: flex;
			align-items: center;
		}

		.row {
			display: flex;
			align-items: center;
			padding: 16px 0;

			.label {
				color: #666666;
			}
		}
	}
}
</style>

/**
 * ****************************************************************************************************
 * 求购租赁模块相关接口
 * ****************************************************************************************************
 */
import HiRequest from "@/request/index.js";
export const hr = new HiRequest();

// 获取搜索数据
export const apiGetSearchItems = (data = {}, options = {}) => hr.get("api/v1/purchase_lease/search_lists", data, options);

// 获取求购租赁列表数据
export const apiGetBuyLeaseList = (data = {}, options = {}) => hr.get("api/v1/purchase_lease/lists", data, options);

// 获取求购租赁详情
export const apiGetBuyLeaseDetails = (data = {}, options = {}) => hr.get("api/v1/purchase_lease/detail", data, options);

// 提交需求表单
export const apiSubmitNeedForm = (data = {}, options = {}) => hr.post("api/v1/purchase_lease/consult", data, options);

<!-- 新闻中心 -->
<template>
	<div class="page-container page-news-center-list-container">
		<WebHeader></WebHeader>
		<div class="page-news-center-list-content layout-main">
			<!-- 面包屑 -->
			<Breadcrumbs :list="breadcrumbs"></Breadcrumbs>

			<!-- 列表 -->
			<NewsInfoList :list="list" class="list" v-if="list && list.length" listType="newsList"></NewsInfoList>

			<!-- 数据为空 -->
			<el-empty description="数据为空" v-else></el-empty>

			<!-- 分页器 -->
			<div class="common-pagination">
				<el-pagination background :page-size="pageSize" :total="total" :pager-count="5" :current-page="pageIndex" hide-on-single-page @current-change="onChangePageIndex"></el-pagination>
			</div>
		</div>
		<WebFooter></WebFooter>
	</div>
</template>

<script>
import WebHeader from "@/components/WebHeader.vue"; // 页面Header
import WebFooter from "@/components/WebFooter.vue"; // 页面Footer
import Breadcrumbs from "@/components/Breadcrumbs.vue"; // 面包屑
import NewsInfoList from "@/components/NewsInfoList.vue"; // 通知公告列表
import { breadcrumbsNewsCenterList } from "@/breadcrumbs/index.js"; // 面包屑数据
import { apiGetNewsList } from "@/apis/news.js";

export default {
	name: "PageNewsCenterList",
	components: {
		WebHeader,
		WebFooter,
		Breadcrumbs,
		NewsInfoList,
	},
	data() {
		return {
			breadcrumbs: breadcrumbsNewsCenterList, // 面包屑数据
			list: [], // 新闻列表
			pageIndex: 1, // 当前页码
			pageSize: 20, // 每页数据长度
			total: 0, // 总数据条数
		};
	},
	mounted() {
		// 查询列表数据
		this.queryList();
	},
	methods: {
		/**
		 * !查询列表
		 */
		queryList() {
			apiGetNewsList({
				page: this.pageIndex,
				limit: this.pageSize,
				need_count: this.pageIndex == 1 ? 1 : 2,
				title: this.$route.query?.search_text || "",
			})
				.then((res) => {
					// 数据总条数
					if (res?.finalData?.count) this.total = res?.finalData?.count;

					// 列表
					this.list = res?.finalData?.lists || [];
				})
				.catch((err) => console.error(err));
		},

		/**
		 * !页码切换事件
		 * @param {Number} current 当前页
		 */
		onChangePageIndex(current) {
			// 清空现有列表
			this.list = [];

			// 更新页码
			this.pageIndex = current;

			// 查询新的列表数据
			this.queryList();
		},
	},
};
</script>

<style scoped lang="scss">
.page-news-center-list-content {
	.list {
		margin-bottom: 18px;
	}
}
</style>

<!-- 忘记密码,重置密码 -->
<template>
	<div class="page-container page-fotgot-password-container">
		<WebHeaderTwo class="header"></WebHeaderTwo>
		<div class="page-fotgot-password-content layout-main">
			<div class="left">
				<img class="welcome" src="../../public/images/login-welcome.png" alt="" />
			</div>
			<div class="right">
				<div class="form-wrapper">
					<h1 class="title">重置您的密码</h1>
					<el-form class="form" ref="form" :model="form" :rules="rules">
						<el-form-item prop="phonenumber">
							<el-input class="input" v-model="form.phonenumber" maxlength="11"
								placeholder="+86  请输入您的手机号"></el-input>
						</el-form-item>
						<el-form-item prop="captcha">
							<el-input class="input" v-model="form.captcha" maxlength="8" placeholder="请输入验证码"></el-input>
							<el-button type="primary" plain class="send-sms-button" @click="onClickSendSmsBtn"
								:disabled="isSendSmsBtnCountdown">{{ sendSmsBtnText }}</el-button>
						</el-form-item>
						<el-form-item prop="pwd" v-if="loginType == 'pwd'">
							<el-input class="input" show-password v-model="form.pwd" maxlength="18"
								placeholder="请输入您的密码"></el-input>
						</el-form-item>
						<el-form-item prop="pwdAgain" v-if="loginType == 'pwd'">
							<el-input class="input" show-password v-model="form.pwdAgain" maxlength="18"
								placeholder="请再次输入您的新密码"></el-input>
						</el-form-item>
						<el-button class="submit-button" type="primary" @click="handleSubmit">确认重置</el-button>
						<div class="links">
							<router-link class="link" :to="{ path: '/login', query: { by: 'pwd' } }"
								replace>返回登录</router-link>
						</div>
					</el-form>
				</div>
			</div>
		</div>
		<WebFooterTwo class="footer"></WebFooterTwo>
	</div>
</template>

<script>
import WebHeaderTwo from "@/components/WebHeaderTwo.vue"; // 页面Header
import WebFooterTwo from "@/components/WebFooterTwo.vue"; // 页面Footer
import { validatePhoneNumber, validatePass2 } from "@/utils/func.js";
import { apiSendSmsCode } from "@/apis/common.js";
import { apiRetrievePassword } from "@/apis/user.js";

export default {
	name: "PageForgotPassword",
	components: {
		WebHeaderTwo,
		WebFooterTwo,
	},
	data() {
		return {
			loginType: "pwd", // 登录方式. "pwd": 密码登录
			form: {
				phonenumber: "", // 手机号
				captcha: "", // 验证码
				pwd: "", // 密码
				pwdAgain: "", // 确认密码
			},
			rules: {
				phonenumber: [{ required: true, message: "请输入您的手机号" }, { validator: validatePhoneNumber }],
				captcha: [{ required: true, message: "请输入验证码" }],
				pwd: [{ required: true, message: "请输入您的密码" }],
				pwdAgain: [{ required: true, message: "请输入您的密码" }, { validator: (rule, value, callback) => validatePass2(rule, value, callback, this.form.pwd) }],
			},
			sendSmsBtnText: "发送验证码", // 发送验证码按钮上显示的文本
			isSendSmsBtnCountdown: false, // 发送验证码按钮是否正在倒计时
			sendSmsCountdownSeconds: 60, // 发送验证码按钮倒计时总秒数
		};
	},
	mounted() { },
	methods: {
		/**
		 * !点击了"发送验证码"按钮
		 */
		onClickSendSmsBtn() {
			// 验证手机号字段
			this.$refs.form.validateField("phonenumber", (errorMessage) => {
				// 验证通过
				if (!errorMessage) {
					// 手机号验证通过
					// 调用接口发送验证码
					apiSendSmsCode(
						{
							phone: this.form.phonenumber,
							mode: 'forgot_password'

						},
						{
							loadingMsg: "正在发送验证码",
							successShow: true,
						}
					)
						.then((res) => {
							// 开始倒计时
							this.countdownStart();
						})
						.catch((err) => console.error(err));
				}
			});

		},
		countdownStart() {
			// 倒计时总秒数
			let seconds = this.sendSmsCountdownSeconds;

			// 更新倒计时状态
			this.isSendSmsBtnCountdown = true;

			// 更新按钮文本
			this.sendSmsBtnText = seconds + "s后重新获取";

			// 设置定时器
			let timer = setInterval(() => {
				// 减秒
				seconds--;

				// 倒计时未结束
				// 更新按钮文本
				if (seconds >= 0) return (this.sendSmsBtnText = seconds + "s后重新获取");

				// 倒计时结束了
				// 更新倒计时文本
				this.sendSmsBtnText = " 重新发送";

				// 更新倒计时状态
				this.isSendSmsBtnCountdown = false;

				// 清除定时器
				clearInterval(timer);
			}, 1000);
		},

		/**
		 * !点击了"确认重置"按钮
		 */
		handleSubmit() {
			this.$refs.form.validate((valid) => {
				if (valid) {
					// 请求接口找回密码
					apiRetrievePassword(
						{
							phone: this.form.phonenumber,
							password: this.form.pwd,
							code: this.form.captcha,
						},
						{
							loadingMsg: "请求中",
							successShow: true,
						},
					)
						.then((res) => {
							// 返回登录页
							this.$router.replace({ path: "/login", query: { by: "pwd" } });
						})
						.catch((err) => console.error(err));
				} else {
					return false;
				}
			});
		},
	},
};
</script>

<style scoped lang="scss">
.page-fotgot-password-container {
	height: 100vh;
	display: flex;
	flex-direction: column;

	.header {
		flex-shrink: 0;
	}

	.page-fotgot-password-content {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		padding: 30px 0;

		.left {
			flex-shrink: 0;
		}

		.welcome {
			display: block;
			width: 520px;
			height: auto;
		}

		.right {
			flex-shrink: 0;
		}

		.form-wrapper {
			width: 460px;
			background-color: #ffffff;
			padding: 87px 60px;

			.title {
				font-size: 24px;
				line-height: 1;
				font-weight: normal;
				text-align: center;
				padding-bottom: 27px;
				border-bottom: 1px solid #f4f4f4;
				margin-bottom: 38px;
			}

			.submit-button {
				width: 100%;
				height: 40px;
				border-radius: 0;
				padding: 0;
			}

			.links {
				display: flex;
				line-height: 1;
				margin-top: 21px;
				justify-content: center;

				.link {
					color: #333333;
					font-size: 12px;
					position: relative;
					text-decoration: none;
					padding: 0 12px;

					&:not(:first-child)::after {
						content: "";
						width: 1px;
						height: 8px;
						background-color: #333333;
						position: absolute;
						left: 0;
						top: 50%;
						margin-top: -4px;
					}

					&:hover {
						color: var(--theme-color);
					}
				}
			}

			.send-sms-button {
				position: absolute;
				height: 100%;
				border-radius: 0;
				right: 0;
				width: 126px;
				background-color: transparent;
				border: none;

				&:hover {
					opacity: 0.9;
					color: var(--theme-color);
				}
			}

			::v-deep {
				.form {
					.el-input__inner {
						height: 48px;
						border-radius: 0;
						background-color: #f5f5f5;
					}
				}
			}
		}
	}

	.footer {
		flex-shrink: 0;
	}
}
</style>

<style>
.page-fotgot-password-container .el-input__inner {
	border-color: transparent;
}
</style>

<!-- Footer Two -->
<template>
	<div class="component-web-footer-two">
		<div class="wrapper layout-main">
			<div class="bottom-left">
				<div class="link" v-html="companyInfo?.view_copyright"></div>
			</div>
			<div class="bottom-right">
				<div class="link" v-html="companyInfo?.website_filing1"></div>
				<span>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</span>
				<div class="link" v-html="companyInfo?.website_filing2"></div>
				<span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
				<span class="text">{{ companyInfo?.company_name }}</span>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from "vuex";
export default {
	name: "WebFooterTwo",
	props: {},
	data() {
		return {};
	},
	computed: mapState(["companyInfo"]),
	mounted() {},
	methods: {},
};
</script>

<style lang="scss" scoped>
.component-web-footer-two {
	background-color: #eaeaea;

	.wrapper {
		padding: 12px 0;
		color: #b3b3b3;
		display: flex;
		line-height: 22px;

		.link {
			color: inherit;
			text-decoration: none;
			cursor: pointer;

			&:hover {
				text-decoration: underline;
			}
		}

		.bottom-left {
			flex: 1;
		}

		.bottom-right {
			flex-shrink: 0;
			display: flex;
			align-items: center;
		}
	}
}
</style>

<style lang="scss">
.component-web-footer-two a {
	color: inherit;
	text-decoration: none;
	cursor: pointer;

	&:hover {
		text-decoration: underline;
	}
}
</style>

<!-- 收藏标的列表 -->
<template>
	<div class="target-list-container">
		<div class="wrapper">
			<div class="item" v-for="(item, index) in list" :key="index" @click="onClickItem(item)">
				<CollectTargetListItem :item="item"></CollectTargetListItem>
			</div>
		</div>
	</div>
</template>

<script>
	import CollectTargetListItem from "@/components/CollectTargetListItem.vue";
	export default {
		name: "CollectTargetList",
		components: {
			CollectTargetListItem,
		},
		props: {
			list: Array, // 列表数据
		},
		methods: {
			/**
			 * !item点击事件
			 * @param {Object} item item数据
			 */
			onClickItem(item) {
				// 跳转到标的详情页
				this.$router.push({ name: "TargetDetails", query: { no: item.no } });
			},
		},
	};
</script>

<style lang="scss" scoped>
	.target-list-container {
		.item {
			margin-bottom: 16px;
		}
	}
</style>

<!-- 标的列表 -->
<template>
	<div class="target-list-container">
		<div class="wrapper">
			<div class="item" v-for="(item, index) in list" :key="index" @click="onClickItem(item)"
				:style="{ width: 100 / columns + '%' }">
				<TargetListItem :item="item"></TargetListItem>
			</div>
		</div>
	</div>
</template>

<script>
import TargetListItem from "@/components/TargetListItem.vue";
export default {
	name: "TargetList",
	components: {
		TargetListItem,
	},
	props: {
		// 列表数据
		list: {
			type: Array,
			default: () => [],
		},

		// 列数
		columns: {
			type: Number,
			default: 4,
		},
	},
	methods: {
		/**
		 * !item点击事件
		 * @param {Object} item item数据
		 */
		onClickItem(item) {
			// 跳转到标的详情页
			// this.$router.push({ name: "TargetDetails", query: { no: item?.no } });
			const href = this.$router.resolve({
				name: 'TargetDetails',
				query: {
					no: item?.no,
				},
			}).href
			window.open(href, '_blank')
		},
	},
};
</script>

<style lang="scss" scoped>
.target-list-container {
	.wrapper {
		margin: 0 -6px;
		display: flex;
		flex-wrap: wrap;
	}

	.item {
		width: 25%;
		padding: 0 6px;
		margin-bottom: 19px;
	}
}
</style>

/**
 * !获取用户信息数据
 */
export const getUserinfo = () => {
	let result = null;
	try {
		result = localStorage.getItem("ZHONGNUO_USERINFO");
	} catch (error) {
		console.error("读取用户信息失败:-> ", error);
	}
	return result;
};

/**
 * !获取公司信息数据
 */
export const getCompanyInfo = () => {
	let result = null;
	try {
		result = JSON.parse(localStorage.getItem("ZHONGNUO_COMPANY_INFO"));
	} catch (error) {
		console.error("读取平台信息失败:-> ", error);
	}
	return result;
};

/**
 * !获取首页数据
 */
export const getPageIndexData = () => {
	let result = null;
	try {
		result = localStorage.getItem("ZHONGNUO_PAGE_INDEX_DATA");
	} catch (error) {
		console.error("读取首页数据失败:-> ", error);
	}
	return result;
};

<!-- 个人中心 - 首页 -->
<template>
	<div class="subpage-container subpage-mine-index-container">
		<MineIndexUserBasicInfo class="user-basic-info" :status="authStatus" :numInHand="tabs.inHand.num" :numDone="tabs.done.num"></MineIndexUserBasicInfo>

		<!-- Tabs && Contents -->
		<div class="tabs-and-contents">
			<el-tabs type="border-card" v-model="tabsValue" @tab-click="handleTabClick">
				<el-tab-pane :label="tab.num === undefined ? tab.label : `${tab.label}(${tab.num})`" :name="key" v-for="(tab, key) in tabs" :key="key">
					<!-- 推荐标的 -->
					<div class="tab-content" v-if="key == 'recommend'">
						<TargetList class="target-list" :list="tab.list" :columns="3" v-if="tab.list.length"></TargetList>
						<el-empty description="数据为空" v-else></el-empty>
					</div>

					<!-- 参拍中或已拍下 -->
					<div class="tab-content" v-else>
						<TargetList class="target-list" :list="tab.list" :columns="3" v-if="tab.list.length"></TargetList>
						<el-empty description="数据为空" v-else></el-empty>
						<div class="common-pagination">
							<el-pagination
								background
								:page-size="tab.pageSize"
								:total="tab.total"
								:pager-count="5"
								:current-page="tab.pageIndex"
								hide-on-single-page
								@current-change="onChangePageIndex"
							></el-pagination>
						</div>
					</div>
				</el-tab-pane>
			</el-tabs>
		</div>
	</div>
</template>

<script>
	import MineIndexUserBasicInfo from "@/components/MineIndexUserBasicInfo.vue"; // 用户基础信息
	import TargetList from "@/components/TargetList.vue"; // 标的列表
	import { apiGetUserConstantlyInfo, apiGetRecommendTargetList, apiGetMyTargetList } from "@/apis/user.js";

	export default {
		name: "PageMineIndex",
		components: {
			MineIndexUserBasicInfo,
			TargetList,
		},
		data() {
			return {
				authStatus: "", // 用户认证状态. 10：注册成功未认证; 20：认证审核中; 30：认证审核失败; 40：认证审核通过;
				tabs: {
					// 推荐标的
					recommend: {
						label: "推荐标的",
						list: [],
					},
					// 参拍中
					inHand: {
						label: "参拍中",
						state: 30,
						num: 0, // 数量
						list: [],
						pageIndex: 1, // 当前页码
						pageSize: 20, // 每页数据长度
						total: 0, // 总数据条数
					},
					// 已拍下
					done: {
						label: "已拍下",
						state: 100,
						num: 0, // 数量
						list: [],
						pageIndex: 1, // 当前页码
						pageSize: 20, // 每页数据长度
						total: 0, // 总数据条数
					},
				}, // tabs
				tabsValue: "recommend", // 当前选中的tab名称
			};
		},
		mounted() {
			this.init();
		},
		methods: {
			init() {
				// 查询用户实时信息
				this.queryUserConstantlyInfo();

				// 查询默认tab下的列表数据
				if (this.tabsValue == "recommend") this.queryRecommondTargetList();
				else this.queryInHandOrDoneList();
			},
			/**
			 * !查询用户实时信息
			 */
			queryUserConstantlyInfo() {
				apiGetUserConstantlyInfo()
					.then((res) => {
						// 用户认证状态
						this.authStatus = res?.finalData?.user_status;

						// 彩排中和已拍下数量
						this.tabs.inHand.num = res?.finalData?.joins_auction_item_ing_count || 0;
						this.tabs.done.num = res?.finalData?.already_turnover || 0;
					})
					.catch((err) => console.error(err));
			},

			/**
			 * !查询推荐标的列表
			 */
			queryRecommondTargetList() {
				apiGetRecommendTargetList()
					.then((res) => {
						// 列表
						res?.finalData?.recommend_item?.forEach((item) => (item.now_time = res?.finalData?.now_time));
						this.tabs.recommend.list = res?.finalData?.recommend_item || [];
					})
					.catch((err) => console.error(err));
			},

			/**
			 * !查询参拍中或已拍下列表
			 */
			queryInHandOrDoneList() {
				apiGetMyTargetList({
					page: this.tabs[this.tabsValue]?.pageIndex,
					limit: this.tabs[this.tabsValue]?.pageSize,
					need_count: this.tabs[this.tabsValue]?.pageIndex == 1 ? 1 : 2,
					type: 1,
					status: this.tabs[this.tabsValue]?.state,
				})
					.then((res) => {
						console.log("参拍中或已拍下", res);
						// 数据总条数
						if (res?.finalData?.count) this.tabs[this.tabsValue].total = res?.finalData?.count;

						// 列表
						res?.finalData?.items?.forEach((item) => (item.now_time = res?.finalData?.now_time));
						this.tabs[this.tabsValue].list = res?.finalData?.items || [];
					})
					.catch((err) => console.error(err));
			},

			/**
			 * !页码切换事件
			 * @param {Number} current 当前页
			 */
			onChangePageIndex(current) {
				// 清空现有列表
				this.tabs[this.tabsValue].list = [];

				// 更新页码
				this.tabs[this.tabsValue].pageIndex = current;

				// 查询新的列表数据
				this.queryInHandOrDoneList();
			},

			/**
			 * !tab 被选中时触发
			 * @param {Object} tab 被选中的标签 tab 实例
			 *
			 */
			handleTabClick(tab) {
				// 如果tab下的数据列表为空,就查询一下数据,否则就不用查询
				if (!this.tabs[this.tabsValue].list.length && this.tabsValue == "recommend") return this.queryRecommondTargetList();
				if (!this.tabs[this.tabsValue].list.length && this.tabsValue != "recommend") return this.queryInHandOrDoneList();
			},
		},
	};
</script>

<style scoped lang="scss">
	.user-basic-info {
		margin-bottom: 18px;
	}

	::v-deep {
		.el-tabs--border-card {
			background-color: transparent;
		}
		.el-tabs__content {
			padding: 20px 0 0 0;
		}
	}
</style>

/**
 * ****************************************************************************************************
 * 用户相关接口
 * ****************************************************************************************************
 */
import HiRequest from "@/request/index.js";
export const hr = new HiRequest();

// 注册
export const apiRegister = (data = {}, options = {}) => hr.post("api/v1/passport/register", data, options);

// 密码登录
export const apiLoginByPwd = (data = {}, options = {}) => hr.post("api/v1/passport/login_password", data, options);

// 验证码登录
export const apiLoginByCaptcha = (data = {}, options = {}) => hr.post("api/v1/passport/login_captcha", data, options);

// 找回密码
export const apiRetrievePassword = (data = {}, options = {}) => hr.post("api/v1/passport/retrieve_password", data, options);

// 用户认证
export const apiUserAttestation = (data = {}, options = {}) => hr.post("api/v1/passport/authentication", data, options);

// 获取用户认证信息
export const apiGetUserAttestationInfo = (data = {}, options = {}) => hr.get("api/v1/passport/authentication_info", data, options);

// 获取用户实时信息(认证状态 + 参拍中数量 + 已拍下数量)
export const apiGetUserConstantlyInfo = (data = {}, options = {}) => hr.get("api/v1/user_center/index", data, options);

// 修改用户信息
export const apiEditUserinfo = (data = {}, options = {}) => hr.post("api/v1/passport/edit_info", data, options);

// 修改密码
export const apiEditPassword = (data = {}, options = {}) => hr.post("api/v1/passport/edit_password", data, options);

// 获取推荐标的列表
export const apiGetRecommendTargetList = (data = {}, options = {}) => hr.get("api/v1/user_center/recommend_item", data, options);

// 获取我的标的列表(参拍中的标的 || 已拍下的标的 || 委托竞拍标的)
export const apiGetMyTargetList = (data = {}, options = {}) => hr.get("api/v1/user_center/enroll_item", data, options);

// 获取用户收藏标的列表
export const apiGetCollectionTargetList = (data = {}, options = {}) => hr.get("api/v1/collect/lists", data, options);

// 获取用户信息（返回意向城市ID）
export const apiGetUserPassPortInfo = (data = {}, options = {}) => hr.get("api/v1/passport/user_info", data, options)

// 获取省市数据
export const apiGetRegionData = (data = {},options = {}) => hr.get("api/v1/common/region", data, options);

// 企业后台登录
export const apiEnterpriseLogin = (data = {}, options = {}) => hr.post("/enterprise/login", data,options)

export const apiEnterpriseDisLogin = (data = {}, options = {}) => hr.post("/disposal/login", data,options)

// 拍卖师登录
export const apiAuctioneerLogin = (data = {}, options = {}) => hr.post("api/auctioneer/v1/login", data, options)

// 注销账号
export const apiCancel = (data = {}, options = {}) => hr.post("api/v1/passport/account_cancel", data,options)
<!-- 认证审核中弹窗 -->
<template>
	<el-dialog
		class="component-auth-in-review-dialog"
		custom-class="component-auth-in-review-dialog"
		:visible="showDialog"
		width="360px"
		:show-close="false"
		destroy-on-close
		@close="showDialog = false"
	>
		<div class="component-auth-adopt-dialog-wrapper">
			<div class="content">
				<i class="icon zn-iconfont zn-iconfont-chenggong"></i>
				<p class="tips">已提交人工审核<br />请耐心等待</p>
				<router-link class="go-home" to="/" replace>回到首页</router-link>
			</div>
			<div class="close-box">
				<i class="close-icon zn-iconfont zn-iconfont-guanbi1" @click="handleClose"></i>
			</div>
		</div>
	</el-dialog>
</template>

<script>
export default {
	name: "AuthInReviewDialog",
	props: {},
	data() {
		return {
			showDialog: false, // 弹窗显示状态
		};
	},
	mounted() {},
	methods: {
		/**
		 * !显示
		 */
		open() {
			this.showDialog = true;
		},

		/**
		 * !关闭
		 */
		close() {
			this.showDialog = false;
		},

		/**
		 * !点击了关闭按钮
		 */
		handleClose() {
			this.close();
			if (sessionStorage["ZHONGNUO_FROM_PATH"]) this.$router.go(-1);
			else this.$router.replace({ path: "/" });
		},
	},
};
</script>

<style lang="scss" scoped>
.component-auth-in-review-dialog {
	display: flex;
	flex-direction: column;
	align-items: center;

	.content {
		display: flex;
		flex-direction: column;
		align-items: center;
		background-color: #ffffff;
		width: 100%;
		padding: 46px 82px 47px;
		margin-bottom: 12px;

		.icon {
			font-size: 66px;
			color: var(--theme-color);
			margin-bottom: 16px;
		}

		.tips {
			line-height: 26px;
			font-size: 20px;
			margin-bottom: 24px;
			text-align: center;
		}

		.go-home {
			padding: 0;
			display: flex;
			width: 100%;
			height: 50px;
			text-decoration: none;
			background-color: var(--theme-color);
			color: #ffffff;
			line-height: 1;
			transition: 0.2s;
			align-items: center;
			justify-content: center;

			&:hover {
				opacity: 0.8;
			}
		}
	}

	.close-box {
		width: 44px;
		height: 44px;
		overflow: hidden;
		background-color: rgba($color: #ffffff, $alpha: 0.5);
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		user-select: none;
		transition: 0.2s;
		margin: 2px auto;

		.close-icon {
			font-size: 24px;
			color: #ffffff;
		}

		&:hover {
			background-color: var(--theme-color);
		}
	}
}
</style>

<style lang="scss">
.component-auth-in-review-dialog .el-dialog {
	background-color: transparent;
	box-shadow: none;
}

.component-auth-in-review-dialog .el-dialog__header {
	display: none;
}

.component-auth-in-review-dialog .el-dialog__body {
	padding: 0;
}
</style>

/**
 * ****************************************************************************************************
 * Request 请求拦截器
 * @param {Object} config 合并后的请求配置项
 * @return {Boolean} 该函数返回 true 则请求继续，否则，中断请求
 * ****************************************************************************************************
 */
export default function (config) {
	return true;
}

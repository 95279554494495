<!-- 新闻资讯列表项 -->
<template>
	<div class="component-news-info-list-item">
		<div class="left">
			<div class="cover-box">
				<img class="cover" :src="require('@/assets/images/news-default-cover.png')" alt="" v-if="listType == 'newsList'" />
				<img class="cover" :src="require('@/assets/images/notice-default-cover.png')" alt="" v-else-if="listType == 'noticeList'" />
			</div>
		</div>
		<div class="content">
			<div class="top">
				<h1 class="title ellipsis2">{{ item?.title }}</h1>
				<div class="desc ellipsis3">{{ item?.profile || item?.description }}</div>
			</div>
			<div class="bot">
				发布时间 <span class="date">{{ dayjs.unix(item?.create_time || item?.publish_time).format("YYYY/MM/DD") }}</span>
			</div>
		</div>
		<div class="right">
			<el-button type="primary button-see-detail">查看详情</el-button>
		</div>
	</div>
</template>

<script>
	export default {
		name: "NewsInfoListItem",
		props: {
			// item数据
			item: {
				type: Object,
				default: () => {},
			},

			// 列表类型. newsList: 新闻列表; noticeList: "公告列表"
			listType: {
				type: String,
				default: "",
			},
		},
	};
</script>

<style lang="scss" scoped>
	.component-news-info-list-item {
		display: flex;
		padding: 30px 0;
		align-items: center;
		cursor: pointer;

		.left {
			flex-shrink: 0;

			.cover-box {
				width: 292px;
				height: 164px;
				overflow: hidden;
				background-color: #dedede;
			}

			.cover {
				display: block;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		.content {
			flex: 1;
			padding: 12px 30px 14px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-self: stretch;

			.title {
				font-size: 18px;
				font-weight: normal;
				line-height: 26px;
				margin-bottom: 8px;
				transition: 0.2s;
			}

			.desc {
				color: #999999;
				line-height: 28px;
				transition: 0.2s;
			}

			.bot {
				color: #c1c1c1;
				line-height: 22px;
				transition: 0.2s;

				.date {
					font-family: "MarkPro";
				}
			}
		}

		.right {
			flex-shrink: 0;
			padding: 0 32px;

			.button-see-detail {
				width: 112px;
				height: 44px;
				padding: 0;
				border: 1px solid var(--theme-color);
				transition: 0.2s;
				border-radius: 0;
				font-size: 16px;

				&:hover {
					background-color: #ffffff;
					color: var(--theme-color);
				}
			}
		}

		&:hover {
			.content {
				.title {
					color: var(--theme-color);
				}

				.desc {
					color: var(--theme-color);
				}

				.bot {
					color: var(--theme-color);
				}
			}

			.right {
				.button-see-detail {
					background-color: #ffffff;
					color: var(--theme-color);
				}
			}
		}
	}
</style>

<!-- 废钢统计 - 全国平均价格 -->
<template>
	<div class="component-average-price-nationwide">
		<div class="head">
			<div class="title-box">
				<h1 class="title">全国平均价格</h1>
				<span class="tip" v-if="type == 20">重废≥6mm</span>
				<span class="tip" v-else>1#废铜价格</span>
			</div>
			<div class="term-box">
				<img class="icon-date" :src="require('@/assets/images/icon-date.png')" alt="" />
				<el-select class="date-picker" v-model="dateValue" placeholder="请选择日期" @change="onChangeDate">
					<el-option v-for="item in dates" :key="item" :label="item" :value="item"> </el-option>
				</el-select>
				<i class="arrow-icon zn-iconfont zn-iconfont-xia"></i>
			</div>
		</div>
		<div class="middle">
			<div class="left">
				<span class="date" v-if="dateValue">{{ dayjs(dateValue || "", "YYYY-MM-DD").format("MM-DD") }} 北京时间</span>
				<span class="date" v-else> 北京时间</span>
				<div class="price-box">
					<span class="price">{{ formatPrice(charts?.[charts?.length - 1]?.price || 0) }}</span>
					<span class="unit">/吨</span>
				</div>
			</div>
			<div class="height-lower">
				<div class="row">
					<label class="label">最高：</label>
					<span class="value highest">{{ formatPrice(highLow?.high_price) }}元 {{ highLow?.high_city_name }}</span>
				</div>
				<div class="row">
					<label class="label">最低：</label>
					<span class="value lowest">{{ formatPrice(highLow?.low_price) }}元 {{ highLow?.low_city_name }}</span>
				</div>
			</div>
			<ul class="dates">
				<li class="item" v-for="(item, index) in dateItems" :key="item.value" :class="{ active: index == dateItemsCurrent }" @click="onChangeDays(index)">
					{{ item.label }}
				</li>
			</ul>
		</div>
		<div class="chart" id="echartsContainer"></div>
	</div>
</template>

<script>
	import * as echarts from "echarts";
	import "@/echarts/theme.js";
	export default {
		name: "AveragePriceNationwide",
		props: {
			dates: Array, // 日期列表
			defaultDate: [String, Date], // 默认日期
			highLow: Object, // 最高最低数据
			charts: Array, // 图表数据
			type:String, // 类型
		},
		data() {
			return {
				dateValue: "", // 选择的日期
				dateItems: [
					{ label: "10天", value: 10 },
					{ label: "15天", value: 15 },
					{ label: "30天", value: 30 },
				], // 展示条件选项
				dateItemsCurrent: 0, // 当前选择的展示条件选项的下标
				echart: {
					instance: null, // 实例
					// 配置项
					option: {
						title: {
							show: false,
						},
						tooltip: {
							trigger: "axis",
						},
						grid: {
							left: "10%",
							top: 20,
							right: 0,
							bottom: 30,
						},
						xAxis: {
							type: "category",
							// data: ["02-10", "02-11", "02-12", "02-13", "02-14", "02-15", "02-16", "02-17", "02-18"],
							data: [],
						},
						yAxis: {
							type: "value",
							min: 0,
							max: 100000,
							splitNumber: 5,
							axisLine: {
								show: false,
							},
							axisLabel: {
								color: "#999999",
								align: "right",
							},
						},
						series: [
							{
								// data: [73678, 73706, 74265, 74068, 74000, 74765, 75000, 74550, 74526],
								data: [],
								type: "line",
							},
						],
					},
				},
			};
		},
		watch: {
			// 监听默认日期的值
			defaultDate: {
				handler() {
					this.dateValue = this.defaultDate;
				},
				deep: true,
				immediate: true,
			},

			// 监听图表数据
			charts: {
				handler() {
					// 图表数据正常
					if (this.charts && this.charts.length) {
						let xAxisData = [];
						let yAxisMin = 0;
						let yAxisMax = 0;
						let yAxisSeriesItemData = [];
						this.charts?.forEach((item, index) => {
							xAxisData.push(item.dates);
							yAxisSeriesItemData.push(item.price);
							if (index == 0) {
								yAxisMin = item.price;
								yAxisMax = item.price;
							} else {
								if (item.price < yAxisMin) yAxisMin = item.price;
								if (item.price > yAxisMax) yAxisMax = item.price;
							}
						});
						this.echart.option.xAxis.data = xAxisData;
						this.echart.option.yAxis.min = yAxisMin;
						this.echart.option.yAxis.max = yAxisMax;
						this.echart.option.series = [{ data: yAxisSeriesItemData, type: "line" }];
					}

					// 图表数据为空
					else {
						this.echart.option.xAxis.data = [];
						this.echart.option.yAxis.min = 0;
						this.echart.option.yAxis.max = 100000;
						this.echart.option.yAxis.series = [{ data: [], type: "line" }];
					}

					// 初始化或更新图表
					this.$nextTick(() => {
						let timer = setTimeout(() => {
							clearTimeout(timer);
							this.initECharts();
						}, 300);
					});
				},
				deep: true,
				immediate: true,
			},
		},
		mounted() {},
		methods: {
			/**
			 * !切换日期
			 * @param {String} date 当前选中日期
			 */
			onChangeDate(date) {
				this.$emit("changeDate", this.getEmitData());
			},

			/**
			 * !获取需要通知给父元素的数据
			 */
			getEmitData() {
				return {
					dates: this.dateValue,
					days: this.dateItems?.[this.dateItemsCurrent]?.value,
				};
			},

			/**
			 * !切换天数
			 * @param {Number} index 点击的天数的下标
			 */
			onChangeDays(index) {
				this.dateItemsCurrent = index;
				this.$emit("changeDate", this.getEmitData());
			},

			/**
			 * !初始化或更新图表
			 */
			initECharts() {
				let echartComtainer = document.getElementById("echartsContainer");

				// 初始化图表
				if (!this.echart.instance && echartComtainer) {
					this.echart.instance = echarts.init(echartComtainer, "zhongnuo-pc");
				}

				// 更新数据
				this.echart.instance?.setOption(this.echart.option);

				// 监听图表容器的大小并改变图表大小
				// window.addEventListener("resize", function () {
				// 	this.echart.instance.resize();
				// });
			},
		},
	};
</script>

<style lang="scss" scoped>
	.component-average-price-nationwide {
		padding: 0 40px 37px 40px;

		.head {
			display: flex;
			align-items: center;
			padding: 28px 0 24px;
			border-bottom: 2px dotted #c5c5c5;

			.title-box {
				flex: 1;
			}

			.title {
				font-size: 22px;
				display: inline-block;
			}

			.tip {
				color: #666666;
				display: inline-block;
				margin-left: 12px;
			}

			.term-box {
				flex-shrink: 0;
				width: 160px;
				position: relative;

				.icon-date {
					display: block;
					width: 18px;
					height: 20px;
					position: absolute;
					z-index: 8;
					top: 50%;
					left: 10px;
					margin-top: -10px;
				}

				.date-picker {
					width: 100%;
				}

				.arrow-icon {
					position: absolute;
					font-size: 16px;
					right: 10px;
					top: 50%;
					transform: translateY(-50%);
				}
			}
		}

		.middle {
			display: flex;
			align-items: stretch;
			padding: 26px 0;
			color: #999999;

			.left {
				flex-shrink: 0;
				width: 132px;
				display: flex;
				flex-direction: column;

				.price-box {
					margin-top: 3px;
				}

				.price {
					font-size: 30px;
					font-family: "MarkPro";
					color: var(--theme-color);
				}
			}

			.height-lower {
				flex: 1;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				padding-bottom: 6px;

				.highest {
					color: var(--theme-color);
				}

				.lowest {
					color: var(--theme-color2);
				}
			}

			.dates {
				flex-shrink: 0;
				list-style: none;
				display: flex;

				.item {
					width: 65px;
					height: 41px;
					display: flex;
					align-items: center;
					justify-content: center;
					color: #666666;
					border: 1px solid #dcdcdc;
					cursor: pointer;
					user-select: none;
					margin-left: 8px;

					&.active {
						color: var(--theme-color);
						border-color: var(--theme-color);
					}
				}
			}
		}

		.chart {
			height: 200px;
			width: 100%;
		}

		::v-deep {
			.date-picker .el-input__inner {
				height: 40px;
				border-radius: 0;
				color: #666666;
				padding-left: 46px;
			}

			.date-picker .el-icon-date {
				opacity: 0;
			}
		}
	}
</style>

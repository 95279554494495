<template>
	<div id="app" v-cloak>
		<router-view :key="key"></router-view>
	</div>
</template>

<script>
	import { apiViewData, apiGetServerTime } from "@/apis/common.js";
	export default {
		name: "App",
		computed: {
			// 给 router-view 加个唯一的key，来保证路由切换时都会重新渲染触发钩子
			key() {
				return this.$route.name ? this.$route.name + new Date() : this.$route + new Date();
			},
		},
		mounted() {
			// 查询平台数据
			this.queryViewData();

			// 页面显示后更新服务器时间
			document.addEventListener("visibilitychange", () => {
				if (document.visibilityState === "visible") {
					let pageHideTime = Number(sessionStorage.getItem("PAGE_HIDE_TIME") || 0);
					sessionStorage.removeItem("PAGE_HIDE_TIME");
					if (!pageHideTime) {
						console.log("无需刷新页面");
						return;
					}
					console.log("时间戳 ->", pageHideTime);
					let currentTime = new Date().getTime();
					console.log("当前时间 ->", currentTime);
					console.log("时间差 ->", currentTime - Number(pageHideTime));

					if (currentTime - pageHideTime >= 60000) {
						console.log("页面在后台驻留超过了1分钟，需要刷新页面");
						const currentInstance = this.$children.find((child) => child.$route === this.$route);
						if (currentInstance && typeof currentInstance.init === "function") {
							currentInstance.init();
						}
						return;
					}
				} else {
					console.log("页面隐藏了");
					sessionStorage.setItem("PAGE_HIDE_TIME", new Date().getTime());
				}
			});
		},
		methods: {
			/**
			 * @description: 查询平台数据
			 * @return {*}
			 */
			queryViewData() {
				apiViewData(
					{},
					{
						loadingShow: false,
						errorShow: false,
					}
				).then((res) => {
					console.log('页面数据',res);
					// 更新数据
					this.$store.commit("updateCompanyInfo", res?.finalData);
				});
			},
		},
	};
</script>

<style lang="scss">
	@import url("https://at.alicdn.com/t/c/font_3949507_hreovnn0jdi.css");
	@import url("~@/assets/fonts/fonts.css");
	@import url("~@/assets/styles/var.scss");
	@import url("~@/assets/styles/unify.scss");
	@import url("~@/assets/styles/classes.scss");
	@import url("~@/assets/styles/element-ui-rewrite.scss");

	:root {
		--theme-color: #d6363b;
		--theme-color-deep: #961629;
		--theme-color-light: rgb(208, 44, 35, 0.1);
		--theme-color2: #20b79a;
		--theme-color3: #999999;
		--theme-color4: #008c0c;
		--theme-color5: #bb1c33;
		--theme-color6: #999999;
		--border-color: #f4f4f4;
		--linear-gradient-blue: linear-gradient(90deg, #4f99f2, #1e6ade);
		--linear-gradient-green: linear-gradient(90deg, #65c431, #45ab19);
		--linear-gradient-ching: linear-gradient(90deg, #5ad9d1, #0a989d);
	}
</style>

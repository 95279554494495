/**
 * !全站所有页面的面包屑数据
 */
// 标的 - 列表
export const breadcrumbsTargetList = [
	{ name: "首页", to: { name: "Index" } },
	{ name: "标的", to: { name: "TargetList" } },
];

// 标的 - 详情
export const breadcrumbsTargetDetails = [
	{ name: "首页", to: { name: "Index" } },
	{ name: "标的", to: { name: "TargetList" } },
	// { name: "山东菏泽钢模板/废旧钢筋头一批拍卖会", to: { name: "TargetDetails" } },
];

// 标的 - 报名
export const breadcrumbsTargetEnroll = [
	{ name: "首页", to: { name: "Index" } },
	{ name: "标的", to: { name: "TargetList" } },
	// { name: "山东菏泽钢模板/废旧钢筋头一批拍卖会", to: { name: "TargetDetails" } },
	// { name: "报名", to: { name: "TargetEnroll" } },
];

// 个人中心
export const breadcrumbsMine = [
	{ name: "首页", to: { name: "Index" } },
	{ name: "个人中心", to: { name: "Mine" } },
];

// 拍卖会 - 列表
export const breadcrumbsAuctionList = [
	{ name: "首页", to: { name: "Index" } },
	{ name: "拍卖会", to: { name: "AuctionList" } },
];

// 拍卖会 - 详情
export const breadcrumbsAuctionDetails = [
	{ name: "首页", to: { name: "Index" } },
	{ name: "拍卖会", to: { name: "AuctionList" } },
	// { name: "山东菏泽钢模板/废旧钢筋头一批拍卖会", to: "AuctionDetails" },
];

// 求购租赁 - 列表
export const breadcrumbsBuyLeaseList = [
	{ name: "首页", to: { name: "Index" } },
	{ name: "求购租赁", to: { name: "BuyLeaseList" } },
];

// 求购租赁 - 详情
export const breadcrumbsBuyLeaseDetails = [
	{ name: "首页", to: { name: "Index" } },
	{ name: "求购租赁", to: { name: "BuyLeaseList" } },
];

// 废钢统计 - 列表
export const breadcrumbsScrapStatisticsList = [
	{ name: "首页", to: { name: "Index" } },
	// { name: "废钢统计", to: { name: "ScrapStatisticsList" } },
];

// 成交案例 - 列表
export const breadcrumbsTransactionCasesList = [
	{ name: "首页", to: { name: "Index" } },
	{ name: "成交案例", to: { name: "TransactionCasesList" } },
];

// 入驻企业 - 列表
export const breadcrumbsSettledEnterpriseList = [
	{ name: "首页", to: { name: "Index" } },
	{ name: "入驻企业", to: { name: "SettledEnterpriseList" } },
];

// 入驻企业 - 详情
export const breadcrumbsSettledEnterpriseDetails = [
	{ name: "首页", to: { name: "Index" } },
	{ name: "入驻企业", to: { name: "SettledEnterpriseList" } },
	// { name: "吉林化学工业进出口有限公司", to: { name: "SettledEnterpriseDetails" } },
];

// 帮助中心 - 首页
export const breadcrumbsHelpCenterIndex = [
	{ name: "首页", to: { name: "Index" } },
	{ name: "帮助中心", to: { name: "HelpCenterIndex" } },
];

// 公告中心 - 首页
export const breadcrumbsNoticeCenterList = [
	{ name: "首页", to: { name: "Index" } },
	{ name: "公告中心", to: { name: "NoticeCenterList" } },
];

// 公告中心 - 详情
export const breadcrumbsNoticeCenterDetails = [
	{ name: "首页", to: { name: "Index" } },
	{ name: "公告中心", to: { name: "NoticeCenterList" } },
	{ name: "公告详情", to: { name: "NoticeCenterDetails" } },
];

// 新闻中心 - 首页
export const breadcrumbsNewsCenterList = [
	{ name: "首页", to: { name: "Index" } },
	{ name: "新闻中心", to: { name: "NewsCenterList" } },
];

// 新闻中心 - 详情
export const breadcrumbsNewsCenterDetails = [
	{ name: "首页", to: { name: "Index" } },
	{ name: "新闻中心", to: { name: "NewsCenterList" } },
	{ name: "新闻详情", to: { name: "NewsCenterDetails" } },
];

<!-- 协议弹窗 -->
<template>
	<el-dialog class="component-agreement-dialog" :visible="showDialog" width="800px" :show-close="false" destroy-on-close @close="showDialog = false">
		<div class="component-agreement-dialog-wrapper">
			<div class="wrapper">
				<h1 class="title">{{ title }}</h1>
				<div class="content">
					<div class="inner">
						<div v-html="content"></div>
					</div>
				</div>
			</div>
			<div class="close-box">
				<i class="close-icon zn-iconfont zn-iconfont-guanbi1" @click="showDialog = false"></i>
			</div>
		</div>
	</el-dialog>
</template>

<script>
export default {
	name: "AgreementDialog",
	props: {
		title: String, // 协议标题
		content: String, // 协议内容
	},
	data() {
		return {
			showDialog: false, // 弹窗显示状态
		};
	},
	mounted() {},
	methods: {
		/**
		 * !显示
		 */
		open() {
			this.showDialog = true;
		},

		/**
		 * !关闭
		 */
		close() {
			this.showDialog = false;
		},
	},
};
</script>

<style lang="scss" scoped>
.component-agreement-dialog-wrapper {
	.wrapper {
		background-color: #ffffff;
		padding: 30px 39px 38px;

		.title {
			font-size: 18px;
			line-height: 26px;
			text-align: center;
			margin-bottom: 20px;
		}

		.content {
			min-height: 160px;
			max-height: 367px;
			overflow-x: hidden;
			overflow-y: auto;
			color: #666666;
			line-height: 20px;
			text-align: justify;

			&::-webkit-scrollbar {
				width: 12px;
				background-color: #f5f5f5;
			}

			&::-webkit-scrollbar-thumb {
				background-color: #dfdfdf;
			}

			.inner {
				padding-right: 8px;
			}
		}
	}

	.close-box {
		width: 44px;
		height: 44px;
		overflow: hidden;
		background-color: #bdbdbd;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		user-select: none;
		transition: 0.2s;
		margin: 12px auto;

		.close-icon {
			font-size: 24px;
			color: #ffffff;
		}

		&:hover {
			background-color: var(--theme-color);
		}
	}
}
</style>

<!-- 聊天室 -->
<template>
	<div class="component-chat-room">
		<div class="head">拍卖师发言</div>
		<div class="content" v-if="canSeeInfo">
			<div class="wrapper" @scroll="onScroll">
				<div class="content" id="ScrollContent">
					<div class="more-wrapper">
						<span class="loadmore" v-if="loadStatus == 'loadmore'" @click="$emit('moreMessage')">查看更多</span>
						<div v-if="loadStatus == 'loading'" class="loading">
							<i class="el-icon-loading"></i>
							<span>加载中</span>
						</div>
						<span v-else-if="loadStatus == 'nomore'">已加载全部聊天消息</span>
					</div>
					<ul class="list">
						<li class="item" v-for="item in list" :key="item.id + '_' + item.create_time">
							<span class="datetime">{{ dayjs.unix(item.create_time).format("YYYY.MM.DD HH:mm:ss") }}</span>
							<div class="msg">{{ item.content }}</div>
						</li>
					</ul>
					<!-- 最底部锚点,用来平滑滚动到最底部 -->
					<div id="BottomAnchorElement"></div>
				</div>
			</div>
		</div>
		<div class="content unauth-tip" v-else>报名审核通过后查看聊天信息</div>
	</div>
</template>

<script>
	export default {
		name: "ChatRoom",
		props: {
			// 聊天消息列表
			list: {
				type: Array,
				default: () => [],
			},

			// 消息加载状态. 'loadmore': 加载更多; 'loading': 加载中; 'nomore': 已加载全部;
			loadStatus: {
				type: String,
				default: "loadmore",
			},

			// 是否可以查看信息
			canSeeInfo: { type: Boolean, default: false },
		},
		data() {
			return {
				isAutoScrollToBottom: true, // 是否自动滚动到最底部?
				openAutoScrollToBottomTimer: null, // 开启自动滚动到最底部的定时器
			};
		},
		watch: {
			// 监听聊天消息列表数据
			list: {
				handler(newList) {
					// 是否需要滚动到最底部
					if (this.isAutoScrollToBottom) this.scrollToBottom();
				},
				deep: true,
				immediate: true,
			},
		},
		methods: {
			/**
			 * !滚动到聊天消息最底部
			 */
			scrollToBottom() {
				this.$nextTick(() => {
					let timer = setTimeout(() => {
						document.getElementById("BottomAnchorElement")?.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "start" });
						clearTimeout(timer);
					}, 200);
				});
			},

			/**
			 * !监听滚动事件
			 */
			onScroll(event) {
				// 滚动容器
				let scrollContainer = event.target;
				let containerHeight = scrollContainer.offsetHeight; // 容器高度
				let scrollHeight = scrollContainer.scrollHeight; // 滚动条总高度
				let scrollTop = scrollContainer.scrollTop; // 滚动距离

				// 滚动到了最底部
				if (containerHeight + scrollTop >= scrollHeight) {
					// 开启自动滚动到最底部功能
					this.isAutoScrollToBottom = true;
				}

				// 否则,没有滚动到最底部
				else {
					// 关闭自动滚动到最底部功能
					this.isAutoScrollToBottom = false;

					// 无操作一定时间后,自动开启自动滚动到最底部的功能,以防用户长时间看不到最新消息
					if (this.openAutoScrollToBottomTimer) {
						clearTimeout(this.openAutoScrollToBottomTimer);
						this.openAutoScrollToBottomTimer = null;
					}
					this.openAutoScrollToBottomTimer = setTimeout(() => {
						this.isAutoScrollToBottom = true;
						clearTimeout(this.openAutoScrollToBottomTimer);
						this.openAutoScrollToBottomTimer = null;
					}, 1000 * 60 * 3);
				}
			},
		},
	};
</script>

<style lang="scss" scoped>
	.component-chat-room {
		background-color: #ffffff;
		display: flex;
		flex-direction: column;

		.head {
			background-color: var(--theme-color);
			color: #ffffff;
			height: 36px;
			line-height: 36px;
			padding: 0 18px;
			flex-shrink: 0;
		}

		.content {
			flex: 1;
			overflow: hidden;

			.wrapper {
				height: 100%;
				overflow-x: hidden;
				overflow-y: auto;

				&::-webkit-scrollbar {
					width: 10px;
					background-color: #e1e1e1;
				}

				&::-webkit-scrollbar-thumb {
					width: 10px;
					background-color: #c1c1c1;
				}
			}

			.more-wrapper {
				display: flex;
				align-items: center;
				justify-content: center;
				margin-top: 12px;
				font-size: 12px;
				margin-bottom: 6px;
				color: #666666;

				.loadmore {
					cursor: pointer;
					user-select: none;
				}

				.loading {
					display: flex;
					align-items: center;
					justify-content: center;

					.el-icon-loading {
						margin-right: 3px;
					}
				}
			}

			.list {
				padding: 18px;
				list-style: none;
			}

			.item {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				margin-bottom: 20px;
			}

			.datetime {
				color: #999999;
				display: block;
				line-height: 1;
				margin-bottom: 11px;
			}

			.msg {
				background-color: #f5f5f5;
				border-radius: 8px;
				overflow: hidden;
				line-height: 20px;
				padding: 12px 20px;
			}
		}

		.unauth-tip {
			text-align: center;
			color: #999999;
			padding-top: 20px;
			font-size: 12px;
		}
	}
</style>

import Vue from "vue";
import ElementUI from "element-ui";
import "./element-ui/theme/index.css";
import App from "./App.vue";
import router from "./route/index.js";
import store from "./store/index.js";
import dayjs from "dayjs"; // 日期处理类库
import currency from "currency.js"; // 货币处理类库
import Swiper from "swiper";
import "swiper/css/swiper.min.css";
import { format400Tel, formatPrice, secondsToHoursMinutesSeconds } from "./utils/func.js";

Vue.use(ElementUI);
Vue.prototype.dayjs = dayjs;
Vue.prototype.currency = currency;
Vue.prototype.Swiper = Swiper;

// 工具函数
Vue.prototype.formatPrice = formatPrice; // 格式化金额
Vue.prototype.format400Tel = format400Tel; // 格式化400电话
Vue.prototype.secondsToHoursMinutesSeconds = secondsToHoursMinutesSeconds; // 将秒数转换成"hh小时xx分钟xx秒"格式

// 防抖指令
// 在用户进行报价等操作时,给按钮添加防抖,防止用户段时间内重复点击
Vue.directive("debounce", {
	inserted(el, binding) {
		el.addEventListener("click", (e) => {
			console.log("el", el.innerHTML, binding);
			let innerHTML = el.innerHTML;
			el.classList.add("is-disabled");
			el.disabled = true;
			if (binding?.value) el.innerHTML = binding.value;
			let timer = setTimeout(() => {
				el.disabled = false;
				el.classList.remove("is-disabled");
				el.innerHTML = innerHTML;
				clearTimeout(timer);
			}, 1000);
		});
	},
});

// 如果你不想在每个 Promise 链中都写.catch()方法来处理错误，但又不想在控制台中看到未捕获的异常错误，你可以使用全局的unhandledrejection事件来捕获未处理的 Promise 错误。
// 在全局范围内监听unhandledrejection事件，并在事件处理程序中处理错误。这样，即使你没有在每个 Promise 链中写.catch()方法，仍然可以捕获并处理未处理的 Promise 错误。
// window.addEventListener("unhandledrejection", (event) => {
// 	// 获取未处理的 Promise 错误
// 	const error = event.reason;

// 	// 处理错误逻辑
// 	// ...

// 	// 防止错误继续传播
// 	event.preventDefault();
// });

Vue.config.productionTip = false;

new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount("#app");

<!-- 拍卖会 - 列表 -->
<template>
	<div class="page-container page-auction-list-container">
		<WebHeader></WebHeader>
		<div class="page-auction-list-content">
			<!-- 面包屑 -->
			<Breadcrumbs :list="breadcrumbs"></Breadcrumbs>

			<!-- 主内容区域 -->
			<div class="layout-main">
				<!-- 搜索 -->
				<SearchFilter
					ref="SearchFilter"
					class="search-filter"
					:region="region"
					:rentTypes="rentTypes"
					:productTypes="productTypes"
					@change="handleSearch"
				></SearchFilter>

				<!-- 筛选 -->
				<DataFilter ref="DataFilter" class="data-filter" @submit="handleSearch"></DataFilter>

				<!-- 租赁 -->
				<div class="module" v-if="!rentTypesId || rentTypesId == 2">
					<h1 class="title">出租</h1>
					<div class="list" v-if="lists.lease.list && lists.lease.list.length">
						<BuyLeaseList class="auction-list" :list="lists.lease.list"></BuyLeaseList>
						<el-button class="btn" type="primary" :loading="lists.lease.loading" v-if="lists.lease.list.length < lists.lease.total" @click="queryList(2)">{{
							lists.lease.loading ? "加载中..." : "加载更多"
						}}</el-button>
					</div>
					<el-empty v-else></el-empty>
				</div>

				<!-- 置换 -->
				<div class="module" v-if="!rentTypesId || rentTypesId == 4">
					<h1 class="title">置换</h1>
					<div class="list" v-if="lists.displace.list && lists.displace.list.length">
						<BuyLeaseList class="auction-list" :list="lists.displace.list"></BuyLeaseList>
						<el-button
							class="btn"
							type="primary"
							:loading="lists.displace.loading"
							v-if="lists.displace.list.length < lists.displace.total"
							@click="queryList(4)"
							>{{ lists.displace.loading ? "加载中..." : "加载更多" }}</el-button
						>
					</div>
					<el-empty v-else></el-empty>
				</div>

				<!-- 求购 -->
				<div class="module" v-if="!rentTypesId || rentTypesId == 1">
					<h1 class="title">求购</h1>
					<div class="list" v-if="lists.buy.list && lists.buy.list.length">
						<!-- <BuyLeaseList class="auction-list" :list="lists.buy.list"></BuyLeaseList> -->
						<BuyItem v-for="item in lists.buy.list" :key="item.id" :item="item" class="buy-item"></BuyItem>
						<el-button class="btn" type="primary" :loading="lists.buy.loading" v-if="lists.buy.list.length < lists.buy.total" @click="queryList(1)">{{
							lists.buy.loading ? "加载中..." : "加载更多"
						}}</el-button>
					</div>
					<el-empty v-else></el-empty>
				</div>

				<!-- 列表 -->
				<!-- <BuyLeaseList class="auction-list" :list="list" v-if="list && list.length"></BuyLeaseList> -->

				<!-- 数据为空 -->
				<!-- <el-empty v-else></el-empty> -->

				<!-- 分页器 -->
				<!-- <div class="common-pagination">
					<el-pagination
						background
						:page-size="pageSize"
						:total="total"
						:pager-count="5"
						:current-page="pageIndex"
						hide-on-single-page
						@current-change="onChangePageIndex"
					></el-pagination>
				</div> -->
			</div>
		</div>
		<WebFooter></WebFooter>
	</div>
</template>

<script>
	import WebHeader from "@/components/WebHeader.vue"; // 页面Header
	import WebFooter from "@/components/WebFooter.vue"; // 页面Footer
	import Breadcrumbs from "@/components/Breadcrumbs.vue"; // 面包屑
	import SearchFilter from "@/components/SearchFilter.vue"; // 搜索过滤
	import DataFilter from "@/components/DataFilter.vue"; // 筛选组件
	import BuyLeaseList from "@/components/BuyLeaseList.vue"; // 求购租赁列表
	import { breadcrumbsBuyLeaseList } from "@/breadcrumbs/index.js"; // 面包屑数据
	import { apiGetSearchItems, apiGetBuyLeaseList } from "@/apis/buyLease.js";
	import BuyItem from "@/components/BuyItem.vue";

	export default {
		name: "PageBuyLeaseList",
		components: {
			WebHeader,
			WebFooter,
			Breadcrumbs,
			SearchFilter,
			DataFilter,
			BuyLeaseList,
			BuyItem,
		},
		data() {
			return {
				// 面包屑数据
				breadcrumbs: breadcrumbsBuyLeaseList,
				region: [], // 地区数据
				rentTypes: [], // 租赁类别
				productTypes: [], // 产品类别
				list: [], // 数据列表
				pageIndex: 1, // 当前页码
				pageSize: 8, // 每页数据长度
				total: 0, // 总数据条数
				// 数据列表
				lists: {
					// 求购
					buy: {
						modeId: 1, // 类别id
						pageIndex: 1, // 当前页码
						pageSize: 8, // 每页数据长度
						total: 0, // 总数据条数
						list: [],
						loading: false,
					},
					// 租赁
					lease: {
						modeId: 2, // 类别id
						pageIndex: 1, // 当前页码
						pageSize: 8, // 每页数据长度
						total: 0, // 总数据条数
						list: [],
						loading: false,
					},
					// 置换
					displace: {
						modeId: 3, // 类别id
						pageIndex: 1, // 当前页码
						pageSize: 8, // 每页数据长度
						total: 0, // 总数据条数
						list: [],
						loading: false,
					},
				},
				rentTypesId: "", // 搜索条件中选中的租赁类别
			};
		},
		async mounted() {
			// 查询搜索数据
			await this.querySearchItems().then().catch();

			// 默认不限租赁类别
			this.queryList(1);
			this.queryList(2);
			this.queryList(4);
		},
		methods: {
			/**
			 * !查询搜索数据
			 */
			querySearchItems() {
				return new Promise((resolve, reject) => {
					apiGetSearchItems()
						.then((res) => {
							console.log("搜索数据->", res);
							this.region = res?.finalData?.region_data || [];
							this.rentTypes = res?.finalData?.mode_data || [];
							this.productTypes = res?.finalData?.type_data || [];
							resolve();
						})
						.catch((err) => {
							console.error(err);
							reject();
						});
				});
			},

			/**
			 * !查询列表数据
			 * @param {String|Number} modeId 指定了租赁类别. 1:求购. 2:租赁. 4:置换.
			 */
			queryList(modeId) {
				// 搜索条件
				let searchFilter = this.$refs.SearchFilter?.getParams();
				this.rentTypesId = searchFilter.rentTypesId;

				// 指定了租赁类别
				if (modeId) searchFilter.rentTypesId = modeId;

				// 排序类型和规则
				let dataFilter = this.$refs.DataFilter?.getParams();

				// 页码和页长
				let pageIndex = 1;
				let pageSize = 8;

				// 求购
				if (searchFilter.rentTypesId == 1) {
					this.lists.buy.loading = true;
					pageIndex = this.lists.buy.pageIndex;
					pageSize = this.lists.buy.pageSize;
				}
				// 租赁
				else if (searchFilter.rentTypesId == 2) {
					this.lists.lease.loading = true;
					pageIndex = this.lists.lease.pageIndex;
					pageSize = this.lists.lease.pageSize;
				}
				// 置换
				else if (searchFilter.rentTypesId == 4) {
					this.lists.displace.loading = true;
					pageIndex = this.lists.displace.pageIndex;
					pageSize = this.lists.displace.pageSize;
				}

				let params = {
					page: pageIndex,
					limit: pageSize,
					need_count: 1,
					mode_id: searchFilter?.rentTypesId,
					type_id: searchFilter?.productTypesId,
					type_pid: searchFilter?.productTypesParentId,
					type_sid: searchFilter?.productTypesChildrenId,
					province_id: searchFilter?.provinceId,
					city_id: searchFilter?.cityId,
					sort_col: dataFilter?.sortByType,
					sort_type: dataFilter?.sortByValue,
					price_min: dataFilter.priceStart,
					price_max: dataFilter.priceEnd,
					name: this.$route.query?.search_text || "",
				};

				apiGetBuyLeaseList(params).then((res) => {
					console.log("列表数据->" + searchFilter?.rentTypesId, res);
					// 数据总条数
					// if (res?.finalData?.count) this.total = res?.finalData?.count;

					// 列表
					// this.list = res?.finalData?.lists || [];

					// 总数据条数
					let total = res?.finalData?.count ?? 0;

					// 列表数据
					let list = res?.finalData?.lists || [];

					// 求购
					if (searchFilter.rentTypesId == 1) {
						this.lists.buy.loading = false;
						this.lists.buy.total = total;
						this.lists.buy.list = this.lists.buy.list.concat(list);
						this.lists.buy.pageIndex = this.lists.buy.pageIndex + 1;
					}
					// 租赁
					else if (searchFilter.rentTypesId == 2) {
						this.lists.lease.loading = false;
						this.lists.lease.total = total;
						this.lists.lease.list = this.lists.lease.list.concat(list);
						this.lists.lease.pageIndex = this.lists.lease.pageIndex + 1;
					}
					// 置换
					else if (searchFilter.rentTypesId == 4) {
						this.lists.displace.loading = false;
						this.lists.displace.total = total;
						this.lists.displace.list = this.lists.displace.list.concat(list);
						this.lists.displace.pageIndex = this.lists.displace.pageIndex + 1;
					}
				});
			},

			/**
			 * !搜索事件
			 * @param {Object} params 搜索条件数据
			 */
			handleSearch() {
				// 清空现有数据
				// this.list = [];

				// 重置页码
				// this.pageIndex = 1;

				// 重置总数据条数
				// this.total = 0;

				// 清空现有数据
				// this.list = [];

				// 清空/重置数据
				this.clearLists();

				// 搜索条件
				let searchFilter = this.$refs.SearchFilter?.getParams();

				// 不限租赁类别
				if (!searchFilter.rentTypesId) {
					this.queryList(1);
					this.queryList(2);
					this.queryList(4);
				}
				// 选择了租赁类别
				else {
					this.queryList();
				}
			},

			/**
			 * !页码切换事件
			 * @param {Number} current 当前页
			 */
			onChangePageIndex(current) {
				// 清空现有数据
				this.list = [];

				// 更新页码
				this.pageIndex = current;

				// 查询列表数据
				this.queryList();
			},

			/**
			 * @description: 清空/重置数据
			 * @return {*}
			 */
			clearLists() {
				this.lists.buy.total = 0;
				this.lists.buy.list = [];
				this.lists.buy.pageIndex = 1;
				this.lists.lease.total = 0;
				this.lists.lease.list = [];
				this.lists.lease.pageIndex = 1;
				this.lists.displace.total = 0;
				this.lists.displace.list = [];
				this.lists.displace.pageIndex = 1;
			},
		},
	};
</script>

<style scoped lang="scss">
	.page-auction-list-container {
		.page-auction-list-content {
			padding-bottom: 60px;
		}

		.search-filter {
			margin-bottom: 20px;
		}

		.data-filter {
			margin-bottom: 17px;
		}

		.module {
			margin-top: 40px;

			.title {
				font-size: 20px;
				margin-bottom: 20px;
			}

			.btn {
				margin-left: auto;
				margin-right: auto;
				display: flex;
			}
		}

		.buy-item {
			margin-bottom: 14px;
		}
	}
</style>

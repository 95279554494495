import CryptoJS from "crypto-js";

export default {
	// 更新当前Header中激活的导航菜单的名称
	updateActiveHeaderNavName(state, payload) {
		state.activeHeaderNavName = payload;
	},

	// 更新公司信息
	updateCompanyInfo(state, payload) {
		if (!payload) return;
		try {
			localStorage.setItem("ZHONGNUO_COMPANY_INFO", JSON.stringify(payload));
			state.companyInfo = payload;
		} catch (error) {
			console.warn("平台信息更新失败，错误的数据格式：", payload);
		}
	},

	// 更新用户信息
	updateUserinfo(state, payload) {
		if (!payload) return;
		try {
			// 用户数据加密存储
			let userinfoBase64 = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(JSON.stringify(payload)));
			localStorage.setItem("ZHONGNUO_USERINFO", userinfoBase64);
			state.userinfo = userinfoBase64;
		} catch (error) {
			console.warn("用户信息更新失败，错误的数据格式：", payload);
		}
	},

	// 清空用户信息
	clearUserinfo(state, payload) {
		localStorage.removeItem("ZHONGNUO_USERINFO");
		state.userinfo = null;
	},

	// 更新首页数据
	updatePageIndexData(state, payload) {
		if (!payload) return;
		try {
			// 首页数据加密存储
			let pageIndexDataBase64 = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(JSON.stringify(payload)));
			localStorage.setItem("ZHONGNUO_PAGE_INDEX_DATA", pageIndexDataBase64);
			state.pageIndexData = pageIndexDataBase64;
		} catch (error) {
			console.warn("首页数据更新失败，错误的数据格式：", payload);
		}
	},
};

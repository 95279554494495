<!-- 首页工具栏 -->
<template>
	<div class="component-index-toolbar">
		<ul class="list">
			<li class="item" :class="{ active: activeCurrent == 0 }" @click="handleToAnchor(0)">拍卖要闻</li>
			<li class="item" :class="{ active: activeCurrent == 1 }" @click="handleToAnchor(1)">竞拍流程</li>
			<li class="item" :class="{ active: activeCurrent == 2 }" @click="handleToAnchor(2)">最新拍卖</li>
			<li class="item" :class="{ active: activeCurrent == 3 }" @click="handleToAnchor(3)">成交案例</li>
			<li class="item" :class="{ active: activeCurrent == 4 }" @click="handleToAnchor(4)">入驻企业</li>
			<li class="item" :class="{ active: activeCurrent == 5 }" @click="handleToAnchor(5)">求购租赁</li>
			<li class="item">
				APP下载
				<div class="qrcode-box">
					<img class="image" :src="companyInfo?.download_app_img" alt="" />
				</div>
			</li>
			<li class="item">
				手机访问
				<div class="qrcode-box">
					<img class="image" :src="companyInfo?.download_app_img" alt="" />
				</div>
			</li>
			<li class="item" @click="handleBackTop">
				<i class="icon zn-iconfont zn-iconfont-fanhuidingbu"></i>
				<span class="text">回到顶部</span>
			</li>
		</ul>
	</div>
</template>

<script>
import { mapState } from "vuex";
export default {
	name: "IndexToolbar",
	props: {},
	computed: mapState(["companyInfo"]),
	data() {
		return {
			activeCurrent: -1, // 当前激活项
		};
	},
	mounted() {
		// 默认调用一遍onScroll事件
		this.onScroll();

		// 添加页面滚动事件
		window.addEventListener("scroll", this.onScroll, true);
	},
	methods: {
		/**
		 * !返回顶部事件
		 */
		handleBackTop() {
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: "smooth", // 平滑过渡
			});
		},

		/**
		 * !滚动事件
		 */
		onScroll() {
			let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
			let clientHeight = document.documentElement.clientHeight;
			let indexModules = document.querySelectorAll(".index-module");
			let indexModulesLen = indexModules.length;
			if (!indexModulesLen) return false;
			if (scrollTop <= indexModules[0].offsetTop) {
				this.activeCurrent = 0;
				return false;
			}
			for (let index = indexModulesLen - 1; index >= 0; index--) {
				let moduleOffsetTop = indexModules[index].offsetTop;
				if (scrollTop >= moduleOffsetTop) {
					this.activeCurrent = index;
					break;
				}
			}
		},

		/**
		 * !滚动到对应的锚点
		 * @param {String} current 模块下标
		 */
		handleToAnchor(current) {
			document.querySelectorAll(".index-module")?.[current]?.scrollIntoView({
				behavior: "smooth", // 平滑过渡
				block: "start", // 滚动到的位置,元素顶部
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.component-index-toolbar {
	position: fixed;
	z-index: 200;
	background-color: #ffffff;
	right: 0;
	top: 50%;
	transform: translateY(-50%);

	.list {
		width: 82px;
		list-style: none;
	}

	.item {
		line-height: 24px;
		color: #666666;
		transition: 0.3s;
		cursor: pointer;
		user-select: none;
		width: 100%;
		text-align: center;
		padding: 10px 0;
		position: relative;

		&.active {
			background-color: var(--theme-color);
			color: #ffffff;
		}

		&:not(.active):hover {
			background-color: #f2f3f4;
		}

		.text {
			display: block;
		}

		.icon {
			font-size: 1.25em;
			color: inherit;
		}

		&:last-child {
			border-top: 2px solid #f9f9f9;
		}

		&:nth-last-child(3) {
			border-top: 2px solid #f9f9f9;
		}

		.qrcode-box {
			position: absolute;
			right: 100%;
			top: 50%;
			width: 90px;
			height: 90px;
			margin-top: -45px;
			background-color: #ffffff;
			transition: 0.3s;
			transform: scale(0);
			opacity: 0;
			transform-origin: right center;

			.image {
				display: block;
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}

		&:hover {
			.qrcode-box {
				transform: scale(1);
				opacity: 1;
			}
		}
	}
}
</style>

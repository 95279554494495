/**
 * ****************************************************************************************************
 * Valid - 各种验证函数
 * ----------------------------------------------------------------------------------------------------
 * | isUnifiedSocialCreditCode | 校验是否为有效的统一社会信用代码
 * | isValidJSON               | 判断给定的字符串是否是 JSON 字符串
 * | isIPv6                    | 校验是否为IPv6地址
 * | isCNPostCode              | 校验是否为中国大陆的邮政编码
 * | haveCNChars               | 校验是否包含中文
 * | isIdNo                    | 校验是否是有效的身份证号
 * | isMail                    | 校验是否是邮箱的邮箱地址
 * | isPhoneNumber             | 校验是否是有效的手机号
 * | isObject                  | 校验数据是否是 Object 类型
 * | isArray                   | 校验数据是否是 Array 类型
 * | isString                  | 校验数据是否是 String 类型
 * | isDate                    | 校验数据是否是 Date 类型
 * | isFunction                | 校验数据是否是 Function 类型
 * | isNumber                  | 校验数据是否是 Number 类型
 * | isBoolean                 | 校验数据是否是 Boolean 类型
 * ****************************************************************************************************
 */
/**
 * @description 校验是否为有效的统一社会信用代码
 * @param {String} value 要校验的字符串
 * @return {Boolean} true: 是有效的统一社会信用代码；false: 不是有效的统一社会信用代码；
 */
export const isUnifiedSocialCreditCode = (value) => {
	if (value == "") {
		return false;
	} else {
		var firstarray = [
			"0",
			"1",
			"2",
			"3",
			"4",
			"5",
			"6",
			"7",
			"8",
			"9",
			"A",
			"B",
			"C",
			"D",
			"E",
			"F",
			"G",
			"H",
			"I",
			"J",
			"K",
			"L",
			"M",
			"N",
			"O",
			"P",
			"Q",
			"R",
			"S",
			"T",
			"U",
			"V",
			"W",
			"X",
			"Y",
			"Z",
		];
		var firstkeys = [3, 7, 9, 10, 5, 8, 4, 2];
		var secondarray = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "A", "B", "C", "D", "E", "F", "G", "H", "J", "K", "L", "M", "N", "P", "Q", "R", "T", "U", "W", "X", "Y"];
		var secondkeys = [1, 3, 9, 27, 19, 26, 16, 17, 20, 29, 25, 13, 8, 24, 10, 30, 28];
		function calc(code, array1, array2, b) {
			var count = 0;
			for (var i = 0; i < array2.length; i++) {
				var a = code[i];
				count += array2[i] * array1.indexOf(a);
			}
			var remainder = count % b;
			return remainder === 0 ? 0 : b - remainder;
		}
		var code = value.toUpperCase();
		if (code.length != 18) {
			return false;
		}
		var reg = /^\w\w\d{6}\w{9}\w$/;
		if (!reg.test(code)) {
			return false;
		} else {
			/*
                登记管理部门代码：使用阿拉伯数字或大写英文字母表示。​
                机构编制：1​
                民政：5​
                工商：9​
                其他：Y
            */
			let reg1 = /^[1,5,9,Y]\w\d{6}\w{9}\w$/;
			if (!reg1.test(code)) {
				return false;
			} else {
				/*
                机构类别代码：使用阿拉伯数字或大写英文字母表示。​
                机构编制机关：11打头​​
                机构编制事业单位：12打头​
                机构编制中央编办直接管理机构编制的群众团体：13打头​​
                机构编制其他：19打头​
                民政社会团体：51打头​
                民政民办非企业单位：52打头​
                民政基金会：53打头​
                村民委员会：54打头
                民政其他：59打头​
                工商企业：91打头​
                工商个体工商户：92打头​
                工商农民专业合作社：93打头​
                其他：Y1打头​
            */
				let reg2 = /^(11|12|13|19|21|31|32|33|34|35|41|51|52|53|54|55|61|62|59|71|72|81|91|92|93|A1|G1|J1|N1|N2|N3|Y1)\d{6}\w{9}\w$/;
				if (!reg2.test(code)) {
					return false;
				} else {
					/*
                    登记管理机关行政区划码：只能使用阿拉伯数字表示。按照GB/T 2260编码。​
                    例如：四川省成都市本级就是510100；四川省自贡市自流井区就是510302。​
                    */
					let reg3 = /^(11|12|13|19|21|31|32|33|34|35|41|51|52|53|54|55|61|62|59|71|72|81|91|92|93|A1|G1|J1|N1|N2|N3|Y1)\d{6}\w{9}\w$/;
					if (!reg3.test(code)) {
						return false;
					} else {
						var firstkey = calc(code.substr(8), firstarray, firstkeys, 11);
						var firstword;
						if (firstkey < 10) {
							firstword = firstkey;
						}
						if (firstkey == 10) {
							firstword = "X";
						} else if (firstkey == 11) {
							firstword = "0";
						}
						if (firstword != code.substr(16, 1)) {
							return false;
						} else {
							var secondkey = calc(code, secondarray, secondkeys, 31);
							var secondword = secondarray[secondkey];
							if (!secondword || secondword != code.substr(17, 1)) {
								return false;
							} else {
								var word = code.substr(0, 16) + firstword + secondword;
								if (code != word) {
									return false;
								} else {
									return true;
								}
							}
						}
					}
				}
			}
		}
	}
};

/**
 * @description 判断给定的字符串是否是 JSON 字符串
 * @param {String} str 需要判断的字符串
 * @return {Boolean} true: 是；false: 否；
 */
export const isValidJSON = (str) => {
	try {
		JSON.parse(str);
		return true;
	} catch (e) {
		return false;
	}
};

/**
 * @description 校验是否为IPv6地址
 * @param {String} str 要校验的字符串
 * @return {Boolean} true: 是IPv6地址；false: 不是IPv6地址；
 */
export const isIPv6 = (str) => {
	return Boolean(str.match(/:/g) ? str.match(/:/g).length <= 7 : false && /::/.test(str) ? /^([\da-f]{1,4}(:|::)){1,6}[\da-f]{1,4}$/i.test(str) : /^([\da-f]{1,4}:){7}[\da-f]{1,4}$/i.test(str));
};

/**
 * @description 校验是否为中国大陆的邮政编码
 * @param {Number|String} value 需要校验的数据
 * @return {Boolean} true: 是中国大陆的邮政编码；false: 不是中国大陆的邮政编码；
 */
export const isCNPostCode = (value) => {
	return /^[1-9][0-9]{5}$/.test(value.toString());
};

/**
 * @description 校验是否包含中文
 * @param {String} val 需要校验的数据
 * @return {Boolean} true: 是，包含中文；false: 否，不包含中文；
 */
export const haveCNChars = (value) => {
	return /[\u4e00-\u9fa5]/.test(value);
};

/**
 * @description 校验是否是有效的身份证号
 * @param {String} val 需要校验的数据
 * @return {Boolean} true: 是有效的身份证号；false: 不是有效的身份证号；
 */
export const isIdNo = (val) => {
	return /^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/.test(val);
};

/**
 * @description 校验是否是邮箱的邮箱地址
 * @param {String} val 需要校验的数据
 * @return {Boolean} true: 是有效的邮箱地址；false: 不是有效的邮箱地址；
 */
export const isMail = (val) => {
	return /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/.test(val);
};

/**
 * @description 校验是否是有效的手机号
 * @param {Number|String} val 需要校验的数据
 * @return {Boolean} true: 是有效的手机号；false: 不是有效的手机号；
 */
export const isPhoneNumber = (val) => {
	return /^1(3|4|5|6|7|8|9)\d{9}$/.test(val);
};

/**
 * @description 校验数据是否是Object类型
 * @param {Any} val 需要校验的数据
 * @return {Boolean} true: 是；false: 否；
 */
export const isObject = (val) => {
	return Object.prototype.toString.call(val) === "[object Object]" && val !== null && val !== undefined;
};

/**
 * @description 校验数据是否是Array类型
 * @param {Any} val 需要校验的数据
 * @return {Boolean} true: 是；false: 否；
 */
export const isArray = (val) => {
	return Object.prototype.toString.call(val) === "[object Array]";
};

/**
 * @description 校验数据是否是String类型
 * @param {Any} val 需要校验的数据
 * @return {Boolean} true: 是；false: 否；
 */
export const isString = (val) => {
	return Object.prototype.toString.call(val) === "[object String]";
};

/**
 * @description 校验数据是否是Date类型
 * @param {Any} val 需要校验的数据
 * @return {Boolean} true: 是；false: 否；
 */
export const isDate = (val) => {
	return Object.prototype.toString.call(val) === "[object Date]";
};

/**
 * @description 校验数据是否是Function类型
 * @param {Any} val 需要校验的数据
 * @return {Boolean} true: 是；false: 否；
 */
export const isFunction = (val) => {
	return Object.prototype.toString.call(val) === "[object Function]";
};

/**
 * @description 校验数据是否是Number类型
 * @param {Any} val 需要校验的数据
 * @return {Boolean} true: 是；false: 否；
 */
export const isNumber = (val) => {
	return Object.prototype.toString.call(val) === "[object Number]";
};

/**
 * @description 校验数据是否是Boolean类型
 * @param {Any} val 需要校验的数据
 * @return {Boolean} true: 是；false: 否；
 */
export const isBoolean = (val) => {
	return Object.prototype.toString.call(val) === "[object Boolean]";
};

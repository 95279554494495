<!-- 标的 - 详情 -->
<template>
	<div class="page-container page-target-details-container">
		<!-- #S 页头 -->
		<WebHeader></WebHeader>
		<!-- #E 页头 -->

		<!-- #S 页体 -->
		<div class="page-target-details-content">
			<!-- 新标的（下一个）标的开始通知 -->
			<NewTargetStartNotice :info="newItemInfo" :show="newItemNoticeShow"></NewTargetStartNotice>

			<!-- #S 面包屑 -->
			<Breadcrumbs :list="breadcrumbs"></Breadcrumbs>
			<!-- #E 面包屑 -->

			<!-- 主内容区域 -->
			<div class="layout-main">
				<!-- #S 顶部模块 -->
				<TargetDetailTopic class="target-detail-topic" :title="details?.auction_title"
					:companyName="details?.company_name" :contactsPhone="details?.contacts_phone" :items="details?.items"
					:current="swiperCurrent" :auctionType1="details?.auction_type_1"></TargetDetailTopic>
				<!-- #E 顶部模块 -->

				<!-- #S 标的介绍 + 聊天室 + 出价列表 -->
				<div class="intro-chat-prices">
					<!-- 标的介绍 -->
					<TargetDetailIntro class="target-detail-intro" ref="TargetDetailIntro" :info="details"
						:canSeeInfo="canSeeInfo" @collection="requestCollection" @uncollection="requestUncollection"
						@confirmOfferPrice="handleConfirmOfferPrice" @share="shareClickHandler"></TargetDetailIntro>

					<!-- 聊天室 + 出价列表 -->
					<div class="chat-prices">
						<!-- 聊天室 -->
						<div class="chat">
							<ChatRoom ref="ChatRoom" class="chat-room" :canSeeInfo="canSeeInfo" :list="details.speech_data"
								:loadStatus="chatListLoadStatus" @moreMessage="handleLoadMoreMessage"></ChatRoom>
						</div>

						<!-- 出价列表 -->
						<TargetDetailOfferPriceList class="prices" :list="details?.quote_data" :info="details"
							:canSeeInfo="canSeeInfo" @more="handleMoreOfferRecord"></TargetDetailOfferPriceList>
					</div>
				</div>
				<!-- #E 标的介绍 + 聊天室 + 出价列表 -->

				<!-- 竞拍流程 -->
				<div class="bidding-process-container layout-main" v-if="details?.process_img">
					<img :src="details?.process_img" class="image" alt="" />
				</div>

				<!-- Tabs && Contents -->
				<div class="tabs-and-contents" id="TabsContents" ref="commodityDetails">
					<el-tabs type="border-card" v-model="tabsValue">
						<el-tab-pane label="标的介绍" name="intro">
							<div class="tab-content">
								<TargetIntro :canSeeInfo="canSeeInfo" :content="details?.content"
									:ptypeId="details?.ptype_id" :vehicle="details?.vehicle" :house="details?.house"
									:annex="details?.annex" :annexName="details?.annex_name" :video="details?.video"
									:videoName="details?.video_name" :images="details?.images"></TargetIntro>
							</div>
						</el-tab-pane>
						<el-tab-pane label="拍卖公告" name="notice">
							<div class="tab-content">
								<TargetRichText :content="details?.notice_content"></TargetRichText>
							</div>
						</el-tab-pane>
						<el-tab-pane label="竞买须知" name="know">
							<div class="tab-content">
								<TargetRichText :content="details?.bidding_content"></TargetRichText>
							</div>
						</el-tab-pane>
						<el-tab-pane label="重要提示" name="tips">
							<div class="tab-content">
								<TargetRichText :content="details?.important_tip"></TargetRichText>
							</div>
						</el-tab-pane>

						<el-tab-pane :label="`竞价记录（${canSeeInfo ? details?.quote_count : 0}）`" name="record">
							<div class="tab-content tab-content-target-bidding-record">
								<TargetBiddingRecord ref="TargetBiddingRecord" :list="details?.quote_data" :info="details"
									:count="details?.quote_count" @more="handleLoadMoreBiddingRecord" v-show="canSeeInfo">
								</TargetBiddingRecord>
								<div class="unauth-tip" v-if="!canSeeInfo">报名审核通过后查看竞价记录</div>
							</div>
						</el-tab-pane>
					</el-tabs>
				</div>
			</div>
		</div>
		<!-- #E 页体 -->

		<!-- #S 页脚 -->
		<WebFooter></WebFooter>
		<!-- #E 页脚 -->

		<div class="qr-share" v-show="qrFlag">
			<img src="@/assets/images/gb.png" alt="" class="gb-icon" @click="gbClickHandler" />
			<div><vue-qr   ref="qrCode"    :text="textValue" :logoSrc="logoPath" :logoScale="30" :size="240"
					:margin="10" /></div>
			<div class="text">
				微信扫描二维码分享
			</div>
		</div>
	</div>
</template>

<script>
import WebHeader from "@/components/WebHeader.vue";
import WebFooter from "@/components/WebFooter.vue";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import TargetDetailTopic from "@/components/TargetDetailTopic.vue";
import TargetDetailIntro from "@/components/TargetDetailIntro.vue";
import TargetDetailOfferPriceList from "@/components/TargetDetailOfferPriceList.vue";
import ChatRoom from "@/components/ChatRoom.vue";
import TargetIntro from "@/components/TargetIntro.vue";
import TargetBiddingRecord from "@/components/TargetBiddingRecord.vue";
import TargetRichText from "@/components/TargetRichText.vue";
import NewTargetStartNotice from "@/components/NewTargetStartNotice.vue";
import { breadcrumbsTargetDetails } from "@/breadcrumbs/index.js";
import { apiGetTargetDetails, apiCollection, apiUncollection, apiGetMoreChatMessage, apiGetMoreOfferList, apiUserOnlineBearheart } from "@/apis/target.js";
import { checkUserLoginStatus, getRandomString, isFunction } from "@/utils/func.js";
import { WsBaseURL } from "@/const.js";
import HiWebSocket from "@/hi-websocket/index.js";
import { mapGetters } from "vuex";
import VueQr from 'vue-qr'
import logoImg from '@/assets/images/qr.png'

export default {
	name: "PageTargetDetails",
	components: {
		WebHeader,
		WebFooter,
		Breadcrumbs,
		TargetDetailTopic,
		TargetDetailIntro,
		TargetDetailOfferPriceList,
		ChatRoom,
		TargetIntro,
		TargetBiddingRecord,
		TargetRichText,
		NewTargetStartNotice,
		VueQr
	},
	data() {
		return {
			breadcrumbs: breadcrumbsTargetDetails, // 面包屑数据
			no: "", // 标的id
			swiperCurrent: 0, // 轮播图默认选中的下标值
			auctionData: {}, // 拍卖会信息
			details: {}, // 标的详情
			tabsValue: "intro", // 当前选中的tab名称
			hiWs: null, // HiWebsocket实例
			chatList: [], // 聊天列表
			chatListLoadStatus: "loading", // 聊天消息加载状态
			// 新（下一个）标的信息
			newItemNoticeShow: false,
			newItemInfo: {
				title: "", // 标的标题
				item_no: "", // 标的编号
			},
			times: null,//定时器,

			// 报价成功声音提示
			audio: new Audio(),
			warnUrl: require("../../assets/ding.mp3"),
			tag: '',
			nowTimer: null,//定时器
			startFlag: true,
			timeupdateWs: null,
			textValue: '',
			logoPath: logoImg,
			qrFlag: false,
		};
	},
	computed: {
		...mapGetters(["userinfo"]),

		// 是否可以查看信息
		canSeeInfo() {
			// 可以查看信息: 标的未限制查看信息 || 报名审核已通过
			return this.details?.enroll_check_detail == 20 || this.details?.enroll_status == 20;
		},
	},
	created() {
		// 滚动到页面顶部
		console.log();
		if (this.$route.query.tag == '1') {
			this.tabsValue = 'know'
			this.$nextTick(() => {
				window.scrollTo({
					top: this.$refs['commodityDetails'].getBoundingClientRect().top + window.scrollY,
					behavior: 'smooth' // 平滑滚动
				})
			})
		} else {
			window.scrollTo(0, 0);
		}
		if (this.$route.query.tag == '2') {
			this.tabsValue = 'tips'
			this.$nextTick(() => {
				window.scrollTo({
					top: this.$refs['commodityDetails'].getBoundingClientRect().top + window.scrollY,
					behavior: 'smooth' // 平滑滚动
				})
			})
		} else {
			window.scrollTo(0, 0);
		}
		if (this.$route.query.tag == '3') {
			this.tabsValue = 'intro'
			this.$nextTick(() => {
				window.scrollTo({
					top: this.$refs['commodityDetails'].getBoundingClientRect().top + window.scrollY,
					behavior: 'smooth' // 平滑滚动
				})
			})
		} else {
			window.scrollTo(0, 0);
		}
	},
	mounted() {
		// 获取标的id
		this.no = this.$route.query?.no || "";
		this.tag = this.$route.query?.tag || "";
		console.log(this.$route.query);

		this.init();
	},
	beforeDestroy() {
		console.log("页面销毁回调函数");
		// 页面销毁前销毁websocket
		if (isFunction(this.hiWs?.destroy)) {
			this.hiWs.destroy();
			this.hiWs = null;
		}
		if (isFunction(this.timeupdateWs?.destroy)) {
			this.timeupdateWs.destroy();
			this.timeupdateWs = null;
		}
		clearInterval(this.times)
		this.times = null
		clearTimeout(this.nowTimer)
		this.nowTimer = null
	},
	methods: {
		init() {
			// 查询标的详情
			this.queryDetails();
		},
		shareClickHandler() {
			console.log("分享");
			this.textValue = `https://www.qipai-wang.com/h5/#/pages/matter/details?id=${this.no}`;
			console.log(this.textValue);
			this.qrFlag = true;
		},
		gbClickHandler(){
			this.qrFlag = false;
		},

		/**
		 * !查询标的详情
		 */
		queryDetails() {
			apiGetTargetDetails({ no: this.no })
				.then((res) => {
					console.log("标的详情:-> ", res.finalData);

					// 更新面包屑导航数据
					this.breadcrumbs = breadcrumbsTargetDetails.concat({ name: res?.finalData?.title, to: { name: "TargetDetails" } });

					// 找到标的轮播图应该默认选中slide的下标
					this.swiperCurrent = res?.finalData?.items?.findIndex((item) => item.no == this.no);

					// 标的详情
					this.details = res.finalData ?? {};

					// 保持在线心跳
					if (this.details?.enroll_status == 20) {
						this.keepOnlineHeart();
						this.times = setInterval(() => {
							this.keepOnlineHeart();
						}, 25000);;
					}

					// 竞价记录
					this.$refs.TargetBiddingRecord?.loadingClose(); // 关闭loading

					// 更新聊天记录数据加载状态
					this.updateChatListLoadStatus(res.finalData?.speech_data);

					// 初始化 websocket
					// 只有在拍卖状态未结束之前再去创建 websocket
					// if (this.details?.status < 70) this.initHiWebsocket();
					// if (this.details?.status >= 30 && this.details?.status < 70) {
					this.initHiWebsocket();
					this.initHiWebsocket2();
					// }
				})
				.catch((err) => {
					console.error("标的详情查询失败:", err);
				});
		},

		/**
		 * @description: 更新最新的聊天数据更新加载状态
		 * @param {Array} list 最新的聊天数据
		 * @return {*}
		 */
		updateChatListLoadStatus(list) {
			if (list?.length >= 10) this.chatListLoadStatus = "loadmore";
			else this.chatListLoadStatus = "nomore";
		},

		/**
		 * !收藏
		 */
		requestCollection() {
			// 用户未登录: 提示用户需登录才能进行操作
			if (!checkUserLoginStatus()) return this.showLoginTipsDialog();

			// 请求接口
			apiCollection({ no: this.no }, { loadingMsg: "收藏...", successShow: true }).then(() => {
				// 更新收藏状态
				this.details.is_collect = true;
			});
		},

		/**
		 * !取消收藏
		 */
		requestUncollection() {
			// 用户未登录: 提示用户需登录才能进行操作
			if (!checkUserLoginStatus()) return this.showLoginTipsDialog();

			// 请求接口
			apiUncollection({ no: this.no }, { loadingMsg: "取消收藏...", successShow: true }).then(() => {
				// 更新收藏状态
				this.details.is_collect = false;
			});
		},

		/**
		 * !显示需要登录提示对话窗
		 */
		showLoginTipsDialog() {
			this.$confirm("您当前进行的操作需要登录后才能继续,是否现在去登录?", "提示", {
				confirmButtonText: "去登录",
				cancelButtonText: "取消",
				type: "info",
			}).then(() => {
				this.$router.push("/login");
			});
		},
		initHiWebsocket2() {
			if (isFunction(this.timeupdateWs?.destroy)) this.timeupdateWs.destroy();
			this.timeupdateWs = new HiWebSocket({ url: `${WsBaseURL}${this.no}_${getRandomString(5)}${new Date().getTime()}${getRandomString(6)}` });
			// socket 连接成功
			this.timeupdateWs.subscribeMessageEvent("onopen", () => {
				let message = {
					controller: "common",
					action: "Time",
					authorization: "",
					params: "",
				};
				this.timeupdateWs.send(JSON.stringify(message));
			});
			this.timeupdateWs?.subscribeMessageEvent("nowtime", (data) => {
				console.log('timeupdateWs', data);
				this.details.now_time = data.finalData?.time;
				this.nowTimer = setTimeout(() => {
					let message = {
						controller: "common",
						action: "Time",
						authorization: "",
						params: "",
					};
					if (this.details.pause_status != 10 && (this.details.status == 40 || this.details.status == 50)) {
						this.timeupdateWs.send(JSON.stringify(message));
					}
				}, 1000)

			});

		},

		/**
		 * !初始化 HiWebsocket
		 */
		initHiWebsocket() {
			// 确保之前的对象已销毁
			if (isFunction(this.hiWs?.destroy)) this.hiWs.destroy();

			// 创建实例
			this.hiWs = new HiWebSocket({ url: `${WsBaseURL}${this.no}_${getRandomString(5)}${new Date().getTime()}${getRandomString(6)}` });

			// socket 连接成功
			this.hiWs.subscribeMessageEvent("onopen", () => {
				// 身份注册
				this.wsRegister();
			});

			// 订阅 - 确认身份注册成功事件
			this.hiWs.subscribeMessageEvent("register", this.wsRegisterSuccess);

			// 拍卖师发言了
			this.hiWs.subscribeMessageEvent("auctioneer_speech", this.onWsAuctioneerSpeech);

			// 标的开始了
			this.hiWs.subscribeMessageEvent("item_start", this.onWsItemStart);

			// 标的状态变化了
			this.hiWs.subscribeMessageEvent("item_change", this.onWsItemChange);

			// 标的暂停了
			this.hiWs.subscribeMessageEvent("item_stop", this.onWsItemStop);

			// 标的继续了
			this.hiWs.subscribeMessageEvent("item_segue", this.onWsItemSegue);

			// 标的继续了
			this.hiWs.subscribeMessageEvent("segue", this.onWsSegue);

			// 标的被编辑了
			this.hiWs.subscribeMessageEvent("item_edit", this.onWsItemEdit);

			// 用户报价了
			this.hiWs.subscribeMessageEvent("quote", this.onWsQuote);

			// 用户报价成功了
			this.hiWs.subscribeMessageEvent("quote_result", this.onWsQuoteResult);

			// 拍卖师删除了竞价记录
			this.hiWs.subscribeMessageEvent("remove_quote", this.onWsRemoveQuote);

			// 标的撤拍了
			this.hiWs.subscribeMessageEvent("withdraw", this.onWsWithdraw);

			// 标的流拍了
			this.hiWs.subscribeMessageEvent("item_unsold", this.onWsItemUnsold);

			// 标的成交了
			this.hiWs.subscribeMessageEvent("turnover", this.onWsTurnover);

			// 新的（下一个）标的开始了
			this.hiWs.subscribeMessageEvent("new_item_start", this.onWsNewItemStart);


			// 2024-5-22 新增
			this.hiWs.subscribeMessageEvent("item_withdraw", this.onWsItemWidth);
			this.hiWs.subscribeMessageEvent("item_turnover", this.onWsItemTurn);
			// this.timeHandler()
			// this.nowTimer = setInterval(() => {
			// 	this.timeHandler()
			// }, 10000);

			// 同步时间
			// this.hiWs.subscribeMessageEvent("nowtime", this.onWsTime);

		},

		/**
		 * @description: socket 身份注册
		 * @return {*}
		 */
		wsRegister() {
			let message = {
				controller: "common",
				action: "Register",
				authorization: localStorage["AUTHORIZATION"] || "",
				params: JSON.stringify({ no: this.no || "" }),
			};
			this.hiWs?.send(JSON.stringify(message));
		},

		// 同步时间
		timeHandler() {
			// let message = {
			// 	controller: "common",
			// 	action: "Time",
			// 	authorization: "",
			// 	params: "",
			// };
			// this.hiWs?.send(JSON.stringify(message));

			// if (this.details.pause_status != 10) {
			// 	this.hiWs?.subscribeMessageEvent("nowtime", this.onWsTime);
			// }
		},
		onWsTime(data) {
			// console.log('time', data.finalData.time);
			// console.log(this.details.now_time);
			// this.details.now_time = data.finalData?.time;
			// this.details.count_down_time = data.finalData?.time;
		},

		/**
		 * @description: 身份注册成功
		 * @param {Object} data socket 推送数据
		 * @return {*}
		 */
		wsRegisterSuccess(data) {
			console.log("socket -> 身份注册成功", data);

			// 得到聊天列表数据
			this.details.speech_data = (data?.finalData?.speech_data || []).reverse();

			// 更新聊天列表数据状态
			this.updateChatListLoadStatus(data.finalData?.speech_data);

			// 得到竞价记录数据
			this.details.quote_count = data?.finalData?.quote_count;
			this.details.quote_data = data?.finalData?.quote_data;

			// 关闭 tab content 中竞价记录列表的加载 loading
			this.$refs.TargetBiddingRecord?.loadingClose();
		},

		/**
		 * @description: 拍卖师发言了
		 * @param {Object} data socket 推送数据
		 * @return {*}
		 */
		onWsAuctioneerSpeech(data) {
			// 新增聊天数据
			this.addChatListItem(data.finalData);
		},

		/**
		 * @description: 标的开始了
		 * @param {Object} data socket 推送数据
		 * @return {*}
		 */
		onWsItemStart(data) {
			// 新增聊天数据
			this.addChatListItem(data.finalData);
		},

		/**
		 * @description: 标的状态变化了
		 * @param {Object} data socket 推送数据
		 * @return {*}
		 */
		onWsItemChange(data) {
			console.log('data', data);
			// 更新服务器时间
			this.details.now_time = data.finalData?.now_time;



			// 更新倒计时
			this.details.count_down_time = data.finalData?.count_down_time;
			if (data.finalData.status == 60) {
				this.details.now_time = data.finalData?.count_down_time;
				clearInterval(this.nowTimer);
				this.nowTimer = null
			}

			// 更新标的状态
			this.details.status = data.finalData?.status;

			// 更新顶部轮播中的标的列表数据
			this.updateTopicSwiperItems();
		},

		/**
		 * @description: 标的暂停了
		 * @param {Object} data socket 推送数据
		 * @return {*}
		 */
		onWsItemStop(data) {
			clearInterval(this.nowTimer);
			this.nowTimer = null
			// this.startFlag = false
			// 新增聊天数据
			this.addChatListItem(data.finalData);

			// 更新标的暂停状态
			this.details.pause_status = 10;

			// 更新服务器时间
			this.details.now_time = data.finalData?.create_time;

			// 更新顶部轮播中的标的列表数据
			this.updateTopicSwiperItems();
		},

		/**
		 * @description: 标的继续了
		 * @param {Object} data socket 推送数据
		 * @return {*}
		 */
		onWsItemSegue(data) {
			// 新增聊天数据
			if (data.finalData) this.addChatListItem(data.finalData);

			// 更新标的暂停状态
			this.details.pause_status = 0;
			let message = {
				controller: "common",
				action: "Time",
				authorization: "",
				params: "",
			};
			this.timeupdateWs.send(JSON.stringify(message));

			// 更新顶部轮播中的标的列表数据
			this.updateTopicSwiperItems();
		},

		/**
		 * @description: 标的继续了
		 * @param {Object} data socket 推送数据
		 * @return {*}
		 */
		onWsSegue(data) {
			// 更新服务器时间
			this.details.now_time = data.finalData?.now_time;

			// 更新出价列表
			this.details.quote_data = data?.finalData?.quote_data;

			// 更新出价列表总数量
			this.details.quote_count = data?.finalData?.quote_count;

			// 更新标的状态
			this.details.status = data.finalData?.status;

			// 更新倒计时
			this.details.count_down_time = data.finalData?.count_down_time;

			// 更新自由竞拍时间
			this.details.free_bidding_time = data.finalData?.free_bidding_time;

			// 更新限时竞拍时间
			this.details.limited_bidding_time = data.finalData?.limited_bidding_time;

			// 更新起始价
			this.details.start_price = data.finalData?.start_price;

			// 更新加价幅度
			this.details.price_range = data.finalData?.price_range;

			// 更新顶部轮播中的标的列表数据
			this.updateTopicSwiperItems();
			// this.nowTimer = setInterval(() => {
			// 	this.timeHandler()
			// }, 10000);
		},

		/**
		 * @description: 标的被编辑了
		 * @param {Object} data socket 推送数据
		 * @return {*}
		 */
		onWsItemEdit(data) {
			// 将收到的新的聊天消息添加到聊天列表中
			if (data.finalData) this.addChatListItem(data.finalData?.speak[0]);

			// 更新服务器时间
			this.details.now_time = data.finalData?.now_time;

			// 更新倒计时
			this.details.count_down_time = data.finalData?.count_down_time;

			// 更新自由竞拍时间
			this.details.free_bidding_time = data.finalData?.free_bidding_time;

			// 更新限时竞拍时间
			this.details.limited_bidding_time = data.finalData?.limited_bidding_time;

			// 更新加价幅度
			this.details.price_range = data.finalData?.price_range;

			// 更新起始价
			this.details.start_price = data.finalData?.start_price;
		},

		/**
		 * @description: 用户报价了
		 * @param {Object} data socket 推送数据
		 * @return {*}
		 */
		onWsQuote(data) {
			// 收到新报价时，播放声音提示
			this.audio.autoplay = true
			this.audio.src = this.warnUrl

			// 将收到的新报价添加到报价列表中
			if (data?.finalData) this.details.quote_data.unshift(data.finalData);

			// 更新出价列表总数量
			this.details.quote_count = data?.finalData?.quote_count;

			// 更新服务器时间
			this.details.now_time = data.finalData?.now_time;

			// 更新倒计时结束时间
			this.details.count_down_time = data.finalData?.count_down_time;
		},

		/**
		 * @description: 用户报价成功了
		 * @param {Object} data socket 推送数据
		 * @return {*}
		 */
		onWsQuoteResult(data) {
			// 更新竞买号
			this.details.nickname = data.finalData?.nickname;

			// 提示一下
			this.$message.success("出价成功!");
		},

		/**
		 * @description: 拍卖师删除了竞价记录
		 * @param {Object} data socket 推送数据
		 * @return {*}
		 */
		onWsRemoveQuote(data) {
			// 更新报价列表
			this.details.quote_data = data?.finalData?.quote_data;

			// 更新出价列表总数量
			this.details.quote_count = data?.finalData?.quote_count;
		},

		/**
		 * @description: 标的撤拍了
		 * @param {Object} data socket 推送数据
		 * @return {*}
		 */
		onWsWithdraw(data) {
			// 更新结束时间
			this.details.item_end_time = data.finalData?.item_end_time;

			// 更新标的状态
			this.details.status = 70;
			this.details.pause_status = 0

			// 更新顶部轮播中的标的列表数据
			this.updateTopicSwiperItems();
		},

		/**
		 * @description: 标的流拍了
		 * @param {Object} data socket 推送数据
		 * @return {*}
		 */
		onWsItemUnsold(data) {
			// 将收到的新的聊天消息添加到聊天列表中
			if (data?.finalData) this.addChatListItem(data.finalData);

			// 更新结束时间
			this.details.item_end_time = data.finalData?.item_end_time;

			// 更新标的状态
			this.details.status = 80;
			this.details.pause_status = 0


			// 更新顶部轮播中的标的列表数据
			this.updateTopicSwiperItems();
		},

		// 2024-5-22 new add
		onWsItemWidth(data) {
			// 将收到的新的聊天消息添加到聊天列表中
			if (data?.finalData) this.addChatListItem(data.finalData);

		},

		onWsItemTurn(data) {
			// 将收到的新的聊天消息添加到聊天列表中
			if (data?.finalData) this.addChatListItem(data.finalData);

		},

		// end

		/**
		 * @description: 标的成交了
		 * @param {Object} data socket 推送数据
		 * @return {*}
		 */
		onWsTurnover(data) {
			// 更新结束时间
			this.details.item_end_time = data.finalData?.item_end_time;

			// 更新标的状态
			this.details.status = 90;
			this.details.pause_status = 0


			// 更新顶部轮播中的标的列表数据
			this.updateTopicSwiperItems();
		},

		/**
		 * @description: 新的（下一个）标的开始了
		 * @param {Object} data socket 推送数据
		 * @return {*}
		 */
		onWsNewItemStart(data) {
			console.log("新的（下一个）标的开始了 -->", data);
			// 更新数据并显示通知
			if (data.finalData.item_no == this.details.no) return

			this.newItemNoticeShow = true;
			this.newItemInfo = data.finalData;

		},

		/**
		 * @description: 新增聊天数据
		 * @param {Object} item 一条聊天数据
		 * @return {*}
		 */
		addChatListItem(item) {
			this.details.speech_data?.push(item);
		},

		/**
		 * !确定出价
		 * @param {Number} myPrice 我的出价价格
		 */
		handleConfirmOfferPrice(myPrice) {
			// 判断当前领先价格是否是自己的出价，是的话提示一下
			if (this.details?.nickname === this.details.quote_data?.[0]?.nickname) {
				this.$confirm("您是当前最新价格出价人，确定继续出价?", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning",
				}).then(() => {
					// 确认继续出价
					this.offerPrice(myPrice);
				});
			}
			// 不是的话，直接报价
			else {
				this.offerPrice(myPrice);
			}
		},

		/**
		 * !报价
		 * @param {Number} price 价格
		 */
		offerPrice(price) {
			let message = {
				controller: "user",
				action: "Quote",
				authorization: localStorage["AUTHORIZATION"] || "",
				params: JSON.stringify({
					item_no: this.no || "",
					price: price || 0,
				}),
			};
			this.hiWs.send(JSON.stringify(message));
		},

		/**
		 * !查看更多聊天消息
		 */
		handleLoadMoreMessage() {
			// 更新聊天数据加载状态
			this.chatListLoadStatus = "loading";

			// 请求接口
			apiGetMoreChatMessage(
				{
					no: this.no,
					limit: 10,
					max_id: this.details.speech_data?.[0]?.id,
				},
				{ loadingShow: false }
			)
				.then((res) => {
					// 更多聊天数据
					let list = (res?.finalData || []).reverse();

					// 更新聊天数据加载状态
					this.updateChatListLoadStatus(list);

					// 将新的聊天数据添加到聊天列表顶部位置
					this.details.speech_data = list.concat(this.details.speech_data);
				})
				.catch((err) => {
					// 更新聊天数据加载状态
					this.chatListLoadStatus = "loadmore";
				});
		},

		/**
		 * !查看更多竞价记录
		 */
		handleMoreOfferRecord() {
			// 切换显示竞价记录 Tab 内容
			this.tabsValue = "record";

			// 滚动视图到对应列表内容
			this.$nextTick(() => {
				document.getElementById("TabsContents").scrollIntoView({ behavior: "smooth", block: "nearest", inline: "start" });
			});
		},

		/**
		 * !加载更多竞价记录
		 */
		handleLoadMoreBiddingRecord() {
			// 打开 loading
			this.$refs.TargetBiddingRecord?.loadingOpen();

			// 请求接口
			apiGetMoreOfferList(
				{
					no: this.no,
					limit: 10,
					max_id: this.details.quote_data?.[this.details.quote_data?.length - 1]?.id,
				},
				{
					loadingShow: false,
				}
			)
				.then((res) => {
					// 更多竞价记录数据
					let list = res?.finalData || [];

					// 关闭 loading
					this.$refs.TargetBiddingRecord?.loadingClose();

					// 将新的聊天数据添加到聊天列表顶部位置
					this.details.quote_data = this.details.quote_data.concat(list);
				})
				.catch((err) => {
					// 关闭 loading
					this.$refs.TargetBiddingRecord?.loadingClose();
				});
		},

		/**
		 * @description: 更新顶部轮播中的标的列表数据
		 * 				当前标的状态变化后顶部轮播中的对应标的的状态也需要同步改变
		 * @return {*}
		 */
		updateTopicSwiperItems() {
			console.log("更新 Swiper List");
			let items = this.details?.items;
			let index = items?.findIndex((item) => item.no == this.no);
			if (index >= 0) {
				let item = items[index];
				item.status = this.details?.status;
				item.pause_status = this.details?.pause_status;
				this.$set(this.details.items, index, item);
			}
		},

		/**
		 * @description: 保持在线心跳
		 * @return {*}
		 */
		keepOnlineHeart() {
			apiUserOnlineBearheart(
				{
					no: this.no,
				},
				{
					loadingShow: false,
					successShow: false,
					errorShow: false,
					failShow: false,
				}
			)
				.then((res) => { })
				.catch((err) => {
					console.error(err);
				});

		},
	},
};
</script>

<style scoped lang="scss">
.qr-share {
	position: fixed;
	top: 50%;
	left: 50%;
	background: #fff;
	border: 1px solid #ccc;
	padding: 10px;
	padding-top: 25px;
	z-index: 99;
	transform: translate(-50%, -50%);
	.text{
		font-size: 16px;
		color: #000;
		text-align: center;
	}
	.gb-icon{
		width: 20px;
		position: absolute;
		right: 5px;
		top: 5px;
		cursor: pointer;
	}
}
.page-target-details-content {
	position: relative;

	.target-detail-topic {
		margin-bottom: 15px;
	}

	.intro-chat-prices {
		margin-bottom: 17px;
		display: flex;
		align-items: stretch;
		max-height: 560px;

		.target-detail-intro {
			flex: 1;
		}

		.chat-prices {
			width: 290px;
			flex-shrink: 0;
			margin-left: 13px;
			display: flex;
			flex-direction: column;

			.chat {
				flex: 1;
				overflow: hidden;

				.chat-room {
					height: 100%;
				}
			}

			.prices {
				flex-shrink: 0;
				margin-top: 15px;
			}
		}
	}

	.bidding-process-container {
		margin-bottom: 17px;

		.image {
			display: block;
			width: 100%;
			height: auto;
		}
	}

	.tabs-and-contents {
		margin-bottom: 72px;

		.tab-content {
			padding: 60px 100px;
		}
	}

	.tab-content-target-bidding-record {
		max-height: 700px;
		overflow-x: hidden;
		overflow-y: auto;

		&::-webkit-scrollbar {
			width: 3px;
			background-color: #e1e1e1;
		}

		&::-webkit-scrollbar-thumb {
			width: 3px;
			background-color: #c1c1c1;
		}
	}

	.unauth-tip {
		color: #999999;
		font-weight: normal;
		padding: 16px 0;
		font-size: 12px;
		text-align: center;
	}
}
</style>

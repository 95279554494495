<!-- 用户空间 -->
<template>
	<div class="user-zone-container">
		<!-- #S 个人信息 -->
		<div class="moudle-wrapper userinfo-wrapper">
			<!-- #S 用户信息 - 未登录 -->
			<div class="userinfo-wrap" v-if="!userinfo">
				<!-- 用户头像 -->
				<div class="avatar-box">
					<img :src="require('@/assets/images/avatar-default2.png')" alt="" class="avatar" />
				</div>

				<!-- 欢迎语 -->
				<!-- <p class="welcome">hi，欢迎来到企拍网</p> -->

				<!-- 操作区域 -->
				<!-- <div class="buttons">
					<router-link to="/login" class="button button-login">登录</router-link>
					<router-link to="/register" class="button button-register button-plain">注册</router-link>
				</div> -->
				<!-- 2023-11-17 fix -->
				<div class="fix-link-box">
					<router-link to="/login" class="jmr links">
						<i class="zn-iconfont zn-iconfont-denglu-02"></i>
						<span>竞买人</span>
					</router-link>
					<div class="wz links" @click="linksHandler">
						<i class="zn-iconfont zn-iconfont-denglu-03"></i>
						<span>物资处置企业</span>
					</div>
				</div>
				<div class="fix-buttons">
					<router-link to="/register" class="fix-button-register">注册</router-link>
				</div>
			</div>
			<!-- #E 用户信息 - 未登录 -->

			<!-- #S 用户信息 - 已登录 -->
			<div class="userinfo-wrap" v-else>
				<!-- #S 用户信息 -->
				<div class="infos">
					<!-- 用户头像 -->
					<div class="avatar-box">
						<img :src="userinfo?.head_img || require('@/assets/images/avatar-default.png')" alt="" class="avatar" />
					</div>

					<!-- #S 用户昵称 + 认证状态 -->
					<div class="name-box">
						<!-- 用户昵称 -->
						<p class="nickname ellipsis1" :title="userinfo?.nickname || ''">{{ userinfo?.nickname || "" }}</p>

						<!-- 认证状态 - 未认证 -->
						<div class="cert-status-box" v-if="userRealTimeInfo?.user_status == 10">
							<i class="zn-iconfont zn-iconfont-renzheng cert-status-icon"></i>
							<span>未认证</span>
						</div>

						<!-- 认证状态 - 审核中 -->
						<div class="cert-status-box" v-else-if="userRealTimeInfo?.user_status == 20">
							<i class="zn-iconfont zn-iconfont-renzheng cert-status-icon"></i>
							<span>审核中</span>
						</div>

						<!-- 认证状态 - 审核失败 -->
						<div class="cert-status-box" v-else-if="userRealTimeInfo?.user_status == 30">
							<i class="zn-iconfont zn-iconfont-renzheng cert-status-icon"></i>
							<span>审核失败</span>
						</div>

						<!-- 认证状态 - 已认证 -->
						<div class="cert-status-box certified" v-else-if="userRealTimeInfo?.user_status == 40">
							<i class="zn-iconfont zn-iconfont-renzheng cert-status-icon"></i>
							<span>已认证</span>
						</div>
					</div>
					<!-- #E 用户昵称 + 审核状态 -->

					<!-- #S 用户历史数据展示 -->
					<div class="numbers">
						<!-- 参拍中 -->
						<div class="number-box">
							<span class="number-value">{{ userRealTimeInfo?.joins_auction_item_ing_count || 0 }}</span>
							<label class="number-label">参拍中</label>
						</div>

						<!-- 已拍下 -->
						<div class="number-box">
							<span class="number-value">{{ userRealTimeInfo?.already_turnover || 0 }}</span>
							<label class="number-label">已拍下</label>
						</div>
					</div>
					<!-- #E 用户历史数据展示 -->
				</div>
				<!-- #E 用户信息 -->

				<!-- #S 操作区域 -->
				<div class="buttons">
					<router-link to="/mine" class="button button-my">个人中心</router-link>
					<div class="button button-logout button-plain" @click="handleLogout">退出登录</div>
				</div>
				<!-- #E 操作区域 -->
			</div>
			<!-- #E 用户信息 - 已登录 -->
		</div>
		<!-- #E 个人信息 -->

		<!-- #S 帮助中心 -->
		<div class="moudle-wrapper help-wrapper">
			<div class="help-wrap">
				<!-- 标题 -->
				<div class="common-title">
					<label class="label">帮助中心</label>
					<router-link class="more" :to="{ name: 'HelpCenterIndex' }">
						<span>更多</span>
						<i class="zn-iconfont zn-iconfont-you icon"></i>
					</router-link>
				</div>

				<!-- 列表 -->
				<div class="process-wrapper">
					<div class="item" v-for="item in helpCenterData" :key="item.id" @click="handleHelpCenterItemClick(item)">
						<div class="icon-box">
							<img class="icon fix-img" :src="item?.image" alt="" />
							<img class="icon fix-img-h" :src="item?.hover_image" alt="" />
						</div>
						<label class="label">{{ item?.name }}</label>
					</div>
				</div>
			</div>
		</div>
		<!-- #E 帮助中心 -->
	</div>
</template>

<script>
	// Vue
	import { mapGetters } from "vuex";

	export default {
		name: "UserZone",
		computed: {
			// 用户信息
			...mapGetters(["userinfo"]),
		},
		props: {
			// 用户实时信息
			userRealTimeInfo: {
				type: Object,
				detault: () => {},
			},

			// 帮助中心数据
			helpCenterData: {
				type: Array,
				default: () => [],
			},
		},
		methods: {
			/**
			 * @description: 退出登录
			 * @return {*}
			 */
			handleLogout() {
				this.$confirm("您正在进行退出登录操作, 是否继续?", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning",
				})
					.then(() => {
						this.$store.dispatch("logout");
						this.$message({
							type: "success",
							message: "退出成功!",
						});
						window.location.reload();
					})
					.catch(() => {
						this.$message({
							type: "info",
							message: "退出失败",
						});
					});
			},

			/**
			 * @description: 帮助中心列表项点击事件
			 * @param {*} item 列表项数据
			 * @return {*}
			 */
			handleHelpCenterItemClick(item) {
				this.$router.push({ name: "HelpCenterIndex", query: { title: item?.name } });
			},
			// 物资处置企业登录页面
			linksHandler(){
				// window.open('http://zhongnuopaimai.biaofun.com.cn/enterprise/login','_blank')
				window.location.href = '/disposal'
			}
		},
	};
</script>

<style scoped lang="scss">
	.user-zone-container {
		height: 100%;
		display: flex;
		flex-direction: column;

		.moudle-wrapper {
			padding: 35px 23px;
		}

		.userinfo-wrapper {
			flex: 1;
			overflow: hidden;
			padding-top: 23px;
			.fix-link-box{
				width: 246px;
				display: flex;
				justify-content: space-between;
				margin-top: 14px;
				.links{
					display: flex;
					justify-content: center;
					align-items: center;
					width: 48%;
					height: 40px;
					background: #f6e6e8;
					color: #c35665;
					font-size: 14px;
					border-radius: 30px;
					text-decoration: none;
					cursor: pointer;
					i{
						font-size: 16px;
						color: #c35665;
						margin-right: 3px;
					}
					&:hover{
						background: #af1e32;
						color: #fff;
					}
					&:hover i{
						color: #fff;
					}
				}
				
			}
			.fix-buttons{
				.fix-button-register{
					display: block;
					width: 246px;
					height: 41px;
					line-height: 41px;
					color: #c35665;
					text-align: center;
					border: 1px solid #c35665;
					text-decoration: none;
					font-size: 14px;
					border-radius: 30px;
					margin-top: 11px;
				}				
			}
			.userinfo-wrap {
				display: flex;
				flex-direction: column;
				align-items: center;
			}

			.avatar-box {
				width: 72px;
				height: 72px;
				border-radius: 50%;
				overflow: hidden;
				background-color: var(--theme-color);
				flex-shrink: 0;
			}

			.avatar {
				display: block;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}

			.welcome {
				font-size: 18px;
				color: #040000;
				/* padding: 1em 0; */
				padding: 15px 0;
			}

			.buttons {
				display: flex;
			}

			.button {
				width: 156px;
				height: 43px;
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;
				user-select: none;
				/* background-color: var(--theme-color); */
				background-color: #bb1c33;
				color: #ffffff;
				transition: 0.2s;
				margin: 0 3px;
				text-decoration: none;
				/* border: 1px solid var(--theme-color); */
				border: 1px solid #db605a;


				&:hover {
					opacity: 0.8;
				}

				&.button-plain {
					background-color: #ffffff;
					/* color: var(--theme-color); */
					color: #c9626e;
				}
			}

			.infos {
				display: flex;
				align-items: center;
				width: 100%;
				margin-bottom: 36px;

				.avatar-box {
					margin-right: 19px;
				}

				.name-box {
					flex: 1;
					display: flex;
					flex-direction: column;
					color: #333333;
					line-height: 1;
					overflow: hidden;
				}

				.nickname {
					font-size: 18px;
					font-weight: bold;
					margin-bottom: 10px;
					display: block;
				}

				.cert-status-box {
					display: flex;
					align-items: center;
					color: #666666;

					.cert-status-icon {
						// color: var(--theme-color);
						color: #666666;
						margin-right: 6px;
					}

					&.certified {
						.cert-status-icon {
							color: var(--theme-color);
						}
					}
				}

				.status-uncert {
					.cert-status-icon {
						color: #999999;
					}
				}

				.numbers {
					flex-shrink: 0;
					display: flex;
					line-height: 1;

					.number-box {
						display: flex;
						flex-direction: column;
						text-align: center;
						padding: 0 16px;
						position: relative;

						&:not(:first-child)::before {
							content: "";
							width: 1px;
							height: 16px;
							position: absolute;
							left: 0;
							top: 50%;
							margin-top: -8px;
							background-color: #bfbfbf;
						}

						.number-value {
							color: var(--theme-color);
							font-family: "MarkPro";
							font-size: 0.285;
						}

						.number-label {
							margin-top: 7px;
						}
					}
				}
			}
		}

		.help-wrapper {
			height: 173px;
			background-color: #fff;
			flex-shrink: 0;
			border-top: 1px solid #ededed;
			padding-top: 25px;

			.common-title {
				margin-bottom: 23px;
			}

			.process-wrapper {
				display: flex;
				justify-content: space-between;

				.item {
					cursor: pointer;
					user-select: none;
					display: flex;
					flex-direction: column;
					align-items: center;
					transition: 0.2s;
					text-decoration: none;
					color: inherit;

					.icon {
						display: block;
						width: 51px;
						height: 51px;
						object-fit: contain;
						margin-bottom: 10px;
					}
					.fix-img-h{
						display: none;
					}

					&:hover {
						color: var(--theme-color);
					}
					&:hover .fix-img-h {
						display: block;
					}
					&:hover .fix-img {
						display: none;
					}
				}
			}
		}
	}
	.more{
		text-decoration: none;
		color: #a6a6a6;
	}
</style>

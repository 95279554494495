<!-- 标的 - 报名 -->
<template>
	<div class="page-container page-target-enroll-container">
		<WebHeader></WebHeader>
		<div class="page-target-enroll-content">
			<!-- 面包屑 -->
			<Breadcrumbs :list="breadcrumbs"></Breadcrumbs>

			<!-- 主内容区域 -->
			<div class="layout-main">
				<div class="main-content">
					<!-- 确认身份信息 -->
					<div class="module id-info">
						<h1 class="module_title">⊙ 请确认身份信息</h1>
						<div class="module_content">
							<div class="module_row">
								<label class="module_row_label">竞买人姓名：</label>
								<span class="module_row_value">{{ pdata?.username }}</span>
							</div>
							<div class="module_row">
								<label class="module_row_label">身份证号码：</label>
								<span class="module_row_value">{{ pdata?.id_number }}</span>
							</div>
							<div class="module_row">
								<label class="module_row_label">联系方式：</label>
								<span class="module_row_value">{{ pdata?.phone }}</span>
								<el-button type="primary" size="small" class="send-sms-button" @click="onClickSendSmsBtn"
									:disabled="isSendSmsBtnCountdown">{{
										sendSmsBtnText
									}}</el-button>
							</div>
							<div class="module_row module_row-captcha">
								<label class="module_row_label">验证码</label>
								<div class="module_row_value">
									<el-input class="module_row_input module_row_input-captcha" v-model="captcha"
										placeholder="输入验证码"></el-input>
								</div>
							</div>
						</div>
					</div>

					<!-- 标的信息 + 报名标的 -->
					<div class="modules">
						<!-- 标的信息 -->
						<div class="module target-info" style="padding-right: 60px;">
							<h1 class="module_title">⊙ 标的信息</h1>
							<div class="module_content">
								<div class="module_row">
									<label class="module_row_label" style="width: 85px;">拍卖公告：</label>
									<span class="module_row_value" style="line-height: 1.5">{{ pdata?.notice_title }}</span>
								</div>
								<div class="module_row">
									<label class="module_row_label" style="width: 85px;">拍卖会：</label>
									<span class="module_row_value" style="line-height: 1.5">{{ pdata?.auction_title }}</span>
								</div>
								<div class="module_row">
									<label class="module_row_label" style="width: 85px;">保证金：</label>
									<div class="module_row_value">
										<span class="module_row_value_price">{{ formatPrice(bail) }}元 </span>
									</div>
								</div>
								<div class="module_row">
									<label class="module_row_label" style="width: 85px;">缴纳方式：</label>
									<span class="module_row_value" style="line-height: 1.5">联系拍卖企业线下支付</span>
								</div>
							</div>
						</div>

						<!-- 报名标的 -->
						<div class="module target-list">
							<h1 class="module_title">⊙ 报名标的</h1>
							<div class="module_content">
								<el-checkbox-group v-model="checkedIds">
									<el-checkbox :label="item.no" v-for="item in pdata?.items || []" :key="item.no"
										:disabled="pdata?.full_enroll == 10 || item?.cannot_enroll">{{ item.title
										}}</el-checkbox>
								</el-checkbox-group>
							</div>
						</div>
					</div>

					<!-- 阅读事项 -->
					<div class="module no-border reading-items">
						<h1 class="module_title">⊙ 竞买人已阅读并同意</h1>
						<div class="module_content">
							<!-- <div class="reading-items-wrapper">
								<div class="reading-items-content" v-html="pdata?.tip_content"></div>
							</div> -->
							<div class="change-text">1. 未实地看样的竞买人视为对本标的实物现状确认。竞买人一旦作出竞买决定，即表明已完全了解，并接受标的物的现状和一切已知及未知的瑕疵。
							</div>
							<div class="change-text">2. 买受人中标后悔拍，将视为违约，保证金不予退还。</div>
							<div class="change-text">
								3. 请详细阅读平台规则及拍卖标的页面的所有文件，包括但不限于
								<router-link :to="{ name: 'NoticeCenterDetails', query: { no: notice_no } }"
									class="links">《拍卖公告》</router-link>
								<router-link :to="{ name: 'TargetDetails', query: { no: no, tag: 1 } }"
									class="links">《竞买须知》</router-link>
								<router-link :to="{ name: 'TargetDetails', query: { no: no, tag: 2 } }"
									class="links">《重要提示》</router-link>
								<router-link :to="{ name: 'TargetDetails', query: { no: no, tag: 3 } }"
									class="links">《标的介绍》</router-link>以及企拍拍卖所有拍卖有关的规则，着重关注上述文件中关于保证金等规则条款。
							</div>
							<div class="change-text">4. 
								<span @click="openHandler(1)" class="links">《企拍网拍卖规则》</span>及
								<span @click="openHandler(2)" class="links">《网络拍卖平台使用协议》</span>
							</div>
						</div>
					</div>

					<!-- 同意勾选状态 -->
					<div class="agree-wrapper">
						<el-checkbox v-model="isAgree" class="links">本人已知晓并同意以上所有事项。</el-checkbox>
						<!-- <router-link :to="{ name: 'NoticeCenterDetails', query: { no: notice_no } }"
							class="links">《拍卖公告》</router-link>
						<router-link :to="{ name: 'TargetDetails', query: { no: no, tag: 1 } }"
							class="links">《竞买须知》</router-link>
						<span class="links" @click="openHandler(1)">《企拍网拍卖规则》</span>
						<span class="links" @click="openHandler(2)">《网络拍卖平台使用协议》</span> -->

					</div>

					<!-- 报名按钮 -->
					<el-button type="primary" size="small" class="enroll-button" @click="handleSubmit">报名</el-button>
				</div>
			</div>
		</div>
		<WebFooter></WebFooter>
		<!-- 拍卖规则弹窗 -->
		<AgreementDialog ref="rules" title="企拍网拍卖规则" :content="platform_auction_rule"></AgreementDialog>
		<!-- 平台使用协议 -->
		<AgreementDialog ref="agreement" title="网络拍卖平台使用协议" :content="platform_use_agreement"></AgreementDialog>


	</div>
</template>

<script>
import WebHeader from "@/components/WebHeader.vue"; // 页面Header
import WebFooter from "@/components/WebFooter.vue"; // 页面Footer
import Breadcrumbs from "@/components/Breadcrumbs.vue"; // 面包屑
import AgreementDialog from "@/components/AgreementDialog.vue"; // 协议弹窗
import { breadcrumbsTargetEnroll } from "@/breadcrumbs/index.js"; // 面包屑数据
import { apiGetEnrollPageData, apiOnEnroll } from "@/apis/target.js";
import { checkUserLoginStatus } from "@/utils/func.js";
import { apiSendSmsCode, apiGetPlatformAuctionRules, apiGetPlatformAgreement } from "@/apis/common.js";
import { isPhoneNumber } from "@/utils/validate";

export default {
	name: "PageTargetEnroll",
	components: {
		WebHeader,
		WebFooter,
		Breadcrumbs,
		AgreementDialog
	},
	data() {
		return {
			breadcrumbs: breadcrumbsTargetEnroll, // 面包屑数据
			auctionNo: "", // 拍卖会id
			no: "", // 标的id
			title: "", // 标的标题
			pdata: {}, // 页面数据
			notice_no: '',
			checkedIds: [], // 选择的要报名的标的id数组
			isAgree: false, // 是否已阅读，并同意上述所有事项
			sendSmsBtnText: "发送验证码", // 发送验证码按钮上显示的文本
			isSendSmsBtnCountdown: false, // 发送验证码按钮是否正在倒计时
			sendSmsCountdownSeconds: 60, // 发送验证码按钮倒计时总秒数
			captcha: "", // 输入的验证码值
			platform_use_agreement: '',//平台使用协议
			platform_auction_rule: '',//平台拍卖规则
		};
	},
	computed: {
		// 保证金
		bail() {
			// 全场报名直接使用接口返回的全场保证金数据
			if (this.pdata?.full_enroll == 10) return this.pdata?.full_enroll_price;
			// 否则，需要自己计算选中的标的的保证金总和
			let totalBond = 0;
			this.pdata?.items?.forEach((item) => {
				if (this.checkedIds.find((no) => no == item.no)) {
					totalBond += item.bond;
				}
			});
			return totalBond;
		},
	},
	mounted() {
		// 得到拍卖会id + 标的id + 标的标题
		this.auctionNo = this.$route.query?.auction_no || "";
		this.no = this.$route.query?.no || "";
		this.title = this.$route.query?.title || "";

		// 更新面包屑数据
		this.breadcrumbs = breadcrumbsTargetEnroll.concat([
			{
				name: this.title,
				to: { name: "TargetDetails", query: { no: this.no } },
				replace: true,
			},
			{
				name: "报名",
				to: { name: "TargetEnroll" },
			},
		]);

		// 检查用户登录状态
		if (!checkUserLoginStatus()) return this.showLoginTipsDialog();

		// 查询页面数据
		this.queryData();
		this.queryAuctionRule()
		this.queryAuctionAgreement()

	},
	methods: {
		openHandler(type) {
			console.log(type);
			if (type == 1) {
				this.$refs.rules?.open();
			} else {
				this.$refs.agreement?.open();
			}
		},
		/**
		 * !显示需要登录提示对话窗
		 */
		showLoginTipsDialog() {
			this.$confirm("您当前进行的操作需要登录后才能继续,是否现在去登录?", "提示", {
				confirmButtonText: "去登录",
				cancelButtonText: "取消",
				type: "info",
			})
				.then(() => {
					this.$router.push("/login");
				})
				.catch(() => {
					if (sessionStorage["ZHONGNUO_FROM_PATH"]) this.$router.go(-1);
					else this.$router.replace({ path: "/" });
				});
		},

		/**
		 * !查询页面数据
		 */
		queryData() {
			apiGetEnrollPageData({
				no: this.auctionNo,
			})
				.then((res) => {
					console.log("res", res);

					this.pdata = res?.finalData || {};
					this.notice_no = res.finalData.notice_no || "";

					// 开启全场报名后,全部标的默认选中,并且不可取消
					if (this.pdata?.full_enroll == 10) {
						this.checkedIds = this.pdata?.items?.map((item) => item.no);
					}
				})
				.catch((err) => console.error(err));
		},

		/**
		 * !点击了"发送验证码"按钮
		 */
		onClickSendSmsBtn() {
			// 验证手机号
			// 有效的手机号
			if (isPhoneNumber(this.pdata?.phone)) {
				// 调用接口发送验证码
				apiSendSmsCode(
					{
						phone: this.pdata.phone,
						mode: 'item_enroll'
					},
					{
						loadingMsg: "正在发送验证码",
						successShow: true,
					}
				)
					.then((res) => {
						// 开始倒计时
						this.countdownStart();
					})
					.catch((err) => console.error(err));
			}

			// 无效的手机号
			else this.$message.warning("无效的手机号!");
		},

		/**
		 * !开始倒计时
		 */
		countdownStart() {
			// 倒计时总秒数
			let seconds = this.sendSmsCountdownSeconds;

			// 更新倒计时状态
			this.isSendSmsBtnCountdown = true;

			// 更新按钮文本
			this.sendSmsBtnText = seconds + "s后重新获取";

			// 设置定时器
			let timer = setInterval(() => {
				// 减秒
				seconds--;

				// 倒计时未结束
				// 更新按钮文本
				if (seconds >= 0) return (this.sendSmsBtnText = seconds + "s后重新获取");

				// 倒计时结束了
				// 更新倒计时文本
				this.sendSmsBtnText = " 重新发送";

				// 更新倒计时状态
				this.isSendSmsBtnCountdown = false;

				// 清除定时器
				clearInterval(timer);
			}, 1000);
		},

		/**
		 * !报名
		 */
		handleSubmit() {
			// 验证是否填写了验证码
			if (this.captcha == "") {
				this.$message.warning("请填写验证码!");
				return;
			}

			// 验证是至少选择了一个标的
			if (!this.checkedIds.length) {
				this.$message.warning("请选择需要报名的标的!");
				return;
			}

			// 验证是否已阅读，并同意上述所有事项
			if (!this.isAgree) {
				this.$message.warning("请阅读，并同意确认阅读事项");
				return;
			}

			// 请求接口进行报名
			apiOnEnroll({
				auction_no: this.auctionNo,
				item_no: this.checkedIds,
				code: this.captcha,
			})
				.then((res) => {
					this.$confirm("报名成功,是否返回上一页?", "提示", {
						confirmButtonText: "返回",
						cancelButtonText: "取消",
						type: "success",
					})
						.then(() => {
							if (sessionStorage["ZHONGNUO_FROM_PATH"]) this.$router.go(-1);
							else this.$router.replace({ path: "/" });
						})
						.catch(() => { });
				})
				.catch((err) => console.error(err));
		},
		// 查询平台拍卖规则
		queryAuctionRule() {
			apiGetPlatformAuctionRules()
				.then((res) => {
					console.log('查询平台拍卖规则', res);
					this.platform_auction_rule = res.finalData.platform_auction_rule

				})
				.catch((err) => console.error(err));
		},
		// 获取平台使用协议
		queryAuctionAgreement() {
			apiGetPlatformAgreement()
				.then((res) => {
					console.log('获取平台使用协议', res);
					this.platform_use_agreement = res.finalData.platform_use_agreement
				})
				.catch((err) => console.error(err));
		}
	},
};
</script>

<style scoped lang="scss">
.change-text {
	margin-bottom: 10px;
	font-size: 18px;
	color: #9b9b9b;
	text-align: justify;

	.links {
		color: #4b48c1;
		font-weight: 600;
	}
}

.page-target-enroll-content {
	.main-content {
		background-color: #ffffff;
		padding: 70px 80px;
		margin-bottom: 72px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.modules {
		display: flex;
		border-bottom: 2px solid #f7f7f7;
		margin-bottom: 45px;
		width: 100%;

		.module {
			width: 50%;
			border-bottom: none;
			padding-bottom: 56px;
			margin-bottom: 0;
		}
	}

	.module {
		width: 100%;
		border-bottom: 2px solid #f7f7f7;
		padding-bottom: 45px;
		margin-bottom: 45px;
		color: #666666;
		font-size: 16px;

		&.no-border {
			border-bottom: none;
		}

		&.reading-items {
			padding-bottom: 18px;
			margin-bottom: 0;
		}

		&_title {
			font-size: 24px;
			line-height: 1;
			margin-bottom: 29px;
			color: #333333;
		}

		&_row {
			display: flex;
			align-items: center;
			line-height: 1;
			padding: 10px 0;

			&_label {
				flex-shrink: 0;
			}

			&_input-captcha {
				width: 130px;
				font-size: 16px;

				::v-deep {
					.el-input__inner {
						height: 45px;
						border-width: 2px;
						border-radius: 0;
					}
				}
			}
		}

		.send-sms-button {
			width: 100px;
			height: 26px;
			line-height: 26px;
			font-size: 14px;
			margin-left: 20px;
			border-radius: 0;
			padding: 0;
		}

		&.id-info {
			.module_row_label {
				width: 96px;
				flex-shrink: 0;
				margin-right: 20px;
			}

			.module_row-captcha {
				.module_row_label {
					width: auto;
				}
			}
		}

		.reading-items-wrapper {
			background-color: #f5f5f5;
			height: 326px;
			overflow-x: hidden;
			overflow-y: auto;
		}

		.reading-items-wrapper::-webkit-scrollbar {
			width: 6px;
			background-color: #e1e1e1;
		}

		.reading-items-wrapper::-webkit-scrollbar-thumb {
			width: 6px;
			height: 64px;
			background-color: #999999;
		}

		.reading-items-content {
			font-size: 14px;
			color: #666666;
			line-height: 30px;
			padding: 53px 50px 54px 45px;
		}
	}

	.agree-wrapper {
		margin-bottom: 28px;
		width: 100%;
		display: flex;
		align-items: center;
	}

	.enroll-button {
		width: 200px;
		height: 44px;
		line-height: 44px;
		padding: 0;
		font-size: 16px;
		border-radius: 0;
	}

	::v-deep {
		.el-checkbox {
			padding: 9px 0;
			line-height: 1;
			display: block;

			.el-checkbox__inner {
				transform: scale(1.4);
				background-color: #f5f5f5;
			}

			.el-checkbox__input.is-checked .el-checkbox__inner {
				background-color: var(--theme-color);
			}

			.el-checkbox__label {
				font-size: 16px;
				line-height: 1;
			}
		}
	}
}

.links {
	font-size: 16px;
	color: #333333;
	text-decoration: none;
	padding: 0px !important;
	cursor: pointer;
}

.page-target-enroll-content .el-checkbox {
	font-size: 23px;
}
</style>

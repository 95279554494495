<!-- 标的列表项 -->
<template>
	<div class="component-average-price-list-item">
		<div class="head">{{ item.name }}</div>
		<div class="content">
			<div class="item">
				<label class="label">均价</label>
				<div class="value">
					<span class="price">{{ formatPrice(item?.price) }}</span>
					<span class="unit">元</span>
				</div>
			</div>
			<div class="item">
				<label class="label">涨跌</label>
				<div class="value">
					<span class="price jia" v-if="item?.rise_fall > 0">{{ formatPrice(item?.rise_fall) }}</span>
					<span class="price jian" v-else-if="item?.rise_fall < 0">{{ formatPrice(item?.rise_fall) }}</span>
					<span class="price"  v-if="item?.rise_fall == 0">{{ formatPrice(item?.rise_fall) }}</span>
					<span class="unit">元</span>
				</div>
			</div>
		</div>
	</div>
</template>
<!-- ping" -->
<script>
	export default {
		name: "AveragePriceListItem",
		props: {
			item: Object,
		},
		data() {
			return {};
		},
		mounted() {},
		methods: {},
	};
</script>

<style lang="scss" scoped>
	.component-average-price-list-item {
		border: 1px solid #eeeeee;
		background-color: #ffffff;

		.head {
			background-color: #fafafa;
			font-size: 18px;
			line-height: 1;
			padding: 15px 17px;
		}

		.content {
			display: flex;
			padding: 17px 6px;

			.item {
				width: 50%;
				display: flex;
				flex-direction: column;
				align-items: center;
				line-height: 1;

				.label {
					color: #666666;
					margin-bottom: 13px;
				}

				.price {
					font-size: 18px;
					font-family: "MarkPro-Medium";

					&.jia {
						color: var(--theme-color5);
					}

					&.jian,
					&.ping {
						color: var(--theme-color4);
					}
				}

				.unit {
					font-size: 12px;
					color: #666666;
					display: inline-block;
					margin-left: 2px;
				}
			}
		}
	}
</style>

import CryptoJS from "crypto-js";

export default {
	// 通过 getter 获取解密后的用户信息
	userinfo: (state) => {
		if (!state.userinfo) return null;
		try {
			let userinfo = JSON.parse(CryptoJS.enc.Base64.parse(state.userinfo).toString(CryptoJS.enc.Utf8));
			return userinfo;
		} catch (error) {
			return null;
		}
	},

	// 通过 getter 获取解密后的首页数据
	pageIndexData: (state) => {
		if (!state.pageIndexData) return null;
		try {
			let pageIndexData = JSON.parse(CryptoJS.enc.Base64.parse(state.pageIndexData).toString(CryptoJS.enc.Utf8));
			return pageIndexData;
		} catch (error) {
			return null;
		}
	},
};

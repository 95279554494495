/**
 * ****************************************************************************************************
 * Request Promise.then 的回调函数
 * @param {Object} config 合并后的请求配置项
 * @param {Response} response 请求接口返回的Response对象
 * @param {Function} resolve Promise.resolve回调函数
 * @param {Function} reject Promise.reject回调函数
 * @return {Boolean} 该函数返回 true 则请求继续，否则，中断请求
 * ****************************************************************************************************
 */
import { isString } from "./utils";
import { closeLoading, showSuccess, showError } from "./functions";
import { handleCodes } from "./codes";
import { decrypt } from "../utils/crypto.js";

export default function (config, response, resolve, reject) {
	// 关闭loading
	if (config.loadingShow) closeLoading(config);

	// 如果 response.data 是字符串，尝试处理一下
	if (isString(response.data)) {
		try {
			response.data = JSON.parse(response.data);
		} catch (e) {
			console.error("尝试 JSON.parse 返回数据异常了：->", e, response);
		}
	}

	// 更新请求凭证
	if (response?.headers?.[config?.apiAuthKey]) localStorage[config.apiAuthKey.toUpperCase()] = response.headers[config.apiAuthKey];
	if (response?.headers?.[config?.apiAuthKey?.toLowerCase()]) localStorage[config.apiAuthKey.toUpperCase()] = response.headers[config.apiAuthKey.toLowerCase()];

	console.log('response',response);
	// 200
	if (response.status == 200) {
		// 数据正常
		if (response.data.code == 1000 || response.data.code == 200) {
			// 是否需要显示success提示
			if (config.successShow) showSuccess(config, response);

			// 需要解密
			if (response.headers && response.headers.secret && response.data && response.data.result) {
				let cryptData = response.data.result || "";
				let decryptData = decrypt(cryptData, null, response.headers.secret);
				response.data.finalData = decryptData;
				response.data.tokens = response.headers
			}
			// 不需要解密
			else {
				response.data.finalData = response.data.result;
			}

			resolve(response.data);
		}

		// 数据异常
		else {
			console.warn("接口请求异常:-> ", response);
			console.log('cccc',config);

			// 是否需要显示error提示
			if (config.errorShow) showError(config, response);

			// 状态码处理函数
			handleCodes(config, response.data);

			reject({
				code: response.data[config.codeKey],
				msg: response.data[config.messageKey],
			});
		}
	}

	// !200
	else {
		// 请求失败
		// 是否需要显示error提示
		if (config.errorShow) showError(config, response);
		reject({
			code: response.statusCode,
			msg: response.errorMsg,
		});
	}
}

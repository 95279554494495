/**
 * ****************************************************************************************************
 * Request 请求配置项
 * ****************************************************************************************************
 */
// 响应拦截器
import interceptor from "./interceptor";

// 接口基准路径
let baseURL = "https://www.qipai-wang.com/";
// let baseURL = "http://zhongnuopaimai.biaofun.com.cn/";


if (process.env.NODE_ENV === "development"){
	// baseURL = "http://zhongnuopaimai.biaofun.com.cn/";
	baseURL = "https://www.qipai-wang.com/";
}
export const apiBaseURL = baseURL;

// 鉴权凭证的名称
export const apiAuthKey = "Authorization";
// const apiAuthKey = "Authorization";

export default {
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// 基础配置
	baseURL: baseURL, // 接口基准地址
	apiAuthKey: apiAuthKey, // 鉴权凭证的名称,每次请求时会将此字段作为参数放到请求header中,每次响应时,如果响应header中有此字段,会更新此字段的值,此字段的值会存储到localStorage中
	messageKey: "msg", // 接口返回数据中的message字段的key名称,因为每个人写的接口此字段叫的名字可能不一样,在这里配置方便一点
	codeKey: "code", // 接口返回数据中的code字段的key名称,同上
	method: "POST", // 默认请求类型
	timeout: 30000, // 默认超时时间，单位毫秒
	headers: {}, // 自定义请求头
	// 表示浏览器将要响应的数据类型
	// 选项包括: 'arraybuffer', 'document', 'json', 'text', 'stream'
	// 浏览器专属：'blob'
	responseType: "json",
	withCredentials: false, // 表示跨域请求时是否需要使用凭证
	interceptor: interceptor, // 请求拦截函数，该函数返回 true 则请求继续，否则，中断请求，该函数会回调合并后的配置数据

	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// loading
	loadingShow: true, // 默认是否显示loading
	loadingTime: 800, // 请求超过此值设置的毫秒数后才会显示loading,设置为0会在请求后立即显示loading
	loadingMsg: "加载中", // 提示的内容
	loadingMask: true, // 是否显示透明蒙层，防止触摸穿透

	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// success: 一般表示 statusCode 和 dataCode 都为 200 时,才算此次请求成功,正常返回了数据
	successShow: false, // 是否显示提示
	successMsg: "", // 提示内容，设置有效值后，会优先显示此提示，不再显示接口返回的提示内容
	successDuration: 2000, // 提示的持续时间
	successCallback: () => {}, // 提示显示结束后的回调函数

	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// error: 一般表示 statusCode 和 dataCode 其中有一个不为 200,或者是接口错误,或者是数据不是预期的数据
	errorShow: true, // 是否显示提示
	errorMsg: "", // 提示内容，设置有效值后，会优先显示此提示，不再显示接口返回的提示内容
	errorDuration: 2000, // 提示的持续时间
	errorCallback: () => {}, // 提示显示结束后的回调函数

	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// fail: 请求未成功，包括：网络不通，请求被强行中断，请求超时，找不到域名，ssl握手失败等时的提示
	failShow: true, // 是否显示提示
	failMsg: "", // 提示内容，设置有效值后，会优先显示此提示，不再显示接口返回的提示内容
	failDuration: 2000, // 提示的持续时间
	failCallback: () => {}, // 提示显示结束后的回调函数

	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// 上传配置
	file: null, // 要上传的文件对象
	fileType: "image", // 文件类型，image/video/audio
	filePath: "", // 要上传的文件路径
	fileName: "file", // 文件对应的 key , 开发者在服务器端通过这个 key 可以获取到文件二进制内容
	formData: {}, // HTTP 请求中其他额外的 form data
	maxSize: 0, // 限制文件大小，0为不限制，单位KB
	suffixs: ["all"], // 允许上传的文件后缀名
	// --------------------------------------------------------------------------------
	// 空文件提示
	fileEmptyShow: true, // 是否显示提示
	fileEmptyMsg: "请选择文件", // 提示内容
	fileEmptyDuration: 2000, // 提示的持续时间
	fileEmptyCallback: () => {}, // 提示显示结束后的回调函数
	// --------------------------------------------------------------------------------
	// 文件过大后的提示
	maxSizeOverflowShow: true, // 是否显示提示
	maxSizeOverflowMsg: "文件大小超出限制", // 提示内容
	maxSizeOverflowDuration: 2000, // 提示的持续时间
	maxSizeOverflowCallback: () => {}, // 提示显示结束后的回调函数
	// --------------------------------------------------------------------------------
	// 文件格式不正确的提示
	suffixsWrongShow: true, // 是否显示提示
	suffixsWrongMsg: "文件格式不正确", // 提示内容
	suffixsWrongDuration: 2000, // 提示的持续时间
	suffixsWrongCallback: () => {}, // 提示显示结束后的回调函数
};

<!-- 数据筛选过滤组件 -->
<template>
	<div class="component-data-filter">
		<div class="item" :class="{ 'is-active': sortByType == 'update_time' }" @click="handleSortByItem('update_time')">
			<span class="label">按更新时间</span>
			<div class="arrows">
				<i class="arrow arrow-up zn-iconfont zn-iconfont-shengxu" :class="{ 'is-active': sortByValue == 'asc' }"></i>
				<i class="arrow arrow-down zn-iconfont zn-iconfont-jiangxu" :class="{ 'is-active': sortByValue == 'desc' }"></i>
			</div>
		</div>
		<div class="item" :class="{ 'is-active': sortByType == 'price' }" @click="handleSortByItem('price')">
			<span class="label">按价格</span>
			<div class="arrows">
				<i class="arrow arrow-up zn-iconfont zn-iconfont-shengxu" :class="{ 'is-active': sortByValue == 'asc' }"></i>
				<i class="arrow arrow-down zn-iconfont zn-iconfont-jiangxu" :class="{ 'is-active': sortByValue == 'desc' }"></i>
			</div>
		</div>
		<div class="item">
			<label class="label">价格区间 ：</label>
			<div class="input-box">
				<span class="symbol">¥</span>
				<el-input class="input" v-model="priceStart"></el-input>
			</div>
			<span class="gap">至</span>
			<div class="input-box">
				<span class="symbol">¥</span>
				<el-input class="input" v-model="priceEnd"></el-input>
			</div>
			<div class="button-wrapper"><el-button type="primary" size="mini" @click="handleSubmit">确认</el-button></div>
		</div>
	</div>
</template>

<script>
export default {
	name: "DataFilter",
	props: {},
	data() {
		return {
			sortByType: "update_time", // 选择的排序类型. update_time: 按更新时间排序; price: 按价格排序;
			sortByValue: "desc", // 选择的排序规则 desc: 降序; asc: 升序;
			priceStart: "", // 价格区间 - 开始价格
			priceEnd: "", // 价格区间 - 结束价格
		};
	},
	mounted() {},
	methods: {
		/**
		 * !排序类型点击事件
		 * @param {String} type 点击的排序类型
		 */
		handleSortByItem(type) {
			// 如果点击的类型 != 当前选择的类型
			if (this.sortByType != type) {
				// 就更新选择的排序类型
				this.sortByType = type;
			}

			// 否则,就切换当前选择的排序类型的排序规则,
			else {
				// 如果当前是降序就改为升序
				if (this.sortByValue == "desc") this.sortByValue = "asc";
				// 如果当前是升序就改为降序
				else if (this.sortByValue == "asc") this.sortByValue = "desc";
			}

			// 提交
			this.handleSubmit();
		},

		/**
		 * !获取查询条件
		 */
		getParams() {
			return {
				sortByType: this.sortByType,
				sortByValue: this.sortByValue,
				priceStart: this.priceStart,
				priceEnd: this.priceEnd,
			};
		},

		/**
		 * !提交
		 */
		handleSubmit() {
			this.$emit("submit", this.getParams());
		},
	},
};
</script>

<style lang="scss" scoped>
.component-data-filter {
	padding: 12px 24px;
	background-color: #ffffff;
	display: flex;
	flex-wrap: wrap;

	.item {
		cursor: pointer;
		margin-right: 54px;
		min-width: 128px;
		display: flex;
		align-items: center;

		.label {
			color: #666666;
			margin-right: 15px;
		}

		.arrows {
			display: flex;
			flex-direction: column;

			.arrow {
				font-size: 12px;
				color: #666666;
			}

			.arrow-up {
				margin-bottom: -2px;
			}

			.arrow-down {
				margin-top: -2px;
			}
		}

		.input-box {
			width: 84px;
			height: 29px;
			position: relative;
		}

		.symbol {
			position: absolute;
			font-size: 12px;
			color: #666666;
			top: 50%;
			transform: translateY(-50%);
			line-height: 1;
			left: 8px;
			z-index: 8;
		}

		.gap {
			margin: 0 14px;
			color: #666666;
		}

		&.is-active {
			.label {
				font-weight: bold;
			}

			.arrow.is-active {
				color: var(--theme-color);
			}
		}
	}

	.button-wrapper {
		display: flex;
		align-items: center;
		margin-left: 8px;
	}

	::v-deep {
		.el-icon-d-caret {
			color: #999999;
		}

		.el-input {
			width: 100%;
			height: 100%;
			border-radius: 0;
		}

		.el-input__inner {
			width: 100%;
			height: 100%;
			border-radius: 0;
			padding: 0 20px;
		}
	}
}
</style>

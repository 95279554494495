<!-- 新闻详情 -->
<template>
	<div class="page-container page-news-center-details-container">
		<WebHeader></WebHeader>
		<div class="page-news-center-details-content layout-main">
			<!-- 面包屑 -->
			<Breadcrumbs :list="breadcrumbs"></Breadcrumbs>

			<!-- 内容 -->
			<div class="content-container">
				<div class="head">
					<h1 class="title">{{ details?.title }}</h1>
					<div class="infos">
						<div class="item">
							<label class="label">发布方：</label>
							<span class="value">{{ details?.publisher }}</span>
						</div>
						<div class="item">
							<label class="label">阅读量：</label>
							<span class="value">{{ details?.read_count }}</span>
						</div>
						<div class="item">
							<label class="label">发布时间：</label>
							<span class="value date">{{ dayjs.unix(details?.create_time).format("YYYY-MM-DD HH:mm:ss") }}</span>
						</div>
					</div>
				</div>
				<div class="wrapper">
					<TargetRichText :content="details?.content"></TargetRichText>
				</div>
			</div>
		</div>
		<WebFooter></WebFooter>
	</div>
</template>

<script>
import WebHeader from "@/components/WebHeader.vue"; // 页面Header
import WebFooter from "@/components/WebFooter.vue"; // 页面Footer
import Breadcrumbs from "@/components/Breadcrumbs.vue"; // 面包屑
import TargetRichText from "@/components/TargetRichText.vue"; // 富文本模块
import { breadcrumbsNewsCenterDetails } from "@/breadcrumbs/index.js"; // 面包屑数据
import { apiGetNewsDetails } from "@/apis/news.js";

export default {
	name: "PageNewsCenterDetails",
	components: {
		WebHeader,
		WebFooter,
		Breadcrumbs,
		TargetRichText,
	},
	data() {
		return {
			breadcrumbs: breadcrumbsNewsCenterDetails, // 面包屑数据
			no: "", // 新闻id
			details: {}, // 新闻详情数据
		};
	},
	mounted() {
		// 得到新闻id
		this.no = this.$route.query?.no || "";

		// 查询新闻详情
		this.queryDetails();
	},
	methods: {
		/**
		 * !查询新闻详情
		 */
		queryDetails() {
			apiGetNewsDetails({
				no: this.no,
			})
				.then((res) => {
					// 详情数据
					this.details = res?.finalData || {};
				})
				.catch((err) => console.error(err));
		},
	},
};
</script>

<style scoped lang="scss">
.page-news-center-details-content {
	.content-container {
		background-color: #ffffff;
		margin-bottom: 72px;
		padding: 0 50px 70px;

		.head {
			padding-top: 44px;
			position: relative;
			padding-bottom: 55px;

			.title {
				font-size: 32px;
				font-weight: normal;
				line-height: 40px;
				text-align: center;
				margin-bottom: 24px;
			}

			.infos {
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 0 120px;

				.item {
					padding: 0 25px;
					font-size: 16px;
					line-height: 24px;

					.label {
						display: inline-block;
						color: #999999;
					}

					.value {
						display: inline-block;
					}

					.date {
						font-family: "MarkPro";
					}
				}
			}

			.button-go {
				width: 112px;
				height: 44px;
				padding: 0;
				position: absolute;
				right: 0;
				bottom: 45px;
				border-radius: 0;
			}
		}
	}
}
</style>

<!-- 轮播 -->
<template>
	<div class="focus-swiper-container">
		<el-carousel class="swiper" :interval="5000" arrow="always" trigger="click">
			<el-carousel-item v-for="(item, index) in list" :key="index">
				<div class="item-content" :style="{ 'background-image': 'url(' + item.image + ')' }" @click="handleClickItem(item)"></div>
			</el-carousel-item>
		</el-carousel>
	</div>
</template>

<script>
	export default {
		name: "FocusSwiper",
		props: {
			list: Array,
		},
		methods: {
			/**
			 * !轮播图点击事件
			 * @param {Object} item 被点击的轮播图滑块数据
			 */
			handleClickItem(item) {
				// 跳转到指定新闻详情页
				if (item?.jump_type == 1) this.$router.push({ name: "NewsCenterDetails", query: { no: item?.jump_val } });
				// 跳转到指定公告详情页
				else if (item?.jump_type == 2) this.$router.push({ name: "NoticeCenterDetails", query: { no: item?.jump_val } });
				// 跳转到指定标的详情页
				else if (item?.jump_type == 3) this.$router.push({ name: "TargetDetails", query: { no: item?.jump_val } });
				// 跳转到指企业详情页
				else if (item?.jump_type == 4) this.$router.push({ name: "SettledEnterpriseDetails", query: { id: item?.jump_val } });
				// 跳转到指拍卖会详情页
				else if (item?.jump_type == 5) this.$router.push({ name: "AuctionDetails", query: { no: item?.jump_val } });
				// 跳转废铜废钢
				else if (item?.jump_type == 6) this.$router.push({ name: "ScrapStatisticsList", query: { type: 20 } });
				else if (item?.jump_type == 7) this.$router.push({ name: "ScrapStatisticsList", query: { type: 10 } });
				// 跳转标的列表
				else if (item?.jump_type == 13) this.$router.push({ name: "TargetList", query: { type: item?.jump_val } });
			},
		},
	};
</script>

<style scoped lang="scss">
	.focus-swiper-container {
		height: 100%;

		.swiper {
			height: 100%;
		}

		::v-deep {
			.el-carousel__container {
				height: 100%;
			}

			.el-carousel__arrow {
				width: 40px;
				height: 60px;
				border-radius: 0;
				// background-color: rgba($color: #000000, $alpha: 0.3);
				font-size: 26px;
			}

			.el-carousel__arrow--left {
				left: 0;
			}

			.el-carousel__arrow--right {
				right: 0;
			}

			.el-carousel__indicators--horizontal {
				left: 52px;
				bottom: 46px;
				transform: translateX(0);
			}

			.el-carousel__indicator--horizontal {
				padding: 0;
				width: 12px;
				height: 12px;
				margin: 0 17px;
			}

			.el-carousel__button {
				border-radius: 50%;
				width: 100%;
				height: 100%;
				background-color: transparent;
				border: 3px solid rgba($color: #ffffff, $alpha: 0.45);
			}

			.el-carousel__indicator.is-active button {
				background-color: #ffffff;
				border-color: #ffffff;
			}
		}

		.item-content {
			display: block;
			width: 100%;
			height: 100%;
			background-position: center center;
			background-repeat: no-repeat;
			background-size: cover;
			cursor: pointer;
		}
	}
</style>

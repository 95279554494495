<!-- 通知、资讯列表 -->
<template>
	<div class="notice-list-container">
		<div class="item" v-for="(item, index) in list" :key="index" @click="onClickItem(item)">
			<NoticeListItem :text="item[keyName]"></NoticeListItem>
		</div>
	</div>
</template>

<script>
	import NoticeListItem from "@/components/NoticeListItem.vue";
	export default {
		name: "NoticeList",
		components: {
			NoticeListItem,
		},
		props: {
			// 列表
			list: {
				type: Array,
				default: () => [],
			},

			// 标题key
			keyName: {
				type: String,
				default: "text",
			},

			// 列表类型. newsList: 新闻列表; noticeList: "公告列表"
			listType: {
				type: String,
				default: "",
			},
		},
		methods: {
			/**
			 * !item点击事件
			 * @param {Object} item item数据
			 */
			onClickItem(item) {
				// 跳转到新闻详情
				if (this.listType == "newsList") this.$router.push({ name: "NewsCenterDetails", query: { no: item?.no } });
				// 跳转到公告详情
				else if (this.listType == "noticeList") this.$router.push({ name: "NoticeCenterDetails", query: { no: item?.no } });
			},
		},
	};
</script>

<style lang="scss" scoped>
	.notice-list-container {
		display: flex;
		flex-wrap: wrap;

		.item {
			width: 50%;
			border-top: 1px solid #f3f3f3;
			border-right: 1px solid #f3f3f3;

			&:nth-child(1),
			&:nth-child(2) {
				border-top: none;
			}

			&:nth-child(odd) {
				padding-right: 38px;
			}

			&:nth-child(even) {
				padding-left: 28px;
				border-right: none;
			}
		}
	}
</style>

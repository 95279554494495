<!-- 入驻企业 - 列表 -->
<template>
	<div class="page-container page-settled-enterprise-list-container">
		<WebHeader></WebHeader>
		<div class="page-settled-enterprise-list-content">
			<!-- 面包屑 -->
			<Breadcrumbs :list="breadcrumbs"></Breadcrumbs>

			<!-- 主内容区域 -->
			<div class="layout-main">
				<!-- 搜索 -->
				<SearchFilter class="search-filter"></SearchFilter>

				<!-- 入驻企业 -->
				<div class="settled-enterprise module-wrapper">
					<h1 class="title">入驻企业</h1>
					<SettledEnterpriseBlockList class="list" :list="settledEnterprise.list" v-if="settledEnterprise.list.length"></SettledEnterpriseBlockList>
					<el-empty description="数据为空" v-else></el-empty>
					<div class="common-pagination">
						<el-pagination
							background
							:page-size="settledEnterprise.pageSize"
							:total="settledEnterprise.total"
							:pager-count="5"
							:current-page="settledEnterprise.pageIndex"
							hide-on-single-page
							@current-change="onChangeSettledEnterpriseListPageIndex"
						></el-pagination>
					</div>
				</div>

				<!-- 合作企业 -->
				<div class="cooperative-enterprise module-wrapper">
					<h1 class="title">合作企业</h1>
					<SettledEnterpriseList class="list" :list="cooperativeEnterpriseList"></SettledEnterpriseList>
				</div>
			</div>
		</div>
		<WebFooter></WebFooter>
	</div>
</template>

<script>
import WebHeader from "@/components/WebHeader.vue"; // 页面Header
import WebFooter from "@/components/WebFooter.vue"; // 页面Footer
import Breadcrumbs from "@/components/Breadcrumbs.vue"; // 面包屑
import SearchFilter from "@/components/SearchFilter.vue"; // 搜索过滤
import SettledEnterpriseBlockList from "@/components/SettledEnterpriseBlockList.vue"; // 入住企业列表
import SettledEnterpriseList from "@/components/SettledEnterpriseList.vue"; // 合作企业列表
import { breadcrumbsSettledEnterpriseList } from "@/breadcrumbs/index.js"; // 面包屑数据
import { apiGetSettledEnterprisePageData } from "@/apis/enterprise.js";

export default {
	name: "PageSettledEnterpriseList",
	components: {
		WebHeader,
		WebFooter,
		Breadcrumbs,
		SearchFilter,
		SettledEnterpriseBlockList,
		SettledEnterpriseList,
	},
	data() {
		return {
			// 面包屑数据
			breadcrumbs: breadcrumbsSettledEnterpriseList,
			// 入驻企业
			settledEnterprise: {
				list: [],
				pageIndex: 1, // 当前页码
				pageSize: 20, // 每页数据长度
				total: 0, // 总数据条数
			},
			cooperativeEnterpriseList: [], // 合作企业列表
		};
	},
	mounted() {
		// 查询页面数据
		this.queryData();
	},
	methods: {
		/**
		 * !查询页面数据
		 */
		queryData() {
			apiGetSettledEnterprisePageData({
				page: this.settledEnterprise.pageIndex,
				limit: this.settledEnterprise.pageSize,
				need_count: this.settledEnterprise.pageIndex == 1 ? 1 : 2,
				company_name: this.$route.query?.search_text || "",
			})
				.then((res) => {
					// 数据总条数
					if (res?.finalData?.count) this.settledEnterprise.total = res?.finalData?.count;

					// 入驻企业列表
					this.settledEnterprise.list = res?.finalData?.lists || [];

					// 合作企业列表
					if (res?.finalData?.cooperate?.length) this.cooperativeEnterpriseList = res?.finalData?.cooperate;
				})
				.catch((err) => console.error(err));
		},

		/**
		 * !入驻企业列表-页码切换事件
		 * @param {Number} current 当前页
		 */
		onChangeSettledEnterpriseListPageIndex(current) {
			// 清空现有列表
			this.settledEnterprise.list = [];

			// 更新页码
			this.settledEnterprise.pageIndex = current;

			// 查询新的列表数据
			this.queryData();
		},
	},
};
</script>

<style scoped lang="scss">
.page-settled-enterprise-list-content {
	.search-filter {
		margin-bottom: 20px;
	}

	.module-wrapper {
		margin-bottom: 72px;

		.title {
			font-size: 32px;
			line-height: 1;
			text-align: center;
			padding: 31px 0;
			font-weight: normal;
		}
	}
}
</style>

<!-- 入住企业列表项(块级列表) -->
<template>
	<div class="component-settled-enterprise-block-list-item" :class="{ effect: effect }">
		<div class="left">
			<div class="logo-box">
				<img class="logo" :src="item.logo" alt="" />
			</div>
			<span class="name">{{ item?.company_name }}</span>
			<span class="location">{{ item?.area }}</span>
		</div>
		<div class="right">
			<div class="desc-box ellipsis5">
				<p v-html="item?.introduce" class="desc"></p>
			</div>
			<div class="total-box">
				<div class="item">
					<span class="value">{{ item?.turnover_count }}</span>
					<label class="label">累计成交</label>
				</div>
				<div class="item">
					<span class="value">{{ item?.item_count }}</span>
					<label class="label">累计标的</label>
				</div>
				<div class="item">
					<span class="value">{{ item?.auction_count }}</span>
					<label class="label">累计拍卖会</label>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "SettledEnterpriseBlockListItem",
	props: {
		item: Object,
		// 是否有鼠标hover效果
		effect: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			countdownText: "", // 倒计时显示内容
		};
	},
	mounted() {},
	methods: {},
};
</script>

<style lang="scss" scoped>
.component-settled-enterprise-block-list-item {
	background-color: #ffffff;
	display: flex;
	padding: 34px 0 30px;
	align-items: stretch;
	cursor: pointer;
	transition: 0.5s;
	position: relative;
	overflow: hidden;

	&::after {
		content: "";
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		z-index: 8;
		border: 2px solid transparent;
		transition: 0.5s;
		position: absolute;
	}

	.left {
		flex-shrink: 0;
		width: 402px;
		display: flex;
		flex-direction: column;
		align-items: center;
		border-right: 2px solid #f0f0f0;

		.logo-box {
			width: 122px;
			height: 122px;
			border: 1px solid #f3f3f3;
			display: flex;
			align-items: center;
			justify-content: center;

			.logo {
				display: block;
				width: 80%;
				height: 80%;
				object-fit: contain;
			}
		}

		.name {
			font-size: 20px;
			line-height: 28px;
			margin-top: 24px;
			margin-bottom: 6px;
		}

		.location {
			color: #999999;
			line-height: 22px;
			margin-bottom: 21px;
		}
	}

	.right {
		flex: 1;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding: 0 90px 0 100px;
		transition: 0.5s;

		.desc-box {
			color: #666666;
			line-height: 24px;
			transition: 0.5s;
		}

		.total-box {
			display: flex;
			overflow: hidden;
			transition: 0.3s;

			.item {
				display: flex;
				flex-direction: column;
				align-items: center;
				text-align: center;
				padding: 0 29px;
				position: relative;

				.value {
					font-size: 28px;
					line-height: 1;
					color: var(--theme-color);
					font-family: "MarkPro";
				}

				.label {
					color: #666666;
					line-height: 1;
					margin-top: 12px;
				}

				&:first-child {
					padding-left: 0;
				}

				&:last-child {
					padding-right: 0;
				}

				&:not(:first-child)::before {
					content: "";
					width: 2px;
					height: 42px;
					background-color: #eeeeee;
					position: absolute;
					left: -1px;
					top: 50%;
					transform: translateY(-50%);
					z-index: 8;
				}
			}
		}
	}

	&.effect:hover {
		box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.5);

		&::after {
			border-color: var(--theme-color);
		}

		.right {
			justify-content: center;
		}

		.total-box {
			transform: translateY(200px);
			opacity: 0;
			height: 0;
		}
	}
}
</style>

<!-- 面包屑 -->
<template>
	<div class="breadcrumbs-container" :class="theme">
		<div class="wrapper layout-main">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item class="item" :to="item.to" v-for="(item, index) in list" :key="index" :replace="!!item?.replace">{{ item.name }}</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
	</div>
</template>

<script>
export default {
	name: "Breadcrumbs",
	props: {
		list: Array,
		theme: String,
	},
	data() {
		return {};
	},
};
</script>

<style lang="scss" scoped>
.breadcrumbs-container {
	.wrapper {
		padding: 30px 0;
	}

	.item:last-child {
		pointer-events: none;
	}

	&.white {
		::v-deep {
			.el-breadcrumb__inner.is-link {
				color: #ffffff;

				&:hover {
					color: var(--theme-color);
				}
			}

			.el-breadcrumb__separator {
				color: #ffffff;
			}
		}
	}
}
</style>

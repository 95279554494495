<!-- 求购/求组列表item -->
<template>
	<div class="component-buy-item" @click="onClickItem">
		<div class="cover-box">
			<img class="cover-image" :src="item.image" alt="" />
		</div>
		<div class="content">
			<div class="top">
				<span class="tag">求购</span>
				<h3 class="title">{{ item.name }}</h3>
			</div>
			<div class="bot">
				<span class="price" v-if="item.price_type == 1">面议</span>
				<div class="price" v-else-if="item.price_type == 2">
					<span class="num">{{ formatPrice(item.price) }}</span>
					<span class="unit">&nbsp;/{{ item.price_unit }}</span>
				</div>
			</div>
		</div>
		<div class="info">
			<div class="col">
				<label class="label">规格：</label>
				<span class="value">{{ item.specs || "-" }}</span>
			</div>
			<div class="col">
				<label class="label">地区：</label>
				<span class="value">{{ item.region || "-" }}</span>
			</div>
			<div class="col">
				<label class="label">浏览量：</label>
				<span class="value">{{ item.read_count || 0 }}</span>
			</div>
			<div class="col">
				<label class="label">时间：</label>
				<span class="value">{{ dayjs.unix(item.create_time).format("YYYY-MM-DD") }}</span>
			</div>
			<div class="col">
				<label class="label">使用周期：</label>
				<span class="value">{{ item.used_life_cycle || "-" }}</span>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "",
		props: {
			item: {
				type: Object,
				default: () => ({}),
			},
		},
		data() {
			return {};
		},
		mounted() {},
		methods: {
			/**
			 * !item点击事件
			 */
			onClickItem() {
				// 跳转到求购租赁详情页
				this.$router.push({ name: "BuyLeaseDetails", query: { id: this.item.id } });
			},
		},
	};
</script>

<style lang="scss" scoped>
	.component-buy-item {
		background: #ffffff;
		padding: 15px;
		display: flex;
		border: 2px solid #ffffff;
		cursor: pointer;
		transition: 0.3s;

		.cover-box {
			width: 154px;
			height: 154px;
			overflow: hidden;
			flex-shrink: 0;
			margin-right: 20px;
		}

		.cover-image {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		.content {
			width: 30%;
			flex-shrink: 0;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			padding: 9px 10px 4px 0;
		}

		.tag {
			width: 80px;
			text-align: center;
			line-height: 32px;
			background: var(--buy-lease-type-bg-1);
			color: var(--buy-lease-type-text-color-1);
			font-size: 14px;
			display: block;
			margin-bottom: 12px;
		}

		.title {
			font-size: 20px;
			font-weight: normal;
		}

		.bot {
			.price {
				font-size: 20px;
				color: var(--theme-color);
			}

			.num {
				font-size: 20px;
				font-family: "MarkPro";
			}

			.unit {
				font-size: 14px;
			}
		}

		.info {
			flex: 1;
			display: flex;
			flex-wrap: wrap;
			align-content: space-between;

			.col {
				width: 33.33333333%;
				flex-shrink: 0;
				font-size: 14px;
				color: #999999;
			}

			.label {
				display: block;
				margin-bottom: 8px;
			}

			.value {
				display: block;
			}
		}

		&:hover {
			border-color: var(--theme-color);
			transform: scale(0.98);
		}
	}
</style>

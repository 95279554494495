<!-- 新闻资讯列表 -->
<template>
	<div class="component-news-info-list">
		<div class="item" v-for="(item, index) in list" :key="index" @click="onClickItem(item)">
			<NewsInfoListItem :item="item" :listType="listType"></NewsInfoListItem>
		</div>
	</div>
</template>

<script>
import NewsInfoListItem from "@/components/NewsInfoListItem.vue";
export default {
	name: "NewsInfoList",
	components: {
		NewsInfoListItem,
	},
	props: {
		// 数据列表
		list: {
			type: Array,
			default: () => [],
		},

		// 列表类型. newsList: 新闻列表; noticeList: "公告列表"
		listType: {
			type: String,
			default: "",
		},
	},
	methods: {
		/**
		 * !item点击事件
		 * @param {Object} item item数据
		 */
		onClickItem(item) {
			// 跳转到新闻详情
			if (this.listType == "newsList") this.$router.push({ name: "NewsCenterDetails", query: { no: item?.no } });
			// 跳转到公告详情
			else if (this.listType == "noticeList") this.$router.push({ name: "NoticeCenterDetails", query: { no: item?.no } });
		},
	},
};
</script>

<style lang="scss" scoped>
.component-news-info-list {
	padding: 0 32px;
	background-color: #ffffff;

	.item:not(:last-child) {
		border-bottom: 1px solid #f6f6f6;
	}
}
</style>

/**
 * ****************************************************************************************************
 * 新闻模块接口
 * ****************************************************************************************************
 */
import HiRequest from "@/request/index.js";
export const hr = new HiRequest();

// 获取新闻列表数据
export const apiGetNewsList = (data = {}, options = {}) => hr.get("api/v1/news/lists", data, options);

// 获取新闻详情数据
export const apiGetNewsDetails = (data = {}, options = {}) => hr.get("api/v1/news/detail", data, options);

<!-- 个人中心 - 首页 - 用户基本信息 -->
<template>
	<div class="component-mine-index-user-basic-info">
		<div class="left">
			<el-avatar :size="91" :src="userinfo?.head_img || require('@/assets/images/avatar-default.png')"></el-avatar>
			<div class="left_content">
				<span class="left_content_nickname">{{ userinfo?.nickname || "" }}</span>
				<div class="left_content_cert-status grey" v-if="status == 10">
					<i class="left_content_cert-status_icon zn-iconfont zn-iconfont-renzheng"></i>
					<span>未认证</span>
				</div>
				<div class="left_content_cert-status grey" v-else-if="status == 20">
					<i class="left_content_cert-status_icon zn-iconfont zn-iconfont-renzheng"></i>
					<span>认证审核中</span>
				</div>
				<div class="left_content_cert-status grey" v-else-if="status == 30">
					<i class="left_content_cert-status_icon zn-iconfont zn-iconfont-renzheng"></i>
					<span>认证审核失败</span>
				</div>
				<div class="left_content_cert-status" v-else-if="status == 40">
					<i class="left_content_cert-status_icon zn-iconfont zn-iconfont-renzheng"></i>
					<span>已认证</span>
				</div>
			</div>
		</div>
		<div class="numbers">
			<div class="numbers_item">
				<span class="numbers_item_value">{{ numInHand }}</span>
				<span class="numbers_item_label">参拍中</span>
			</div>
			<div class="numbers_item">
				<span class="numbers_item_value">{{ numDone }}</span>
				<span class="numbers_item_label">已拍下</span>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
	name: "MineIndexUserBasicInfo",
	computed: {
		...mapGetters(["userinfo"]),
	},
	props: {
		// 用户认证状态
		status: {
			type: [String, Number],
			default: "",
		},

		// 参拍中数量
		numInHand: {
			type: [Number, String],
			default: 0,
		},

		// 已拍下数量
		numDone: {
			type: [Number, String],
			default: 0,
		},
	},
	data() {
		return {};
	},
	mounted() {},
	methods: {},
};
</script>

<style lang="scss" scoped>
.component-mine-index-user-basic-info {
	background-color: #ffffff;
	display: flex;
	padding: 26px 76px 29px 41px;
	align-items: center;
	line-height: 1;

	.left {
		display: flex;
		align-items: center;
		flex: 1;

		&_content {
			display: flex;
			flex-direction: column;
			margin-left: 18px;

			&_nickname {
				font-size: 26px;
				font-weight: bold;
				margin-bottom: 21px;
			}

			&_cert-status {
				display: flex;
				align-items: center;
				font-size: 18px;

				&_icon {
					margin-right: 7px;
					color: var(--theme-color);
					font-size: 18px;
				}
			}

			&_cert-status.grey {
				color: #999999;

				.left_content_cert-status_icon {
					color: #999999;
				}
			}
		}
	}

	.numbers {
		flex-shrink: 0;
		display: flex;

		&_item {
			display: flex;
			flex-direction: column;
			align-items: center;
			margin-left: 80px;

			&_value {
				font-family: "MarkPro";
				font-size: 36px;
				color: var(--theme-color);
			}

			&_label {
				font-size: 18px;
				margin-top: 18px;
			}
		}
	}

	/deep/ .el-avatar > img {
		width: 100%;
	}
}
</style>

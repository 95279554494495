<!-- 拍卖会 - 列表 -->
<template>
	<div class="page-container page-auction-list-container">
		<WebHeader></WebHeader>
		<div class="page-auction-list-content">
			<!-- 面包屑 -->
			<Breadcrumbs :list="breadcrumbs"></Breadcrumbs>

			<!-- 主内容区域 -->
			<div class="layout-main">
				<!-- 搜索 -->
				<SearchFilter
					class="search-filter"
					:region="region"
					:auctionStatus="auctionStatus"
					showDateItems
					@change="handleSearch"
					@submit="handleSearch"
				></SearchFilter>

				<!-- 列表 -->
				<AuctionBlockList class="auction-list" :list="list" v-if="list && list.length"></AuctionBlockList>

				<!-- 数据为空 -->
				<el-empty description="数据为空" v-else></el-empty>

				<!-- 分页器 -->
				<div class="common-pagination">
					<el-pagination
						background
						:page-size="pageSize"
						:total="total"
						:pager-count="5"
						:current-page="pageIndex"
						hide-on-single-page
						@current-change="onChangePageIndex"
					></el-pagination>
				</div>
			</div>
		</div>
		<WebFooter></WebFooter>
	</div>
</template>

<script>
	import WebHeader from "@/components/WebHeader.vue"; // 页面Header
	import WebFooter from "@/components/WebFooter.vue"; // 页面Footer
	import Breadcrumbs from "@/components/Breadcrumbs.vue"; // 面包屑
	import SearchFilter from "@/components/SearchFilter.vue"; // 搜索过滤
	import AuctionBlockList from "@/components/AuctionBlockList.vue"; // 拍卖会列表
	import { breadcrumbsAuctionList } from "@/breadcrumbs/index.js"; // 面包屑数据
	import { apiGetSearchItems, apiGetAuctionList } from "@/apis/auction.js";

	export default {
		name: "PageAuctionList",
		components: {
			WebHeader,
			WebFooter,
			Breadcrumbs,
			SearchFilter,
			AuctionBlockList,
		},
		data() {
			return {
				// 面包屑数据
				breadcrumbs: breadcrumbsAuctionList,
				region: [], // 地区数据
				auctionStatus: [], // 拍卖状态数据
				list: [], // 列表
				pageIndex: 1, // 当前页码
				pageSize: 10, // 每页数据长度
				total: 0, // 总数据条数
				// 搜索条件数据
				searchParams: {
					provinceId: "", // 省份id
					cityId: "", // 城市id
					auctionStatusId: "", // 拍卖会状态id
					dateStart: "", // 开始日期
					dateEnd: "", // 结束日期
				},
			};
		},
		async mounted() {
			// 查询搜索数据
			await this.querySearchItems().then().catch();

			this.init();
		},
		methods: {
			async init() {
				// 查询列表数据
				this.queryList();
			},
			/**
			 * !查询搜索数据
			 */
			querySearchItems() {
				return new Promise((resolve, reject) => {
					apiGetSearchItems()
						.then((res) => {
							this.region = res?.finalData?.region_data || [];
							this.auctionStatus = res?.finalData?.status_data || [];
							resolve();
						})
						.catch((err) => {
							console.error(err);
							reject();
						});
				});
			},

			/**
			 * !查询列表数据
			 */
			queryList() {
				apiGetAuctionList({
					page: this.pageIndex,
					limit: this.pageSize,
					need_count: this.pageIndex == 1 ? 1 : 2,
					province_id: this.searchParams?.provinceId,
					city_id: this.searchParams?.cityId,
					status_id: this.searchParams?.auctionStatusId,
					start_date: this.searchParams?.dateStart ? this.dayjs(this.searchParams.dateStart).format("YYYY-MM-DD") : "",
					end_date: this.searchParams.dateEnd ? this.dayjs(this.searchParams.dateEnd).format("YYYY-MM-DD") : "",
					title: this.$route.query?.search_text || "",
				})
					.then((res) => {
						console.log("拍卖会列表->", res);
						// 数据总条数
						if (res?.finalData?.count) this.total = res?.finalData?.count;
						// 列表
						res?.finalData?.lists?.forEach((item) => (item.now_time = res?.finalData?.now_time));
						this.list = res?.finalData?.lists || [];
					})
					.catch((err) => console.error(err));
			},

			/**
			 * !搜索事件
			 * @param {Object} params 搜索条件数据
			 */
			handleSearch(params) {
				// 更新搜索条件
				this.searchParams = params;

				// 清空数据列表
				this.list = [];

				// 重置页码
				this.pageIndex = 1;

				// 重置总数据条数
				this.total = 0;

				// 查询列表数据
				this.queryList();
			},

			/**
			 * !页码切换事件
			 * @param {Number} current 当前页
			 */
			onChangePageIndex(current) {
				// 清空数据列表
				this.list = [];

				// 更新页码
				this.pageIndex = current;

				// 查询列表数据
				this.queryList();
			},
		},
	};
</script>

<style scoped lang="scss">
	.page-auction-list-container {
		.search-filter {
			margin-bottom: 20px;
		}
	}
</style>

<!-- 荣誉资质轮播 -->
<template>
	<div class="component-honors-qualifications">
		<div class="swiper-container">
			<div class="swiper-wrapper">
				<div class="swiper-slide" v-for="(item, index) in list" :key="index">
					<div class="image-box">
						<img class="image" :src="item.image" alt="" />
					</div>
					<p class="name">{{ item.title || "" }}</p>
				</div>
			</div>
			<i class="swiper-arrow swiper-arrow-prev zn-iconfont zn-iconfont-zuo"></i>
			<i class="swiper-arrow swiper-arrow-next zn-iconfont zn-iconfont-you"></i>
			<div class="swiper-progressbar-container">
				<div class="swiper-pagination"></div>
				<div class="numbers">
					<span class="number">{{ current < 10 ? "0" + current : current }}</span>
					<span>/</span>
					<span class="number">{{ list.length < 10 ? "0" + list.length : list.length }}</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "HonorsQualifications",
	props: {
		list: Array, // 列表数据
	},
	data() {
		return {
			swiperInstance: null,
			current: 1,
		};
	},
	watch: {
		list: {
			handler() {
				this.$nextTick(() => {
					let timer = setTimeout(() => {
						clearTimeout(timer);
						if (this.swiperInstance) {
							// 更新Swiper
							this.swiperInstance.update();
						} else {
							// 实例化swiper
							this.initSwiper();
						}
					}, 1000);
				});
			},
			deep: true,
			immediate: true,
		},
	},
	mounted() {},
	methods: {
		/**
		 * !实例化swiper
		 */
		initSwiper() {
			this.swiperInstance = new this.Swiper(".component-honors-qualifications .swiper-container", {
				slidesPerView: 3,
				spaceBetween: 0,
				loop: true,
				loopPreventsSlide: true,
				slideToClickedSlide: true,
				centeredSlides: true,
				speed: 500,
				navigation: {
					nextEl: ".component-honors-qualifications .swiper-arrow-next",
					prevEl: ".component-honors-qualifications .swiper-arrow-prev",
				},
				pagination: {
					el: ".component-honors-qualifications .swiper-pagination",
					type: "progressbar",
				},
				on: {
					init: (swiper) => {
						this.current = (this?.swiperInstance?.realIndex || 0) + 1;
					},
					transitionEnd: (swiper) => {
						this.current = (this?.swiperInstance?.realIndex || 0) + 1;
					},
				},
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.component-honors-qualifications {
	.image-box {
		width: 100%;
		padding-bottom: 55.84%;
		position: relative;
		background-color: #ffffff;
		overflow: hidden;
		margin-top: calc(55.84% * 0.125);
		transition: 0.5s;

		.image {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: contain;
			position: absolute;
			left: 0;
			top: 0;
			z-index: 0;
		}
	}

	.name {
		font-size: 18px;
		line-height: 26px;
		margin-top: 17px;
		text-align: center;
		opacity: 0;
		transform: scale(0);
		transition: 0.5s;
		display: block;
		margin-top: calc(17px + (55.84% * 0.125));
	}

	.swiper-slide-prev {
		.image-box {
			transform: scale(0.74);
			transform-origin: left center;
		}
	}

	.swiper-slide-next {
		.image-box {
			transform: scale(0.74);
			transform-origin: right center;
		}
	}

	.swiper-slide-active {
		.image-box {
			transform: scale(1.25);
		}

		.name {
			opacity: 1;
			transform: scale(1);
		}
	}

	.swiper-arrow {
		position: absolute;
		z-index: 8;
		width: 30px;
		height: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: rgba($color: #000000, $alpha: 0.6);
		color: #ffffff;
		top: 35%;
		cursor: pointer;
		user-select: none;
		transition: 0.4s;

		&:hover {
			background-color: var(--theme-color);
		}
	}

	.swiper-arrow-prev {
		left: 0;
	}

	.swiper-arrow-next {
		right: 0;
	}

	.swiper-progressbar-container {
		display: flex;
		align-items: center;
		margin-top: 30px;

		.swiper-pagination {
			flex: 1;
			position: static;
			height: 2px;
			border-radius: 2px;
			overflow: hidden;
			background-color: #cecece;
		}

		.numbers {
			flex-shrink: 0;
			font-size: 20px;
			line-height: 1;
			font-family: "MarkPro-Book";
			margin-left: 24px;
		}
	}

	::v-deep {
		.swiper-pagination-progressbar-fill {
			background: var(--theme-color);
		}
	}
}
</style>

<style></style>

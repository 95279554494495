/**
 * ****************************************************************************************************
 * 复用函数
 * ****************************************************************************************************
 */
import { isFunction } from "./utils";
import { Loading } from "element-ui";
import { Message } from "element-ui";

/**
 * !显示loading
 * @param {Object} config 合并后的请求配置项
 */
export const showLoading = (config) => {
	config.loadingTimer = setTimeout(() => {
		config.loadingInstance = Loading.service({
			text: config.loadingMsg,
			fullscreen: config.loadingMask,
		});
	}, config.loadingTime);
};

/**
 * !关闭loading
 * @param {Object} config 合并后的请求配置项
 */
export const closeLoading = (config) => {
	clearTimeout(config.loadingTimer);
	config.loadingTimer = null;
	if (config.loadingInstance) config.loadingInstance.close();
};

/**
 * !显示success
 * @param {Object} config 合并后的请求配置项
 * @param {Response} response 请求接口返回的 Response 对象
 */
export const showSuccess = (config, response) => {
	Message({
		message: config.successMsg || response.data[config.messageKey] || response.errMsg || "",
		type: "success",
		duration: config.successDuration,
	});
	if (isFunction(config.successCallback)) {
		let timer = setTimeout(() => {
			config.successCallback();
			clearTimeout(timer);
		}, config.successDuration);
	}
};

/**
 * !显示error
 * @param {Object} config 合并后的请求配置项
 * @param {Response} response 请求接口返回的 Response 对象
 */
export const showError = (config, response) => {
	Message({
		message: config.errorMsg || response.data[config.messageKey] || response.errMsg || "",
		type: "error",
		duration: config.errorDuration,
	});
	if (isFunction(config.errorCallback)) {
		let timer = setTimeout(() => {
			config.errorCallback();
			clearTimeout(timer);
		}, config.errorDuration);
	}
};

/**
 * !显示fail
 * @param {Object} config 合并后的请求配置项
 */
export const showFail = (config, error) => {
	Message({
		message: config.failMsg || error?.response?.data?.errMsg || error?.response?.statusText || "服务器错误，请稍后再试",
		type: "info",
		duration: config.failDuration,
	});
	if (isFunction(config.failCallback)) {
		let timer = setTimeout(() => {
			config.failCallback();
			clearTimeout(timer);
		}, config.failDuration);
	}
};

/**
 * !显示空文件提示
 * @param {Object} config 合并后的请求配置项
 */
export const showFileEmptyTips = (config) => {
	Message({
		message: config.fileEmptyMsg || "",
		type: "warning",
		duration: config.fileEmptyDuration,
	});
	if (isFunction(config.fileEmptyCallback)) {
		let timer = setTimeout(() => {
			config.fileEmptyCallback();
			clearTimeout(timer);
		}, config.fileEmptyDuration);
	}
};

/**
 * !显示文件超出大小的提示
 * @param {Object} config 合并后的请求配置项
 */
export const showMaxSizeOverflowTips = (config) => {
	Message({
		message: config.maxSizeOverflowMsg || "",
		type: "warning",
		duration: config.maxSizeOverflowDuration,
	});
	if (isFunction(config.maxSizeOverflowCallback)) {
		let timer = setTimeout(() => {
			config.maxSizeOverflowCallback();
			clearTimeout(timer);
		}, config.maxSizeOverflowDuration);
	}
};

/**
 * !显示文件格式不正确的提示
 * @param {Object} config 合并后的请求配置项
 */
export const showSuffixsWrongTips = (config) => {
	Message({
		message: config.suffixsWrongMsg || "",
		type: "warning",
		duration: config.suffixsWrongDuration,
	});
	if (isFunction(config.suffixsWrongCallback)) {
		let timer = setTimeout(() => {
			config.suffixsWrongCallback();
			clearTimeout(timer);
		}, config.suffixsWrongDuration);
	}
};

/**
 * !验证文件是否有效,不为空
 * @param {Object} config 合并后的请求配置项
 * @return {Boolean} 不为空返回 true，否则返回 false
 */
export const isHasFile = (config) => {
	if (!config.filePath && !config.file) {
		if (config.fileEmptyShow) showFileEmptyTips(config);
		return false;
	}
	return true;
};

/**
 * @description 验证文件大小是否超出了设置的最大值
 * @param {Object} config 合并后的请求配置项
 * @return {Boolean} 未超出返回 true，否则返回 false
 */
export const isFileSizeOverflow = (config = {}) => {
	if (config.maxSize != 0) {
		if (config.file.size > config.maxSize * 1024) {
			Message.warning(config.maxSizeMsg);
			if (config.maxSizeOverflowShow) config.showMaxSizeOverflowTips();
			return false;
		}
	}
	return true;
};

/**
 * @description 验证文件后缀是否不再配置的后缀列表范围内
 * @param {Object} config 合并后的请求配置项
 * @return {Boolean} 符合范围返回 true，否则返回 false
 */
const isFileSuffixInRange = (config = {}) => {
	let findSuffixsAll = config.suffixs.findIndex((el) => {
		return el == "all";
	});
	if (findSuffixsAll == -1) {
		let name = config.fileName || config.filePath || "";
		let lastIndex = name.lastIndexOf(".");
		if (lastIndex == -1) {
			if (config.suffixsWrongShow) showSuffixsWrongTips(config);
			return false;
		}
		let suffix = name.substring(lastIndex);
		let findSuffix = config.suffixs.findIndex((el) => {
			return el == suffix;
		});
		if (findSuffix == -1) {
			Message.warning(config.suffixsMsg);
			return false;
		}
	}
	return true;
};

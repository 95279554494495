import CryptoJS from "crypto-js";

/**
 * !加密
 * @param {String} word 加密文本
 * @param {String} keyStr 加密key
 * @param {String} ivStr 加密iv
 */
export const encrypt = (word, keyStr, ivStr) => {
	const key = CryptoJS.enc.Latin1.parse(keyStr);
	const iv = CryptoJS.enc.Latin1.parse(ivStr);
	const encoded = CryptoJS.AES.encrypt(word, key, {
		iv: iv,
		mode: CryptoJS.mode.CBC,
		adding: CryptoJS.pad.ZeroPadding,
	}).toString();
	return encoded;
};

/**
 * !解密
 * @param {String} word 文本
 * @param {String} key key
 * @param {String} ivS iv
 */
export const decrypt = (word, key, iv) => {
	// 解密word
	let baseResult = CryptoJS.enc.Base64.parse(word); // Base64解密
	let ciphertext = CryptoJS.enc.Base64.stringify(baseResult); // Base64解密

	// 默认key
	if (key === null || key === undefined) key = "Dk4OsYE4JRaAcaIyEhkqRLNn1euIXErw";

	// 解iv
	let words = CryptoJS.enc.Base64.parse(iv); // Base64解密
	let wordsText = CryptoJS.enc.Utf8.stringify(words); // Base64解密

	// 解数据
	let decryptResult = CryptoJS.AES.decrypt(ciphertext, CryptoJS.enc.Utf8.parse(key), {
		//  AES解密
		iv: CryptoJS.enc.Utf8.parse(wordsText),
		mode: CryptoJS.mode.CBC,
		padding: CryptoJS.pad.Pkcs7,
	});

	// 解密后的字符串
	let resData = decryptResult.toString(CryptoJS.enc.Utf8).toString();

	// 尝试转化成json数据
	try {
		resData = JSON.parse(resData);
	} catch (error) {}

	// 返回解密后的数据
	return resData;
};

<!-- 求购租赁列表项 -->
<template>
	<div class="buy-lease-list-item-container">
		<!-- Header -->
		<div class="head">
			<BuyLeaseTypeTag :type="item?.mode_id" class="tag"></BuyLeaseTypeTag>
		</div>

		<!-- 封面 -->
		<div class="cover-box">
			<img class="cover" :src="item.image" alt="" />
		</div>

		<!-- 内容 -->
		<div class="texts">
			<!-- 标题、名称 -->
			<h1 class="name ellipsis1" :title="item.name">{{ item.name }}</h1>

			<!-- 成交价格 -->
			<div class="text-row price">
				<label class="label">价格：</label>
				<span class="value theme-color" v-if="item.price_type == 1">面议</span>
				<div class="value theme-color" v-else-if="item.price_type == 2">
					<span class="number markpro">{{ formatPrice(item.price) }}</span>
					<span class="unit">&nbsp;/{{ item.price_unit }}</span>
				</div>
			</div>

			<!-- 发布时间 -->
			<div class="text-row date">
				<label class="label">发布时间：</label>
				<span class="value markpro">{{ dayjs.unix(item.create_time).format("YYYY.MM.DD HH:mm:ss") }}</span>
			</div>
		</div>
	</div>
</template>

<script>
	import { EnmuBuyLeaseTypes } from "@/const.js";
	import BuyLeaseTypeTag from "@/components/BuyLeaseTypeTag.vue";
	export default {
		name: "BuyLeaseListItem",
		components: {
			BuyLeaseTypeTag,
		},
		props: {
			item: {
				type: Object,
				default: () => {},
			},
		},
		data() {
			return {
				buyLeaseTypes: EnmuBuyLeaseTypes,
				countdownText: "", // 倒计时显示内容
			};
		},
		mounted() {},
		methods: {},
	};
</script>

<style lang="scss" scoped>
	.buy-lease-list-item-container {
		position: relative;
		overflow: hidden;
		background-color: #ffffff;
		cursor: pointer;
		transition: 0.2s;

		&::after {
			content: "";
			width: 100%;
			height: 100%;
			position: absolute;
			left: 0;
			top: 0;
			border: 2px solid transparent;
			z-index: 10;
			transition: 0.2s;
		}

		.head {
			height: 33px;
			display: flex;
			position: absolute;
			width: 100%;
			left: 0;
			top: 0;
			z-index: 3;

			.tag {
				width: 80px;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				color: #ffffff;
				flex-shrink: 0;
			}
		}

		.cover-box {
			width: 100%;
			padding-bottom: 56.25%;
			position: relative;
			overflow: hidden;

			.cover {
				position: absolute;
				width: 100%;
				height: 100%;
				left: 0;
				top: 0;
				object-fit: cover;
				z-index: 1;
			}
		}

		.texts {
			padding: 13px 30px 20px 20px;

			.name {
				font-size: 18px;
				font-weight: normal;
				line-height: 24px;
				margin-bottom: 22px;
				height: 24px;
				display: block;
			}

			.text-row {
				color: #999999;
				line-height: 22px;
			}

			.theme-color {
				color: var(--theme-color);
			}

			.markpro {
				font-family: "MarkPro";
			}

			.price {
				display: flex;
				margin-bottom: 6px;
				align-items: flex-end;

				.value {
					font-size: 20px;
				}

				.number {
					font-size: 22px;
				}

				.unit {
					font-size: 14px;
				}
			}
		}

		&:hover {
			transform: translateY(-5px);

			&::after {
				border-color: var(--theme-color);
			}

			.foot {
				background-color: #f7f7f7;

				&::before {
					transform: scale(0);
					opacity: 0;
				}
			}
		}
	}
</style>

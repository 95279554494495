import { render, staticRenderFns } from "./Attestation.vue?vue&type=template&id=55826f81&scoped=true"
import script from "./Attestation.vue?vue&type=script&lang=js"
export * from "./Attestation.vue?vue&type=script&lang=js"
import style0 from "./Attestation.vue?vue&type=style&index=0&id=55826f81&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55826f81",
  null
  
)

export default component.exports
<!-- 公司介绍 -->
<template>
	<div class="component-company-intro">
		<div class="left">
			<h1 class="title">{{ intro.title || "" }}</h1>
			<div class="content">
				<div v-html="intro.content || ''"></div>
			</div>
		</div>
		<div class="right">
			<!-- <img class="image" :src="intro.image" alt="" /> -->
			<div class="video">
				<!-- <div id="video"></div> -->
				<el-carousel indicator-position="outside" :interval="5000" arrow="always" trigger="click">
					<el-carousel-item v-for="item in intro.video" :key="item">
					<img :src="item" alt="">
					</el-carousel-item>
				</el-carousel>
			</div>
		</div>
	</div>
</template>

<script>
// import Player from "xgplayer";
// import "./.xgplayer/skin/index.js";

export default {
	name: "CompanyIntro",
	props: {
		intro: Object, // 模块内容
	},
	data() {
		return {
			player: null, // 视频播放器对象
		};
	},
	watch: {
		// 监听简介数据
		// 当视频地址变动后初始化或重新初始化视频
		// "intro.video": {
		// 	handler(newVal) {
		// 		// 已经初始化了视频播放器
		// 		// 直接更新视频地址
		// 		if (this.player) {
		// 			this.player.src = newVal;
		// 			this.player.poster = newVal + "?vframe/jpg/offset/1";
		// 		}
		// 		// 否则,需要初始化视频播放器
		// 		else {
		// 			this.initVideoPlayer();
		// 		}
		// 	},
		// },
	},
	mounted() {},
	beforeDestroy() {
		// 销毁播放器实例
		// this.player?.destroy();
		// this.player = null;
	},
	methods: {
		// 初始化视频
		// initVideoPlayer() {
		// 	this.$nextTick(() => {
		// 		let timer = setTimeout(() => {
		// 			this.player = new Player({
		// 				id: "video",
		// 				url: this.intro?.video,
		// 				poster: this.intro?.video + "?vframe/jpg/offset/1",
		// 				width: "100%",
		// 				height: "100%",
		// 			});
		// 			clearTimeout(timer);
		// 		}, 300);
		// 	});
		// },
	},
};
</script>

<style lang="scss" scoped>
@import "@/xgplayer/index.css";

.component-company-intro {
	display: flex;

	.left {
		flex: 1;
		padding-right: 56px;
		padding-top: 32px;

		.title {
			font-size: 32px;
			font-weight: normal;
			line-height: 1;
			margin-bottom: 24px;
		}

		.content {
			color: #666666;
			line-height: 26px;
			font-size: 14px;
			text-align: justify;
		}
	}

	.right {
		flex-shrink: 0;

		.image {
			display: block;
			width: 595px;
			height: auto;
		}

		.video {
			display: block;
			width: 595px;
			height: 261px;
			/* background-color: #000000; */
		}
	}
}

::v-deep {

			.el-carousel__arrow {
				width: 40px;
				height: 60px;
				border-radius: 0;
				// background-color: rgba($color: #000000, $alpha: 0.3);
				font-size: 26px;
			}

			.el-carousel__arrow--left {
				left: 0;
			}

			.el-carousel__arrow--right {
				right: 0;
			}
		}
</style>

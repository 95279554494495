<!-- 平均价格列表 -->
<template>
	<div class="component-average-price-list">
		<div class="wrapper">
			<div class="item" v-for="(item, index) in list" :key="index">
				<AveragePriceListItem :item="item"></AveragePriceListItem>
			</div>
		</div>
	</div>
</template>

<script>
import AveragePriceListItem from "@/components/AveragePriceListItem.vue";
export default {
	name: "AveragePriceList",
	components: {
		AveragePriceListItem,
	},
	props: {
		list: Array, // 列表数据
	},
	methods: {},
};
</script>

<style lang="scss" scoped>
.component-average-price-list {
	.wrapper {
		margin: 0 -10px;
		display: flex;
		flex-wrap: wrap;
	}

	.item {
		width: 16.66666666%;
		padding: 0 10px;
		margin-bottom: 15px;
	}
}
</style>

/**
 * ****************************************************************************************************
 * 企业相关接口
 * ****************************************************************************************************
 */
import HiRequest from "@/request/index.js";
export const hr = new HiRequest();

// 获取入住企业页面数据
export const apiGetSettledEnterprisePageData = (data = {}, options = {}) => hr.get("api/v1/settled_enterprise", data, options);

// 获取入住企业详情
export const apiGetSettledEnterpriseDetails = (data = {}, options = {}) => hr.get("api/v1/settled_enterprise/detail", data, options);

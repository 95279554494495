<!-- 废钢统计 - 列表 -->
<template>
	<div class="page-container page-scrap-statistics-list-container">
		<WebHeader></WebHeader>
		<div class="page-scrap-statistics-list-content">
			<!-- 面包屑 -->
			<Breadcrumbs :list="breadcrumbs"></Breadcrumbs>

			<!-- 主内容区域 -->
			<div class="layout-main">
				<!-- 全国平均价格 + 区域平均价格 -->
				<div class="average-price-layout">
					<div class="left">
						<AveragePriceNationwide ref="AveragePriceNationwide" :dates="dates" :type="type"
							:defaultDate="defaultDate" :highLow="highLowData" :charts="chartsData"
							@changeDate="onChangeDate"></AveragePriceNationwide>
					</div>
					<div class="right">
						<AveragePriceAreaTable :list="zoneList"></AveragePriceAreaTable>
					</div>
				</div>

				<!-- 各省份平均价格 -->
				<div class="module-wrapper">
					<h1 class="title">各省份平均价格</h1>
					<div class="list-wrapper">
						<AveragePriceList :list="averagePriceProvinces"></AveragePriceList>
					</div>
				</div>

				<!-- 全国主要城市价格汇总 -->
				<div class="module-wrapper">
					<h1 class="title">全国主要城市价格汇总</h1>
					<div class="submodule" v-for="module in averagePriceMainCities" :key="module.id">
						<div class="submodule-title">
							<i class="location-icon zn-iconfont zn-iconfont-dingwei"></i>
							<h2 class="subtitle">{{ module?.name }}</h2>
							<p class="tips">（{{ module?.province_names }}）</p>
						</div>
						<div class="list">
							<AveragePriceList :list="module?.cities"></AveragePriceList>
						</div>
					</div>
				</div>
				<div style="margin-bottom: 20px;text-align: justify;background: #fff;width: 100%;padding: 1%;font-size: 16px;line-height: 1.7;">
					免责声明：企拍网提交行情报价提供的行情信息仅供参考，可能和市场行情略有偏差，请结合实际情况合理决策，并不构成对客户决策的直接建议，客户不应以此取代自己的独立判断，客户做出的任何决策与企拍网无关。
				</div>
			</div>
		</div>
		<WebFooter></WebFooter>
	</div>
</template>

<script>
import WebHeader from "@/components/WebHeader.vue"; // 页面Header
import WebFooter from "@/components/WebFooter.vue"; // 页面Footer
import Breadcrumbs from "@/components/Breadcrumbs.vue"; // 面包屑
import AveragePriceNationwide from "@/components/AveragePriceNationwide.vue"; // 全国平均价格
import AveragePriceAreaTable from "@/components/AveragePriceAreaTable.vue"; // 区域统计表格
import AveragePriceList from "@/components/AveragePriceList.vue"; // 平均价列表
import { breadcrumbsScrapStatisticsList } from "@/breadcrumbs/index.js"; // 面包屑数据
import { apiGetScrapDatas, apiGetScrapDates } from "@/apis/scrap.js";

export default {
	name: "PageScrapStatisticsList",
	components: {
		WebHeader,
		WebFooter,
		Breadcrumbs,
		AveragePriceNationwide,
		AveragePriceAreaTable,
		AveragePriceList,
	},
	data() {
		return {
			// 面包屑数据
			type: "",
			breadcrumbs: breadcrumbsScrapStatisticsList,
			dates: [], // 日期列表
			defaultDate: "", // 默认日期
			highLowData: {}, // 最高最低数据
			chartsData: [], // 图表数据
			zoneList: [], // 区域平均价格
			averagePriceProvinces: [], // 各省份平均价列表
			averagePriceMainCities: [], // 全国主要城市价格汇总
		};
	},
	async mounted() {
		// 获取数据类型
		this.type = this.$route.query?.type || "";

		// 设置面包屑数据
		this.setBreadcrumbs();

		// 查询日期数据
		await this.queryDates().then().catch();

		// 查询统计数据
		this.queryDatas();
	},
	methods: {
		/**
		 * @description: 设置面包屑数据
		 * @return {*}
		 */
		setBreadcrumbs() {
			// 废钢统计
			if (this.type == "20") {
				this.breadcrumbs = breadcrumbsScrapStatisticsList.concat({ name: "废钢行情", to: { name: "ScrapStatisticsList", query: { type: "20" } } });
			}
			// 废铜统计
			else if (this.type == "10") {
				this.breadcrumbs = breadcrumbsScrapStatisticsList.concat({ name: "废铜行情", to: { name: "ScrapStatisticsList", query: { type: "10" } } });
			}
		},

		/**
		 * !查询日期数据
		 */
		queryDates() {
			return new Promise((resolve, reject) => {
				apiGetScrapDates({
					type: this.type,
				})
					.then((res) => {
						// 得到日期列表
						this.dates = res?.finalData || [];

						// 设置默认日期
						this.defaultDate = this.dates?.[0] || "";

						resolve();
					})
					.catch((err) => {
						reject();
					});
			});
		},

		/**
		 * !查询统计数据
		 */
		queryDatas() {
			// 获取参数数据
			let params = this.$refs?.AveragePriceNationwide?.getEmitData();
			params.type = this.type;
			console.log(params);
			if (params.dates === "") {
				return false
			} else {
				apiGetScrapDatas(params)
					.then((res) => {
						console.log("统计数据", res);

						// 最高最低数据
						this.highLowData = res?.finalData?.high_low || [];

						// 区域平均价格
						this.zoneList = res?.finalData?.zone || [];

						// 各省平均价格
						this.averagePriceProvinces = res?.finalData?.province || [];

						// 全国主要城市价格汇总
						this.averagePriceMainCities = res?.finalData?.region || [];

						// 图表数据
						this.chartsData = res?.finalData?.echarts || [];
					})
					.catch((err) => console.error(err));
			}


		},

		/**
		 * !切换日期
		 */
		onChangeDate() {
			// 清除页面数据
			this.clearPageData();

			// 重新查询统计数据
			this.queryDatas();
		},

		/**
		 * !清除页面数据
		 */
		clearPageData() {
			this.highLowData = {};
			this.chartsData = [];
			this.zoneList = [];
			this.averagePriceProvinces = [];
			this.averagePriceMainCities = [];
		},
	},
};
</script>

<style scoped lang="scss">
.page-scrap-statistics-list-container {
	.average-price-layout {
		display: flex;
		align-items: stretch;
		margin-bottom: 15px;

		.left {
			flex: 1;
			background-color: #ffffff;
		}

		.right {
			flex-shrink: 0;
			margin-left: 15px;
			background-color: #ffffff;
			width: 465px;
		}
	}

	.module-wrapper {
		margin-bottom: 15px;

		&:last-child {
			margin-bottom: 72px;
		}

		.title {
			font-size: 22px;
			text-align: center;
			line-height: 1;
			padding: 40px 0;
		}

		.list-wrapper {
			padding: 36px 36px 21px 36px;
			background-color: #ffffff;
		}

		.submodule {
			padding: 0 44px;
			background-color: #ffffff;
			margin-bottom: 18px;

			.list {
				padding: 22px 0;
			}
		}

		.submodule-title {
			display: flex;
			align-items: center;
			padding: 32px 0 26px 0;
			border-bottom: 1px solid #e3e3e3;

			.location-icon {
				color: var(--theme-color);
				flex-shrink: 0;
				font-size: 18px;
				margin-right: 5px;
			}

			.subtitle {
				color: var(--theme-color);
				flex-shrink: 0;
				font-size: 18px;
				margin-right: 5px;
			}

			.tips {
				flex: 1;
				font-size: 18px;
				color: #666666;
			}
		}
	}
}
</style>

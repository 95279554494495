import { Message } from "element-ui";
import { decrypt } from "@/utils/crypto.js";

export default class HiWebSocket {
	constructor(config) {
		// 指定连接的 URL
		this.url = config?.url || "";

		// 一个协议字符串或一个协议字符串数组。
		// 这些字符串用来指定子协议，这样一个服务器就可以实现多个WebSocket子协议
		this.protocols = config?.protocols;

		// WebSocket 实例
		this.ws = null;

		// 连接状态.
		// "connecting"    : 连接中
		// "opened"        : 连接成功;
		// "normalClosed"  : 正常关闭,无需重连
		// "errorClosed"   : 异常关闭,需要重连
		// "error"         : 连接失败
		// "unsupported"   : 客户端不支持WebSocket
		this.connectStatus = "normalClosed";

		// 最近一次尝试创建连接时的时间戳
		this.lastConnectionTimestamp = 0;

		// 连接失败或异常断开连接后重新创建连接的间隔(秒)
		this.reconnectionTimeInterval = 5;

		// 消息事件管理中心
		this.eventCenter = {};

		// 重新连接检查定时器
		this.reconnectTimer = null;

		// 心跳定时器
		this.heartbeatTimer = null;

		// 创建 WebSocket 实例
		this.createWs();
	}

	// 创建 WebSocket 实例
	createWs() {
		// 更新连接状态
		this.connectStatus = "connecting";

		// 更新最近一次尝试创建连接时的时间戳
		this.lastConnectionTimestamp = new Date().getTime();

		// 检查客户端是否支持WebSocket
		if ("WebSocket" in window) {
			// 实例化
			this.ws = new WebSocket(this.url);

			// 监听事件
			this.onopen();
			this.onerror();
			this.onclose();
			this.onmessage();
		} else {
			console.error("您的浏览器不支持WebSocket!");

			// 更新连接状态
			this.connectStatus = "unsupported";
		}
	}

	// 连接已建立
	onopen() {
		this.ws.onopen = (e) => {
			console.log("Ws --- 连接已建立 --- ", this.ws);

			// 更新连接状态
			this.connectStatus = "opened";

			// 尝试调用订阅中心的 onopen 函数,如果有的话
			if (this.eventCenter?.onopen) this.eventCenter?.onopen();

			// 设置心跳
			this.setHeartBeat();
		};
	}

	// 创建连接错误
	onerror() {
		this.ws.onerror = (err) => {
			console.error("Ws --- 创建连接错误 --- ", err);

			// 更新连接状态
			this.connectStatus = "error";

			// 尝试调用订阅中心的 onerror 函数,如果有的话
			if (this.eventCenter?.onerror) this.eventCenter?.onerror(err);

			// 重新创建连接
			this.reconnect();
		};
	}

	// 连接关闭
	onclose() {
		this.ws.onclose = (event) => {
			console.error("Ws --- 连接被关闭 --- ", event, this.connectStatus);

			// 清除心跳定时器
			this.clearHeartBeatTimer();

			// 尝试调用订阅中心的 onclose 函数,如果有的话
			if (this.eventCenter?.onclose) this.eventCenter?.onclose(err);

			// 正常关闭,不重连
			if (event?.code == 1000 || this.connectStatus == "normalClosed") {
				console.log("Ws --- 正常关闭 --- 无需重连");
				return;
			}

			console.log("Ws --- 异常关闭 --- 需要重连");

			// 更新连接状态
			this.connectStatus = "errorClosed";

			// 异常关闭,重连
			this.reconnect();
		};
	}

	// 重连
	reconnect() {
		// 客户端不支持WebSocket
		if (this.connectStatus == "unsupported") {
			console.log("Ws...客户端不支持WebSocket, 无法重连");
			this.destroy();
			return;
		}

		// 正在连接中
		if (this.connectStatus == "connecting") {
			console.log("Ws...正在连接中, 无需重连");
			this.clearReconnectTimer();
			return;
		}

		// 已连接
		if (this.connectStatus == "opened") {
			console.log("Ws...已连接, 无需重连");
			this.clearReconnectTimer();
			return;
		}

		// 正常关闭
		if (this.connectStatus == "normalClosed") {
			console.log("Ws...正常关闭, 无需重连");
			this.clearReconnectTimer();
			return;
		}

		// 如果距离最近一次的连接时间间隔了一定的秒数
		if (new Date().getTime() - this.lastConnectionTimestamp >= this.reconnectionTimeInterval * 1000) {
			// 立即创建连接
			this.createWs();
			return;
		}

		// 否则,每隔500毫秒,递归调用自己一次
		// 这里使用定时器减少递归调用自己的次数,防止消耗过大
		// 没有对应的定时器时再创建定时器
		// 不重复创建定时器
		if (!this.reconnectTimer) {
			this.reconnectTimer = setInterval(() => {
				// 再次尝试重连
				this.reconnect();
			}, 500);
		}
	}

	// 清除重连定时器
	clearReconnectTimer() {
		if (this.reconnectTimer) {
			clearInterval(this.reconnectTimer);
			this.reconnectTimer = null;
		}
	}

	// 收到消息
	onmessage() {
		this.ws.onmessage = (msg) => {
			console.log("");
			console.log("############################################################");
			console.log("Ws --- 收到消息 --- ");

			// 收到的数据
			let data = msg.data;

			// 收到的数据是字符串,尝试JSON.parse()
			if (this.isString(msg.data)) {
				try {
					data = JSON.parse(msg.data);
				} catch (error) {
					console.error("尝试将接收到的消息数据JSON.parse()转换失败:", error);
				}
			}

			console.log("Ws --- 消息为 --- ", data);

			// 消息携带的数据
			data.finalData = data.result;

			// 数据需要解密
			if (data.result?.sign && data.result?.iv) data.finalData = decrypt(data?.result?.sign, null, data?.result?.iv);

			// 数据状态码异常,非正常状态码
			if (data?.code != 1000) {
				Message.error(data?.msg);
				console.error(data);
				return;
			}

			// 数据状态码正常
			if (data?.code == 1000) {
				// 如果该事件已注册,广播对应的事件
				if (this.isFunction(this.eventCenter[data?.event])) this.eventCenter[data.event](data);
			}
		};
	}

	// 发送消息
	// errTips: 发送消息失败后的提示,如果为空,则不提示
	send(message, errTips = "") {
		// 连接正常
		if (this.ws && this.ws.readyState == 1) {
			// 发送消息
			this.ws.send(message);
		}

		// 连接异常,无法发送消息
		else {
			// 需要提示
			if (errTips) {
				Message.error(errTips);
			}
		}
	}

	// 设置心跳
	setHeartBeat() {
		// 确保清除上次心跳定时器
		this.clearHeartBeatTimer();

		// 心跳定时器
		this.heartbeatTimer = setInterval(() => {
			console.log("PING");
			this.send("PING");
		}, 60000);
	}

	// 清除心跳定时器
	clearHeartBeatTimer() {
		if (this.heartbeatTimer) {
			clearInterval(this.heartbeatTimer);
			this.heartbeatTimer = null;
		}
	}

	// 订阅消息事件
	subscribeMessageEvent(name, func) {
		this.eventCenter[name] = func;
	}

	// 取消订阅消息事件
	unsubscribeMessageEvent(name, func) {
		delete this.eventCenter[name];
	}

	// 销毁
	destroy() {
		this.connectStatus = "normalClosed";
		this.ws?.close(1000, "正常关闭");
		this.ws = null;
		this.eventCenter = {};
		this.clearReconnectTimer();
		this.clearHeartBeatTimer();
	}

	/**
	 * @description 校验数据是否是Function类型
	 * @param {Any} val 需要校验的数据
	 * @return {Boolean} true: 是；false: 否；
	 */
	isFunction(val) {
		return Object.prototype.toString.call(val) === "[object Function]";
	}

	/**
	 * @description 校验数据是否是String类型
	 * @param {Any} val 需要校验的数据
	 * @return {Boolean} true: 是；false: 否；
	 */
	isString(val) {
		return Object.prototype.toString.call(val) === "[object String]";
	}
}

/**
 * ****************************************************************************************************
 * 公共接口
 * ****************************************************************************************************
 */
import HiRequest from "@/request/index.js";
export const hr = new HiRequest();

// 页面通用数据
export const apiViewData = (data = {}, options = {}) => hr.get("api/v1/web_config/view_data", data, options);

// 首页数据
export const apiIndexViewData = (data = {}, options = {}) => hr.get("api/v1/index", data, options);

// 发送验证码
export const apiSendSmsCode = (data = {}, options = {}) => hr.post("api/v1/passport/send_sms", data, options);

// 获取用户服务内容
export const apiGetServiceAgreement = (data = {}, options = {}) => hr.get("api/v1/web_config/services_agreement", data, options);

// 获取隐私政策内容
export const apiGetPrivacyPolicy = (data = {}, options = {}) => hr.get("api/v1/web_config/privacy_policy", data, options);

// 获取喜好,爱好选项列表
export const apiGetHobbyList = (data = {}, options = {}) => hr.get("api/v1/passport/hobby", data, options);

// 保存喜好,爱好
export const apiSaveUserHobby = (data = {}, options = {}) => hr.post("api/v1/passport/edit_hobby", data, options);

// 获取七牛配置信息
export const apiGetQiniuConfig = (data = {}, options = {}) => hr.get("api/v1/common/qiniu_token", data, options);

// 获取服务器时间
export const apiGetServerTime = (data = {}, options = { loadingShow: false, errorShow: false, failShow: false }) => hr.get("api/v1/common/now_time", data, options);

// 获取平台拍卖规则
export const apiGetPlatformAuctionRules = (data = {}, options = {}) => hr.get("api/v1/web_config/platform_auction_rule", data, options);

// 获取平台使用协议
export const apiGetPlatformAgreement = (data = {}, options = {}) => hr.get("api/v1/web_config/platform_use_agreement", data, options);

// 获取注销协议
export const apiGetLogoutAgreement = (data = {}, options = {}) => hr.get("api/v1/web_config/account_cancel", data, options);
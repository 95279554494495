<template>
	<div class="header">
		<div class="topic-container">
			<div class="topic-wrapper layout-main">
				<div class="left">
					<div class="userinfo" v-if="userinfo">
						<span>您好！{{ (userinfo && userinfo.nickname) || "" }}</span>
						<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
						<span class="link" @click="handleLogout">退出登录</span>
						<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;拍卖企业</span>
						<a target="_blank" class="link" @click="handleEnterprise">登录</a>
					</div>
					<div class="userinfo" v-else>
						<span>您好！欢迎来到企拍网&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
						<template v-if="!hideUserLogin">
							<span>请先</span>
							<router-link class="link" to="/login">登录</router-link>
							<span>/</span>
							<router-link class="link" to="/register">注册</router-link>
						</template>
						<!-- <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;拍卖企业</span>
						<a target="_blank" class="link" @click="handleEnterprise">登录</a> -->
					</div>
				</div>
				<div class="right">
					<span>客服电话：</span>
					<a class="link tel" :href="'tel:' + companyInfo?.customer_tel || ''">{{ format400Tel(companyInfo?.customer_tel || "") }}</a>
				</div>
			</div>
		</div>
		<div class="main-container">
			<div class="main-wrapper layout-main">
				<div class="left">
					<router-link to="/" class="logo-link">
						<img class="logo" :src="companyInfo?.view_logo_top" alt="" />
					</router-link>
					<div class="search-wrapper">
						<el-dropdown class="dropdown" placement="bottom-start" trigger="click" @command="handleChangeTypesValue">
							<span class="el-dropdown-link">{{ typesValue }}<i class="el-icon-arrow-down el-icon--right"></i> </span>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item v-for="(item, index) in types" :key="item + index" :command="item">{{ item }}</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
						<el-input v-model="searchText" placeholder="请输入搜索内容"></el-input>
						<el-button type="primary" class="button" @click="handleSearch">搜索</el-button>
					</div>
				</div>
				<div class="right">
					<img class="slogan" :src="companyInfo?.slogan_img" alt="" />
				</div>
			</div>
		</div>
		<div class="navs-container">
			<div class="navs-wrapper layout-main">
				<ul class="navs-list">
					<li class="navs-item" v-for="(item, index) in navs" :key="index" :class="{ active: item.name == activeHeaderNavName }" @click="navJump(item)">
						<div class="navs-item-content">
							<i class="zn-iconfont navs-item-icon" :class="item.icon" v-if="item.icon"></i>
							<span>{{ item.name }}</span>
						</div>
						<div class="second-items-wrapper" v-if="item.list">
							<div class="second-items-item" v-for="secondItem in item.list" :key="secondItem.name" @click="navJump(secondItem)">
								<i class="zn-iconfont navs-item-icon" :class="secondItem.icon" v-if="secondItem.icon"></i>
								<span>{{ secondItem.name }}</span>
							</div>
						</div>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapState, mapGetters } from "vuex";
	import { jumpToEnterpriseAdmin,jumpToAuctionAdmin } from "@/utils/func.js";
	export default {
		name: "WebHeader",
		computed: {
			...mapState(["activeHeaderNavName", "companyInfo"]),
			...mapGetters(["userinfo"]),
		},
		props: {
			// 隐藏用户“登录/注册”链接?
			hideUserLogin: {
				type: Boolean,
				default: false,
			},
		},
		data() {
			return {
				// 搜索数据
				searchText: "", // 搜索内容
				types: ["标的", "拍卖会", "公告", "新闻", "求购租赁", "企业"], // 类型列表
				typesValue: "标的", // 选中的类型

				// 导航菜单数据
				// name: 模块名称，必须和路由配置中的activeHeaderNavName字段对应起来，否则激活状态会失效
				// icon: 图标名称
				// to: 点击后要跳转到的路由数据
				navs: [
					{ name: "首页", icon: "", to: { name: "Index" } },
					{ name: "标的", icon: "", to: { name: "TargetList" } },
					{ name: "拍卖会", icon: "", to: { name: "AuctionList" } },
					{
						name: "每日行情",
						list: [
							{ name: "废钢行情", icon: "", to: { name: "ScrapStatisticsList", query: { type: "20" } } },
							{ name: "废铜行情", icon: "", to: { name: "ScrapStatisticsList", query: { type: "10" } } },
						],
					},
					{ name: "求购租赁", icon: "", to: { name: "BuyLeaseList" } },
					{ name: "成交案例", icon: "", to: { name: "TransactionCasesList" } },
					{ name: "入驻企业", icon: "", to: { name: "SettledEnterpriseList" } },
					{ name: "企拍网", icon: "", to: { name: "ZhongNuoAuction" } },
					{ name: "帮助中心", icon: "", to: { name: "HelpCenterIndex" } },
					{ name: "个人中心", icon: "zn-iconfont-yonghu", to: { name: "Mine" } },
				],
			};
		},
		mounted() {
			// 得到默认搜索类型和内容
			this.typesValue = this.$route.query?.search_mode || "标的";
			this.searchText = this.$route.query?.search_text || "";
		},
		methods: {
			/**
			 * !切换搜索类型
			 * @param {*} command 类型值
			 */
			handleChangeTypesValue(command) {
				this.typesValue = command;
			},

			/**
			 * !导航跳转
			 * @param {Object} item 导航菜单数据
			 */
			navJump(item) {
				if (item.list) return;
				this.$router.push(item.to);
			},

			/**
			 * !企业登录
			 */
			handleEnterprise() {
				// jumpToEnterpriseAdmin();
				jumpToAuctionAdmin()
			},

			/**
			 * !退出登录
			 */
			handleLogout() {
				this.$confirm("您正在进行退出登录操作, 是否继续?", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning",
				})
					.then(() => {
						this.$store.dispatch("logout");
						this.$message({
							type: "success",
							message: "退出成功!",
						});
						window.location.reload();
					})
					.catch(() => {
						this.$message({
							type: "info",
							message: "退出失败",
						});
					});
			},

			/**
			 * !搜索
			 */
			handleSearch() {
				if (this.typesValue == "标的") this.$router.push({ name: "TargetList", query: { search_mode: this.typesValue, search_text: this.searchText } });
				else if (this.typesValue == "拍卖会") this.$router.push({ name: "AuctionList", query: { search_mode: this.typesValue, search_text: this.searchText } });
				else if (this.typesValue == "公告")
					this.$router.push({ name: "NoticeCenterList", query: { search_mode: this.typesValue, search_text: this.searchText } });
				else if (this.typesValue == "新闻")
					this.$router.push({ name: "NewsCenterList", query: { search_mode: this.typesValue, search_text: this.searchText } });
				else if (this.typesValue == "求购租赁")
					this.$router.push({ name: "BuyLeaseList", query: { search_mode: this.typesValue, search_text: this.searchText } });
				else if (this.typesValue == "企业")
					this.$router.push({ name: "SettledEnterpriseList", query: { search_mode: this.typesValue, search_text: this.searchText } });
			},
		},
	};
</script>

<style scoped lang="scss">
	.header {
		background-color: #ffffff;
		// overflow: hidden auto;

		.topic-container {
			border-bottom: 1px solid var(--border-color);
			color: #666666;

			.topic-wrapper {
				height: 42px;
				display: flex;
				align-items: center;
			}

			.link {
				text-decoration: none;
				transition: color 0.2s;
				color: inherit;
			}

			.left {
				flex: 1;

				.link {
					color: var(--theme-color);
					cursor: pointer;

					&:hover {
						text-decoration: underline;
					}
				}
			}

			.right {
				flex-shrink: 0;

				.link:hover {
					color: var(--theme-color);
				}
			}
		}

		.main-container {
			.main-wrapper {
				display: flex;
				align-items: center;
				height: 106px;
			}

			.left {
				flex: 1;
				display: flex;
				align-items: center;

				.logo-link {
					display: block;
					margin-right: 38px;
					/* background-image: url("@/assets/images/logo.png"); */
					width: 207px;
					height: 57px;
					animation: logoAnimation 4.04s steps(1) infinite;
				}

				.logo {
					display: block;
					height: 57px;
					/* display: none; */
				}

				.search-wrapper {
					width: 413px;
					height: 43px;
					border: 1px solid var(--theme-color);
					display: flex;
				}

				.dropdown {
					flex-shrink: 0;
					height: 100%;
					display: flex;
					align-items: center;
					padding: 0 15px;
					cursor: pointer;
					user-select: none;
				}

				::v-deep .el-input__inner {
					border: none;
					padding: 0 10px 0 0;
				}

				.button {
					width: 94px;
					border-radius: 0;
					font-size: 16px;
					flex-shrink: 0;
				}
			}

			.right {
				flex-shrink: 0;

				.slogan {
					height: 36px;
					display: block;
				}
			}
		}

		.navs-container {
			/* background-color: var(--theme-color); */
			/* color: #ffffff; */
			color: #333333;
			font-size: 16px;
			border-top: 1px solid #ededed;

			.navs-wrapper {
				height: 57px;
				display: flex;
			}

			.navs-list {
				flex: 1;
				list-style: none;
				height: 100%;
				display: flex;
			}

			.navs-item {
				/* flex: 1; */
				width: 10%;
				height: 100%;
				display: flex;
				align-items: center;
				/* justify-content: center; */
				cursor: pointer;
				user-select: none;
				padding: 0 6px;
				position: relative;

				&.active {
					/* background-color: var(--theme-color-deep); */
					color: #ba1b33;
				}

				.navs-item-icon {
					font-size: inherit;
					margin-right: 8px;
				}
				&:hover{
					color: #ba1b33;
				}

				&:last-child {
					flex: 1.25;
					padding-left: 26px;

					&::before {
						content: "";
						width: 1px;
						height: 16px;
						position: absolute;
						/* left: 0; */
						left: -10px;
						top: 50%;
						margin-top: -8px;
						/* background-color: #dd8d99; */
						background-color: #737373;
					}

					&.active {
						&::before {
							opacity: 0;
						}
					}
				}

				&:nth-last-child(2).active + .navs-item {
					&::before {
						opacity: 0;
					}
				}
			}

			.second-items-wrapper {
				position: absolute;
				width: 100%;
				top: 100%;
				left: -21px;
				z-index: 10;
				text-align: center;
				background: var(--theme-color);
				max-height: 0px;
				overflow: hidden;
				transition: max-height 0.2s ease-in-out, opacity 0.2s ease-in-out;
				opacity: 0;

				.second-items-item {
					height: 57px;
					display: flex;
					align-items: center;
					justify-content: center;
					cursor: pointer;
					color: #fff;

					&:hover {
						background-color: var(--theme-color-deep);
					}
				}
			}

			.navs-item:hover .second-items-wrapper {
				max-height: 200px;
				opacity: 1;
			}
		}

		@keyframes logoAnimation {
			0% {
				background-position: 0px 0px;
			}
			1.00% {
				background-position: -207px 0px;
			}
			2.00% {
				background-position: -414px 0px;
			}
			3.00% {
				background-position: -621px 0px;
			}
			4.00% {
				background-position: -828px 0px;
			}
			5.00% {
				background-position: -1035px 0px;
			}
			6.00% {
				background-position: -1242px 0px;
			}
			7.00% {
				background-position: -1449px 0px;
			}
			8.00% {
				background-position: -1656px 0px;
			}
			9.00% {
				background-position: -1863px 0px;
			}
			10.00% {
				background-position: -2070px 0px;
			}
			11.00% {
				background-position: -2277px 0px;
			}
			12.00% {
				background-position: -2484px 0px;
			}
			13.00% {
				background-position: -2691px 0px;
			}
			14.00% {
				background-position: -2898px 0px;
			}
			15.00% {
				background-position: -3105px 0px;
			}
			16.00% {
				background-position: -3312px 0px;
			}
			17.00% {
				background-position: -3519px 0px;
			}
			18.00% {
				background-position: -3726px 0px;
			}
			19.00% {
				background-position: -3933px 0px;
			}
			20.00% {
				background-position: -4140px 0px;
			}
			21.00% {
				background-position: -4347px 0px;
			}
			22.00% {
				background-position: -4554px 0px;
			}
			23.00% {
				background-position: -4761px 0px;
			}
			24.00% {
				background-position: 0px -57px;
			}
			25.00% {
				background-position: -207px -57px;
			}
			26.00% {
				background-position: -414px -57px;
			}
			27.00% {
				background-position: -621px -57px;
			}
			28.00% {
				background-position: -828px -57px;
			}
			29.00% {
				background-position: -1035px -57px;
			}
			30.00% {
				background-position: -1242px -57px;
			}
			31.00% {
				background-position: -1449px -57px;
			}
			32.00% {
				background-position: -1656px -57px;
			}
			33.00% {
				background-position: -1863px -57px;
			}
			34.00% {
				background-position: -2070px -57px;
			}
			35.00% {
				background-position: -2277px -57px;
			}
			36.00% {
				background-position: -2484px -57px;
			}
			37.00% {
				background-position: -2691px -57px;
			}
			38.00% {
				background-position: -2898px -57px;
			}
			39.00% {
				background-position: -3105px -57px;
			}
			40.00% {
				background-position: -3312px -57px;
			}
			41.00% {
				background-position: -3519px -57px;
			}
			42.00% {
				background-position: -3726px -57px;
			}
			43.00% {
				background-position: -3933px -57px;
			}
			44.00% {
				background-position: -4140px -57px;
			}
			45.00% {
				background-position: -4347px -57px;
			}
			46.00% {
				background-position: -4554px -57px;
			}
			47.00% {
				background-position: -4761px -57px;
			}
			48.00% {
				background-position: 0px -114px;
			}
			49.00% {
				background-position: -207px -114px;
			}
			50.00% {
				background-position: -414px -114px;
			}
			51.00% {
				background-position: -621px -114px;
			}
			52.00% {
				background-position: -828px -114px;
			}
			53.00% {
				background-position: -1035px -114px;
			}
			54.00% {
				background-position: -1242px -114px;
			}
			55.00% {
				background-position: -1449px -114px;
			}
			56.00% {
				background-position: -1656px -114px;
			}
			57.00% {
				background-position: -1863px -114px;
			}
			58.00% {
				background-position: -2070px -114px;
			}
			59.00% {
				background-position: -2277px -114px;
			}
			60.00% {
				background-position: -2484px -114px;
			}
			61.00% {
				background-position: -2691px -114px;
			}
			62.00% {
				background-position: -2898px -114px;
			}
			63.00% {
				background-position: -3105px -114px;
			}
			64.00% {
				background-position: -3312px -114px;
			}
			65.00% {
				background-position: -3519px -114px;
			}
			66.00% {
				background-position: -3726px -114px;
			}
			67.00% {
				background-position: -3933px -114px;
			}
			68.00% {
				background-position: -4140px -114px;
			}
			69.00% {
				background-position: -4347px -114px;
			}
			70.00% {
				background-position: -4554px -114px;
			}
			71.00% {
				background-position: -4761px -114px;
			}
			72.00% {
				background-position: 0px -171px;
			}
			73.00% {
				background-position: -207px -171px;
			}
			74.00% {
				background-position: -414px -171px;
			}
			75.00% {
				background-position: -621px -171px;
			}
			76.00% {
				background-position: -828px -171px;
			}
			77.00% {
				background-position: -1035px -171px;
			}
			78.00% {
				background-position: -1242px -171px;
			}
			79.00% {
				background-position: -1449px -171px;
			}
			80.00% {
				background-position: -1656px -171px;
			}
			81.00% {
				background-position: -1863px -171px;
			}
			82.00% {
				background-position: -2070px -171px;
			}
			83.00% {
				background-position: -2277px -171px;
			}
			84.00% {
				background-position: -2484px -171px;
			}
			85.00% {
				background-position: -2691px -171px;
			}
			86.00% {
				background-position: -2898px -171px;
			}
			87.00% {
				background-position: -3105px -171px;
			}
			88.00% {
				background-position: -3312px -171px;
			}
			89.00% {
				background-position: -3519px -171px;
			}
			90.00% {
				background-position: -3726px -171px;
			}
			91.00% {
				background-position: -3933px -171px;
			}
			92.00% {
				background-position: -4140px -171px;
			}
			93.00% {
				background-position: -4347px -171px;
			}
			94.00% {
				background-position: -4554px -171px;
			}
			95.00% {
				background-position: -4761px -171px;
			}
			96.00% {
				background-position: 0px -228px;
			}
			97.00% {
				background-position: -207px -228px;
			}
			98.00% {
				background-position: -414px -228px;
			}
			99.00% {
				background-position: -621px -228px;
			}
			100.00% {
				background-position: -828px -228px;
			}
		}
	}
</style>

<!-- 喜好,爱好选择弹窗 -->
<template>
	<el-dialog
		class="component-hobby-select-dialog"
		:visible="showDialog"
		width="800px"
		:show-close="false"
		destroy-on-close
		:close-on-click-modal="false"
		@close="showDialog = false"
	>
		<div class="component-hobby-select-dialog-wrapper">
			<div class="wrapper">
				<h1 class="title">{{ title }}</h1>
				<h2 class="subtitle">{{ subtitle }}</h2>
				<div class="content">
					<div class="inner">
						<div class="list-wrapper">
							<ul class="list">
								<li class="item" :class="{ 'is-selected': calcIsSelected(item) }" v-for="item in list" :key="item.id" @click="handleItemClick(item)">
									<div class="item-content ellipsis1">{{ item?.name }}</div>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div class="foot">
					<span class="button button-confirm" @click="handleSave">我选好了</span>
					<span class="button button-cancel" @click="handleCancel">以后再选</span>
				</div>
			</div>
			<div class="close-box">
				<i class="close-icon zn-iconfont zn-iconfont-guanbi1" @click="handleClose"></i>
			</div>
		</div>
	</el-dialog>
</template>

<script>
	import { apiGetHobbyList, apiSaveUserHobby } from "@/apis/common.js";

	export default {
		name: "HobbySelectDialog",
		props: {
			// 标题
			title: {
				type: String,
				default: "选择我的喜好类型（可多选）",
			},

			// 副标题
			subtitle: {
				type: String,
				default: "我们将根据您的喜好为您推荐拍卖信息",
			},
		},
		data() {
			return {
				showDialog: false, // 弹窗显示状态
				list: [], // 喜好,爱好选项列表
				selectedIds: [], // 被选中的喜好ids
			};
		},
		mounted() {},
		methods: {
			/**
			 * !显示
			 */
			open() {
				if (!this.list.length) this.queryHobbyList();
				this.showDialog = true;
			},

			/**
			 * !关闭
			 */
			close() {
				this.showDialog = false;
			},

			/**
			 * !查询喜好,爱好列表
			 */
			queryHobbyList() {
				apiGetHobbyList()
					.then((res) => {
						// 所有喜好,爱好选项列表
						this.list = res?.finalData?.all_hobby || [];

						// 用户当前选择的喜好,爱好选项的ids
						this.selectedIds = res?.finalData?.hobby_ids || [];
					})
					.catch((err) => console.error(err));
			},

			/**
			 * !计算选项是否被选中
			 * @param {object} item 选项数据
			 */
			calcIsSelected(item) {
				return this.selectedIds.find((el) => el == item.id);
			},

			/**
			 * !选项点击事件
			 * @param {object} item 被点击的选项数据
			 */
			handleItemClick(item) {
				let findIndex = this.selectedIds.findIndex((el) => el == item.id);
				if (findIndex < 0) this.selectedIds.push(item.id);
				else this.selectedIds.splice(findIndex, 1);
			},

			/**
			 * !保存爱好
			 */
			handleSave() {
				// 需要至少选择一项
				if (!this.selectedIds.length) return this.$message.warning("请至少选择一项");

				// 请求接口进行保存
				apiSaveUserHobby({
					ids: this.selectedIds,
				})
					.then((res) => {
						this.$emit("next");
					})
					.catch((err) => console.error(err));
			},

			/**
			 * !以后再选
			 */
			handleCancel() {
				this.close();
				this.$emit("next");
			},

			/**
			 * !点击了关闭按钮
			 */
			handleClose() {
				this.close();
				this.$emit("next");
			},
		},
	};
</script>

<style lang="scss" scoped>
	.component-hobby-select-dialog-wrapper {
		.wrapper {
			background-color: #ffffff;
			padding: 30px 39px 20px;

			.title {
				font-size: 18px;
				line-height: 26px;
				text-align: center;
				margin-bottom: 16px;
			}

			.subtitle {
				font-size: 16px;
				line-height: 24px;
				font-weight: normal;
				color: #999999;
				text-align: center;
				margin-bottom: 20px;
			}

			.content {
				min-height: 160px;
				max-height: 367px;
				overflow-x: hidden;
				overflow-y: auto;
				color: #666666;
				line-height: 20px;
				text-align: justify;

				&::-webkit-scrollbar {
					width: 12px;
					background-color: #f5f5f5;
				}

				&::-webkit-scrollbar-thumb {
					background-color: #dfdfdf;
				}

				.inner {
					padding-right: 8px;
				}
			}

			.list-wrapper {
				overflow: hidden;

				.list {
					display: flex;
					flex-wrap: wrap;
					list-style: none;
					margin: 0 -6px;
				}

				.item {
					width: 20%;
					padding: 9px 6px;
					font-size: 16px;
					line-height: 24px;
					text-align: center;
				}

				.item-content {
					padding: 14px 5px;
					border-radius: 10px;
					border: solid 1px #bfbfbf;
					cursor: pointer;
					user-select: none;
					display: block;
				}

				.item.is-selected .item-content {
					border-color: var(--theme-color);
					color: var(--theme-color);
				}
			}

			.foot {
				.button {
					width: 132px;
					height: 40px;
					line-height: 40px;
					text-align: center;
					font-size: 16px;
					color: #ffffff;
					display: block;
					margin: 16px auto;
					background-color: var(--theme-color);
					font-weight: bold;
					cursor: pointer;
					user-select: none;
				}

				.button-cancel {
					background-color: #ededed;
					color: #666666;
				}
			}
		}

		.close-box {
			width: 44px;
			height: 44px;
			overflow: hidden;
			background-color: #bdbdbd;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			user-select: none;
			transition: 0.2s;
			margin: 12px auto;

			.close-icon {
				font-size: 24px;
				color: #ffffff;
			}

			&:hover {
				background-color: var(--theme-color);
			}
		}
	}
</style>

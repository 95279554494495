<!-- 搜索过滤组件 -->
<template>
	<div class="search-filter-container">
		<!-- #S 地区 -->
		<div class="row-group row-group__region" v-if="region && region.length">
			<!-- 标签 -->
			<label class="row-group-label">地区：</label>

			<!-- #S 标签内容 -->
			<div class="row-group-value">
				<!-- 不限 -->
				<span class="tab tab-unlimited" :class="{ active: activeProvinceId == '' }" @click="onSelectAllProvince">不限</span>

				<!-- #S 第一层 -->
				<ul class="tabs tabs-parent">
					<li
						class="tab tab-parent"
						v-for="(province, index) in region"
						:key="index"
						:class="{ active: province.id == activeProvinceId }"
						@click="onClickProvince(province, index)"
					>
						<!-- #S 第一层标签 -->
						<div class="tab-content tab-parent-content">
							<span>{{ province.name }}</span>
							<i class="arrow zn-iconfont zn-iconfont-xia"></i>
						</div>
						<!-- #E 第一层标签 -->

						<!-- #S 第二层 -->
						<ul class="tabs tabs-children" @click.stop="">
							<!-- 全部 -->
							<li class="tab tab-children" :class="{ active: activeCityId == '' }" @click="onSelectAllCity">全部</li>

							<!-- 第二层标签 -->
							<li
								class="tab tab-children"
								:class="{ active: activeCityId == city.id }"
								v-for="(city, current) in province.children"
								:key="current"
								@click="onClickCity(city)"
							>
								{{ city.name }}
							</li>
						</ul>
						<!-- #E 第二层 -->
					</li>
				</ul>
				<!-- #E 第一层 -->

				<!-- 收起 -->
				<span class="tab tab-row-close" :class="{ active: activeProvinceId != '' }" @click="onSelectAllProvince">收起</span>
			</div>
			<!-- #E 标签内容 -->
		</div>
		<!-- #E 地区 -->

		<!-- #S 标的类型 -->
		<div class="row-group row-group__target-types" v-if="targetTypes && targetTypes.length">
			<!-- 标签 -->
			<label class="row-group-label">标的类型：</label>

			<!-- #S 标签内容 -->
			<div class="row-group-value">
				<!-- 不限 -->
				<span class="tab tab-unlimited" :class="{ active: activeTargetParentId == '' }" @click="onSelectAllTargetTypes">不限</span>

				<!-- #S 第一层 -->
				<ul class="tabs tabs-parent">
					<li
						class="tab tab-parent"
						v-for="(parent, index) in targetTypes"
						:key="index"
						:class="{ active: parent.id == activeTargetParentId }"
						@click="onClickTargetParent(parent, index)"
					>
						<!-- #S 第一层标签 -->
						<div class="tab-content tab-parent-content">
							<span>{{ parent.name }}</span>
							<i class="arrow zn-iconfont zn-iconfont-xia"></i>
						</div>
						<!-- #E 第一层标签 -->

						<!-- #S 第二层 -->
						<ul class="tabs tabs-children" @click.stop="">
							<!-- 全部 -->
							<li class="tab tab-children" :class="{ active: activeTargetChildrenId == '' }" @click="onSelectAllTargetTypeChildren">全部</li>

							<!-- 第二层标签 -->
							<li
								class="tab tab-children"
								:class="{ active: activeTargetChildrenId == child.id }"
								v-for="(child, current) in parent.children"
								:key="current"
								@click="onClickTargetChild(child)"
							>
								{{ child.name }}
							</li>
						</ul>
						<!-- #E 第二层 -->
					</li>
				</ul>
				<!-- #E 第一层 -->

				<!-- 收起 -->
				<span class="tab tab-row-close" :class="{ active: activeTargetParentId != '' }" @click="onSelectAllTargetTypes">收起</span>
			</div>
			<!-- #E 标签内容 -->
		</div>
		<!-- #E 标的类型 -->

		<!-- #S 拍卖状态 -->
		<div class="row-group" v-if="auctionStatus && auctionStatus.length">
			<!-- 标签 -->
			<label class="row-group-label">拍卖状态：</label>

			<!-- #S 标签内容 -->
			<div class="row-group-value">
				<!-- 不限 -->
				<span class="tab tab-unlimited" :class="{ active: auctionStatusValue == '' }" @click="onChangeAuctionStatus('')">不限</span>

				<!-- #S 选项列表 -->
				<ul class="tabs">
					<li
						class="tab unchild"
						v-for="(item, index) in auctionStatus"
						:key="index"
						:class="{ active: item.id == auctionStatusValue }"
						@click="onChangeAuctionStatus(item.id)"
					>
						{{ item.name }}
					</li>
				</ul>
				<!-- #E 选项列表 -->
			</div>
			<!-- #E 标签内容 -->
		</div>
		<!-- #E 拍卖状态 -->

		<!-- #S 租赁类别 -->
		<div class="row-group" v-if="rentTypes && rentTypes.length">
			<!-- 标签 -->
			<label class="row-group-label">租赁类别：</label>

			<!-- #S 标签内容 -->
			<div class="row-group-value">
				<!-- 不限 -->
				<span class="tab tab-unlimited" :class="{ active: rentTypesValue == '' }" @click="onChangeRentType('')">不限</span>

				<!-- #S 选项列表 -->
				<ul class="tabs">
					<li
						class="tab unchild"
						v-for="(item, index) in rentTypes"
						:key="index"
						:class="{ active: item.id == rentTypesValue }"
						@click="onChangeRentType(item.id)"
					>
						{{ item.name }}
					</li>
				</ul>
				<!-- #E 选项列表 -->
			</div>
			<!-- #E 标签内容 -->
		</div>
		<!-- #E 租赁类别 -->

		<!-- #S 产品类别 -->
		<!-- <div class="row-group" v-if="productTypes && productTypes.length">
			<label class="row-group-label">产品类别：</label>
			<div class="row-group-value">
				<span class="tab tab-unlimited" :class="{ active: productTypesValue == '' }" @click="onChangeProductType('')">不限</span>
				<ul class="tabs">
					<li
						class="tab unchild"
						v-for="(item, index) in productTypes"
						:key="index"
						:class="{ active: item.id == productTypesValue }"
						@click="onChangeProductType(item.id)"
					>
						{{ item.name }}
					</li>
				</ul>
			</div>
		</div> -->

		<div class="row-group row-group__product-types" v-if="productTypes && productTypes.length">
			<label class="row-group-label">产品类别：</label>
			<div class="row-group-value">
				<span class="tab tab-unlimited" :class="{ active: activeProductTypesParentId == '' }" @click="onSelectAllProductTypes">不限</span>
				<ul class="tabs tabs-parent">
					<li
						class="tab tab-parent"
						v-for="(parent, index) in productTypes"
						:key="index"
						:class="{ active: parent.id == activeProductTypesParentId }"
						@click="onClickProductTypeParent(parent, index)"
					>
						<div class="tab-content tab-parent-content">
							<span>{{ parent.name }}</span>
							<i class="arrow zn-iconfont zn-iconfont-xia"></i>
						</div>
						<ul class="tabs tabs-children" @click.stop="">
							<li class="tab tab-children" :class="{ active: activeProductTypesChildrenId == '' }" @click="onSelectAllProductTypeChildren">全部</li>
							<li
								class="tab tab-children"
								:class="{ active: activeProductTypesChildrenId == child.id }"
								v-for="(child, current) in parent.children"
								:key="current"
								@click="onClickProductTypeChild(child)"
							>
								{{ child.name }}
							</li>
						</ul>
					</li>
				</ul>
				<span class="tab tab-row-close" :class="{ active: activeProductTypesParentId != '' }" @click="onSelectAllProductTypes">收起</span>
			</div>
			<!-- #E 标签内容 -->
		</div>
		<!-- #E 产品类别 -->

		<!-- #S 开始时间 -->
		<div class="row-group" v-if="showDateItems">
			<!-- 标签 -->
			<label class="row-group-label">开始时间：</label>

			<!-- #S 标签内容 -->
			<div class="row-group-value">
				<!-- 不限 -->
				<span class="tab tab-unlimited" :class="{ active: dateItemsValue == '' }" @click="onChangeDateItemsValue('')">不限</span>

				<!-- #S 选项列表 -->
				<ul class="tabs">
					<!-- 预定义时间周期选项 -->
					<li
						class="tab unchild"
						v-for="(item, index) in dateItems"
						:key="index"
						:class="{ active: item.id == dateItemsValue }"
						@click="onChangeDateItemsValue(item.id)"
					>
						{{ item.name }}
					</li>

					<!-- 自选时间周期 -->
					<li class="date-wrapper">
						<el-date-picker
							class="date-picker"
							v-model="dateStart"
							:default-value="dateStart"
							type="date"
							placeholder="开始日期"
							size="mini"
							@change="onChangeStartDate"
						></el-date-picker>
						<span class="date-separator">~</span>
						<el-date-picker
							class="date-picker"
							v-model="dateEnd"
							:default-value="dateEnd"
							type="date"
							placeholder="结束日期"
							size="mini"
							@change="onChangeEndDate"
						></el-date-picker>
					</li>

					<!-- 确认 -->
					<li class="button-wrapper"><el-button type="primary" size="mini" @click="handleSubmit">确认</el-button></li>
				</ul>
				<!-- #E 选项列表 -->
			</div>
			<!-- #E 标签内容 -->
		</div>
		<!-- #E 开始时间 -->
	</div>
</template>

<script>
	import { EnumDateItems } from "@/const.js";

	export default {
		name: "SearchFilter",
		props: {
			region: Array, // 地区数据
			auctionStatus: Array, // 拍卖状态数据
			targetTypes: Array, // 标的类型
			rentTypes: Array, // 租赁类别
			productTypes: Array, // 产品类别
			showDateItems: Boolean, // 是否显示预定义时间周期选项列表
			parentId: String, // 父级id
			childrenId: String, // 子级id
		},
		data() {
			return {
				activeProvinceId: "", // 激活的省份id
				activeCityId: "", // 激活的城市id
				activeTargetParentId: "", // 激活的标的类型父类型
				activeTargetChildrenId: "", // 激活的标的类型子类型
				auctionStatusValue: "", // 选中的拍卖状态值
				rentTypesValue: "", // 租赁类别选中的值
				productTypesValue: "", // 产品类别选中的值
				activeProductTypesParentId: "", // 激活的产品类别父类型
				activeProductTypesChildrenId: "", // 激活的产品类别子类型
				dateItems: EnumDateItems, // 开始时间的可选项
				dateItemsValue: "", // 选择的开始时间选项的id
				dateStart: "", // 开始时间
				dateEnd: "", // 结束时间
			};
		},
		watch: {
			
			parentId: {
				handler(newList) {
					console.log(123, Number(newList));
					// 是否需要滚动到最底部
					this.activeTargetParentId = newList
					
				},
				immediate: true,
			},
			childrenId: {
				handler(newList) {
					console.log(123, Number(newList));
					// 是否需要滚动到最底部
					this.activeTargetChildrenId = newList

				},
				// deep: true,
				immediate: true,
			},
		},
		
		methods: {
			
			/**
			 * !选择“不限”省份
			 */
			onSelectAllProvince() {
				// 关闭当前展开的省份
				this.closeChildren(".row-group__region");

				// 清空选择的省份id和城市id
				this.activeProvinceId = "";
				this.activeCityId = "";

				// 触发 change 事件
				this.handleChange();
			},

			/**
			 * !"省份"点击事件
			 * @param {Object} province 省份数据
			 */
			onClickProvince(province) {
				// 当前省份为展开状态
				if (this.activeProvinceId == province.id) {
					// 关闭当前展开的省份
					this.closeChildren(".row-group__region");

					// 清空选择的省份id和城市id
					this.activeProvinceId = "";
					this.activeCityId = "";
				}

				// 当前省份为关闭状态
				else {
					// 找到当前为打开状态的父元素
					let openParent = this.findOpenParentDOM(".row-group__region");

					// 更新激活省份的id
					this.activeProvinceId = province.id;

					// 清空激活城市的id
					this.activeCityId = "";

					// 打开当前省份的城市列表
					this.openChildren(".row-group__region", openParent);
				}

				// 触发 change 事件
				this.handleChange();
			},

			/**
			 * !选择“全部”城市
			 */
			onSelectAllCity() {
				this.activeCityId = "";

				// 触发 change 事件
				this.handleChange();
			},

			/**
			 * !"城市"点击事件
			 * @param {Object} city 城市数据
			 */
			onClickCity(city) {
				this.activeCityId = city.id;

				// 触发 change 事件
				this.handleChange();
			},

			/**
			 * !找到当前为打开状态的父元素
			 * @param {String} topicParentClassName 顶级父元素class名称
			 * @return {DOM|undefined} 返回找到的DOM元素，没找到则返回undefined
			 */
			findOpenParentDOM(topicParentClassName) {
				return document.querySelector(topicParentClassName + " .tab-parent.active");
			},

			/**
			 * !展开子类型
			 * @param {String} topicParentClassName 顶级父元素class名称
			 * @param {String} oldOpenParent 切换之前为打开状态的父元素
			 */
			openChildren(topicParentClassName, oldOpenParent) {
				// 更新高度需要等待页面重新渲染完成后
				this.$nextTick(() => {
					let timer = setTimeout(() => {
						// 找到父元素
						let parent = document.querySelector(topicParentClassName + " .tab-parent.active");

						// 找到子元素
						let children = parent.querySelector(".tabs-children");

						// 打开子元素
						parent.style.paddingBottom = children.offsetHeight + "px";

						// 关闭之前展开的父元素
						// 在这里设置时为了解决切换闪烁的问题
						if (oldOpenParent) oldOpenParent.style.paddingBottom = "0px";
					}, 50);
				});
			},

			/**
			 * !收起子类型
			 * @param {String} topicParentClassName 顶级父元素class名称
			 */
			closeChildren(topicParentClassName) {
				// 找到父子元素
				let parent = document.querySelector(topicParentClassName + " .tab-parent.active");

				// 关闭子元素
				parent.style.paddingBottom = "0px";
			},

			/**
			 * !选择“不限”标的类型
			 */
			onSelectAllTargetTypes() {
				// 关闭当前展开的标的类型
				this.closeChildren(".row-group__target-types");

				// 清空选择的标的id和子类型id
				this.activeTargetParentId = "";
				this.activeTargetChildrenId = "";

				// 触发 change 事件
				this.handleChange();
			},

			/**
			 * !"标的类型"点击事件
			 * @param {Object} parent 标的类型数据
			 */
			onClickTargetParent(parent) {
				// 当前标的类型为展开状态
				if (this.activeTargetParentId == parent.id) {
					// 关闭当前展开的标的类型
					this.closeChildren(".row-group__target-types");

					// 清空选择的标的类型id和子类型id
					this.activeTargetParentId = "";
					this.activeTargetChildrenId = "";
				}

				// 当前标的类型为关闭状态
				else {
					// 找到当前为打开状态的父元素
					let openParent = this.findOpenParentDOM(".row-group__target-types");

					// 更新激活标的类型的id
					this.activeTargetParentId = parent.id;

					// 清空激活标的子类型的id
					this.activeTargetChildrenId = "";

					// 打开当前标的类型的子类型列表
					this.openChildren(".row-group__target-types", openParent);
				}

				// 触发 change 事件
				this.handleChange();
			},

			/**
			 * !选择“全部”标的子类型
			 */
			onSelectAllTargetTypeChildren() {
				this.activeTargetChildrenId = "";

				// 触发 change 事件
				this.handleChange();
			},

			/**
			 * !"标的子类型"点击事件
			 * @param {Object} child 子类型数据
			 */
			onClickTargetChild(child) {
				this.activeTargetChildrenId = child.id;

				// 触发 change 事件
				this.handleChange();
			},

			/**
			 * !切换拍卖状态
			 * @param {String|Number} id 切换后的激活的拍卖状态的id
			 */
			onChangeAuctionStatus(id) {
				this.auctionStatusValue = id;

				// 触发 change 事件
				this.handleChange();
			},

			/**
			 * !切换租赁类别
			 * @param {String|Number} id 切换后的激活的租赁类别的id
			 */
			onChangeRentType(id) {
				this.rentTypesValue = id;

				// 触发 change 事件
				this.handleChange();
			},

			/**
			 * !切换产品类别
			 * @param {String|Number} id 切换后的激活的产品类别的id
			 */
			onChangeProductType(id) {
				this.productTypesValue = id;

				// 触发 change 事件
				this.handleChange();
			},

			/**
			 * !切换开始时间选项
			 * @param {String|Number} id 切换后的激活的选项的id
			 */
			onChangeDateItemsValue(id) {
				this.dateItemsValue = id;

				// 选择的不限
				if (id == "") {
					this.dateStart = "";
					this.dateEnd = "";
				}

				// 选择的今天
				else if (id == 1) {
					this.dateStart = new Date();
					this.dateEnd = new Date();
				}

				// 选择的未来3天
				else if (id == 2) {
					let dt = new this.dayjs();
					let start = dt.add(1, "day");
					let end = dt.add(4, "day");
					this.dateStart = start.toDate();
					this.dateEnd = end.toDate();
				}

				// 选择的未来一周
				else if (id == 3) {
					let dt = new this.dayjs();
					let start = dt.add(1, "day");
					let end = dt.add(8, "day");
					this.dateStart = start.toDate();
					this.dateEnd = end.toDate();
				}

				// 触发 change 事件
				this.handleChange();
			},

			/**
			 * !切换开始时间
			 * @param {Date} date 日期对象
			 */
			onChangeStartDate(date) {
				// 将此行激活项设置为自定义
				this.dateItemsValue = -1;

				// 结束时间为空,可以随便选择开始时间
				if (this.dateEnd == "") {
				}

				// 结束时间不为空
				// 并且此时选择的开始时间 >= 结束时间
				else if (this.dateStart > this.dateEnd) {
					this.$message.error("提示:开始时间不能大于结束时间!");
				}

				// 触发 change 事件
				this.handleChange();
			},

			/**
			 * !切换结束时间
			 * @param {Date} date 日期对象
			 */
			onChangeEndDate(date) {
				// 将此行激活项设置为自定义
				this.dateItemsValue = -1;

				// 开始时间为空,可以随便选择结束时间
				if (this.dateStart == "") {
				}

				// 开始时间不为空
				// 并且此时选择的结束时间 <= 开始时间
				else if (this.dateEnd < this.dateStart) {
					this.$message.error("提示:结束时间不能小于开始时间!");
				}

				// 触发 change 事件
				this.handleChange();
			},

			/**
			 * !切换激活项
			 */
			handleChange() {
				this.$emit("change", this.getParams());
			},

			/**
			 * !提交
			 */
			handleSubmit() {
				this.$emit("submit", this.getParams());
			},

			/**
			 * !获取查询条件
			 */
			getParams() {
				return {
					provinceId: this.activeProvinceId,
					cityId: this.activeCityId,
					targetParentId: this.activeTargetParentId,
					targetChildrenId: this.activeTargetChildrenId,
					auctionStatusId: this.auctionStatusValue,
					rentTypesId: this.rentTypesValue,
					productTypesId: this.productTypesValue,
					productTypesParentId: this.activeProductTypesParentId,
					productTypesChildrenId: this.activeProductTypesChildrenId,
					dateStart: this.dateStart,
					dateEnd: this.dateEnd,
				};
			},

			// ##################################################################################################################################
			// ## 求购租赁 - 产品类别
			// ##################################################################################################################################
			// 选择“不限”
			onSelectAllProductTypes() {
				// 关闭当前展开的二级类型
				this.closeChildren(".row-group__product-types");

				// 清空选择的父id + 子id
				this.activeProductTypesParentId = "";
				this.activeProductTypesChildrenId = "";

				// 触发 change 事件
				this.handleChange();
			},

			// 父类型点击事件
			// parent: 父类型类型数据
			onClickProductTypeParent(parent) {
				// 当前的父类型为展开状态
				if (this.activeProductTypesParentId == parent.id) {
					// 关闭当前展开的父类型
					this.closeChildren(".row-group__product-types");

					// 清空选择的父id + 子id
					this.activeProductTypesParentId = "";
					this.activeProductTypesChildrenId = "";
				}

				// 当前父类型为关闭状态
				else {
					// 找到当前为打开状态的父类型
					let openParent = this.findOpenParentDOM(".row-group__product-types");

					// 更新激活标的父类型id
					this.activeProductTypesParentId = parent.id;

					// 清空激活的子类型id
					this.activeProductTypesChildrenId = "";

					// 展开当前被点击的父类型
					this.openChildren(".row-group__product-types", openParent);
				}

				// 触发 change 事件
				this.handleChange();
			},

			// 选择“全部”子类型
			onSelectAllProductTypeChildren() {
				// 清空激活的子类型id
				this.activeProductTypesChildrenId = "";

				// 触发 change 事件
				this.handleChange();
			},

			// 子类型点击事件
			// child: 子类型数据
			onClickProductTypeChild(child) {
				// 更新激活的子类型id
				this.activeProductTypesChildrenId = child.id;

				// 触发 change 事件
				this.handleChange();
			},
		},
	};
</script>

<style lang="scss" scoped>
	.search-filter-container {
		border-top: 1px solid #e9e9e9;
		border-left: 1px solid #e9e9e9;
		border-right: 1px solid #e9e9e9;

		.row-group {
			display: flex;
			border-bottom: 1px solid #e9e9e9;
			background-color: #fbfbfb;
		}

		.row-group-label {
			flex-shrink: 0;
			line-height: 48px;
			width: 120px;
			color: #666666;
			padding-left: 22px;
			border-right: 1px solid #e9e9e9;
			height: 100%;
			margin-right: -1px;
		}

		.row-group-value {
			flex: 1;
			background-color: #ffffff;
			display: flex;
			padding-left: 16px;
			color: #666666;
			border-left: 1px solid #e9e9e9;
		}

		.tabs {
			display: flex;
			flex-wrap: wrap;
			list-style: none;
		}

		.tabs-parent {
			position: relative;
			flex: 1;
		}

		.tab {
			line-height: 48px;
			padding: 0 12px;
			flex-shrink: 0;
			cursor: pointer;
			user-select: none;
			list-style: none;
			transition: background-color 0.2s;

			.tab-content {
				display: flex;
				align-items: center;
			}

			.arrow {
				margin-left: 3px;
				font-size: 12px;
			}

			&.unchild.active {
				color: var(--theme-color);
				font-weight: bold;
			}

			&.tab-unlimited {
				flex-shrink: 0;
			}

			&.tab-row-close {
				flex-shrink: 0;
				color: var(--theme-color);
				opacity: 0;
				transition: 0.2s;
				pointer-events: none;
			}

			&.tab-row-close.active {
				opacity: 1;
				pointer-events: auto;
			}

			&.tab-unlimited.active,
			&.tab-children.active {
				color: var(--theme-color);
				font-weight: bold;
			}

			&.tab-parent.active {
				background-color: #f5f5f5;

				.tab-parent-content {
					color: var(--theme-color);
					font-weight: bold;
				}
			}

			.tabs-children {
				width: 100%;
				overflow: hidden;
				opacity: 0;
				position: absolute;
				left: 0;
				background-color: #f5f5f5;
				z-index: -5;
			}

			&.tab-parent.active {
				.tabs-children {
					z-index: 0;
					opacity: 1;
				}
			}
		}

		.date-wrapper {
			display: flex;
			align-items: center;
			margin-left: 40px;

			.el-date-editor {
				width: 140px;
			}

			.date-separator {
				margin: 0 5px;
			}
		}

		.button-wrapper {
			display: flex;
			align-items: center;
			margin-left: 8px;
		}
	}
</style>

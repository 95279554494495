<template>
    <div class="subpage-mine-basic-info-container">
        <div v-if="nextFlag == 1">
            <div class="title">将“{{ userinfo.nickname }}”账号注销</div>
            <div class="sub-title">注意:注销账号后以下信息将被清空且无法找回</div>
            <div class="info-box">
                <div>·身份、账户信息</div>
                <div>·参拍记录、我的收藏、我的预约</div>
                <div>请确保所有交易已完结且无纠纷（确保没有已报名未参拍的标的）</div>
            </div>
            <div class="prompt">点击【确认注销】即代表您已经同意<span @click="openHandler">《用户注销协议》</span></div>
            <div style="text-align: center;">
                <div class="btn" @click="nextFlag = 2">确认注销</div>
            </div>
        </div>
        <div v-if="nextFlag == 2">
            <div class="title">为确保是您本人操作，请完成以下验证</div>
            <div class="number-box">{{ userinfo.phone }}</div>
            <div class="input-box">
                <el-form class="form" ref="form" :model="form">
                    <el-form-item prop="captcha">
                        <el-input class="input" v-model="form.captcha" maxlength="8" placeholder="请输入验证码"></el-input>
                        <el-button type="primary" plain class="send-sms-button" @click="onClickSendSmsBtn"
                            :disabled="isSendSmsBtnCountdown">{{ sendSmsBtnText }}</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div style="text-align: center;">
                <div class="btn" @click="nextHandler">下一步</div>
            </div>
        </div>
        <div style="text-align: center;" v-if="nextFlag == 3">
            <img src="@/assets/images/success.png" alt="">
            <div style="font-size: 18px;color: #000;margin-top: 10px;">账户注销成功</div>
            <div class="btn">确认</div>
        </div>
        <!-- 注销协议弹窗 -->
        <AgreementDialog ref="rules" title="用户注销协议" :content="account_cancel_agreement"></AgreementDialog>
    </div>
</template>

<script>
import { apiSendSmsCode, apiGetLogoutAgreement } from "@/apis/common.js";
import { apiCancel } from '@/apis/user.js'
import { mapGetters } from "vuex";
import AgreementDialog from "@/components/AgreementDialog.vue"; // 协议弹窗


export default {
    components: {
        AgreementDialog
    },
    data() {
        return {
            form: {
                captcha: "", // 验证码
            },
            sendSmsBtnText: "发送验证码", // 发送验证码按钮上显示的文本
            isSendSmsBtnCountdown: false, // 发送验证码按钮是否正在倒计时
            sendSmsCountdownSeconds: 60, // 发送验证码按钮倒计时总秒数
            nextFlag: 1,
            account_cancel_agreement: '',
        }
    },
    computed: {
        ...mapGetters(["userinfo"]),
    },
    mounted() {
        apiGetLogoutAgreement().then((res => {
            this.account_cancel_agreement = res.finalData.account_cancel_agreement
        })).catch(err => {
            console.error(err);
        })
    },
    methods: {
        nextHandler() {
            apiCancel({ code: this.form.captcha }).then(res => {
                if (res.code == 1000) {
                    this.$store.dispatch("logout");
                    this.$message({
                        type: "success",
                        message: "注销成功!",
                    });
                    setTimeout(() => {
                        window.location.reload();
                    }, 1500);
                }
            }).catch(err => {
                console.error(err);
            })
            console.log(this.userinfo);
        },
        openHandler() {
            this.$refs.rules?.open();
        },
        /**
         * !点击了"发送验证码"按钮
         */
        onClickSendSmsBtn() {
            // 验证手机号字段
            // 验证通过
            // 手机号验证通过
            // 调用接口发送验证码
            apiSendSmsCode(
                {
                    phone: this.userinfo.phone,
                    mode: 'cancel'
                },
                {
                    loadingMsg: "正在发送验证码",
                    successShow: true,
                },
            )
                .then((res) => {
                    // 开始倒计时
                    this.countdownStart();
                })
                .catch((err) => console.error(err));
        },

        /**
         * !开始倒计时
         */
        countdownStart() {
            // 倒计时总秒数
            let seconds = this.sendSmsCountdownSeconds;

            // 更新倒计时状态
            this.isSendSmsBtnCountdown = true;

            // 更新按钮文本
            this.sendSmsBtnText = seconds + "s后重新获取";

            // 设置定时器
            let timer = setInterval(() => {
                // 减秒
                seconds--;

                // 倒计时未结束
                // 更新按钮文本
                if (seconds >= 0) return (this.sendSmsBtnText = seconds + "s后重新获取");

                // 倒计时结束了
                // 更新倒计时文本
                this.sendSmsBtnText = " 重新发送";

                // 更新倒计时状态
                this.isSendSmsBtnCountdown = false;

                // 清除定时器
                clearInterval(timer);
            }, 1000);
        },

    }
}

</script>

<style lang="scss" scoped>
.subpage-mine-basic-info-container {
    background-color: #ffffff;
    min-height: 100%;
    padding: 20px 60px 63px 42px;

    .title {
        color: #000;
        font-size: 34px;
        font-weight: 700;
        margin-bottom: 15px;
    }

    .sub-title {
        color: #bb1c33;
        font-size: 18px;
        margin-bottom: 15px;
    }

    .info-box {
        width: 70%;
        height: auto;
        padding: 20px;
        padding-bottom: 10px;
        background: #f5f5f5;
        border-radius: 15px;
        margin-bottom: 25px;

        div {
            font-size: 16px;
            color: #777777;
            margin-bottom: 10px;
        }
    }

    .prompt {
        font-size: 18px;
        font-weight: 700;
        color: #6d7477;

        span {
            cursor: pointer;
            color: #6cafff;
        }
    }

    .btn {
        cursor: pointer;
        margin-top: 30px;
        display: inline-block;
        padding: 10px 30px;
        color: #fff;
        background: #bb1c33;
        text-align: center;
        font-size: 16px;
        border-radius: 10px;

    }
}


.send-sms-button {
    height: 48px;
    border-radius: 0;
    width: 126px;
    margin-left: 20px;
    background-color: transparent;
    /* border: none; */
}

::v-deep {
    .form {
        .el-input {
            width: 30%;
        }

        .el-input__inner {
            height: 48px;
            border-radius: 0;
            font-size: 16px;
            /* background-color: #f5f5f5; */
            border: none;
            border-bottom: 1px solid #dcdfe6;
        }
    }
}
</style>
<style>
.page-login-container .el-input__inner {
    border-color: transparent;
}

.number-box {
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
}
</style>
<!-- 认证 -->
<template>
	<div class="page-container page-attestation-container">
		<WebHeaderTwo></WebHeaderTwo>
		<div class="page-register-content layout-main">
			<el-tabs v-model="activeName">
				<el-tab-pane label="个人认证" name="tab-1">
					<Personal @pending="$refs.authInReviewDialog?.open()" @pass="$refs.authAdoptDialog?.open()"></Personal>
				</el-tab-pane>
				<el-tab-pane label="企业认证" name="tab-2">
					<Enterprise @pending="$refs.authInReviewDialog?.open()" @pass="$refs.authAdoptDialog?.open()"></Enterprise>
				</el-tab-pane>
			</el-tabs>
		</div>
		<WebFooterTwo></WebFooterTwo>

		<!-- 认证通过弹窗 -->
		<AuthAdoptDialog ref="authAdoptDialog"></AuthAdoptDialog>

		<!-- 认证审核中弹窗 -->
		<AuthInReviewDialog ref="authInReviewDialog"></AuthInReviewDialog>
	</div>
</template>

<script>
import WebHeaderTwo from "@/components/WebHeaderTwo.vue"; // 页面Header
import WebFooterTwo from "@/components/WebFooterTwo.vue"; // 页面Footer
import Personal from "@/views/attestation/Personal.vue"; // 个人认证
import Enterprise from "@/views/attestation/Enterprise.vue"; // 企业认证
import AuthAdoptDialog from "@/components/AuthAdoptDialog.vue"; // 认证通过弹窗
import AuthInReviewDialog from "@/components/AuthInReviewDialog.vue"; // 认证审核中弹窗

export default {
	name: "PageAttestation",
	components: {
		WebHeaderTwo,
		WebFooterTwo,
		Personal,
		Enterprise,
		AuthAdoptDialog,
		AuthInReviewDialog,
	},
	data() {
		return {
			activeName: "tab-1", // 激活的选项卡名称
		};
	},
	mounted() {
		// 默认激活的选项卡
		this.activeName = `tab-${this.$route.query?.type || "1"}`;
	},
	methods: {},
};
</script>

<style scoped lang="scss">
.page-attestation-container {
	min-height: 100vh;
	display: flex;
	flex-direction: column;

	.header {
		flex-shrink: 0;
	}

	.page-register-content {
		flex: 1;
		width: 100%;
		padding: 72px 0 30px;
	}

	.footer {
		flex-shrink: 0;
	}

	::v-deep {
		.el-tabs {
			width: 100%;
		}

		.el-tabs__header {
			margin-bottom: 0;
			border-bottom: 1px solid #f8f8f8;
		}

		.el-tabs__item {
			height: 62px;
			line-height: 62px;
			font-size: 16px;
			width: 110px;
			text-align: center;
			padding: 0 !important;
			position: relative;
			border-bottom: 4px solid transparent;
			transition: border 0.2s;
		}

		.el-tabs__item.is-active {
			background-color: transparent;
			color: var(--theme-color);
			border-bottom: 4px solid var(--theme-color);

			&:hover {
				color: var(--theme-color);
			}
		}

		.el-tabs__active-bar {
			height: 4px;
			display: none;
		}

		.el-tabs__header::after,
		.el-tabs__nav-wrap::after {
			display: none;
		}

		.el-tabs__nav {
			float: none;
			display: flex;
			justify-content: center;
		}
	}
}
</style>

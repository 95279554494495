<!-- 收藏标的列表项 -->
<template>
	<div class="component-collect-target-list-item">
		<div class="left">
			<div class="inner">
				<img class="cover" :src="item.image" alt="" />
				<TargetStatus :status="item?.status" class="status"></TargetStatus>
			</div>
		</div>
		<div class="right">
			<h1 class="name ellipsis2" :title="item.name">{{ item.title }}</h1>

			<!-- 价格 - 可查看 -->
			<div class="text-row" v-if="item?.is_show_price">
				<label class="label">{{ price?.label }}：</label>
				<span class="value price-value markpro">¥{{ price?.value }}</span>
			</div>

			<!-- 价格 - 不可查看 -->
			<div class="text-row text-row-hidden" v-else>
				<label class="label">{{ price?.label }}：</label>
				<span class="value price-value markpro">--</span>
			</div>

			<!-- 时间 -->
			<div class="text-row">
				<label class="label">{{ date?.label }}：</label>
				<span class="value">{{ date?.value }}</span>
			</div>
		</div>
	</div>
</template>

<script>
	import TargetStatus from "@/components/TargetStatus.vue";
	import { EnumTargetStatus } from "@/const.js";
	import { getTargetShowPriceDataByStatus, getTargetShowDateDataByStatus } from "@/common/functions/custom.js";
	export default {
		name: "CollectTargetListItem",
		components: {
			TargetStatus,
		},
		props: {
			item: Object,
		},
		data() {
			return {
				targetStatus: EnumTargetStatus,
			};
		},
		computed: {
			price() {
				return getTargetShowPriceDataByStatus(this.item);
			},
			date() {
				return getTargetShowDateDataByStatus(this.item);
			},
		},
		mounted() {},
		methods: {},
	};
</script>

<style lang="scss" scoped>
	.component-collect-target-list-item {
		display: flex;
		background-color: #f5f5f5;
		align-items: center;
		position: relative;
		cursor: pointer;
		transition: 0.2s;

		&::after {
			content: "";
			width: 100%;
			height: 100%;
			position: absolute;
			left: 0;
			top: 0;
			border: 2px solid transparent;
			z-index: 10;
			transition: 0.2s;
		}

		.left {
			flex-shrink: 0;
			width: 290px;

			.inner {
				padding-bottom: 56.55%;
				background-color: #dedede;
				position: relative;
				overflow: hidden;
			}

			.cover {
				display: block;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				z-index: 0;
				object-fit: cover;
				position: absolute;
			}

			.status {
				width: 80px;
				height: 33px;
				position: absolute;
				left: 0;
				top: 0;
				z-index: 2;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}

		.right {
			padding: 0 60px;

			.name {
				font-size: 18px;
				font-weight: normal;
				margin-bottom: 20px;
			}

			.text-row {
				color: #999999;
				margin-bottom: 7px;

				&-hidden {
					opacity: 0;
					visibility: hidden;
					user-select: none;
				}
			}

			.price-value {
				font-size: 24px;
				color: var(--theme-color);
			}

			.markpro {
				font-family: "MarkPro";
			}
		}

		&:hover {
			transform: scale(0.99);

			&::after {
				border-color: var(--theme-color);
			}
		}
	}
</style>

<!-- 个人中心 - 修改密码 -->
<template>
	<div class="subpage-container subpage-mine-change-pwd-container">
		<h1 class="module-title">修改密码</h1>
		<el-form class="form" ref="form" :model="form" label-width="108px" label-position="right" :rules="rules">
			<!-- <el-form-item label="绑定手机号：">
				<div class="phonenumber-wrapper">
					<span>{{ phonenumber }}</span>
					<el-button type="primary" plain class="send-sms-button" @click="onClickSendSmsBtn" :disabled="isSendSmsBtnCountdown">{{ sendSmsBtnText }}</el-button>
				</div>
			</el-form-item> -->
			<!-- <el-form-item label="验证码：" prop="captcha">
				<el-input v-model="form.captcha" placeholder="请输入验证码" style="width: 282px"></el-input>
			</el-form-item> -->
			<el-form-item label="您的新密码：" prop="newPwd">
				<el-input show-password v-model="form.newPwd" placeholder="请输入新密码" style="width: 282px"></el-input>
			</el-form-item>
			<el-form-item label="确认新密码：" prop="newPwdAgain">
				<el-input show-password v-model="form.newPwdAgain" placeholder="请再次输入新密码" style="width: 282px"></el-input>
			</el-form-item>
		</el-form>
		<el-button type="primary" class="submit-button" @click="onClickSubmit">确认重置</el-button>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import { apiSendSmsCode } from "@/apis/common.js";
import { apiEditPassword } from "@/apis/user.js";
import { validatePass2, phonenumberPrivatization } from "@/utils/func.js";

export default {
	name: "PageMineChangePwd",
	computed: {
		...mapGetters(["userinfo"]),

		// 手机号脱敏处理
		phonenumber() {
			return phonenumberPrivatization(this.userinfo?.phone);
		},
	},
	components: {},
	data() {
		return {
			// 表单数据
			form: {
				// captcha: "", // 验证码
				newPwd: "", // 新密码
				newPwdAgain: "", // 确认新密码
			},
			// 表单验证规则
			rules: {
				// captcha: [{ required: true, message: "请填写验证码" }],
				newPwd: [{ required: true, message: "请填写新密码" }],
				newPwdAgain: [{ required: true, message: "请填写确认新密码" }, { validator: (rule, value, callback) => validatePass2(rule, value, callback, this.form.newPwd) }],
			},
			// sendSmsBtnText: "发送验证码", // 发送验证码按钮上显示的文本
			// isSendSmsBtnCountdown: false, // 发送验证码按钮是否正在倒计时
			// sendSmsCountdownSeconds: 60, // 发送验证码按钮倒计时总秒数
		};
	},
	mounted() {},
	methods: {
		/**
		 * !点击了"发送验证码"按钮
		 */
		// onClickSendSmsBtn() {
		// 	// 调用接口发送验证码
		// 	apiSendSmsCode(
		// 		{
		// 			phone: this.userinfo?.phone,
		// 		},
		// 		{
		// 			loadingMsg: "正在发送验证码",
		// 			successShow: true,
		// 		},
		// 	)
		// 		.then((res) => {
		// 			// 开始倒计时
		// 			this.countdownStart();
		// 		})
		// 		.catch((err) => {
		// 			console.error(err);
		// 		});
		// },

		/**
		 * !开始倒计时
		 */
		// countdownStart() {
		// 	// 倒计时总秒数
		// 	let seconds = this.sendSmsCountdownSeconds;

		// 	// 更新倒计时状态
		// 	this.isSendSmsBtnCountdown = true;

		// 	// 更新按钮文本
		// 	this.sendSmsBtnText = seconds + "s";

		// 	// 设置定时器
		// 	let timer = setInterval(() => {
		// 		// 减秒
		// 		seconds--;

		// 		// 倒计时未结束
		// 		// 更新按钮文本
		// 		if (seconds >= 0) return (this.sendSmsBtnText = seconds + "s");

		// 		// 倒计时结束了
		// 		// 更新倒计时文本
		// 		this.sendSmsBtnText = " 重新发送";

		// 		// 更新倒计时状态
		// 		this.isSendSmsBtnCountdown = false;

		// 		// 清除定时器
		// 		clearInterval(timer);
		// 	}, 1000);
		// },

		/**
		 * !点击了"确认重置"按钮
		 */
		onClickSubmit() {
			this.$refs.form.validate((valid) => {
				// 表单验证通过
				if (valid) {
					// 提交修改新密码
					this.onSubmitChange();
				}
			});
		},

		/**
		 * !提交修改新密码
		 */
		onSubmitChange() {
			apiEditPassword(
				{
					password: this.form.newPwd,
				},
				{
					loadingMsg: "正在更新密码",
					successShow: true,
				},
			)
				.then((res) => {})
				.catch((err) => console.error(err));
		},
	},
};
</script>

<style scoped lang="scss">
.subpage-mine-change-pwd-container {
	background-color: #ffffff;
	min-height: 100%;
	padding: 0 60px 46px 42px;

	.module-title {
		font-size: 24px;
		line-height: 1;
		font-weight: normal;
		padding: 39px 0 21px;
		border-bottom: 1px solid #fafafa;
	}

	.form {
		margin-top: 40px;
	}

	::v-deep {
		.el-form-item {
			padding-top: 0;
			padding-bottom: 0;
			margin-bottom: 18px;
		}
	}

	.send-sms-button {
		width: 100px;
		height: 32px;
		padding: 0;
		margin-left: 24px;
	}

	.submit-button {
		width: 102px;
		height: 44px;
		padding: 0;
		font-size: 16px;
		margin-top: 20px;
	}
}
</style>

<template>
	<div class="footer">
		<div class="numbers-container">
			<!-- <div class="numbers-wrapper layout-main">
				<div class="numbers-item">
					<i class="zn-iconfont numbers-item-icon zn-iconfont-leijipaimaihui"></i>
					<div class="numbers-item-right">
						<span class="numbers-item-value">{{ companyInfo?.auction_count || "0+" }}</span>
						<span class="numbers-item-label">累积拍卖会</span>
					</div>
				</div>
				<div class="numbers-item">
					<i class="zn-iconfont numbers-item-icon zn-iconfont-leijibiaode"></i>
					<div class="numbers-item-right">
						<span class="numbers-item-value">{{ companyInfo?.auction_item_count || "0+" }}</span>
						<span class="numbers-item-label">累积标的</span>
					</div>
				</div>
				<div class="numbers-item">
					<i class="zn-iconfont numbers-item-icon zn-iconfont-yiruzhuqiye"></i>
					<div class="numbers-item-right">
						<span class="numbers-item-value">{{ companyInfo?.settled_enterprise_count || "0+" }}</span>
						<span class="numbers-item-label">已入驻企业</span>
					</div>
				</div>
				<div class="numbers-item">
					<i class="zn-iconfont numbers-item-icon zn-iconfont-yichengjiaoanli"></i>
					<div class="numbers-item-right">
						<span class="numbers-item-value">{{ companyInfo?.transactions_count || "0+" }}</span>
						<span class="numbers-item-label">已成交案例</span>
					</div>
				</div>
			</div> -->
			<div class="add-link-box layout-main">
				<div class="title">友情链接</div>
				<div class="link-box">
					<!-- <a href="https://www.mysteel.com/" target="_blank" class="link" v-for="item ,index in 1" :key="index">我的钢铁网</a> -->
					<div v-if="companyInfo?.friendship_link1" class="link" v-html="companyInfo?.friendship_link1"></div>
					<div v-if="companyInfo?.friendship_link2" class="link" v-html="companyInfo?.friendship_link2"></div>
					<div v-if="companyInfo?.friendship_link3" class="link" v-html="companyInfo?.friendship_link3"></div>
					<div v-if="companyInfo?.friendship_link4" class="link" v-html="companyInfo?.friendship_link4"></div>
					<!-- <a href="https://www.ccmn.cn/" target="_blank" class="link">长江有色金属网</a> -->
				</div>
			</div>
		</div>
		<div class="middle-container">
			<div class="middle-wrapper layout-main">
				<div class="middle-left">
					<router-link to="/" class="logo-link">
						<img :src="companyInfo?.view_logo" alt="" class="logo" />
					</router-link>
					<div class="navs-wrapper">
						<div class="navs-module" v-for="(module, index) in navs" :key="index">
							<label class="middle-title">{{ module.title }}</label>
							<ul class="navs-list">
								<li class="navs-item" v-for="(item, current) in module.list" :key="current">
									<router-link :to="item.to" class="item-link">{{ item.name }}</router-link>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div class="middle-right">
					<div class="company-info-wrapper">
						<label class="middle-title">联系我们</label>
						<div class="company-info-group">
							<label class="company-info-group-label">客服热线：</label>
							<a class="company-info-group-value" :href="'tel:' + companyInfo?.customer_tel || ''">{{
							format400Tel(companyInfo?.customer_tel || "") }}</a>
						</div>
						<div class="company-info-group" v-if="companyInfo?.view_email">
							<label class="company-info-group-label">邮箱：</label>
							<a class="company-info-group-value" :href="'mailto:' + companyInfo?.view_email || ''">{{
							companyInfo?.view_email }}</a>
						</div>
					</div>
					<div class="qrcode-wrapper">
						<label class="middle-title">关注我们</label>
						<div class="qrcode-groups">
							<div class="qrcode-group">
								<img class="qrcode-image" :src="companyInfo?.wechat_offiaccount_qr_img" alt="" />
								<label class="qrcode-label">关注公众号</label>
							</div>
							<div class="qrcode-group">
								<img class="qrcode-image" :src="companyInfo?.download_app_img" alt="" />
								<label class="qrcode-label">下载app</label>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="bottom-container">
			<div class="bottom-wrapper layout-main">
				<div class="bottom-left">
					<div class="link" v-html="companyInfo?.view_copyright"></div>
				</div>
				<div class="bottom-right">
					<div class="link" v-html="companyInfo?.website_filing1"></div>
					<span>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</span>
					<div class="link" v-html="companyInfo?.website_filing2"></div>
					<span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
					<span class="text">{{ companyInfo?.company_name }}</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from "vuex";
export default {
	name: "WebFooter",
	data() {
		return {
			// 导航菜单
			navs: [
				{
					title: "帮助中心",
					list: [
						{ name: "竞拍流程", to: { name: "HelpCenterIndex", query: { title: "竞拍流程" } } },
						// { name: "收费规则", to: { name: "HelpCenterIndex", query: { title: "收费规则" } } },
						{ name: "出价规则", to: { name: "HelpCenterIndex", query: { title: "出价规则" } } },
						{ name: "成交规则", to: { name: "HelpCenterIndex", query: { title: "成交规则" } } },
					],
				},
				{
					title: "公司简介",
					list: [
						{ name: "公司介绍", to: { name: "ZhongNuoAuction", query: { anchor: "公司介绍" } } },
						{ name: "企业优势", to: { name: "ZhongNuoAuction", query: { anchor: "企业优势" } } },
						{ name: "企业合作", to: { name: "ZhongNuoAuction", query: { anchor: "企业合作" } } },
					],
				},
			],
		};
	},
	computed: mapState(["companyInfo"]),
};
</script>

<style scoped lang="scss">
.footer {
	background-color: #333333;

	.numbers-container {
		.numbers-wrapper {
			padding-top: 32px;
			padding-bottom: 28px;
			border-bottom: 2px solid #575757;
			display: flex;
			justify-content: space-between;
			color: rgba($color: #ffffff, $alpha: 0.4);
		}

		.numbers-item {
			display: flex;
			height: 76px;
			overflow: hidden;
		}

		.numbers-item-icon {
			width: 76px;
			height: 76px;
			border: 1px solid rgba($color: #ffffff, $alpha: 0.2);
			border-radius: 50%;
			text-align: center;
			line-height: 76px;
			font-size: 30px;
			flex-shrink: 0;
		}

		.numbers-item-right {
			margin-left: 18px;
			display: flex;
			height: 76px;
			flex-direction: column;
			justify-content: space-between;
			padding: 10px 0;
		}

		.numbers-item-value {
			font-size: 36px;
			color: var(--theme-color);
			font-family: "MarkPro";
			line-height: 1;
		}
	}

	.middle-wrapper {
		padding-top: 40px;
		padding-bottom: 69px;
		display: flex;
		color: rgba($color: #ffffff, $alpha: 0.5);
		line-height: 1;

		.middle-title {
			font-size: 16px;
			color: #ffffff;
			margin-bottom: 32px;
			display: block;
		}

		.middle-left {
			flex: 1;
			display: flex;

			.logo-link {
				display: block;
				flex-shrink: 0;
				margin-right: 15px;
				/* margin-top: 6px; */

				.logo {
					height: 45px;
				}
			}

			.navs-wrapper {
				flex: 1;
				display: flex;
				justify-content: flex-end;
				padding-right: 50px;

				.navs-module {
					margin-right: 59px;

					&:last-child {
						margin-right: 0;
					}
				}

				.navs-list {
					list-style: none;
				}

				.navs-item {
					margin-bottom: 14px;
				}

				.item-link {
					color: inherit;
					font-size: inherit;
					text-decoration: none;

					&:hover {
						color: var(--theme-color);
					}
				}
			}
		}

		.middle-right {
			width: 56.66%;
			border-left: 1px solid #575757;
			padding-left: 50px;
			display: flex;
			justify-content: space-between;

			.company-info-group {
				margin-bottom: 34px;

				&:last-child {
					margin-bottom: 0;
				}
			}

			.company-info-group-label {
				display: block;
				margin-bottom: 14px;
			}

			.company-info-group-value {
				font-size: 24px;
				color: #ffffff;
				text-decoration: none;
				cursor: pointer;
				transition: 0.2s;
				font-family: "MarkPro-Book";

				&:hover {
					text-decoration: underline;
				}
			}

			.qrcode-wrapper {
				.middle-title {
					margin-bottom: 20px;
					text-align: center;
				}
			}

			.qrcode-groups {
				display: flex;
			}

			.qrcode-group {
				margin: 0 6px;
			}

			.qrcode-image {
				display: block;
				width: 122px;
				height: 122px;
			}

			.qrcode-label {
				display: block;
				text-align: center;
				margin-top: 20px;
			}
		}
	}

	.bottom-container {
		background-color: #272828;
		line-height: 1;

		.bottom-wrapper {
			padding: 1.14em 0;
			color: rgba($color: #ffffff, $alpha: 0.3);
			display: flex;
		}

		.link {
			color: inherit;
			text-decoration: none;
			cursor: pointer;

			&:hover {
				text-decoration: underline;
			}
		}

		.bottom-left {
			flex: 1;
		}

		.bottom-right {
			flex-shrink: 0;
			display: flex;
			align-items: center;
		}
	}
}
</style>
<style lang="scss">
.bottom-container a {
	color: inherit;
	text-decoration: none;
	cursor: pointer;

	&:hover {
		text-decoration: underline;
	}
}

.add-link-box {
	padding-top: 20px;
	padding-bottom: 20px;
	display: flex;
	align-items: center;
	border-bottom: 2px solid #575757;

	.title {
		font-size: 34px;
		color: #fff;
		margin-right: 145px;
	}

	.link-box {
		display: flex;
		align-items: center;

		.link a{
			font-size: 20px;
			color: #fff;
			text-decoration: none;
			margin-right: 30px;
		}
		.link a:last-child{
			margin-right: 0;
		}

		.link a:hover {
			color: var(--theme-color);
		}
	}
}
</style>

/**
 * ****************************************************************************************************
 * 状态码处理函数集
 * @param {Object} config 合并后的请求配置项
 * @param {Object} code 状态码
 * ****************************************************************************************************
 */
import store from "@/store/index.js";

export const handleCodes = (config, code) => {
	// 900: 登录过期
	if (code == 900) {
		// 清除用户信息和登录凭证
		store.dispatch("logout");
		return;
	}
};

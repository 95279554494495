import { apiAuthKey } from "@/request/config.js";

export default {
	// 退出登录
	logout(context) {
		// 清除登录凭证
		localStorage.removeItem(apiAuthKey?.toUpperCase());
		localStorage.removeItem('auction')

		// 清除用户信息
		context.commit("clearUserinfo");
	},
};

<!-- 个人中心 - 基本资料 -->
<template>
	<div class="subpage-container subpage-mine-basic-info-container">
		<h1 class="module-title">基本资料编辑</h1>
		<el-form ref="form" :model="form" label-width="122px" label-position="right" :rules="rules">
			<el-form-item label="用户头像：" prop="avatar">
				<div class="upload-box">
					<el-upload
						class="avatar-uploader"
						:action="action"
						:show-file-list="false"
						:on-success="(res, file) => handleUploadPicSuccess(res, file, 'avatar')"
						:before-upload="beforePicUpload"
						:data="postData"
					>
						<img :src="form.avatar || require('@/assets/images/avatar-default.png')" class="avatar" />
						<span class="mask">修改头像</span>
					</el-upload>
				</div>
			</el-form-item>
			<el-form-item label="用户昵称：" prop="nickname">
				<el-input v-model="form.nickname" placeholder="请输入用户昵称" style="width: 282px"></el-input>
			</el-form-item>
			<el-form-item label="手机号：">
				<el-input v-model="form.tell" style="width: 282px" disabled></el-input>
			</el-form-item>
			<el-form-item label="意向城市：" prop="region">
				<el-cascader class="cascader" v-model="form.region" :options="region" :props="setting" placeholder="请选择您的意向城市"></el-cascader>
			</el-form-item>

		</el-form>
		<el-button type="primary" class="save-button" @click="onClickSave">保存</el-button>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import { validateBeforeUploadImage, createFileName } from "@/utils/func.js";
import { apiGetQiniuConfig } from "@/apis/common.js";
import { apiEditUserinfo,apiGetUserPassPortInfo,apiGetRegionData } from "@/apis/user.js";
import region from "@/assets/region.js";


export default {
	name: "PageMineBasicInfo",
	components: {},
	computed: {
		...mapGetters(["userinfo"]),
	},
	data() {
		return {
			// 表单数据
			form: {
				avatar: "", // 用户头像
				nickname: "", // 用户昵称
				region: [], // 地区
				tell:'',//手机号
			},
			region: [], // 地区
			// 表单验证规则
			rules: {
				avatar: [{ required: true, message: "请选择头像" }],
				nickname: [{ required: true, message: "请填写昵称" }],
				region: [
						{ required: true, message: "请选择意向城市" },
						{
							validator: (rule, value, callback) => {
								if (value.length < 2) {
									callback(new Error("请选择意向城市"));
								} else {
									callback();
								}
							},
						},
					],
			},
			action: "", // 七牛直传地址
			prefixUrl: "", // 上传到的七牛空间地址
			postData: {
				key: "", // 文件名
				token: "", // 七牛上传token
			},
			setting:{
				value:'id',
				label:'name'
			}
		};
	},
	mounted() {
		// 设置表单默认数据
		this.form.avatar = this.userinfo?.head_img;
		this.form.nickname = this.userinfo?.nickname;
		this.form.tell = this.userinfo?.phone;
		this.queryUserPassPortInfo()
		this.queryRegionData()

		// 处理地区数据，删除区级数据
		// region.forEach((province) => {
		// 	province.children.forEach((city) => {
		// 		delete city.children;
		// 	});
		// });
		// this.region = region;
		// 获取用户意向城市id
	},
	methods: {
		queryRegionData() {
			apiGetRegionData().then(res => {
				console.log('region', res);
				this.region = res.finalData
			}).catch(err => {
				console.error(err);
			})
		},
		/**
		 * !查询七牛配置信息
		 */
		queryQiniuConfig() {
			return new Promise((resolve, reject) => {
				apiGetQiniuConfig()
					.then((res) => {
						// 七牛token
						this.postData.token = res?.finalData?.token;

						// 七牛直传地址
						this.action = res?.finalData?.endpoint_url;

						// 上传到的七牛空间地址
						this.prefixUrl = res?.finalData?.prefix_url;

						resolve();
					})
					.catch((err) => {
						console.error(err);
						reject();
					});
			});
		},

		/**
		 * !图片上传成功的回调函数
		 * @param {Response} res 上传成功后返回的Response对象
		 * @param {File} file 文件对象
		 * @param {String} picFieldKey 图片字段的key
		 */
		handleUploadPicSuccess(res, file, picFieldKey) {
			console.log(res);
			// 更新图片路径
			this.form[picFieldKey] = this.prefixUrl + "/" + res?.key;
		},

		/**
		 * !图片上传之前的回调函数
		 * @param {File} file 文件对象
		 */
		async beforePicUpload(file) {
			// 保证文件名唯一性
			// this.postData.key = createFileName(file);
			this.postData.key = file;

			// 校验文件类型和文件大小的结果
			let result = validateBeforeUploadImage(file);

			// 校验文件类型和文件大小通过
			if (result) {
				try {
					// 查询七牛配置信息
					await this.queryQiniuConfig();

					// 获取七牛配置信息成功,可以上传
					result = true;
				} catch (error) {
					// 获取七牛配置信息失败,无法上传
					result = false;
				}
			}

			// 返回最终结果
			return result;
		},

		/**
		 * !点击了"保存"按钮
		 */
		onClickSave() {
			this.$refs.form.validate((valid) => {
				// 表单验证通过
				if (valid) {
					// 请求接口保存用户信息
					this.onSave();
				} else {
					console.error("表单验证失败");
					return false;
				}
			});
		},

		/**
		 * !请求接口保存用户信息
		 */
		onSave() {
			console.log(this.form);
			// return
			apiEditUserinfo(
				{
					nickname: this.form.nickname,
					head_img: this.form.avatar,
					province_id: Number(this.form.region[0]),
					city_id: Number(this.form.region[1]),
				},
				{
					loadingMsg: "更新中",
					successShow: true,
					successMsg: "用户信息更新成功!",
				},
			)
				.then((res) => {
					// 更新用户信息
					this.$store.commit("updateUserinfo", res?.finalData);
				})
				.catch((err) => console.error(err));
		},

		/**
		 * !请求接口获取用户意向城市信息
		 */
		 queryUserPassPortInfo(){
			apiGetUserPassPortInfo().then(res => {
				console.log('res',res);
				// 意向城市回显
				this.form.region = [res.finalData.province_id,res.finalData.city_id]
			}).catch(err => {
				console.error(err);
			})
		 }
	},
};
</script>

<style scoped lang="scss">
::v-deep .el-input.is-disabled .el-input__inner {
	color: #606266;
}
.subpage-mine-basic-info-container {
	background-color: #ffffff;
	min-height: 100%;
	padding: 0 60px 63px 42px;

	.module-title {
		font-size: 24px;
		line-height: 1;
		font-weight: normal;
		padding: 39px 0 21px;
	}

	.upload-box {
		width: 140px;
		height: 140px;
		border-radius: 50%;
		overflow: hidden;
		position: relative;

		::v-deep {
			.avatar-uploader {
				width: 100%;
				height: 100%;
				border-radius: inherit;
			}

			.el-upload {
				width: 100%;
				height: 100%;
				border-radius: inherit;
			}

			.el-upload--text {
				background-color: #f2f3f4;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 24px;
				font-weight: bold;
				color: #999999;
			}

			.avatar {
				width: 100%;
				height: 100%;
				border-radius: inherit;
				object-fit: cover;
			}
		}

		.mask {
			position: absolute;
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 16px;
			color: #ffffff;
			background-color: rgba(0, 0, 0, 0.5);
		}
	}

	::v-deep {
		.el-form-item {
			padding-top: 18px;
			padding-bottom: 18px;
			border-top: 1px solid #fafafa;
			margin-bottom: 0;
		}
	}

	.save-button {
		width: 102px;
		height: 44px;
		padding: 0;
		font-size: 16px;
		margin-top: 20px;
	}
}
</style>

<!-- 入住企业列表(块级列表) -->
<template>
	<div class="component-settled-enterprise-block-list">
		<div class="wrapper">
			<div class="item" v-for="(item, index) in list" :key="index" @click="onClickItem(item)">
				<SettledEnterpriseBlockListItem :item="item"></SettledEnterpriseBlockListItem>
			</div>
		</div>
	</div>
</template>

<script>
import SettledEnterpriseBlockListItem from "@/components/SettledEnterpriseBlockListItem.vue";
export default {
	name: "SettledEnterpriseBlockList",
	components: {
		SettledEnterpriseBlockListItem,
	},
	props: {
		list: Array,
	},
	methods: {
		/**
		 * !item点击事件
		 * @param {Object} item item数据
		 */
		onClickItem(item) {
			// 跳转到入驻企业详情页
			this.$router.push({ name: "SettledEnterpriseDetails", query: { id: item.id } });
		},
	},
};
</script>

<style lang="scss" scoped>
.component-settled-enterprise-block-list {
	.item {
		margin-bottom: 16px;
	}
}
</style>

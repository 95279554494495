<!-- 标的富文本模块 -->
<template>
	<div class="component-target-richtext">
		<div class="content" v-html="content"></div>
	</div>
</template>

<script>
	export default {
		name: "TargetRichText",
		props: {
			content: String, // 富文本内容
		},
	};
</script>

<style lang="scss" scoped>
	.component-target-richtext {
		.content {
			line-height: 1.8;
			color: #666666;
		}
	}
</style>

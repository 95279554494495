<!-- 标的介绍模块 -->
<template>
	<div class="component-target-intro">
		
		<!-- 机车数据 -->
		<div class="fields" v-if="ptypeId == 1">
			<div v-if="vehicle?.is_pack == 20">
				<div class="fields_row">
					<div class="fields_row_item">
						<label class="fields_row_item_label">机动车行驶证：</label>
						<span class="fileds_row_item_value" v-if="vehicle?.is_vehicle_license == 10">有</span>
						<span class="fileds_row_item_value" v-else-if="vehicle?.is_vehicle_license == 20">无</span>
						<span class="fileds_row_item_value" v-else>--</span>
						<div class="fields_row_item_link"
							v-if="vehicle?.is_vehicle_license == 10 && vehicle?.vehicle_license_image">
							点击查看证件
							<el-image class="fields_row_item_link_image"
								:src="vehicle?.vehicle_license_image?.split(',')?.[0]"
								:preview-src-list="vehicle?.vehicle_license_image?.split(',')">
							</el-image>
						</div>
					</div>
					<div class="fields_row_item">
						<label class="fields_row_item_label">是否正常启动：</label>
						<span class="fileds_row_item_value" v-if="vehicle?.is_normal_startup == 10">是</span>
						<span class="fileds_row_item_value" v-else-if="vehicle?.is_normal_startup == 20">否</span>
						<span class="fileds_row_item_value" v-else-if="vehicle?.is_normal_startup == 30">不确定</span>
						<span class="fileds_row_item_value" v-else>--</span>
					</div>
					<div class="fields_row_item">
						<label class="fields_row_item_label">是否为报废车：</label>
						<span class="fileds_row_item_value" v-if="vehicle?.is_scrap == 10">是</span>
						<span class="fileds_row_item_value" v-else-if="vehicle?.is_scrap == 20">否</span>
						<span class="fileds_row_item_value" v-else-if="vehicle?.is_scrap == 30">不确定</span>
						<span class="fileds_row_item_value" v-else>--</span>
					</div>
				</div>
				<div class="fields_row">
					<div class="fields_row_item">
						<label class="fields_row_item_label">机动车登记证：</label>
						<span class="fileds_row_item_value" v-if="vehicle?.is_registration == 10">有</span>
						<span class="fileds_row_item_value" v-else-if="vehicle?.is_registration == 20">无</span>
						<span class="fileds_row_item_value" v-else>--</span>
						<span class="fields_row_item_link"
							v-if="vehicle?.is_registration == 10 && vehicle?.registration_image">
							点击查看证件
							<el-image class="fields_row_item_link_image" :src="vehicle?.registration_image.split(',')?.[0]"
								:preview-src-list="vehicle?.registration_image.split(',')">
							</el-image>
						</span>
					</div>
					<div class="fields_row_item">
						<label class="fields_row_item_label">是否为水泡车：</label>
						<span class="fileds_row_item_value" v-if="vehicle?.is_water == 10">是</span>
						<span class="fileds_row_item_value" v-else-if="vehicle?.is_water == 20">否</span>
						<span class="fileds_row_item_value" v-else-if="vehicle?.is_water == 30">不确定</span>
						<span class="fileds_row_item_value" v-else>--</span>
					</div>
					<div class="fields_row_item">
						<label class="fields_row_item_label">是否为火烧车：</label>
						<span class="fileds_row_item_value" v-if="vehicle?.is_fire == 10">是</span>
						<span class="fileds_row_item_value" v-else-if="vehicle?.is_fire == 20">否</span>
						<span class="fileds_row_item_value" v-else-if="vehicle?.is_fire == 30">不确定</span>
						<span class="fileds_row_item_value" v-else>--</span>
					</div>
				</div>
				<div class="fields_row">
					<div class="fields_row_item">
						<label class="fields_row_item_label">车辆识别代码VIN：</label>
						<span class="fileds_row_item_value">{{ vehicle?.vin_code }}</span>
					</div>
					<div class="fields_row_item">
						<label class="fields_row_item_label">是否为改装车：</label>
						<span class="fileds_row_item_value" v-if="vehicle?.is_refit == 10">是</span>
						<span class="fileds_row_item_value" v-else-if="vehicle?.is_refit == 20">否</span>
						<span class="fileds_row_item_value" v-else-if="vehicle?.is_refit == 30">不确定</span>
						<span class="fileds_row_item_value" v-else>--</span>
					</div>
					<div class="fields_row_item">
						<label class="fields_row_item_label">是否为事故车：</label>
						<span class="fileds_row_item_value" v-if="vehicle?.is_collision == 10">是</span>
						<span class="fileds_row_item_value" v-else-if="vehicle?.is_collision == 20">否</span>
						<span class="fileds_row_item_value" v-else-if="vehicle?.is_collision == 30">不确定</span>
						<span class="fileds_row_item_value" v-else>--</span>
					</div>
				</div>
				<div class="fields_row">
					<div class="fields_row_item">
						<label class="fields_row_item_label">车辆牌照：</label>
						<span class="fileds_row_item_value">{{ vehicle?.license_plates }}</span>
					</div>
					<div class="fields_row_item">
						<label class="fields_row_item_label">车辆来源：</label>
						<span class="fileds_row_item_value" v-if="vehicle?.source_mode == 10">个人</span>
						<span class="fileds_row_item_value" v-else-if="vehicle?.source_mode == 20">私营企业</span>
						<span class="fileds_row_item_value" v-else-if="vehicle?.source_mode == 30">国有企业</span>
						<span class="fileds_row_item_value" v-else-if="vehicle?.source_mode == 40">政府机关</span>
						<span class="fileds_row_item_value" v-else>其它</span>
					</div>
					<div class="fields_row_item">
						<label class="fields_row_item_label">营运性质：</label>
						<span class="fileds_row_item_value" v-if="vehicle?.use_mode == 10">运营</span>
						<span class="fileds_row_item_value" v-else-if="vehicle?.use_mode == 20">非运营</span>
						<span class="fileds_row_item_value" v-else-if="vehicle?.use_mode == 30">不确定</span>
						<span class="fileds_row_item_value" v-else>--</span>
					</div>
				</div>
				<div class="fields_row">
					<div class="fields_row_item">
						<label class="fields_row_item_label">鉴定报告：</label>
						<span class="fileds_row_item_value" v-if="vehicle?.is_probation_report == 10">有</span>
						<span class="fileds_row_item_value" v-else-if="vehicle?.is_probation_report == 20">无</span>
						<span class="fileds_row_item_value" v-else>--</span>
					</div>
					<div class="fields_row_item">
						<label class="fields_row_item_label">发动机号：</label>
						<span class="fileds_row_item_value">{{ vehicle?.engine_number }}</span>
					</div>
					<div class="fields_row_item">
						<label class="fields_row_item_label">登记所在地：</label>
						<span class="fileds_row_item_value">{{ vehicle?.registration_address }}</span>
					</div>
				</div>
				<div class="fields_row">
					<div class="fields_row_item">
						<label class="fields_row_item_label">评估报告：</label>
						<span class="fileds_row_item_value" v-if="vehicle?.is_appraisal_report == 10">有</span>
						<span class="fileds_row_item_value" v-else-if="vehicle?.is_appraisal_report == 20">无</span>
						<span class="fileds_row_item_value" v-else>--</span>
					</div>
					<div class="fields_row_item">
						<label class="fields_row_item_label">年检有效期：</label>
						<span class="fileds_row_item_value">{{ vehicle?.yearly_inspection_expire_date }}</span>
					</div>
				</div>
			</div>
			<div class="annex-wrapper" v-else>
				<h1 class="annex-title">打包拍卖附件</h1>
				<ul class="annex-list">
					<li class="annex-item" v-if="vehicle.pack_annex">
						<a :href="`${vehicle.pack_annex}?attname=${vehicle.pack_annex_name}`" class="annex-item-link"
							target="_blank" :download="vehicle.pack_annex_name">
							{{ vehicle.pack_annex_name }}
						</a>
					</li>
				</ul>
			</div>
		</div>

		<!-- 房产数据 -->
		<div class="fields" v-if="ptypeId == 2">
			<div class="fields_row">
				<div class="fields_row_item">
					<label class="fields_row_item_label">房屋用途：</label>
					<span class="fileds_row_item_value" v-if="house?.house_purpose == 10">住宅</span>
					<span class="fileds_row_item_value" v-else-if="house?.house_purpose == 20">商业</span>
					<span class="fileds_row_item_value" v-else>--</span>
				</div>
				<div class="fields_row_item">
					<label class="fields_row_item_label">土地性质：</label>
					<span class="fileds_row_item_value" v-if="house?.land_ment == 10">国有出让土地</span>
					<span class="fileds_row_item_value" v-else-if="house?.land_ment == 20">非国有出让土地</span>
					<span class="fileds_row_item_value" v-else>--</span>
				</div>
				<div class="fields_row_item">
					<label class="fields_row_item_label">土地用途：</label>
					<span class="fileds_row_item_value" v-if="house?.land_purpose == 10">住宅</span>
					<span class="fileds_row_item_value" v-else-if="house?.land_purpose == 20">商业</span>
					<span class="fileds_row_item_value" v-else>--</span>
				</div>
			</div>
			<div class="fields_row">
				<div class="fields_row_item">
					<label class="fields_row_item_label">是否已腾空：</label>
					<span class="fileds_row_item_value" v-if="house?.house_empty == 10">是</span>
					<span class="fileds_row_item_value" v-else-if="house?.house_empty == 20">否</span>
					<span class="fileds_row_item_value" v-else>--</span>
				</div>
				<div class="fields_row_item">
					<label class="fields_row_item_label">租赁情况：</label>
					<span class="fileds_row_item_value" v-if="house?.rent_type == 10">出租</span>
					<span class="fileds_row_item_value" v-else-if="house?.rent_type == 20">未出租</span>
					<span class="fileds_row_item_value" v-else>--</span>
				</div>
				<div class="fields_row_item">
					<label class="fields_row_item_label">出租至：</label>
					<span class="fileds_row_item_value">{{ house?.rent_stop_date }}</span>
				</div>
			</div>
			<div class="fields_row">
				<div class="fields_row_item">
					<label class="fields_row_item_label">落户情况：</label>
					<span class="fileds_row_item_value" v-if="house?.can_settle == 10">能落户</span>
					<span class="fileds_row_item_value" v-else-if="house?.can_settle == 20">不能落户</span>
					<span class="fileds_row_item_value" v-else>--</span>
				</div>
				<div class="fields_row_item">
					<label class="fields_row_item_label">经营情况：</label>
					<span class="fileds_row_item_value" v-if="house?.operate_mode == 10">营业</span>
					<span class="fileds_row_item_value" v-else-if="house?.operate_mode == 20">未营业</span>
					<span class="fileds_row_item_value" v-else>--</span>
				</div>
				<div class="fields_row_item">
					<label class="fields_row_item_label">建筑类型：</label>
					<span class="fileds_row_item_value" v-if="house?.build_type == 10">塔楼</span>
					<span class="fileds_row_item_value" v-else-if="house?.build_type == 20">板楼</span>
					<span class="fileds_row_item_value" v-else-if="house?.build_type == 30">塔板结合</span>
					<span class="fileds_row_item_value" v-else-if="house?.build_type == 40">其它</span>
					<span class="fileds_row_item_value" v-else>--</span>
				</div>
			</div>
			<div class="fields_row">
				<div class="fields_row_item">
					<label class="fields_row_item_label">钥匙：</label>
					<span class="fileds_row_item_value" v-if="house?.has_key == 10">有</span>
					<span class="fileds_row_item_value" v-else-if="house?.has_key == 20">无</span>
					<span class="fileds_row_item_value" v-else>--</span>
				</div>
				<div class="fields_row_item">
					<label class="fields_row_item_label">建筑总面积：</label>
					<span class="fileds_row_item_value">{{ house?.build_area }}㎡</span>
				</div>
				<div class="fields_row_item">
					<label class="fields_row_item_label">公摊总面积：</label>
					<span class="fileds_row_item_value">{{ house?.shared_area }}㎡</span>
				</div>
			</div>
			<div class="fields_row">
				<div class="fields_row_item">
					<label class="fields_row_item_label">土地总面积：</label>
					<span class="fileds_row_item_value">{{ house?.land_area }}㎡</span>
				</div>
				<div class="fields_row_item">
					<label class="fields_row_item_label">房龄：</label>
					<span class="fileds_row_item_value">{{ house?.house_age }}年</span>
				</div>
				<div class="fields_row_item">
					<label class="fields_row_item_label">装修情况：</label>
					<span class="fileds_row_item_value" v-if="house?.decoration_type == 10">毛坯</span>
					<span class="fileds_row_item_value" v-else-if="house?.decoration_type == 20">简装</span>
					<span class="fileds_row_item_value" v-else-if="house?.decoration_type == 30">中装</span>
					<span class="fileds_row_item_value" v-else-if="house?.decoration_type == 40">精装</span>
					<span class="fileds_row_item_value" v-else-if="house?.decoration_type == 50">豪装</span>
					<span class="fileds_row_item_value" v-else>--</span>
				</div>
			</div>
			<div class="fields_row">
				<div class="fields_row_item">
					<label class="fields_row_item_label">楼层：</label>
					<span class="fileds_row_item_value">{{ house?.storey }}</span>
				</div>
				<div class="fields_row_item">
					<label class="fields_row_item_label">房屋朝向：</label>
					<span class="fileds_row_item_value">{{ house?.house_direction }}</span>
				</div>
				<div class="fields_row_item">
					<label class="fields_row_item_label">户型：</label>
					<span class="fileds_row_item_value">{{ houseShape }}</span>
				</div>
			</div>
			<div class="fields_row">
				<div class="fields_row_item" style="width: 100%">
					<label class="fields_row_item_label">周边配套说明：</label>
					<span class="fileds_row_item_value">{{ house?.periphery_assort }}</span>
				</div>
			</div>
			<div class="fields_row">
				<div class="fields_row_item" style="width: 100%">
					<label class="fields_row_item_label">其它介绍：</label>
					<span class="fileds_row_item_value">{{ house?.other_introduce }}</span>
				</div>
			</div>
		</div>

		<!-- 富文本内容 -->
		<TargetRichText class="content" :content="content"></TargetRichText>

		<!-- 标的图片 -->
		<div class="images">
			<img :src="item" :alt="item" v-for="item in images" :key="item" class="image" />
		</div>
		<!-- 附件 -->
		<div class="annex-wrapper">
			<h1 class="annex-title">附件内容</h1>
			<span class="annex-tip" v-if="!canSeeInfo">暂无附件查看权限</span>
			<span class="annex-tip" v-else-if="!annex && !video">没有相关下载附件</span>
			<ul class="annex-list" v-else>
				<li class="annex-item" v-if="annex">
					<a :href="`${annex}?attname=${annexName}`" class="annex-item-link" target="_blank"
						:download="annexName">
						{{ annexName }}
					</a>
				</li>
				<li class="annex-item" v-if="video">
					<a :href="`${video}?attname=${videoName}`" class="annex-item-link" target="_blank"
						:download="videoName">
						{{ videoName }}
					</a>
				</li>
			</ul>
		</div>

		


	</div>
</template>

<script>
import TargetRichText from "@/components/TargetRichText.vue"; // 富文本模块
export default {
	name: "TargetIntro",
	components: {
		TargetRichText,
	},
	props: {
		// 是否可以查看信息
		canSeeInfo: { type: Boolean, default: false },
		// 拍卖会类型。1：机车。2：房产。其它值不用管
		ptypeId: {
			type: [String, Number],
			default: "",
		},

		// 机车数据
		vehicle: {
			type: Object,
			default: () => { },
		},

		// 房产数据
		house: {
			type: Object,
			default: () => { },
		},

		// 富文本内容
		content: {
			type: [String, Number],
			default: "",
		},
		// 附件数据
		annex: {
			type: [String, Number],
			default: "",
		},
		annexName: {
			type: [String, Number],
			default: "",
		},
		// 视频数据
		video: {
			type: [String, Number],
			default: "",
		},
		videoName: {
			type: [String, Number],
			default: "",
		},
		// 标的图片列表
		images: {
			type: Array,
			default: () => [],
		},
	},
	computed: {
		// 户型
		houseShape() {
			if (!this.house?.house_shape) return "";
			let arr = this.house.house_shape.split(",");
			return `${arr?.[0]}室${arr?.[1]}厅${arr?.[2]}卫${arr?.[3]}阳台`;
		},
	},
};
</script>

<style lang="scss" scoped>
.component-target-intro {
	.fields {
		border-bottom: 2px dotted #b9b9b9;
		padding-bottom: 30px;
		margin-bottom: 38px;

		&_row {
			display: flex;

			&_item {
				display: flex;
				// align-items: center;
				line-height: 1.5;
				padding: 6px 0;

				&:nth-child(1) {
					width: 40%;
				}

				&:nth-child(2) {
					width: 33%;
				}

				&_label {
					color: #999999;
					flex-shrink: 0;
					margin-right: 5px;
				}

				&_link {
					font-size: 12px;
					color: var(--theme-color);
					border: 1px solid var(--theme-color);
					height: 24px;
					line-height: 22px;
					padding: 0 9px;
					border-radius: 24px;
					transition: 0.2s;
					display: inline-flex;
					cursor: pointer;
					user-select: none;
					margin-left: 12px;
					position: relative;
					overflow: hidden;

					&:hover {
						background-color: var(--theme-color-light);
					}

					&_image {
						position: absolute;
						width: 100%;
						height: 100%;
						left: 0;
						top: 0;
						opacity: 0;
					}
				}
			}
		}
	}

	.images {
		.image {
			display: block;
			max-width: 100%;
			margin: 5px auto;
		}
	}

	.annex-wrapper {
		margin-top: 10px;
		margin-bottom:20px;

		.annex-title {
			font-size: 16px;
			background: #f6f6f6;
			border-left: 3px solid var(--theme-color);
			padding: 10px;
		}

		.annex-tip {
			color: var(--theme-color6);
			display: block;
			padding: 15px 10px;
		}

		.annex-list {
			padding: 10px;
		}

		.annex-item {
			list-style: none;
			padding: 5px 0;
		}

		.annex-item-link {
			color: inherit;
			transition: color 0.1s;

			&:hover {
				color: var(--theme-color);
			}
		}
	}
}
</style>

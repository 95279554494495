/**
 * ****************************************************************************************************
 * 废钢统计模块相关接口
 * ****************************************************************************************************
 */
import HiRequest from "@/request/index.js";
export const hr = new HiRequest();

// 获取废钢统计数据
export const apiGetScrapDatas = (data = {}, options = {}) => hr.get("api/v1/steel_scrap", data, options);

// 获取废钢数据日期
export const apiGetScrapDates = (data = {}, options = {}) => hr.get("api/v1/steel_scrap/dates", data, options);

<!-- 拍卖会基础信息卡片 -->
<template>
	<div class="component-auction-basic-info-card">
		<div class="left">
			<div class="cover-box">
				<div class="box">
					<img :src="card?.image" class="image" alt="" />
				</div>
			</div>
		</div>
		<div class="middle">
			<h1 class="title">{{ card?.title || "xxxxxxxx" }}</h1>
			<div class="date">{{ dayjs.unix(card?.start_time).format("YYYY年MM月DD日 HH:mm") }}开始</div>
			<div class="row">
				<label class="label">拍卖企业：</label>
				<span class="value">{{ card?.company_name || "xxxxxxxx" }}</span>
			</div>
			<div class="row">
				<label class="label">联系电话：</label>
				<span class="value">{{ card?.contacts_phone || "xxxxxxxxxxx" }}</span>
			</div>
			<div class="datas">
				<div class="item">
					<span class="value">{{ card?.item_count || 0 }}</span>
					<span class="label">标的数量</span>
				</div>
				<div class="item">
					<span class="value">{{ card?.read_count || 0 }}</span>
					<span class="label">围观数量</span>
				</div>
			</div>
		</div>
		<div class="right">
			<el-button type="primary" class="button button-see-notice"
				@click="$router.push({ name: 'NoticeCenterDetails', query: { no: card?.notice_no } })">查看拍卖公告</el-button>
			<div class="share">
				<div class="title">分享：</div>
				<div class="img-box" @click="clickHandlers">
					<img src="../../public/images/wx.png" alt="分享到微信">
				</div>
			</div>
		</div>
		<span class="badge badge-10" v-if="card?.type == 10">同步拍</span>
		<span class="badge badge-20" v-else-if="card?.type == 20">网络拍</span>
	</div>
</template>

<script>
export default {
	name: "AuctionBasicInfoCard",
	props: {
		card: Object,
	},
	data() {
		return {};
	},
	mounted() { },
	methods: {
		clickHandlers(){
			this.$emit("share")
		}
	},
};
</script>

<style lang="scss" scoped>
.share{
	display: flex;
	align-items: center;
	margin-top: 40px;
	.title{
		font-size: 16px;
		color: #000;
	}
	.img-box{
		cursor: pointer;
		width: 50px;
		height: 50px;
		margin-left: 10px;
		img{
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}
}
.component-auction-basic-info-card {
	position: relative;
	overflow: hidden;
	background-color: #ffffff;
	display: flex;
	align-items: center;

	.left {
		flex-shrink: 0;
		width: 37.33%;
		position: relative;

		.cover-box {
			width: 100%;
		}

		.box {
			padding-bottom: 56.25%;
			position: relative;
			overflow: hidden;
			background-color: #dedede;
		}

		.image {
			position: absolute;
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
			z-index: 0;
		}
	}

	.middle {
		flex: 1;
		padding: 0 20px 0 72px;
		display: flex;
		flex-direction: column;
		justify-content: center;

		.title {
			font-size: 22px;
			font-weight: normal;
			line-height: 32px;
		}

		.date {
			font-weight: bold;
			margin-top: 8px;
			line-height: 1;
			margin-bottom: 24px;
			color: var(--theme-color);
		}

		.row {
			display: flex;
			color: #999999;
			line-height: 24px;

			.label {
				flex-shrink: 0;
			}
		}

		.datas {
			display: flex;
			align-items: center;
			color: #999999;
			line-height: 1;
			margin-top: 20px;

			.item {
				display: flex;
				flex-direction: column;
				align-items: center;
				padding: 0 28px;
				position: relative;

				.value {
					font-size: 22px;
					color: #333333;
					margin-bottom: 12px;
				}
			}

			.item:first-child {
				padding-left: 0;
			}

			.item:not(:first-child)::after {
				content: "";
				width: 2px;
				height: 44px;
				position: absolute;
				left: 0;
				top: 50%;
				transform: translateY(-50%);
				background-color: #eaeaea;
			}
		}
	}

	.right {
		flex-shrink: 0;
		padding-right: 74px;

		.button-see-notice {
			width: 132px;
			height: 44px;
			padding: 0;
			font-size: 16px;
		}
	}

	.badge {
		position: absolute;
		padding: 5px 6px 5px 12px;
		font-size: 12px;
		right: 0;
		top: 0;
		border-radius: 100px 0 0 100px;
		z-index: 10;

		&-10 {
			background: var(--auction-type-bg-10);
			color: var(--auction-type-text-color-10);
		}

		&-20 {
			background: var(--auction-type-bg-10);
			color: var(--auction-type-text-color-10);
		}
	}
}
</style>

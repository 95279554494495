<!-- 用户身份 -->
<template>
	<div class="component-user-identity">
		<ul class="tabs">
			<li class="tab" :class="{ active: tabCurrent == 0 }" @click="tabCurrent = 0">我是处置企业</li>
			<li class="tab" :class="{ active: tabCurrent == 1 }" @click="tabCurrent = 1">我是竞买人</li>
		</ul>
		<div class="contents">
			<div class="content" v-if="tabCurrent == 0">
				<div class="left">
					<img class="image" :src="info?.enterprise?.bigImage" alt="" />
				</div>
				<div class="right">
					<ul class="items">
						<li class="item" v-for="(item, index) in info?.enterprise?.images || []" :key="index">
							<img class="item-image" :src="item" alt="" />
						</li>
					</ul>
					<!-- 跳转企业后台 -->
					<el-button type="primary" class="button" @click="handleEnterprise">我要处置</el-button>
				</div>
			</div>
			<div class="content" v-else-if="tabCurrent == 1">
				<div class="left">
					<img class="image" :src="info?.buyPeople?.bigImage" alt="" />
				</div>
				<div class="right">
					<ul class="items">
						<li class="item" v-for="(item, index) in info?.buyPeople?.images || []" :key="index">
							<img class="item-image" :src="item" alt="" />
						</li>
					</ul>
					<!-- 转个人中心首页（没有登录的情况下需要跳登录页） -->
					<el-button type="primary" class="button" @click="$router.push('/mine/index')">我要竞买</el-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { jumpToEnterpriseAdmin } from "@/utils/func.js";
export default {
	name: "UserIdentity",
	props: {
		// 信息数据
		info: {
			type: Object,
			default: () => {},
		},
	},
	data() {
		return {
			tabCurrent: 0,
		};
	},
	mounted() {},
	methods: {
		/**
		 * !跳转企业后台
		 */
		handleEnterprise() {
			jumpToEnterpriseAdmin();
		},
	},
};
</script>

<style lang="scss" scoped>
.component-user-identity {
	background-color: #ffffff;

	.tabs {
		list-style: none;
		display: flex;
		border-bottom: 1px solid #eeeeee;

		.tab {
			flex: 1;
			height: 79px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 26px;
			line-height: 1;
			transition: 0.2s;
			cursor: pointer;
			user-select: none;

			&.active {
				background-color: var(--theme-color);
				color: #ffffff;
			}
		}
	}

	.contents {
		padding: 60px 0 60px 78px;
	}

	.content {
		display: flex;
		height: 226px;

		.left {
			flex-shrink: 0;
			margin-right: 10px;

			.image {
				width: 503px;
				display: block;
				height: auto;
			}
		}

		.right {
			padding: 20px 130px 0;
			flex: 1;
			display: flex;
			flex-direction: column;
			align-items: center;

			.items {
				display: flex;
				list-style: none;
				width: 100%;

				.item {
					flex: 1;
					display: flex;
					flex-direction: column;
					align-items: center;
					text-align: center;

					.item-image {
						display: block;
						width: 73px;
						height: auto;
					}
				}
			}

			.button {
				width: 132px;
				height: 44px;
				padding: 0;
				font-size: 16px;
				margin-top: 30px;
				border-radius: 0;
			}
		}
	}
}
</style>

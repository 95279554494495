<!-- 资讯列表项 -->
<template>
	<div class="component-news-list-item">
		<div class="title-wrapper">
			<h1 class="title ellipsis2">{{ item?.title }}</h1>
		</div>
		<div class="date">{{ dayjs.unix(item.create_time).format("YYYY-MM-DD HH:mm:ss") }}</div>
	</div>
</template>

<script>
export default {
	name: "NewsListItem",
	props: {
		item: Object,
	},
};
</script>

<style lang="scss" scoped>
.component-news-list-item {
	line-height: 24px;
	height: 70px;
	display: flex;
	cursor: pointer;
	transition: color 0.2s;
	padding: 14px 45px 0;

	.title-wrapper {
		height: 48px;
		position: relative;
		flex: 1;
		display: flex;

		&::before {
			content: "●";
			border-radius: 50%;
			line-height: inherit;
			transform: scale(0.7);
			display: block;
			flex-shrink: 0;
			margin-top: -5px;
		}

		.title {
			font-size: 14px;
			font-weight: normal;
			line-height: 24px;
		}
	}

	.date {
		flex-shrink: 0;
		color: #999999;
		font-family: "MarkPro";
		margin-left: 29px;
	}

	&:hover {
		color: var(--theme-color);

		.date {
			color: var(--theme-color);
		}
	}
}
</style>

/**
 * ****************************************************************************************************
 * HiRequest用到的函数
 * ****************************************************************************************************
 */
/**
 * @description 校验数据是否是String类型
 * @param {Any} val 需要校验的数据
 * @return {Boolean} true: 是；false: 否；
 */
export const isString = (val) => Object.prototype.toString.call(val) === "[object String]";

/**
 * @description 校验数据是否是Function类型
 * @param {Any} val 需要校验的数据
 * @return {Boolean} true: 是；false: 否；
 */
export const isFunction = (val) => Object.prototype.toString.call(val) === "[object Function]";

/**
 * !深度克隆对象
 * @param {Object} obj 源对象
 * @return {Object} 深度克隆后的新对象
 */
export const deepClone = (obj = {}) => {
	if ([null, undefined, NaN, false].includes(obj)) return obj;
	if (typeof obj !== "object" && typeof obj !== "function") return obj;
	let o = Object.prototype.toString.call(obj) === "[object Array]" ? [] : {};
	for (let i in obj) {
		if (obj.hasOwnProperty(i)) o[i] = typeof obj[i] === "object" ? deepClone(obj[i]) : obj[i];
	}
	return o;
};

/**
 * @description 深度合并对象，不会改变原来的对象
 * @param {Object} obj1 对象1
 * @param {Object} obj2 对象2
 * @return {Object} 深度合并后的新对象
 */
export const deepMerge = (obj1 = {}, obj2 = {}) => {
	const result = deepClone(obj1);
	if (typeof result !== "object" || typeof obj2 !== "object") return false;
	for (let key in obj2) {
		if (result[key] && result[key].toString() === "[object Object]") {
			let keyObj = deepMerge(result[key], obj2[key]);
			result[key] = keyObj;
		} else {
			result[key] = obj2[key];
		}
	}
	return result;
};

import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index.js";
import { checkUserLoginStatus } from "@/utils/func.js";

// 解决路由守卫转发页面后浏览器控制台报下面错误的问题
// Uncaught (in promise) Error: Redirected when going from "/index" to "/mine/index" via a navigation guard.
// 有两种解决方案:
// 1. 降低 vue-router 版本到 3.0.7
// 2. 添加如下的代码
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
	if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
	return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

// 1. 定义 (路由) 组件
import Index from "@/views/Index.vue"; // 首页
import TargetList from "@/views/target/List.vue"; // 标的 - 列表
import TargetDetails from "@/views/target/Details.vue"; // 标的 - 详情
import TargetEnroll from "@/views/target/Enroll.vue"; // 标的 - 报名
import Mine from "@/views/Mine.vue"; // 个人中心
import MineIndex from "@/views/mine/Index.vue"; // 个人中心 - 首页
import MineBasicInfo from "@/views/mine/BasicInfo.vue"; // 个人中心 - 基本资料
import MineChangePwd from "@/views/mine/ChangePwd.vue"; // 个人中心 - 修改密码
import MineAccountAuth from "@/views/mine/AccountAuth.vue"; // 个人中心 - 账户认证
import MineCancel from '@/views/mine/CancelAccount.vue'; // 个人中心 - 注销账户
import MineCollectTarget from "@/views/mine/CollectTarget.vue"; // 个人中心 - 收藏标的
import MineEntrustedBidding from "@/views/mine/EntrustedBidding.vue"; // 个人中心 - 委托竞拍
import AuctionList from "@/views/auction/List.vue"; // 拍卖会 - 列表
import AuctionDetails from "@/views/auction/Details.vue"; // 拍卖会 - 详情
import BuyLeaseList from "@/views/buy-lease/List.vue"; // 求购租赁 - 列表
import BuyLeaseDetails from "@/views/buy-lease/Details.vue"; // 求购租赁 - 详情
import ScrapStatisticsList from "@/views/scrap-statistics/List.vue"; // 废钢统计 - 列表
import TransactionCasesList from "@/views/transaction-cases/List.vue"; // 成交案例 - 列表
import SettledEnterpriseList from "@/views/settled-enterprise/List.vue"; // 入驻企业 - 列表
import SettledEnterpriseDetails from "@/views/settled-enterprise/Details.vue"; // 入驻企业 - 详情
import ZhongNuoAuction from "@/views/about/ZhongNuoAuction.vue"; // 企拍拍卖
import HelpCenterIndex from "@/views/help-center/Index.vue"; // 帮助中心
import NoticeCenterList from "@/views/notice-center/List.vue"; // 消息中心 - 消息列表
import NoticeCenterDetails from "@/views/notice-center/Details.vue"; // 消息中心 - 消息详情
import NewsCenterList from "@/views/news-center/List.vue"; // 新闻中心 - 新闻列表
import NewsCenterDetails from "@/views/news-center/Details.vue"; // 新闻中心 - 新闻详情
import Login from "@/views/Login.vue"; // 登录
import Register from "@/views/Register.vue"; // 注册
import Attestation from "@/views/Attestation.vue"; // 认证
import ForgotPassword from "@/views/ForgotPassword.vue"; // 忘记密码,找回密码

// 2. 定义路由
// mate.activeHeaderNavName: 该路由页面所属的模块，用来判断Header中应该激活哪个菜单
const routes = [
	// 首页
	{ path: "", redirect: "/index" },
	{ path: "/", redirect: "/index" },
	{ path: "/index", name: "Index", component: Index, meta: { activeHeaderNavName: "首页" } },

	// 标的
	{ path: "/target", redirect: "/target/list" },
	{ path: "/target/list", name: "TargetList", component: TargetList, meta: { activeHeaderNavName: "标的" } },
	{ path: "/target/details", name: "TargetDetails", component: TargetDetails, meta: { activeHeaderNavName: "标的" } },
	{ path: "/target/enroll", name: "TargetEnroll", component: TargetEnroll, meta: { activeHeaderNavName: "标的" } },

	// 个人中心
	{
		path: "/mine",
		name: "Mine",
		redirect: "/mine/index",
		component: Mine,
		meta: { activeHeaderNavName: "个人中心", requireAuth: true },
		children: [
			{
				path: "/mine/index",
				name: "MineIndex",
				component: MineIndex,
				meta: { activeHeaderNavName: "个人中心", requireAuth: true },
			},
			{
				path: "/mine/basic-info",
				name: "MineBasicInfo",
				component: MineBasicInfo,
				meta: { activeHeaderNavName: "个人中心", requireAuth: true },
			},
			{
				path: "/mine/cancel-account",
				name: "MineCancel",
				component: MineCancel,
				meta: { activeHeaderNavName: "个人中心", requireAuth: true },
			},
			{
				path: "/mine/change-pwd",
				name: "MineChangePwd",
				component: MineChangePwd,
				meta: { activeHeaderNavName: "个人中心", requireAuth: true },
			},
			{
				path: "/mine/account-auth",
				name: "MineAccountAuth",
				component: MineAccountAuth,
				meta: { activeHeaderNavName: "个人中心", requireAuth: true },
			},
			{
				path: "/mine/collect-target",
				name: "MineCollectTarget",
				component: MineCollectTarget,
				meta: { activeHeaderNavName: "个人中心", requireAuth: true },
			},
			{
				path: "/mine/entrusted-bidding",
				name: "MineEntrustedBidding",
				component: MineEntrustedBidding,
				meta: { activeHeaderNavName: "个人中心", requireAuth: true },
			},
		],
	},

	// 拍卖会
	{ path: "/auction", redirect: "/auction/list" },
	{ path: "/auction/list", name: "AuctionList", component: AuctionList, meta: { activeHeaderNavName: "拍卖会" } },
	{ path: "/auction/details", name: "AuctionDetails", component: AuctionDetails, meta: { activeHeaderNavName: "拍卖会" } },

	// 求购租赁
	{ path: "/buy-lease", redirect: "/buy-lease/list" },
	{ path: "/buy-lease/list", name: "BuyLeaseList", component: BuyLeaseList, meta: { activeHeaderNavName: "求购租赁" } },
	{ path: "/buy-lease/details", name: "BuyLeaseDetails", component: BuyLeaseDetails, meta: { activeHeaderNavName: "求购租赁" } },

	// 数据统计
	{ path: "/scrap-statistics", redirect: "/scrap-statistics/list" },
	{ path: "/scrap-statistics/list", name: "ScrapStatisticsList", component: ScrapStatisticsList, meta: { activeHeaderNavName: "每日行情" } },

	// 成交案例
	{ path: "/transaction-cases", redirect: "/transaction-cases/list" },
	{ path: "/transaction-cases/list", name: "TransactionCasesList", component: TransactionCasesList, meta: { activeHeaderNavName: "成交案例" } },

	// 入驻企业
	{ path: "/settled-enterprise", redirect: "/settled-enterprise/list" },
	{ path: "/settled-enterprise/list", name: "SettledEnterpriseList", component: SettledEnterpriseList, meta: { activeHeaderNavName: "入驻企业" } },
	{ path: "/settled-enterprise/details", name: "SettledEnterpriseDetails", component: SettledEnterpriseDetails, meta: { activeHeaderNavName: "入驻企业" } },

	// 企拍拍卖
	{ path: "/about", redirect: "/zhongnuo-auction" },
	{ path: "/about-us", redirect: "/zhongnuo-auction" },
	{ path: "/zhongnuo-auction", name: "ZhongNuoAuction", component: ZhongNuoAuction, meta: { activeHeaderNavName: "企拍网" } },

	// 帮助中心
	{ path: "/help", redirect: "/help-center" },
	{ path: "/help-center", name: "HelpCenterIndex", component: HelpCenterIndex, meta: { activeHeaderNavName: "帮助中心" } },

	// 通知公告中心
	{ path: "/notice", redirect: "/notice-center/list" },
	{ path: "/notice-center", redirect: "/notice-center/list" },
	{ path: "/notice-center/list", name: "NoticeCenterList", component: NoticeCenterList, meta: { activeHeaderNavName: "通知中心" } },
	{ path: "/notice-center/details", name: "NoticeCenterDetails", component: NoticeCenterDetails, meta: { activeHeaderNavName: "通知中心" } },

	// 新闻中心
	{ path: "/news", redirect: "/news-center/list" },
	{ path: "/news-center", redirect: "/news-center/list" },
	{ path: "/news-center/list", name: "NewsCenterList", component: NewsCenterList, meta: { activeHeaderNavName: "新闻中心" } },
	{ path: "/news-center/details", name: "NewsCenterDetails", component: NewsCenterDetails, meta: { activeHeaderNavName: "通知中心" } },

	// 登录
	{ path: "/login", name: "Login", component: Login, meta: { noRecordFromPath: true } },

	// 注册
	{ path: "/register", name: "Register", component: Register, meta: { noRecordFromPath: true } },

	// 认证
	{ path: "/attestation", name: "Attestation", component: Attestation, meta: { requireAuth: true, noRecordFromPath: true } },

	// 忘记密码,找回密码
	{ path: "/forgot-password", name: "ForgotPassword", component: ForgotPassword, meta: { noRecordFromPath: true } },
];

// 3. 创建 router 实例，然后传 `routes` 配置
const router = new VueRouter({
	routes,
});

// 4. 路由守卫
// - 全局前置钩子
router.beforeEach((to, from, next) => {
	// 切换路由时将滚动条位置设置为顶部
	window.scrollTo(0, 0);

	// 需要记录上一页的路径
	// 在登录, 注册, 找回密码等页面操作成功后按正常逻辑需要返回上一页,但是,如果没有上一页,就得跳转到首页的页面
	// 所以,在这里存储一个属性用来判断是否有上面页面之外的历史记录
	if (!from.meta?.noRecordFromPath) {
		sessionStorage.setItem("ZHONGNUO_FROM_PATH", from.fullPath);
	}

	// 导航到需要登录后才能查看的页面时
	// 验证用户是否已登录
	if (to?.meta?.requireAuth) {
		// 已登录
		if (checkUserLoginStatus()) next();
		// 未登录
		else next({ name: "Login" });
	}

	// 否则,不需要验证用户登录状态,直接导航到对应页面
	else next();
});

// - 全局后置钩子
router.afterEach((to, from) => {
	store.commit("updateActiveHeaderNavName", to.meta.activeHeaderNavName);
});

// 5. 导出路由实例
export default router;

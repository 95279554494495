/**
 * ****************************************************************************************************
 * Request Get、Post 请求
 * @param {Object} config 合并后的请求配置项
 * @return {Promise} 返回一个 Promise 对象
 * ****************************************************************************************************
 */
import thenCallbac from "./then";
import catchCallback from "./catch";
import { showLoading } from "./functions";
import axios from "axios";

export default function (config) {
	// 显示loading
	if (config.loadingShow) showLoading(config);

	// 请求拦截器
	if (!config.interceptor()) return;

	// 设置请求凭证
	let authValue = localStorage?.[config?.apiAuthKey?.toUpperCase()] || null;
	if (authValue && authValue != "undefined" && authValue != "null") {
		config.headers[config.apiAuthKey] = localStorage?.[config?.apiAuthKey?.toUpperCase()];
	}

	// 返回一个Promise对象
	return new Promise((resolve, reject) => {
		axios(config)
			.then((response) => {
				thenCallbac(config, response, resolve, reject);
			})
			.catch((error) => {
				catchCallback(config, error, reject);
			});
	});
}

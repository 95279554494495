<!-- 个人中心 -->
<template>
	<div class="page-container page-mine-container">
		<WebHeader></WebHeader>
		<!-- 面包屑 -->
		<Breadcrumbs :list="breadcrumbs"></Breadcrumbs>

		<div class="page-mine-content layout-main">
			<!-- 左侧导航菜单 -->
			<div class="module-left-navs">
				<MineNavs :activeIndex="activeNavValue"></MineNavs>
			</div>

			<!-- 右侧对应菜单内容 -->
			<div class="module-right-contents">
				<router-view></router-view>
			</div>
		</div>
		<WebFooter></WebFooter>
	</div>
</template>

<script>
import WebHeader from "@/components/WebHeader.vue"; // 页面Header
import WebFooter from "@/components/WebFooter.vue"; // 页面Footer
import Breadcrumbs from "@/components/Breadcrumbs.vue"; // 面包屑
import MineNavs from "@/components/MineNavs.vue"; // 左侧导航菜单
import { breadcrumbsMine } from "@/breadcrumbs/index.js"; // 面包屑数据

export default {
	name: "PageMine",
	components: {
		WebHeader,
		WebFooter,
		Breadcrumbs,
		MineNavs,
	},
	data() {
		return {
			breadcrumbs: breadcrumbsMine, // 面包屑数据
			activeNavValue: "1", // 当前激活的左侧菜单对应的值
		};
	},
	mounted() {
		// 获取url中的参数(默认激活的左侧菜单的值)
		this.activeNavValue = this.$route.query.i || "1";
	},
	methods: {},
};
</script>

<style scoped lang="scss">
.page-mine-content {
	display: flex;
	margin-bottom: 72px;

	.module-left-navs {
		flex-shrink: 0;
		margin-right: 12px;
	}

	.module-right-contents {
		flex: 1;
	}
}
</style>

/**
 * ****************************************************************************************************
 * 帮助中心模块相关接口
 * ****************************************************************************************************
 */
import HiRequest from "@/request/index.js";
export const hr = new HiRequest();

// 获取分类列表
export const apiGetHelpTypes = (data = {}, options = {}) => hr.get("api/v1/help_center/type", data, options);

// 获取类型数据
export const apiGetHelpTypeItems = (data = {}, options = {}) => hr.get("api/v1/help_center", data, options);

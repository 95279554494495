import { isPhoneNumber, isIdNo, isUnifiedSocialCreditCode } from "@/utils/validate.js";
import { Message } from "element-ui";
import currency from "currency.js"; // 货币处理类库
import store from "@/store/index.js";

/**
 * !格式化400电话
 * @param {String} num 电话号码
 * @param {String} str 格式,如"3-3-4"就会将电话格式成"400-000-0000"格式
 */
export const format400Tel = (num, str = "3-3-4") => {
	var renum = "";
	var arr = new Array();
	var i,
		m = 0,
		n;
	if (str.indexOf("-") > -1) {
		arr = str.split("-");
		for (i = 0; i < arr.length; i++) {
			n = m + Number(arr[i]);
			renum += num.substring(m, n);
			if (i < arr.length - 1) renum += "-";
			m = n;
		}
	}
	return renum;
};

/**
 * !计算数字应该保留的位数
 * !数字是整数则不保留小数位数
 * !数字是小数则保留对应的小数位数
 * !最多保留两位小数
 * @param {String|Number} number 要计算的数字
 * @returns {Number} 返回应该保留的位数
 */
export const calcNumberReservedDigits = (number) => {
	if (number == undefined) return 0;
	if (number == null) return 0;
	let numStr = number.toString();
	let decimal = numStr.split(".")[1];
	if (decimal == undefined) return 0;
	if (decimal.length >= 2) return 2;
	return decimal.length;
};

/**
 * !el-form自定义验证函数
 * !手机号格式验证函数
 */
export const validatePhoneNumber = (rule, value, callback) => {
	if (!isPhoneNumber(value)) {
		callback(new Error("请输入有效的手机号"));
	} else {
		callback();
	}
};

/**
 * !el-form自定义验证函数
 * !两次密码一致性验证函数
 */
export const validatePass2 = (rule, value, callback, pwd1) => {
	if (value === "") {
		callback(new Error("请再次输入您的新密码"));
	} else if (value !== pwd1) {
		callback(new Error("两次输入密码不一致!"));
	} else {
		callback();
	}
};

/**
 * !el-form自定义验证函数
 * !身份证号格式验证函数
 */
export const validateIdNo = (rule, value, callback, errTips = "请输入有效的身份证号") => {
	if (!isIdNo(value)) {
		callback(new Error(errTips));
	} else {
		callback();
	}
};

/**
 * !el-form自定义验证函数
 * !统一社会信用代码格式验证函数
 */
export const validateUnifiedSocialCreditCode = (rule, value, callback) => {
	if (!isUnifiedSocialCreditCode(value)) {
		callback(new Error("请输入有效的统一社会信用代码"));
	} else {
		callback();
	}
};

/**
 * !生成唯一的文件名
 * @param {File} file 文件对象
 */
export const createFileName = (file) => {
	let fileName = file.name;
	let arr = fileName.split(".");
	let arrLen = arr.length;
	let suffix = arr[arrLen - 1];
	let timestamp = new Date().getTime();
	return file.uid + "__" + timestamp + "__." + suffix;
};

/**
 * !el-form uoload 图片上传之前的验证函数
 * @param {File} file 文件对象
 * @param {Array[String]} suffix 允许上传的图片后缀类型
 * @param {Number} maxSize 允许上传的最大文件大小(MB)
 * @param {String} suffixTips 文件类型不正确时的提示语
 * @param {String} maxSizeTips 文件大小超限时的提示语
 * @returns {Boolean} 验证通过返回true,否则返回false
 */
export const validateBeforeUploadImage = (
	file,
	suffix = ["image/jpeg", "image/png"],
	maxSize = 5,
	suffixTips = "上传图片只能是JPG或PNG格式!",
	maxSizeTips = "上传图片大小不能超过5MB!"
) => {
	// 文件格式
	const isImg = suffix.find((item) => item == file.type); // 验证是否是图片文件
	const isLtxM = file.size / 1024 / 1024 < maxSize; // 验证文件是否小于指定大小

	// 非图片文件
	if (!isImg) {
		Message.error(suffixTips);
	}

	// 文件超出大小
	if (!isLtxM) {
		Message.error(maxSizeTips);
	}

	return isImg && isLtxM;
};

/**
 * !隐私化手机号
 * !手机号中间4位改为****
 * @param {String} phonenumber 手机号
 */
export const phonenumberPrivatization = (phonenumber) => {
	if (!phonenumber) return "";
	var reg = /^(\d{3})\d{4}(\d{4})$/;
	return phonenumber.replace(reg, "$1****$2");
};

/**
 * !计算两个日期的相差值
 * @param {Date} dateStart 开始日期,dayjs日期对象
 * @param {Date} dateEnd 结束日期,dayjs日期对象
 * @returns {Object} 返回相差的相关数据
 */
export const calcDateDiff = (dateStart, dateEnd) => {
	let result = {
		DD: "00",
		D: 0,
		hh: "00",
		h: 0,
		mm: "00",
		m: 0,
		ss: "00",
		s: 0,
		// 计算相差的总秒数
		// 如果 结束时间 > 开始时间,值为正数
		// 如果 结束时间 == 开始时间,值为0
		// 如果 结束时间 < 开始时间,值为负值
		seconds: 0,
	};

	// 示例:
	// let showText = `${result.DD}天${result.hh}时${result.mm}分${result.ss}秒后开始`;
	// let showText = `两个日期总共相差${result.seconds}秒`;

	// 如果：无效的开始或结束时间
	// 直接返回result
	if (!dateStart || !dateEnd) return result;

	// 计算相差的总秒数
	result.seconds = dateEnd.diff(dateStart);

	// 如果：结束时间 <= 开始时间
	// 直接返回result
	if (result.seconds <= 0) return result;

	// 正常计算差值
	let diffDays = dateEnd.diff(dateStart, "day"); // 相差的天数
	let diffHours = dateEnd.diff(dateStart, "hour"); // 相差的小时数
	let diffMinutes = dateEnd.diff(dateStart, "minute"); // 相差的分钟数
	let diffSeconds = dateEnd.diff(dateStart, "second"); // 相差的秒数
	let showDays = diffDays; // 显示的天数
	let showHours = diffHours - diffDays * 24; // 显示的小时数
	let showMinutes = diffMinutes - diffHours * 60; // 显示的分钟数
	let showSeconds = diffSeconds - diffMinutes * 60; // 显示的秒数

	// 赋值
	result.D = showDays;
	result.h = showHours;
	result.m = showMinutes;
	result.s = showSeconds;

	// 补零
	if (showDays < 10) showDays = "0" + showDays;
	if (showHours < 10) showHours = "0" + showHours;
	if (showMinutes < 10) showMinutes = "0" + showMinutes;
	if (showSeconds < 10) showSeconds = "0" + showSeconds;

	// 返回数据
	result.DD = showDays;
	result.hh = showHours;
	result.mm = showMinutes;
	result.ss = showSeconds;
	return result;
};

/**
 * !将秒数转换成"hh小时xx分钟xx秒"格式
 * @param {Number} seconds 秒数
 */
export const secondsToHoursMinutesSeconds = (seconds) => {
	let hours = parseInt(seconds / 3600);
	seconds = seconds - 3600 * hours;
	let minutes = parseInt(seconds / 60);
	seconds = seconds - 60 * minutes;
	if (hours > 0) return `${hours}时${minutes}分${seconds}秒`;
	else return `${minutes}分${seconds}秒`;
};

/**
 * !跳转到物资处置企业后台
 */
export const jumpToEnterpriseAdmin = () => {
	window.open(`https://${document.domain}/disposal`);
};
/**
 * !跳转到拍卖企业后台
 */
export const jumpToAuctionAdmin = () => {
    window.open(`https://${document.domain}/enterprise`);
}

/**
 * !防抖函数
 */
export const debounce = (fun, delay = 10000) => {
	return function (args) {
		let that = this;
		let _args = args;
		clearTimeout(fun.id);
		fun.id = setTimeout(function () {
			fun.call(that, _args);
		}, delay);
	};
};

/**
 * !格式化金额
 */
export const formatPrice = (price, symbol = "") => {
	return currency(price, { symbol: symbol, precision: calcNumberReservedDigits(price) }).format();
};

/**
 * !验证用户登录状态
 * @returns {Boolean} 已登录:返回true; 否则:返回false;
 */
export const checkUserLoginStatus = () => {
	return !!store.getters.userinfo;
};

/**
 * !生成GUID
 */
export const createGUID = () => {
	return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
		var r = (Math.random() * 16) | 0,
			v = c == "x" ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
};

/**
 * !随机生成字符串
 * @param {Number} len 指定生成字符串长度
 */
export const getRandomString = (len) => {
	let _charStr = "abacdefghjklmnopqrstuvwxyzABCDEFGHJKLMNOPQRSTUVWXYZ0123456789",
		min = 0,
		max = _charStr.length - 1,
		_str = ""; //定义随机字符串 变量
	//判断是否指定长度，否则默认长度为15
	len = len || 15;
	//循环生成字符串
	for (var i = 0, index; i < len; i++) {
		index = (function (randomIndexFunc, i) {
			return randomIndexFunc(min, max, i, randomIndexFunc);
		})(function (min, max, i, _self) {
			let indexTemp = Math.floor(Math.random() * (max - min + 1) + min),
				numStart = _charStr.length - 10;
			if (i == 0 && indexTemp >= numStart) {
				indexTemp = _self(min, max, i, _self);
			}
			return indexTemp;
		}, i);
		_str += _charStr[index];
	}
	return _str;
};

/**
 * @description 校验数据是否是Function类型
 * @param {Any} val 需要校验的数据
 * @return {Boolean} true: 是；false: 否；
 */
export const isFunction = (val) => {
	return Object.prototype.toString.call(val) === "[object Function]";
};

/**
 * !数字金额大写转换(可以处理整数,小数,负数)
 * @param {Number} n 金额数字
 * @return {String} 转换成大写金额的字符串
 */
export const dealBigMoney = (n) => {
	var fraction = ["角", "分"];
	var digit = ["零", "壹", "贰", "叁", "肆", "伍", "陆", "柒", "捌", "玖"];
	var unit = [
		["元", "万", "亿"],
		["", "拾", "佰", "仟"],
	];
	var head = n < 0 ? "欠" : "";
	n = Math.abs(n);

	var s = "";

	for (var i = 0; i < fraction.length; i++) {
		s += (digit[Math.floor(n * 10 * Math.pow(10, i)) % 10] + fraction[i]).replace(/零./, "");
	}
	s = s || "整";
	n = Math.floor(n);

	for (var i = 0; i < unit[0].length && n > 0; i++) {
		var p = "";
		for (var j = 0; j < unit[1].length && n > 0; j++) {
			p = digit[n % 10] + unit[1][j] + p;
			n = Math.floor(n / 10);
		}
		s = p.replace(/(零.)*零$/, "").replace(/^$/, "零") + unit[0][i] + s;
	}
	return (
		head +
		s
			.replace(/(零.)*零元/, "元")
			.replace(/(零.)+/g, "零")
			.replace(/^整$/, "零元整")
	);
};

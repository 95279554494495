<!-- 个人中心左侧导航菜单组件 -->
<template>
	<div class="component-mine-navs">
		<div class="content">
			<el-menu class="menus" :default-active="activeIndex" :default-openeds="['2', '3', '4']" router>
				<el-menu-item index="1" class="menus_submenu" @click="onClickNavItem('1')">
					<div class="menus_submenu_title">
						<i class="menus_submenu_title_icon zn-iconfont zn-iconfont-shouye"></i>
						<span class="menus_submenu_title_text">首页</span>
					</div>
				</el-menu-item>
				<el-submenu index="2" class="menus_submenu">
					<template slot="title">
						<div class="menus_submenu_title">
							<i class="menus_submenu_title_icon zn-iconfont zn-iconfont-zhanghuanquan"></i>
							<span class="menus_submenu_title_text">账户安全</span>
						</div>
					</template>
					<el-menu-item index="2-1" class="menus_submenu_item" @click="onClickNavItem('2-1')">基本资料</el-menu-item>
					<el-menu-item index="2-2" class="menus_submenu_item" @click="onClickNavItem('2-2')">修改密码</el-menu-item>
					<el-menu-item index="2-3" class="menus_submenu_item" @click="onClickNavItem('2-3')">账户认证</el-menu-item>
					<el-menu-item index="2-4" class="menus_submenu_item" @click="onClickNavItem('2-4')">注销账号</el-menu-item>
				</el-submenu>
				<el-submenu index="3" class="menus_submenu">
					<template slot="title">
						<div class="menus_submenu_title">
							<i class="menus_submenu_title_icon zn-iconfont zn-iconfont-wodeshoucang" style="font-size: 16px"></i>
							<span class="menus_submenu_title_text">我的收藏</span>
						</div>
					</template>
					<el-menu-item index="3-1" class="menus_submenu_item" @click="onClickNavItem('3-1')">收藏标的</el-menu-item>
				</el-submenu>
				<el-submenu index="4" class="menus_submenu">
					<template slot="title">
						<div class="menus_submenu_title">
							<i class="menus_submenu_title_icon zn-iconfont zn-iconfont-wodejingpai" style="font-size: 15px"></i>
							<span class="menus_submenu_title_text">我的竞拍</span>
						</div>
					</template>
					<el-menu-item index="4-1" class="menus_submenu_item" @click="onClickNavItem('4-1')">委托竞拍</el-menu-item>
				</el-submenu>
			</el-menu>
		</div>
	</div>
</template>

<script>
	export default {
		name: "MineNavs",
		props: {
			activeIndex: [String, Number], // 默认激活的菜单值
		},
		data() {
			return {};
		},
		mounted() {},
		methods: {
			/**
			 * !菜单点击事件
			 * @param {String} 被点击的菜单绑定的index值
			 */
			onClickNavItem(index) {
				// 首页
				if (index == "1") {
					this.$router.push({
						name: "MineIndex",
						query: {
							i: index,
						},
					});
					return;
				}

				// 基本资料
				if (index == "2-1") {
					this.$router.push({
						name: "MineBasicInfo",
						query: {
							i: index,
						},
					});
					return;
				}

				// 修改密码
				if (index == "2-2") {
					this.$router.push({
						name: "MineChangePwd",
						query: {
							i: index,
						},
					});
					return;
				}

				// 账户认证
				if (index == "2-3") {
					this.$router.push({
						name: "MineAccountAuth",
						query: {
							i: index,
						},
					});
					return;
				}
				// 注销账号
				if (index == "2-4") {
					this.$router.push({
						name: "MineCancel",
						query: {
							i: index,
						},
					});
					return;
				}

				// 收藏标的
				if (index == "3-1") {
					this.$router.push({
						name: "MineCollectTarget",
						query: {
							i: index,
						},
					});
					return;
				}

				// 委托竞拍
				if (index == "4-1") {
					this.$router.push({
						name: "MineEntrustedBidding",
						query: {
							i: index,
						},
					});
					return;
				}

				// 默认跳转到个人中心首页
				this.$router.push({
					name: "MineIndex",
					query: {
						i: index,
					},
				});
				return;
			},
		},
	};
</script>

<style lang="scss" scoped>
	.component-mine-navs {
		width: 190px;
		background-color: #ffffff;

		.menus {
			&_submenu {
				color: #333333;

				&_title {
					display: flex;
					line-height: 1;
					height: 66px;
					align-items: center;
					font-size: 18px;
					color: inherit;
					border-top: 1px solid #f1f1f1;
					padding: 0 30px;

					&_icon {
						color: inherit;
						margin-right: 10px;
						width: 20px;
						text-align: center;
						font-size: 18px;
					}

					& + .el-submenu__icon-arrow {
						margin-top: 0;
					}
				}

				&.is-active {
					color: var(--theme-color);
				}
			}
		}

		::v-deep {
			.el-submenu__title {
				height: 66px;
				line-height: 66px;
				padding-left: 0 !important;
				padding-right: 0;
				color: inherit;

				&:hover {
					background-color: transparent;
					color: var(--theme-color);
				}
			}

			.menus_submenu_title + .el-submenu__icon-arrow {
				margin-top: 0;
				transform: translateY(-50%);
				color: #6b6b6b;
				font-size: 14px;
				font-weight: bold;
			}

			.menus_submenu.is-active .menus_submenu_title + .el-submenu__icon-arrow {
				color: var(--theme-color);
			}

			.el-menu-item.menus_submenu {
				height: 66px;
				line-height: 1;
				display: flex;
				align-items: center;
				padding-left: 30px !important;
				padding-right: 30px;

				.menus_submenu_title {
					padding: 0;
				}
			}

			.el-submenu .el-menu-item {
				min-width: 100%;
				color: #999999;
				font-size: 16px;
				line-height: 32px;
				height: auto;
				padding-left: 60px !important;

				&:first-child {
					margin-top: 2px;
				}

				&:last-child {
					margin-bottom: 12px;
				}

				&.is-active {
					color: var(--theme-color);
				}
			}

			.el-menu-item:focus,
			.el-menu-item:hover {
				background-color: transparent;
				color: var(--theme-color);
			}
		}
	}
</style>

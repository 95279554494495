<!-- 我的委托竞拍列表 -->
<template>
	<div class="component-mine-entrusted-bidding-list">
		<table class="table">
			<thead>
				<tr>
					<th>标的</th>
					<th>价格</th>
					<th>状态</th>
					<th>时间</th>
					<th>操作</th>
				</tr>
			</thead>
			<tbody>
				<template v-for="(item, index) in list">
					<tr :key="index" class="item-head">
						<td colspan="4">
							拍卖企业：{{ item.company_name || "-" }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;联系人：{{
								item.contact_person || "-"
							}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;联系电话：{{ item.contacts_phone || "-" }}
						</td>
						<td>
							我的竞买号：<span class="bidding-no">{{ item.nickname }}</span>
						</td>
					</tr>
					<tr class="item-content">
						<td class="td1">
							<div class="column column1">
								<div class="image-box">
									<div class="inner">
										<img :src="item.image" class="image" alt="" />
									</div>
								</div>
								<span class="title">{{ item.title }}</span>
							</div>
						</td>
						<td class="td2">
							<!-- 价格 - 可查看 -->
							<div class="column column2" v-if="item?.is_show_price">
								<label class="label">{{ getTargetShowPriceDataByStatus(item)?.label }}：</label>
								<span class="value">{{ getTargetShowPriceDataByStatus(item)?.value }}</span>
							</div>

							<!-- 价格 - 不可查看 -->
							<div class="column column2 column-hidden" v-else>
								<!-- <label class="label">{{ getTargetShowPriceDataByStatus(item)?.label }}：</label>
								<span class="value">--</span> -->
							</div>
						</td>
						<td class="td3">
							<TargetStatus :status="item?.status" class="column column3"></TargetStatus>
						</td>
						<td class="td4">
							<div class="column column4">
								<label class="label">{{ getTargetShowDateDataByStatus(item)?.label }}</label>
								<span class="value">{{ getTargetShowDateDataByStatus(item)?.value }}</span>
							</div>
						</td>
						<td class="td5">
							<div class="column column5">
								<el-button type="primary" plain class="button button-see" @click="handleSeeMatter(item)">查看标的</el-button>
							</div>
						</td>
					</tr>
				</template>
			</tbody>
		</table>
	</div>
</template>

<script>
	import TargetStatus from "@/components/TargetStatus.vue";
	import { getTargetShowPriceDataByStatus, getTargetShowDateDataByStatus } from "@/common/functions/custom.js";
	export default {
		name: "MineEntrustedBiddingList",
		components: { TargetStatus },
		props: {
			list: Array,
		},
		data() {
			return {};
		},
		mounted() {},
		methods: {
			getTargetShowPriceDataByStatus,
			getTargetShowDateDataByStatus,
			/**
			 * !查看标的
			 * @param {Object} item  标的数据
			 */
			handleSeeMatter(item) {
				this.$router.push({
					name: "TargetDetails",
					query: {
						no: item.no,
					},
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	.component-mine-entrusted-bidding-list {
		.table {
			width: 100%;
			border-spacing: 0 0;
			border-collapse: separate;

			thead {
				th {
					font-weight: normal;
					font-size: 16px;
					padding: 19px 26px;
				}

				th:first-child {
					text-align: left;
				}
			}

			tbody {
				tr.item-head {
					background-color: #f5f5f5;

					td:first-child {
						text-align: left;
					}

					td:last-child {
						text-align: center;
						font-size: 14px;
					}

					td {
						/* padding: 14px 26px; */
						padding: 14px 10px;
					}
				}

				tr.item-content {
					td {
						height: 1px;
						padding-bottom: 20px;
					}
				}

				.bidding-no {
					color: var(--theme-color);
				}

				.td1 {
					width: 38.45%;
				}

				.td2 {
					width: 15%;
				}

				.td3 {
					width: 14.12%;
				}

				.td4 {
					width: 14.86%;
				}

				.column {
					height: 100%;
					border-right: 1px solid #efefee;
					border-bottom: 1px solid #efefee;
					padding: 14px 0;
					display: flex;
					align-items: center;
					justify-content: center;
				}

				.column1 {
					display: flex;
					align-items: center;
					padding: 14px 26px;
					border-left: 1px solid #efefee;
					justify-content: flex-start;

					.image-box {
						width: 40%;
						flex-shrink: 0;
						margin-right: 8px;

						.inner {
							padding-bottom: 57%;
							position: relative;
							overflow: hidden;

							.image {
								display: block;
								position: absolute;
								width: 100%;
								height: 100%;
								top: 0;
								left: 0;
								z-index: 0;
							}
						}
					}

					.title {
						font-size: 16px;
						color: #666666;
						text-align: left;
					}
				}

				.column2 {
					display: flex;
					flex-direction: column;
					text-align: center;

					.label {
						color: #999999;
						margin-bottom: 5px;
					}

					.value {
						font-size: 24px;
						color: var(--theme-color);
						font-family: "MarkPro";
					}
				}

				.column3 {
					font-size: 14px;
					color: #666666;
					background: transparent;
				}

				.column4 {
					display: flex;
					flex-direction: column;
					text-align: center;

					.label {
						color: #999999;
						margin-bottom: 9px;
					}

					.value {
						font-size: 16px;
						color: #666666;
						font-family: "MarkPro";
					}
				}
			}
		}
	}
</style>

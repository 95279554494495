<!-- 标的 - 列表 -->
<template>
	<div class="page-container page-target-list-container">
		<!-- #S 页头 -->
		<WebHeader></WebHeader>
		<!-- #E 页头 -->

		<!-- #S 页体 -->
		<div class="page-target-list-content">
			<!-- #S 面包屑 -->
			<Breadcrumbs :list="breadcrumbs"></Breadcrumbs>
			<!-- #E 面包屑 -->

			<!-- #S 主内容 -->
			<div class="layout-main">
				<!-- #S 搜索 -->
				<SearchFilter class="search-filter" :region="region" :targetTypes="targetTypes"
					:auctionStatus="auctionStatus" :parentId="searchParams.targetParentId"
					:childrenId="searchParams.targetChildrenId" showDateItems @change="handleSearch" @submit="handleSearch">
				</SearchFilter>
				<!-- #E 搜索 -->

				<!-- 列表 -->
				<TargetList class="target-list" :list="list" v-if="list && list.length"></TargetList>

				<!-- 数据为空 -->
				<el-empty description="数据为空" v-else></el-empty>

				<!-- 分页器 -->
				<div class="common-pagination">
					<el-pagination background :page-size="pageSize" :total="total" :pager-count="5"
						:current-page="pageIndex" hide-on-single-page @current-change="onChangePageIndex"></el-pagination>
				</div>
			</div>
			<!-- #E 主内容 -->
		</div>
		<!-- #E 页体 -->

		<!-- #S 页脚 -->
		<WebFooter></WebFooter>
		<!-- #E 页脚 -->
	</div>
</template>

<script>
import WebHeader from "@/components/WebHeader.vue";
import WebFooter from "@/components/WebFooter.vue";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import SearchFilter from "@/components/SearchFilter.vue";
import TargetList from "@/components/TargetList.vue";
import { breadcrumbsTargetList } from "@/breadcrumbs/index.js";
import { apiGetSearchItems, apiGetTargetList } from "@/apis/target.js";
export default {
	name: "PageTargetList",
	components: {
		WebHeader,
		WebFooter,
		Breadcrumbs,
		SearchFilter,
		TargetList,
	},
	data() {
		return {
			breadcrumbs: breadcrumbsTargetList, // 面包屑数据
			region: [], // 地区数据
			targetTypes: [], // 标的类型数据
			auctionStatus: [], // 拍卖状态数据
			list: [], // 标的列表
			pageIndex: 1, // 当前页码
			pageSize: 20, // 每页数据长度
			total: 0, // 总数据条数
			// 搜索条件数据
			searchParams: {
				provinceId: "", // 省份id
				cityId: "", // 城市id
				targetParentId: "", // 标的类型一级分类id
				targetChildrenId: "", // 标的类型二级分类id
				auctionStatusId: "", // 拍卖状态id
				dateStart: "", // 开始日期
				dateEnd: "", // 结束日期
			},
		};
	},
	async mounted() {
		// 查询搜索数据
		await this.querySearchItems().then().catch();
		if (this.$route.query?.type) {
			let types = this.$route.query?.type.split(",");
			if (types.length === 2) {
				this.searchParams.targetParentId = types[0];
				this.searchParams.targetChildrenId = types[1];
			} else {
				this.searchParams.targetParentId = types[0];
			}
			console.log(types);
		}


		this.init();
	},
	methods: {
		async init() {
			// 查询列表数据
			this.queryList();
		},

		/**
		 * !查询搜索数据
		 */
		querySearchItems() {
			return new Promise((resolve, reject) => {
				apiGetSearchItems()
					.then((res) => {
						this.region = res?.finalData?.region_data || [];
						this.targetTypes = res?.finalData?.type_data || [];
						this.auctionStatus = res?.finalData?.status_data || [];
						resolve();
					})
					.catch((err) => {
						console.error(err);
						reject();
					});
			});
		},

		/**
		 * !查询列表数据
		 */
		queryList() {
			apiGetTargetList({
				page: this.pageIndex,
				limit: this.pageSize,
				need_count: this.pageIndex == 1 ? 1 : 2,
				province_id: this.searchParams?.provinceId,
				city_id: this.searchParams?.cityId,
				ptype_id: this.searchParams?.targetParentId,
				stype_id: this.searchParams?.targetChildrenId,
				status_id: this.searchParams?.auctionStatusId,
				start_date: this.searchParams?.dateStart ? this.dayjs(this.searchParams.dateStart).format("YYYY-MM-DD") : "",
				end_date: this.searchParams.dateEnd ? this.dayjs(this.searchParams.dateEnd).format("YYYY-MM-DD") : "",
				title: this.$route.query?.search_text || "",
			})
				.then((res) => {
					// 数据总条数
					if (res?.finalData?.count) this.total = res?.finalData?.count;

					// 列表
					res?.finalData?.lists?.forEach((item) => (item.now_time = res?.finalData?.now_time)); // 为每项增加当前服务器时间字段
					this.list = res?.finalData?.lists || [];
				})
				.catch((err) => console.error(err));
		},

		/**
		 * !搜索事件
		 * @param {Object} params 搜索条件数据
		 */
		handleSearch(params) {
			// 更新搜索条件
			this.searchParams = params;

			// 清空当前列表
			this.list = [];

			// 重置页码
			this.pageIndex = 1;

			// 重置总数据条数
			this.total = 0;

			// 查询列表数据
			this.queryList();
		},

		/**
		 * !页码切换事件
		 * @param {Number} current 当前页
		 */
		onChangePageIndex(current) {
			// 清空当前列表
			this.list = [];

			// 更新页码
			this.pageIndex = current;

			// 查询列表数据
			this.queryList();
		},
	},
};
</script>

<style scoped lang="scss">
.page-target-list-content {
	.search-filter {
		margin-bottom: 20px;
	}
}
</style>

/**
 * ****************************************************************************************************
 * Request 封装
 * ****************************************************************************************************
 */
import { deepMerge } from "./utils"; // 工具函数
import config from "./config"; // 默认配置
import request from "./request"; // Request(Get|Post)

export default class HiRequest {
	constructor() {
		// 单次实例的默认配置
		// 注意:每次实例可以单独设置不同的默认配置项,这样可以更好的为不同的模块设置不同的配置项
		this.config = deepMerge(config, {
			url: "", // 接口名称
			data: {}, // 请求参数
			// loading定时器
			// 说明：请求loading超时时间的意义为，一般情况下，请求会在几十毫秒返回，时间极短，无需loading，如果显示loading，会导致 动画一闪而过，体验不好。
			// 如果用户网络慢，或者服务器堵塞，可能一个请求需要几秒钟，这时请求达到设定时间(ms)， 就会显示loading，几秒钟后请求返回，loading消失。
			loadingTimer: null,
		});

		/**
		 * @description 设置配置项
		 * @param {Object} newConfig 新的配置项
		 */
		this.setConfig = (newConfig) => (this.config = deepMerge(this.config, newConfig));

		/**
		 * @description 设置请求拦截函数
		 * @param {Function} fn 新的请求拦截函数
		 */
		this.setIntercept = (fn) => (this.config.interceptor = fn);

		/**
		 * @description Get
		 * @param {String} url 请求地址
		 * @param {Object} data 请求参数
		 * @param {Object} options 请求配置
		 */
		this.get = (url = "", data = {}, options = {}) => {
			options.url = url;
			options.params = data;
			options.method = "get";
			return request(deepMerge(this.config, options));
		};

		/**
		 * @description Post
		 * @param {String} url 请求地址
		 * @param {Object} data 请求参数
		 * @param {Object} options 请求配置
		 */
		this.post = (url = "", data = {}, options = {}) => {
			options.url = url;
			options.data = data;
			options.method = "post";
			return request(deepMerge(this.config, options));
		};

		/**
		 * @description Upload
		 * @param {String} url 请求地址
		 * @param {Object} data 请求参数
		 * @param {Object} options 请求配置
		 */
		this.upload = (url = "", data = {}, options = {}) => {
			options.url = url;
			options.data = data;
			options.method = "upload";
			options.headers = {
				"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
			};
			return request(deepMerge(this.config, options));
		};
	}
}

<!-- 拍卖会 - 详情 -->
<template>
	<div class="page-container page-auction-details-container">
		<WebHeader></WebHeader>
		<div class="page-auction-details-content">
			<!-- 面包屑 -->
			<Breadcrumbs :list="breadcrumbs"></Breadcrumbs>

			<!-- 主内容区域 -->
			<div class="layout-main">
				<!-- 拍卖会基础信息 -->
				<AuctionBasicInfoCard :card="details" @share="shareClickHandler"></AuctionBasicInfoCard>

				<!-- 标的列表 -->
				<TargetList :list="targetList" class="target-list" v-if="targetList && targetList.length"></TargetList>

				<!-- 数据为空 -->
				<el-empty description="数据为空" v-else></el-empty>
			</div>
		</div>
		<WebFooter></WebFooter>
		<div class="qr-share" v-show="qrFlag">
			<img src="@/assets/images/gb.png" alt="" class="gb-icon" @click="gbClickHandler" />
			<div><vue-qr   ref="qrCode"    :text="textValue" :logoSrc="logoPath" :logoScale="30" :size="240"
					:margin="10" /></div>
			<div class="text">
				微信扫描二维码分享
			</div>
		</div>
	</div>
</template>

<script>
import WebHeader from "@/components/WebHeader.vue"; // 页面Header
import WebFooter from "@/components/WebFooter.vue"; // 页面Footer
import Breadcrumbs from "@/components/Breadcrumbs.vue"; // 面包屑
import AuctionBasicInfoCard from "@/components/AuctionBasicInfoCard.vue"; // 拍卖会基础信息卡片
import TargetList from "@/components/TargetList.vue"; // 标的列表
import { breadcrumbsAuctionDetails } from "@/breadcrumbs/index.js"; // 面包屑数据
import { apiGetAuctionDetails } from "@/apis/auction.js";
import VueQr from 'vue-qr'
import logoImg from '@/assets/images/qr.png'

export default {
	name: "PageAuctionDetails",
	components: {
		WebHeader,
		WebFooter,
		Breadcrumbs,
		AuctionBasicInfoCard,
		TargetList,
		VueQr
	},
	data() {
		return {
			breadcrumbs: breadcrumbsAuctionDetails, // 面包屑数据
			targetList: [], // 标的列表
			no: "", // 拍卖会编号
			details: null, // 拍卖会详情数据
			textValue: '',
			logoPath: logoImg,
			qrFlag: false,
		};
	},
	mounted() {
		// 获取拍卖会编号
		this.no = this.$route.query?.no;

		this.init();
	},
	methods: {
		shareClickHandler() {
			console.log("分享");
			this.textValue = `https://www.qipai-wang.com/h5/#/pages/auction/hall?id=${this.no}`;
			console.log(this.textValue);
			this.qrFlag = true;
		},
		gbClickHandler(){
			this.qrFlag = false;
		},
		init() {
			// 获取拍卖会编号
			this.no = this.$route.query?.no;

			// 查询拍卖会详情
			this.queryDetails();
		},
		/**
		 * !查询拍卖会详情
		 * @return {*}
		 */
		queryDetails() {
			apiGetAuctionDetails({
				no: this.no,
			})
				.then((res) => {
					console.log("拍卖会详情->", res);

					// 判断面包屑长度以解决面包屑数据重复添加的问题
					if (this.breadcrumbs.length <= 2) {
						this.breadcrumbs.push({
							name: res?.finalData?.title,
							to: { name: "AuctionDetails" },
						});
					}

					// 拍卖会详情数据
					this.details = res?.finalData;

					// 标的列表
					res?.finalData?.items.forEach((item) => {
						item.start_time = res?.finalData?.start_time;
						item.now_time = res?.finalData?.now_time;
					});
					this.targetList = res?.finalData?.items || [];
				})
				.catch((err) => console.error(err));
		},
	},
};
</script>

<style scoped lang="scss">
.page-auction-details-container {
	.target-list {
		margin-top: 20px;
		margin-bottom: 72px;
	}
}

.qr-share {
	position: fixed;
	top: 50%;
	left: 50%;
	background: #fff;
	border: 1px solid #ccc;
	padding: 10px;
	padding-top: 25px;
	z-index: 99;
	transform: translate(-50%, -50%);
	.text{
		font-size: 16px;
		color: #000;
		text-align: center;
	}
	.gb-icon{
		width: 20px;
		position: absolute;
		right: 5px;
		top: 5px;
		cursor: pointer;
	}
}
</style>

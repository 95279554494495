<!-- 标的详情 - 出价列表模块 -->
<template>
	<div class="component-target-detail-offer-price-list">
		<table class="table">
			<thead>
				<tr>
					<th>状态</th>
					<th>竞买号</th>
					<th>出价</th>
					<th>时间</th>
				</tr>
			</thead>
			<tbody v-if="canSeeInfo">
				<tr v-for="(row, index) in list.slice(0, 4)" :key="index">
					<td>
						<span v-if="row?.nickname == info?.nickname">我的</span>{{ index == 0 ? "领先" : "落后" }}
					</td>
					<td>{{ row?.nickname }}</td>
					<td>¥{{ formatPrice(row?.price) }}</td>
					<td>{{ dayjs.unix(row?.create_time).format("HH:mm:ss") }}</td>
					<!-- <p>{{ dayjs.unix(row?.create_time).format("HH:mm:ss") }}</p> -->
				</tr>
			</tbody>
		</table>
		<div class="unauth-tip" v-if="!canSeeInfo">报名审核通过后查看竞价记录</div>
		<div class="more" v-else-if="list.length" @click="$emit('more')">
			<span>查看更多</span>
			<i class="icon zn-iconfont zn-iconfont-you"></i>
		</div>
		<div class="no-data" v-else>暂无竞价记录</div>
	</div>
</template>

<script>

export default {
	name: "TargetDetailOfferPriceList",
	props: {
		// 列表数据
		list: {
			type: Array,
			default: () => [],
		},
		info: {
			type: Object,
			default: () => {},
		},
		// 是否可以查看信息
		canSeeInfo: { type: Boolean, default: false },
	},
};
</script>

<style lang="scss" scoped>
.component-target-detail-offer-price-list {
	background-color: #fff;
	display: flex;
	flex-direction: column;
	align-items: center;

	.table {
		border-collapse: collapse;
		border-spacing: 0;
		width: 100%;

		th {
			background-color: var(--theme-color);
			color: #ffffff;
			height: 36px;
		}

		td {
			height: 36px;
			text-align: center;
		}

		tbody tr:first-child td {
			color: var(--theme-color);
			font-weight: bold;
		}
	}

	.more {
		color: #999999;
		display: inline-flex;
		align-items: center;
		margin-bottom: 20px;
		margin-top: 10px;
		cursor: pointer;
		user-select: none;
		transition: 0.2s;

		&:hover {
			color: var(--theme-color);
			text-decoration: underline;
		}

		.icon {
			font-size: 12px;
		}
	}

	.no-data {
		color: #999999;
		font-weight: normal;
		padding: 16px 0;
	}

	.unauth-tip {
		color: #999999;
		font-weight: normal;
		padding: 16px 0;
		font-size: 12px;
		text-align: center;
	}
}
</style>

<!-- 注册 -->
<template>
	<div class="page-container page-register-container">
		<WebHeaderTwo class="header"></WebHeaderTwo>
		<div class="page-register-content layout-main">
			<div class="left">
				<img class="welcome" src="../../public/images/register-welcome.png" alt="" />
			</div>
			<div class="right">
				<div class="form-wrapper">
					<h1 class="title">请填写以下信息完成注册</h1>
					<el-form class="form" ref="form" :model="form" :rules="rules">
						<el-form-item>
							<el-input class="input" placeholder="注册角色：竞买人" disabled></el-input>
						</el-form-item>
						<el-form-item prop="phonenumber">
							<el-input class="input" v-model="form.phonenumber" maxlength="11"
								placeholder="+86  请输入您的手机号"></el-input>
						</el-form-item>
						<el-form-item prop="captcha">
							<el-input class="input" v-model="form.captcha" maxlength="8" placeholder="请输入验证码"></el-input>
							<el-button type="primary" plain class="send-sms-button" @click="onClickSendSmsBtn"
								:disabled="isSendSmsBtnCountdown">{{
									sendSmsBtnText
								}}</el-button>
						</el-form-item>
						<el-form-item prop="pwd" v-if="loginType == 'pwd'">
							<el-input class="input" show-password v-model="form.pwd" maxlength="18"
								placeholder="请输入您的密码"></el-input>
						</el-form-item>
						<el-form-item prop="pwdAgain" v-if="loginType == 'pwd'">
							<el-input class="input" show-password v-model="form.pwdAgain" maxlength="18"
								placeholder="请再次输入您的新密码"></el-input>
						</el-form-item>
						<el-form-item prop="region" v-if="loginType == 'pwd'">
							<el-cascader class="cascader" v-model="form.region" :options="region" :props="setting"
								placeholder="请选择您的意向城市"></el-cascader>
						</el-form-item>
						<div class="agreements">
							<el-checkbox v-model="agreementChecked">
								<div class="cont">我已阅读并同意</div>
							</el-checkbox>
							<span class="agreement" @click="onClickAgreement('1')">《用户服务协议》</span>
							<span class="agreement" @click="onClickAgreement('2')">《隐私政策》</span>
						</div>
						<el-button class="submit-button" type="primary" @click="handleSubmit">完成注册</el-button>
						<div class="links">
							<router-link class="link" :to="{ path: '/login', query: { by: 'pwd' } }"
								replace>返回登录</router-link>
						</div>
					</el-form>
					<div class="fix-tishi">物资处置企业注册，请联系我们：13176021118</div class="fix-tishi">
				</div>
			</div>
		</div>
		<WebFooterTwo class="footer"></WebFooterTwo>

		<!-- 注册成功的弹窗 -->
		<el-dialog class="register-success-dialog" :visible="showSuccessDialog" width="360px" :show-close="false"
			destroy-on-close @close="handleNotCert">
			<div class="register-success-dialog-wrapper">
				<div class="content">
					<i class="icon zn-iconfont zn-iconfont-chenggong"></i>
					<p class="tips">您已成功注册</p>
					<p class="subtips">欢迎加入企拍网大家庭</p>
					<p class="cert-tips">完成实名认证后解锁竞拍功能</p>
					<el-button class="cert-button" type="primary" @click="handleCertNow">立即认证</el-button>
					<div class="no-cert-tips" @click="handleNotCert">暂不认证，先逛逛吧</div>
				</div>
				<div class="close-box">
					<i class="close-icon zn-iconfont zn-iconfont-guanbi1" @click="handleNotCert"></i>
				</div>
			</div>
		</el-dialog>

		<!-- 用户服务协议弹窗 -->
		<AgreementDialog ref="userServiceAgreementDialog" title="用户服务协议" :content="userServiceAgreementContent">
		</AgreementDialog>

		<!-- 隐私政策弹窗 -->
		<AgreementDialog ref="privacyPolicyDialog" title="隐私政策" :content="privacyPolicyContent"></AgreementDialog>

		<!-- 喜好,爱好选择弹窗 -->
		<HobbySelectDialog ref="hobbySelectDialog" @next="handleHobbySelectNext"></HobbySelectDialog>
	</div>
</template>

<script>
import WebHeaderTwo from "@/components/WebHeaderTwo.vue"; // 页面Header
import WebFooterTwo from "@/components/WebFooterTwo.vue"; // 页面Footer
import AgreementDialog from "@/components/AgreementDialog.vue"; // 协议弹窗
import HobbySelectDialog from "@/components/HobbySelectDialog.vue"; // 喜好,爱好选择弹窗
import { apiSendSmsCode, apiGetServiceAgreement, apiGetPrivacyPolicy } from "@/apis/common.js";
import { apiRegister, apiGetRegionData } from "@/apis/user.js";
import { validatePhoneNumber, validatePass2 } from "@/utils/func.js";
import region from "@/assets/region.js";

export default {
	name: "PageRegister",
	components: {
		WebHeaderTwo,
		WebFooterTwo,
		AgreementDialog,
		HobbySelectDialog,
	},
	data() {
		return {
			loginType: "pwd", // 登录方式. "pwd": 密码登录
			region: [], // 地区
			form: {
				phonenumber: "", // 手机号
				captcha: "", // 验证码
				pwd: "", // 密码
				pwdAgain: "", // 确认密码
				region: "", // 地区
			},
			rules: {
				phonenumber: [{ required: true, message: "请输入您的手机号" }, { validator: validatePhoneNumber }],
				captcha: [{ required: true, message: "请输入验证码" }],
				pwd: [{ required: true, message: "请输入您的密码" }],
				pwdAgain: [
					{ required: true, message: "请输入您的密码" },
					{ validator: (rule, value, callback) => validatePass2(rule, value, callback, this.form.pwd) },
				],
				// region: [
				// 	{ required: true, message: "请选择意向城市" },
				// 	{
				// 		validator: (rule, value, callback) => {
				// 			if (value.length < 2) {
				// 				callback(new Error("请选择意向城市"));
				// 			} else {
				// 				callback();
				// 			}
				// 		},
				// 	},
				// ],
			},
			sendSmsBtnText: "发送验证码", // 发送验证码按钮上显示的文本
			isSendSmsBtnCountdown: false, // 发送验证码按钮是否正在倒计时
			sendSmsCountdownSeconds: 60, // 发送验证码按钮倒计时总秒数
			agreementChecked: false, // 勾选状态
			showSuccessDialog: false, // 注册成功提示弹窗的显示状态
			userServiceAgreementContent: "", // 用户服务协议内容
			privacyPolicyContent: "", // 隐私政策内容
			setting:{
				value:'id',
				label:'name'
			}
		};
	},
	mounted() {
		// 处理地区数据，删除区级数据
		// region.forEach((province) => {
		// 	province.children.forEach((city) => {
		// 		delete city.children;
		// 	});
		// });
		// this.region = region;

		this.queryRegionData()
		// 查询用户隐私协议内容
		this.queryServiceAgreement();

		// 查询隐私政策内容
		this.queryPrivacyPolicy();
	},
	methods: {
		queryRegionData() {
			apiGetRegionData().then(res => {
				console.log('region', res);
				this.region = res.finalData
			}).catch(err => {
				console.error(err);
			})
		},
		/**
		 * !查询用户隐私协议内容
		 */
		queryServiceAgreement() {
			apiGetServiceAgreement(
				{},
				{
					loadingShow: false,
					errorShow: false,
				}
			)
				.then((res) => {
					this.userServiceAgreementContent = res?.finalData?.services_agreement || "";
				})
				.catch((err) => console.error(err));
		},

		/**
		 * !查询隐私政策内容
		 */
		queryPrivacyPolicy() {
			apiGetPrivacyPolicy(
				{},
				{
					loadingShow: false,
					errorShow: false,
				}
			)
				.then((res) => {
					this.privacyPolicyContent = res?.finalData?.privacy_policy || "";
				})
				.catch((err) => console.error(err));
		},

		/**
		 * !点击了"发送验证码"按钮
		 */
		onClickSendSmsBtn() {
			// 验证手机号字段
			this.$refs.form.validateField("phonenumber", (errorMessage) => {
				// 验证通过
				if (!errorMessage) {
					// 手机号验证通过
					// 调用接口发送验证码
					apiSendSmsCode(
						{
							phone: this.form.phonenumber,
							mode:'register'
						},
						{
							loadingMsg: "正在发送验证码",
							successShow: true,
						}
					)
						.then((res) => {
							// 开始倒计时
							this.countdownStart();
						})
						.catch((err) => console.error(err));
				}
			});
		},

		/**
		 * !开始倒计时
		 */
		countdownStart() {
			// 倒计时总秒数
			let seconds = this.sendSmsCountdownSeconds;

			// 更新倒计时状态
			this.isSendSmsBtnCountdown = true;

			// 更新按钮文本
			this.sendSmsBtnText = seconds + "s后重新获取";

			// 设置定时器
			let timer = setInterval(() => {
				// 减秒
				seconds--;

				// 倒计时未结束
				// 更新按钮文本
				if (seconds >= 0) return (this.sendSmsBtnText = seconds + "s后重新获取");

				// 倒计时结束了
				// 更新倒计时文本
				this.sendSmsBtnText = " 重新发送";

				// 更新倒计时状态
				this.isSendSmsBtnCountdown = false;

				// 清除定时器
				clearInterval(timer);
			}, 1000);
		},

		/**
		 * !点击了"确认重置"按钮
		 */
		handleSubmit() {
			// 检查底部协议勾选状态
			if (!this.agreementChecked) {
				this.$message.error("请勾选底部的相关协议!");
				return false;
			}

			// 验证表单字段
			this.$refs.form.validate((valid) => {
				// 验证通过
				if (valid) {
					// 请求接口进行注册
					apiRegister(
						{
							phone: this.form.phonenumber,
							password: this.form.pwd,
							code: this.form.captcha,
							province_id: Number(this.form.region[0]),
							city_id: Number(this.form.region[1]),
							
						},
						{
							loadingMsg: "正在注册",
						}
					)
						.then((res) => {
							// 更新用户信息
							this.$store.commit("updateUserinfo", res?.finalData);

							// 显示选择喜好弹窗
							this.$refs.hobbySelectDialog?.open();
						})
						.catch((err) => console.error(err));
				}
			});
		},

		/**
		 * !协议链接点击事件
		 * @param {String} agreementType 协议类型. 1: 用户服务协议; 2: 隐私政策;
		 */
		onClickAgreement(agreementType) {
			// 查看用户服务协议
			if (agreementType == "1") {
				this.$refs.userServiceAgreementDialog.open();
				return false;
			}

			// 查看隐私政策
			if (agreementType == "2") {
				this.$refs.privacyPolicyDialog.open();
				return false;
			}
		},

		/**
		 * !立即认证
		 */
		handleCertNow() {
			this.$router.replace("/attestation");
		},

		/**
		 * !暂不认证
		 */
		handleNotCert() {
			if (sessionStorage["ZHONGNUO_FROM_PATH"]) this.$router.go(-1);
			else this.$router.replace({ path: "/" });
		},

		/**
		 * !选择爱后后的下一步
		 */
		handleHobbySelectNext() {
			// 显示注册成功提示窗
			this.showSuccessDialog = true;
		},
	},
};
</script>

<style scoped lang="scss">
.fix-tishi{
	color: #666;
	font-size: 16px;
	margin-top: 30px;
	text-align: justify;
}

.page-register-container {
	height: 100vh;
	display: flex;
	flex-direction: column;
	.header {
		flex-shrink: 0;
	}

	.page-register-content {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		padding: 30px 0;

		.left {
			flex-shrink: 0;
		}

		.welcome {
			display: block;
			width: 520px;
			height: auto;
		}

		.right {
			flex-shrink: 0;
		}

		.form-wrapper {
			width: 460px;
			background-color: #ffffff;
			padding: 87px 60px;
			
			.title {
				font-size: 24px;
				line-height: 1;
				font-weight: normal;
				text-align: center;
				padding-bottom: 27px;
				border-bottom: 1px solid #f4f4f4;
				margin-bottom: 38px;
			}

			.cascader {
				width: 100%;
			}

			.agreements {
				margin-bottom: 18px;

				.cont {
					display: inline-block;
					color: #666666;
					line-height: 22px;
				}

				.agreement {
					color: var(--theme-color);
					cursor: pointer;

					&:hover {
						opacity: 0.8;
					}
				}
			}

			.submit-button {
				width: 100%;
				height: 40px;
				border-radius: 0;
				padding: 0;
			}

			.links {
				display: flex;
				line-height: 1;
				margin-top: 21px;
				justify-content: center;

				.link {
					color: #333333;
					font-size: 12px;
					position: relative;
					text-decoration: none;
					padding: 0 12px;

					&:not(:first-child)::after {
						content: "";
						width: 1px;
						height: 8px;
						background-color: #333333;
						position: absolute;
						left: 0;
						top: 50%;
						margin-top: -4px;
					}

					&:hover {
						color: var(--theme-color);
					}
				}
			}

			.send-sms-button {
				position: absolute;
				height: 100%;
				border-radius: 0;
				right: 0;
				width: 126px;
				background-color: transparent;
				border: none;

				&:hover {
					opacity: 0.9;
					color: var(--theme-color);
				}
			}

			::v-deep {
				.form {
					.el-input__inner {
						height: 48px;
						border-radius: 0;
						background-color: #f5f5f5;
					}

					.el-checkbox__inner {
						border-radius: 50%;
						margin-right: -4px;
					}

					.el-radio__input {
						margin-top: -1px;
						margin-right: -4px;
					}
					.el-input.is-disabled .el-input__inner{
						border-color: #f5f5f5 !important;
					}
				}
			}
		}
	}

	.footer {
		flex-shrink: 0;
	}
}

::v-deep {
	.el-dialog {
		background-color: transparent;
		box-shadow: none;
	}

	.el-dialog__header {
		display: none;
	}

	.el-dialog__body {
		padding: 0;
	}

	.register-success-dialog-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;

		.content {
			display: flex;
			flex-direction: column;
			align-items: center;
			background-color: #ffffff;
			width: 100%;
			padding: 49px 30px 38px;
			margin-bottom: 12px;

			.icon {
				font-size: 66px;
				color: var(--theme-color);
				margin-bottom: 19px;
			}

			.tips {
				line-height: 1;
				font-size: 20px;
				margin-bottom: 13px;
			}

			.subtips {
				line-height: 1;
				color: #999999;
				margin-bottom: 39px;
			}

			.cert-tips {
				line-height: 1;
				font-size: 12px;
				margin-bottom: 15px;
			}

			.cert-button {
				width: 198px;
				height: 50px;
				padding: 0;
				border-radius: 0;
				margin-bottom: 13px;
			}

			.no-cert-tips {
				color: #999999;
				line-height: 1;
				cursor: pointer;

				&:hover {
					color: #666666;
				}
			}
		}

		.close-box {
			width: 44px;
			height: 44px;
			overflow: hidden;
			background-color: #bdbdbd;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			user-select: none;
			transition: 0.2s;

			.close-icon {
				font-size: 24px;
				color: #ffffff;
			}

			&:hover {
				background-color: var(--theme-color);
			}
		}
	}
}
</style>

<style>.page-register-container .el-input__inner {
	border-color: transparent;
}</style>

<!-- 入住企业列表项 -->
<template>
	<div class="settled-enterprise-list-item-container" :title="item.name || item?.company_name">
		<div class="logo-box">
			<img :src="item?.image || item?.logo" alt="" class="logo" />
		</div>
		<p class="name ellipsis2">{{ item?.name || item?.company_name }}</p>
	</div>
</template>

<script>
	export default {
		name: "SettledEnterpriseListItem",
		props: {
			item: {
				type: Object,
				default: () => {},
			},
		},
		data() {
			return {};
		},
		mounted() {},
		methods: {},
	};
</script>

<style lang="scss" scoped>
	.settled-enterprise-list-item-container {
		display: flex;
		align-items: center;
		height: 91px;
		padding: 18px 46px 12px 21px;
		background-color: #ffffff;

		.logo-box {
			width: 80px;
			height: 100%;
			flex-shrink: 0;
			margin-right: 18px;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.logo {
			max-width: 100%;
			max-height: 90%;
			display: block;
		}

		.name {
			font-size: 18px;
			line-height: 24px;
		}
	}
</style>

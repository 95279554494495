<!-- 首页 -->
<template>
	<div class="page-container page-index-container">
		<!-- #S 页头 -->
		<WebHeader :hideUserLogin="false"></WebHeader>
		<!-- #E 页头 -->

		<!-- #S 页体 -->
		<div class="page-index-content">
			<!-- #S 轮播 + 用户空间 -->
			<div class="topic-module-container">
				<div class="topic-module-wrapper layout-main">
					<!-- #S 轮播 -->
					<div class="topic-focus-swiper-wrapper">
						<FocusSwiper :list="pageIndexData?.banner"></FocusSwiper>
					</div>
					<!-- #E 轮播 -->

					<!-- #S 用户空间 -->
					<div class="topic-userinfo-wrapper">
						<UserZone :userRealTimeInfo="pageIndexData?.user_data" :helpCenterData="pageIndexData?.help_center_type"></UserZone>
					</div>
					<!-- #E 用户空间 -->
				</div>
			</div>
			<!-- #E 轮播 + 用户空间 -->

			<!-- #S 拍卖公告 + 最新资讯 -->
			<div class="notice-module-container layout-main index-module">
				<!-- #S 拍卖公告 -->
				<div class="notice-module-wrapper">
					<div class="common-title notice-module-title">
						<label class="label">拍卖公告</label>
						<router-link class="more" :to="{ name: 'NoticeCenterList' }">
							<span class="more-span">更多</span>
							<i class="icon zn-iconfont zn-iconfont-you"></i>
						</router-link>
					</div>
					<NoticeList :list="pageIndexData?.notice" keyName="title" listType="noticeList"></NoticeList>
				</div>
				<!-- #E 拍卖公告 -->

				<!-- #S 最新资讯 -->
				<div class="notice-module-wrapper">
					<div class="common-title notice-module-title">
						<label class="label">最新资讯</label>
						<router-link class="more" :to="{ name: 'NewsCenterList' }">
							<span>更多</span>
							<i class="icon zn-iconfont zn-iconfont-you"></i>
						</router-link>
					</div>
					<NoticeList :list="pageIndexData?.news" keyName="title" listType="newsList"></NoticeList>
				</div>
				<!-- #E 最新资讯 -->
			</div>
			<!-- #E 拍卖公告 + 最新资讯 -->

			<!-- #S 竞拍流程 -->
			<div class="bidding-process-container layout-main index-module" v-if="companyInfo?.index_process_img">
				<img :src="companyInfo?.index_process_img" class="image" alt="" />
			</div>
			<!-- #E 竞拍流程 -->

			<!-- #S 最近拍卖会 -->
			<div class="recent-auctions-module-container layout-main index-module">
				<div class="fix-title">
					<h1 class="recent-auctions-module-title common-title-big">最近拍卖会</h1>
					<router-link class="more-link" :to="{ name: 'AuctionList' }">
						<span>了解更多</span>
						<i class="icon zn-iconfont zn-iconfont-you"></i>
					</router-link>
				</div>
				<AuctionList class="list" :list="pageIndexData?.auction"></AuctionList>
				
			</div>
			<!-- #E 最近拍卖会 -->

			<!-- #S 成交案例 -->
			<div class="case-module-container index-module">
				<div class="case-module-wrapper layout-main">
					<div class="fix-title">
						<div class="left-box">
							<h1 class="case-module-title common-title-big">成交案例</h1>
							<h2 class="case-module-subtitile common-subtitle">/ 累计成交 {{ pageIndexData?.turnover_item_count }}例</h2>
						</div>
						<router-link class="more-link" :to="{ name: 'TransactionCasesList' }">
							<span>了解更多</span>
							<i class="icon zn-iconfont zn-iconfont-you"></i>
						</router-link>
					</div>
					<TargetList class="list" :list="pageIndexData?.turnover_item" isCase></TargetList>
				</div>
			</div>
			<!-- #E 成交案例 -->

			<!-- #S 入住企业精选 -->
			<div class="settled-enterprise-module-container index-module">
				<div class="settled-enterprise-module-wrapper layout-main">
					<div class="fix-title">
						<h1 class="settled-enterprise-module-title common-title-big">入驻企业精选</h1>
						<router-link class="more-link theme-white" :to="{ name: 'SettledEnterpriseList' }">
							<span>了解更多</span>
							<i class="icon zn-iconfont zn-iconfont-you"></i>
						</router-link>
					</div>
					<SettledEnterpriseList class="list" :list="pageIndexData?.enterprise"></SettledEnterpriseList>
				</div>
			</div>
			<!-- #E 入住企业精选 -->

			<!-- #S 求购租赁精选 -->
			<div class="buy-lease-module-container index-module">
				<div class="buy-lease-module-wrapper layout-main">
					<div class="fix-title">
						<h1 class="buy-lease-module-title common-title-big">求购租赁精选</h1>
						<router-link class="more-link" :to="{ name: 'BuyLeaseList' }">
							<span>了解更多</span>
							<i class="icon zn-iconfont zn-iconfont-you"></i>
						</router-link>
					</div>
					<BuyLeaseList class="list" :list="pageIndexData?.purchase_lease"></BuyLeaseList>
				</div>
			</div>
			<!-- #E 求购租赁精选 -->
		</div>
		<!-- #E 页体 -->

		<!-- #S 页脚 -->
		<WebFooter></WebFooter>
		<!-- #E 页脚 -->

		<!-- #S 侧边栏 -->
		<IndexToolbar></IndexToolbar>
		<!-- #E 侧边栏 -->
	</div>
</template>

<script>
	import WebHeader from "@/components/WebHeader.vue";
	import WebFooter from "@/components/WebFooter.vue";
	import IndexToolbar from "@/components/IndexToolbar.vue";
	import FocusSwiper from "@/components/FocusSwiper.vue";
	import UserZone from "@/components/UserZone.vue";
	import NoticeList from "@/components/NoticeList.vue";
	import AuctionList from "@/components/AuctionList.vue";
	import TargetList from "@/components/TargetList.vue";
	import SettledEnterpriseList from "@/components/SettledEnterpriseList.vue";
	import BuyLeaseList from "@/components/BuyLeaseList.vue";
	import { apiIndexViewData, apiGetServerTime } from "@/apis/common.js";
	import { mapGetters, mapState } from "vuex";
	export default {
		name: "PageIndex",
		components: {
			WebHeader,
			WebFooter,
			IndexToolbar,
			FocusSwiper,
			UserZone,
			NoticeList,
			AuctionList,
			TargetList,
			SettledEnterpriseList,
			BuyLeaseList,
		},
		data() {
			return {};
		},
		computed: {
			// 首页数据
			...mapGetters(["pageIndexData"]),

			// 平台信息
			...mapState(["companyInfo"]),
		},
		mounted() {
			this.init();
		},
		methods: {
			init() {
				// 查询页面数据
				this.queryData();
			},
			/**
			 * @description: 查询页面数据
			 * @return {*}
			 */
			queryData() {
				apiIndexViewData({}, { loadingShow: false }).then((res) => {
					console.log("首页数据->", res);
					// 为拍卖会列表每项添加服务器时间字段
					res?.finalData?.auction?.forEach((item) => (item.now_time = res?.finalData?.now_time));

					// 为成交案例列表每项添加服务器时间字段
					res?.finalData?.turnover_item?.forEach((item) => (item.now_time = res?.finalData?.now_time));

					// 更新首页数据
					this.$store.commit("updatePageIndexData", res?.finalData);
				});
			},
		},
	};
</script>

<style scoped lang="scss">
	.page-index-content {
		// background: linear-gradient(180deg, rgba(187, 28, 51, 0.8), rgba(255, 120, 118, 0) 680px);
		background:#f3f5f7;

		.common-title-big {
			padding-top: 49px;
			padding-bottom: 32px;
			text-align: center;
		}

		.common-subtitle {
			text-align: center;
			margin-top: -14px;
			margin-bottom: 37px;
		}

		.topic-module-container {
			padding-top: 24px;
			margin-bottom: 13px;

			.topic-module-wrapper {
				display: flex;
				height: 387px;
				align-items: stretch;
			}

			.topic-focus-swiper-wrapper {
				flex: 1;
			}

			.topic-userinfo-wrapper {
				margin-left: 12px;
				width: 392px;
				background-color: #ffffff;
			}
		}

		.notice-module-container {
			display: flex;
			justify-content: space-between;
			margin-bottom: 10px;

			.notice-module-wrapper {
				width: calc(50% - 6px);
				background-color: #ffffff;
				padding: 0 25px;
			}

			.notice-module-title {
				height: 63px;
				border-bottom: 1px solid #eeeeee;

				.label {
					font-size: 22px;
				}
			}
		}

		.bidding-process-container {
			.image {
				display: block;
				width: 100%;
				height: auto;
			}
		}

		.recent-auctions-module-container {
			/* padding-bottom: 47px; */

			.list {
				margin-bottom: 20px;
			}
			.fix-title{
				width: 100%;
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-top: 45px;
				margin-bottom: 30px;
				.more-link{
					margin: 0;
					border: none;
					justify-content: flex-end;
					color: #a6a6a6;
					width: auto;
					height: auto;
				}
				.more-link:hover{
					background: transparent;
					color: #666;
				}
				.common-title-big{
					padding: 0;
				}
				
			}
			
		}

		.case-module-container {
			/* background-image: url(~@/assets/images/bg-case.png); */
			background-position: center center;
			background-repeat: no-repeat;
			background-size: cover;
			/* padding-bottom: 63px; */

			.list {
				margin-bottom: 22px;
			}
			.fix-title{
				width: 100%;
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-top: 45px;
				margin-bottom: 30px;
				.left-box{
					display: flex;
					align-items: flex-end;
					.common-subtitle{
						margin: 0;
						margin-left: 15px;
					}
				}
				.more-link{
					margin: 0;
					border: none;
					justify-content: flex-end;
					color: #a6a6a6;
					width: auto;
					height: auto;
				}
				.more-link:hover{
					background: transparent;
					color: #666;
				}
				.common-title-big{
					padding: 0;
				}
				
			}
		}

		.settled-enterprise-module-container {
			/* background-image: url(~@/assets/images/bg-settled-enterprise.png); */
			background-position: center center;
			background-repeat: no-repeat;
			background-size: cover;
			/* padding-bottom: 76px; */

			.common-title-big {
				color: #333;
			}

			.list {
				margin-bottom: 34px;
			}
			.fix-title{
				width: 100%;
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-top: 45px;
				margin-bottom: 30px;
				.more-link{
					margin: 0;
					border: none;
					justify-content: flex-end;
					color: #a6a6a6;
					width: auto;
					height: auto;
				}
				.more-link:hover{
					background: transparent;
					color: #666;
				}
				.common-title-big{
					padding: 0;
				}
				
			}
		}

		.buy-lease-module-container {
			padding-bottom: 64px;

			.list {
				margin-bottom: 8px;
			}
			.fix-title{
				width: 100%;
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-top: 45px;
				margin-bottom: 30px;
				.more-link{
					margin: 0;
					border: none;
					justify-content: flex-end;
					color: #a6a6a6;
					width: auto;
					height: auto;
				}
				.more-link:hover{
					background: transparent;
					color: #666;
				}
				.common-title-big{
					padding: 0;
				}
				
			}
		}
	}
	.more{
		text-decoration: none;
		color: #a6a6a6;
	}
</style>

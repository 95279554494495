<!-- 帮助中心 - 首页 -->
<template>
	<div class="page-container page-help-center-index-container">
		<WebHeader></WebHeader>
		<!-- Banner -->
		<BannerInner class="banner-inner" :banner="banner" title="帮助中心"
			subtitle="若您在使用企拍网时遇到问题，<br/>您可以智能客服、自助服务、热线电话等寻求帮助。">
			<!-- 面包屑 -->
			<Breadcrumbs class="breadcrumbs" :list="breadcrumbs" theme="white"></Breadcrumbs>
		</BannerInner>

		<div class="page-help-center-index-content layout-main">
			<!-- 左侧导航菜单 -->
			<div class="module-left-navs">
				<HelpCenterNavs :activeIndex="activeNavValue" :defaultOpeneds="[]" :navs="navs" @click="onClickNavsMenu">
				</HelpCenterNavs>
			</div>

			<!-- 右侧对应菜单内容 -->
			<div class="module-right-content">
				<h1 class="big-title">{{ bigTitle }}</h1>
				<div class="richtext-list" v-if="contents && contents.length">
					<div class="richtext-module" v-for="item in contents" :key="item.id" :id="'_' + item.id + '_'">
						<h2 class="richtext-module-title">{{ item?.title || "" }}</h2>
						<div class="richtext-module-content">
							<TargetRichText :content="item.content"></TargetRichText>
						</div>
					</div>
				</div>
				<el-empty description="未找到相关问题" v-else></el-empty>
			</div>
		</div>
		<WebFooter></WebFooter>
	</div>
</template>

<script>
import WebHeader from "@/components/WebHeader.vue"; // 页面Header
import WebFooter from "@/components/WebFooter.vue"; // 页面Footer
import Breadcrumbs from "@/components/Breadcrumbs.vue"; // 面包屑
import BannerInner from "@/components/BannerInner.vue"; // Banner
import HelpCenterNavs from "@/components/HelpCenterNavs.vue"; // 左侧菜单
import TargetRichText from "@/components/TargetRichText.vue"; // 富文本模块
import { breadcrumbsHelpCenterIndex } from "@/breadcrumbs/index.js"; // 面包屑数据
import { apiGetHelpTypes, apiGetHelpTypeItems } from "@/apis/helpCenter.js";

export default {
	name: "PageHelpCenterIndex",
	components: {
		WebHeader,
		WebFooter,
		Breadcrumbs,
		BannerInner,
		HelpCenterNavs,
		TargetRichText,
	},
	data() {
		return {
			breadcrumbs: breadcrumbsHelpCenterIndex, // 面包屑数据
			banner: "pc/images/banner-help-center.png", // Banner图
			activeNavValue: "-1", // 当前激活的菜单对应绑定的index值
			navs: [], // 左侧菜单列表
			bigTitle: "", // 内容大标题
		};
	},
	computed: {
		// 显示的内容数据
		contents() {
			let nav = this.navs.find((item) => item.name == this.bigTitle);
			if (nav) return nav?.contents || null;
			return null;
		},
	},
	mounted() {
		// 得到要查看的大标题的值
		this.bigTitle = this.$route.query?.title || "";

		// 查询分类列表
		this.queryTypes();
	},
	methods: {
		/**
		 * !查询分类列表
		 * @param {Number|String} id 分类id
		 * @return {*}
		 */
		queryTypes() {
			apiGetHelpTypes()
				.then((res) => {
					console.log('typessss',res);
					// 得到分类列表
					res?.finalData?.forEach((item) => (item.contents = null));
					this.navs = res?.finalData || [];

					// 如果指定了要查看某个分类
					if (this.bigTitle) {
						let nav = this.navs.find((item) => item.name == this.bigTitle);
						if (nav) {
							this.activeNavValue = "_" + nav?.id + "_" + nav?.children?.[0]?.id; // 默认展开对应的分类
							this.queryTypeContents(nav?.id); // 则默认查询这个分类的对应内容
						}
						return;
					}

					// 否则
					this.activeNavValue = "_" + this.navs?.[0]?.id + "_" + this.navs?.[0]?.children?.[0]?.id; // 默认展开第一个分类
					this.bigTitle = this.navs?.[0]?.name || ""; // 设置大标题
					this.queryTypeContents(this.navs?.[0]?.id); // 默认查询第一个分类对应的内容
				})
				.catch((err) => console.error(err));
		},

		/**
		 * !查询分类对应的内容
		 * @param {Number|String} id 分类id
		 * @return {*}
		 */
		queryTypeContents(id) {
			apiGetHelpTypeItems({
				id,
			})
				.then((res) => {
					// 得到内容数据
					let contents = res?.finalData || [];

					// 将内容数据存放到对应的一级菜单内容中
					let nav = this.navs.find((item) => item.name == this.bigTitle);
					if (nav) nav.contents = contents;
				})
				.catch((err) => console.error(err));
		},

		/**
		 * !菜单点击事件
		 * @param {Object} menu 一级菜单数据
		 * @param {Object} item 二级菜单数据
		 */
		onClickNavsMenu(menu, item) {
			// 1. 如果点击的是同一个大分类
			if (menu?.name == this.bigTitle) {
				// 1.1. 无二级菜单: 什么都不需要做
				if (!menu.children) return;

				// 1.2. 有二级菜单: 直接滚动到二级菜单内容锚点定位位置,不需要再查询接口, 以减少接口请求次数
				document.getElementById("_" + item?.id + "_").scrollIntoView({
					behavior: "smooth",
					block: "start",
				});
				return;
			}

			// 2. 点击的不是同一个大分类
			// 2.1 更新内容大标题
			this.bigTitle = menu.name;

			// 2.2 检查是否查询过该大分类对应的内容数据
			// 2.2.1 已经查询过该大分类的内容数据,直接使用,不再请求接口进行查询,以减少接口请求次数
			if (menu.contents && menu.contents.length) return;

			// 2.2.2 查询分类对应的内容
			this.queryTypeContents(menu?.id);
		},
	},
};
</script>

<style scoped lang="scss">
.page-help-center-index-container {
	::v-deep {
		.banner-inner {
			.subtitle {
				font-size: 16px;
				font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, Segoe UI, Arial, Roboto, "PingFang SC", miui, "Hiragino Sans GB",
					"Microsoft Yahei", sans-serif;
				line-height: 25px;
			}
		}

		.breadcrumbs {
			position: absolute;
			color: #ffffff;
			left: 0;
			top: 0;
		}

	}

	.page-help-center-index-content {
		display: flex;
		margin-top: 19px;
		margin-bottom: 72px;

		.module-left-navs {
			flex-shrink: 0;
			margin-right: 12px;
		}

		.module-right-content {
			flex: 1;
			background-color: #ffffff;
			padding: 16px 89px 72px;

			.big-title {
				font-size: 28px;
				font-weight: normal;
				line-height: 36px;
				padding: 30px 0;
				border-bottom: 2px solid #f0f0f0;
			}

			.richtext-module {
				margin-top: 36px;
			}

			.richtext-module-title {
				font-size: 14px;
				margin-bottom: 28px;
			}
		}
	}
}

::v-deep .el-menu-item {
	white-space: inherit;
}
</style>

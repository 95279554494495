<!-- 企拍拍卖 -->
<template>
	<div class="page-container page-about-zhongnuo-auction-container">
		<WebHeader></WebHeader>
		<div class="page-about-zhongnuo-auction-content">
			<!-- Banner -->
			<BannerInner :banner="companyBannerImg" title="平台简介" subtitle="PLATFORM INTRODUCTION"></BannerInner>

			<!-- 公司介绍 -->
			<div class="intro-module" id="companyIntro">
				<div class="layout-main">
					<CompanyIntro :intro="intro"></CompanyIntro>
				</div>
			</div>

			<!-- 资讯播报 -->
			<div class="news-module">
				<div class="layout-main">
					<h1 class="title">资讯播报</h1>
					<NewsList class="list" :list="newsList"></NewsList>
					<router-link class="more-link" :to="{ name: 'NewsCenterList' }">更多资讯</router-link>
				</div>
			</div>

			<!-- 用户身份 -->
			<div class="user-identity-module">
				<div class="layout-main">
					<UserIdentity :info="userIdentityData"></UserIdentity>
				</div>
			</div>

			<!-- 荣誉资质 2023-12-6删除--> 
			<!-- <div class="honors-module" id="honorsQualifications">
				<div class="layout-main">
					<h1 class="title">荣誉资质</h1>
					<HonorsQualifications :list="honors"></HonorsQualifications>
				</div>
			</div> -->

			<!-- 合作企业 -->
			<div class="cooperative-enterprise-module" id="cooperativeEnterprise">
				<div class="layout-main">
					<h1 class="title">合作企业</h1>
					<SettledEnterpriseList class="list" :list="settledEnterpriseList"></SettledEnterpriseList>
				</div>
			</div>
		</div>
		<WebFooter></WebFooter>
	</div>
</template>

<script>
	import WebHeader from "@/components/WebHeader.vue"; // 页面Header
	import WebFooter from "@/components/WebFooter.vue"; // 页面Footer
	import BannerInner from "@/components/BannerInner.vue"; // Banner
	import CompanyIntro from "@/components/CompanyIntro.vue"; // 公司介绍
	import NewsList from "@/components/NewsList.vue"; // 资讯列表
	import UserIdentity from "@/components/UserIdentity.vue"; // 用户身份
	import HonorsQualifications from "@/components/HonorsQualifications.vue"; // 荣誉资质
	import SettledEnterpriseList from "@/components/SettledEnterpriseList.vue"; // 合作企业精选列表
	import { apiGetAboutUs } from "@/apis/about.js";

	export default {
		name: "PageAboutZhongNuoAuction",
		components: {
			WebHeader,
			WebFooter,
			BannerInner,
			CompanyIntro,
			NewsList,
			UserIdentity,
			HonorsQualifications,
			SettledEnterpriseList,
		},
		data() {
			return {
				// 公司介绍
				intro: {
					title: "平台简介", // 模块标题
					content: "", // 介绍内容
					image: "./images/img-18.png", // 介绍图片
					video: "", // 介绍视频
				},
				// 用户身份数据
				userIdentityData: {
					// 我是处置企业
					enterprise: {
						bigImage: "", // 左侧大图
						images: [], // 右侧小图
					},
					// 我是竞买人
					buyPeople: {
						bigImage: "", // 左侧大图
						images: [], // 右侧小图
					},
				},
				newsList: [], // 新闻列表
				honors: [], // 荣誉资质
				settledEnterpriseList: [], // 合作企业列表
				companyBannerImg:''
			};
		},
		mounted() {
			// 查询关于我们内容
			this.queryAboutUs();
		},
		methods: {
			/**
			 * !查询关于我们内容
			 */
			queryAboutUs() {
				apiGetAboutUs()
					.then((res) => {
						console.log('SDaDADA',res);
						// 顶部banner图
						this.companyBannerImg = res?.finalData?.company_profile_background;
						// 公司介绍
						this.intro.content = res?.finalData?.company_introduce;
						this.intro.video = res?.finalData?.company_video;

						// 新闻列表
						this.newsList = res?.finalData?.news || [];

						// 用户身份数据
						this.userIdentityData.enterprise.bigImage = res?.finalData?.handle_enterprise_left_image;
						this.userIdentityData.enterprise.images = res?.finalData?.handle_enterprise_images || [];
						this.userIdentityData.buyPeople.bigImage = res?.finalData?.compete_buy_left_image;
						this.userIdentityData.buyPeople.images = res?.finalData?.compete_buy_images || [];

						// 荣誉资质
						this.honors = res?.finalData?.honor || [];

						// 合作企业列表
						this.settledEnterpriseList = res?.finalData?.cooperate || [];

						// 滚动到锚点位置
						this.$nextTick(() => {
							this.scrollToAnchor();
						});
					})
					.catch((err) => console.error(err));
			},

			/**
			 * !滚动到锚点位置
			 */
			scrollToAnchor() {
				let anchor = this.$route.query?.anchor || "";
				if (!anchor) return false;
				let id = "";
				if (anchor == "公司介绍") id = "#companyIntro";
				else if (anchor == "企业合作" || anchor == "合作企业") id = "#cooperativeEnterprise";
				else if (anchor == "企业优势" || anchor == "荣誉资质") id = "#honorsQualifications";
				if (!id) return false;
				document.querySelector(id).scrollIntoView({
					behavior: "smooth",
					block: "start",
					inline: "nearest",
				});
			},
		},
	};
</script>

<style scoped lang="scss">
	.page-about-zhongnuo-auction-content {
		.intro-module {
			padding-top: 74px;
			padding-bottom: 76px;
			background-color: #ffffff;
		}

		.news-module {
			padding-top: 75px;
			padding-bottom: 74px;

			.layout-main {
				display: flex;
				flex-direction: column;
				align-items: center;
			}

			.title {
				font-size: 32px;
				font-weight: normal;
				line-height: 1;
				margin-bottom: 34px;
				text-align: center;
			}

			.list {
				width: 100%;
				background-color: #ffffff;
				margin-bottom: 29px;
			}

			.more-link {
				width: 132px;
				height: 44px;
				font-size: 16px;
				background-color: var(--theme-color);
				color: #ffffff;
				line-height: 1;

				&:hover {
					opacity: 0.8;
				}
			}
		}

		.user-identity-module {
			padding: 75px 0;
			background-image: url("@/assets/images/bg-iam.png");
			background-repeat: no-repeat;
			background-position: center center;
			background-size: cover;
		}

		.honors-module {
			padding-top: 98px;
			padding-bottom: 140px;
			background-image: url("@/assets/images/bg-honors.png");
			background-repeat: no-repeat;
			background-position: center center;
			background-size: cover 100%;

			.title {
				font-size: 32px;
				font-weight: normal;
				line-height: 1;
				margin-bottom: 81px;
				text-align: center;
			}
		}

		.cooperative-enterprise-module {
			padding-top: 76px;
			padding-bottom: 66px;

			.title {
				font-size: 32px;
				font-weight: normal;
				line-height: 1;
				margin-bottom: 33px;
				text-align: center;
			}
		}
	}
</style>

<!-- 拍卖会列表项(块级列表) -->
<template>
	<div class="component-auction-block-list-item">
		<div class="left">
			<!-- 封面 -->
			<div class="cover-box">
				<div class="box">
					<img :src="item.image" class="image" alt="" />
				</div>
			</div>

			<!-- Header -->
			<div class="head">
				<AuctionStatus :status="item?.status" class="status"></AuctionStatus>
				<div class="countdown" v-if="auctionStatus[item?.status]?.name === '即将开始' && countdownText">开拍倒计时：{{ countdownText }}</div>
			</div>
		</div>
		<div class="middle">
			<h1 class="title">{{ item?.title }}</h1>
			<div class="date">拍卖时间：{{ dayjs.unix(item.start_time).format("YYYY-MM-DD HH:mm") }}</div>
			<div class="row">
				<label class="label">拍卖企业：</label>
				<span class="value">{{ item.company_name }}</span>
			</div>
			<div class="datas">
				<div class="item">
					<span>{{ item.read_count }}</span
					>次围观
				</div>
				<div class="item">
					<span>{{ item.item_count }}</span
					>个标的
				</div>
			</div>
		</div>
		<div class="right">
			<el-button type="primary" class="button button-catalogue" @click.stop="handleClickTargetCatalog">标的目录</el-button>
		</div>
		<span class="badge badge-10" v-if="item?.type == 10">同步拍</span>
		<span class="badge badge-20" v-else-if="item?.type == 20">网络拍</span>
	</div>
</template>

<script>
	import { calcDateDiff } from "@/utils/func.js";
	import AuctionStatus from "@/components/AuctionStatus.vue";
	import { EnumAuctionStatus } from "@/const.js";
	export default {
		name: "AuctionBlockListItem",
		components: {
			AuctionStatus,
		},
		props: {
			item: Object,
		},
		data() {
			return {
				auctionStatus: EnumAuctionStatus, // 拍卖状态常量
				timer: null, // 倒计时定时器
				countdownText: "", // 倒计时显示内容
			};
		},
		mounted() {
			// 开始倒计时
			if (this.auctionStatus[this.item?.status]?.name === "即将开始") this.createCountdownTimer(this.item?.now_time, this.item?.start_time);
		},
		beforeDestroy() {
			// 组件销毁时清除倒计时定时器
			this.clearCountdownTimer();
		},
		methods: {
			/**
			 * @description: 创建倒计时定时器
			 * 				 注意：这里用 setTimeout 实现的倒计时，因为用 setInterval 实现的倒计时会出现长时间从后台切换到前台后倒计时不准确的问题
			 * @param {Number} startTime 倒计时开始时间，秒
			 * @param {Number} endTime 倒计时结束时间，秒
			 * @return {*}
			 */
			createCountdownTimer(startTime, endTime) {
				// Debug
				// console.log("⏰", startTime, endTime);

				// 无效的开始时间  = 清空倒计时文本 = 清除倒计时定时器
				// 无效的结束时间 = 清空倒计时文本 = 清除倒计时定时器
				if (!startTime || !endTime) {
					this.countdownText = "";
					this.clearCountdownTimer();
					return;
				}

				// 计算时间差
				let diffTime = calcDateDiff(this.dayjs.unix(parseInt(startTime)), this.dayjs.unix(parseInt(endTime)));

				// 天数为 0 时，不显示天数
				if (diffTime.DD == "00") this.countdownText = `${diffTime.hh}时${diffTime.mm}分${diffTime.ss}秒`;
				// 否则，显示天数
				else this.countdownText = `${diffTime.DD}天${diffTime.hh}时${diffTime.mm}分${diffTime.ss}秒`;

				// Debug
				// console.log("⏰", this.countdownText);

				// 如果倒计时开始时间 >= 倒计时结束时间 = 倒计时已结束 = 清除倒计时定时器
				if (startTime >= endTime) return this.clearCountdownTimer();

				// 递归自身
				this.timer = setTimeout(() => this.createCountdownTimer(startTime + 1, endTime), 1000);
			},

			/**
			 * @description: 清除倒计时定时器
			 * @return {*}
			 */
			clearCountdownTimer() {
				clearTimeout(this.timer);
				this.timer = null;
			},

			/**
			 * !"标的目录"按钮点击事件
			 * @return {*}
			 */
			handleClickTargetCatalog() {
				this.$router.push({
					name: "AuctionDetails",
					query: { no: this.item?.no },
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	.component-auction-block-list-item {
		position: relative;
		overflow: hidden;
		background-color: #ffffff;
		cursor: pointer;
		transition: 0.2s;
		display: flex;
		align-items: center;

		&::after {
			content: "";
			width: 100%;
			height: 100%;
			position: absolute;
			left: 0;
			top: 0;
			border: 2px solid transparent;
			z-index: 10;
			transition: 0.2s;
		}

		.left {
			flex-shrink: 0;
			width: 33.66%;
			position: relative;

			.cover-box {
				width: 100%;
			}

			.box {
				padding-bottom: 56.18%;
				position: relative;
				overflow: hidden;
				background-color: #dedede;
			}

			.image {
				position: absolute;
				width: 100%;
				height: 100%;
				left: 0;
				top: 0;
				z-index: 0;
			}

			.head {
				height: 33px;
				line-height: 33px;
				display: flex;
				position: absolute;
				width: 100%;
				left: 0;
				top: 0;
				z-index: 3;

				.status {
					width: 80px;
					height: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
					flex-shrink: 0;
				}

				.countdown {
					color: #ffffff;
					display: flex;
					align-items: center;
					padding: 0 22px;
					background-color: rgba($color: #000000, $alpha: 0.8);
					height: 100%;
				}
			}
		}

		.middle {
			flex: 1;
			padding: 0 44px;
			display: flex;
			flex-direction: column;
			justify-content: center;

			.title {
				font-size: 22px;
				font-weight: normal;
				line-height: 32px;
			}

			.date {
				font-weight: bold;
				margin-top: 8px;
				line-height: 1;
				margin-bottom: 24px;
			}

			.row {
				display: flex;
				color: #999999;
				line-height: 24px;

				.label {
					flex-shrink: 0;
				}
			}

			.datas {
				display: flex;
				align-items: center;
				color: #999999;
				line-height: 1;
				margin-top: 20px;

				.item {
					display: flex;
					align-items: center;
					padding: 0 32px;
					position: relative;
				}

				.item:first-child {
					padding-left: 0;
				}

				.item:not(:first-child)::after {
					content: "";
					width: 2px;
					height: 14px;
					position: absolute;
					left: 0;
					top: 50%;
					transform: translateY(-50%);
					background-color: #d1d1d1;
				}
			}
		}

		.right {
			flex-shrink: 0;
			padding-right: 90px;

			.button-catalogue {
				width: 112px;
				height: 44px;
				padding: 0;
				font-size: 16px;
				border-radius: 0;
			}
		}

		&:hover {
			transform: scale(0.99);

			&::after {
				border-color: var(--theme-color);
			}
		}

		.badge {
			position: absolute;
			padding: 5px 6px 5px 12px;
			font-size: 12px;
			right: 0;
			top: 45px;
			border-radius: 100px 0 0 100px;

			&-10 {
				background: var(--auction-type-bg-10);
				color: var(--auction-type-text-color-10);
			}

			&-20 {
				background: var(--auction-type-bg-20);
				color: var(--auction-type-text-color-20);
			}
		}
	}
</style>

<!-- 拍卖会列表项 -->
<template>
	<div class="auction-list-item-container">
		<!-- Header -->
		<div class="head status-30">
			<AuctionStatus :status="item?.status" class="status"></AuctionStatus>
			<div class="countdown" v-if="auctionStatus[item?.status]?.name === '即将开始' && countdownText">开拍倒计时：{{ countdownText }}</div>
		</div>

		<!-- 封面 -->
		<div class="cover-box">
			<img class="cover" :src="item?.image" alt="" />
			<span class="badge badge-10" v-if="item?.type == 10">同步拍</span>
			<span class="badge badge-20" v-else-if="item?.type == 20">网络拍</span>
		</div>

		<!-- 内容 -->
		<div class="texts">
			<!-- 标题、名称 -->
			<h1 class="name ellipsis2" :title="item?.name">{{ item?.title }}</h1>

			<!-- 开拍时间 -->
			<div class="text-row date">
				<label class="label">开拍时间：</label>
				<span class="value theme-color markpro">{{ dayjs.unix(item.start_time).format("YYYY-MM-DD HH:mm") }}</span>
			</div>

			<!-- 所在地 -->
			<div class="text-row address">
				<label class="label">所在地：</label>
				<span class="value">{{ item?.province_name }}</span>
			</div>
		</div>

		<!-- Footer -->
		<div class="foot">
			<!-- 浏览量 -->
			<div class="group">
				<label class="label">浏览量：</label>
				<span class="value">{{ item?.read_count }}次</span>
			</div>

			<!-- 标的数 -->
			<div class="group">
				<label class="label">标的数：</label>
				<span class="value">{{ item?.item_count }}个</span>
			</div>
		</div>
	</div>
</template>

<script>
	import { calcDateDiff } from "@/utils/func.js";
	import { EnumAuctionStatus } from "@/const.js";
	import AuctionStatus from "@/components/AuctionStatus.vue";
	export default {
		name: "AuctionListItem",
		components: {
			AuctionStatus,
		},
		props: {
			item: {
				type: Object,
				default: () => {
					return {};
				},
			},
		},
		data() {
			return {
				auctionStatus: EnumAuctionStatus, // 拍卖状态常量
				timer: null, // 倒计时定时器
				countdownText: "", // 倒计时显示内容
			};
		},
		mounted() {
			// 开始倒计时
			if (this.auctionStatus[this.item?.status]?.name === "即将开始") this.createCountdownTimer(this.item?.now_time, this.item?.start_time);
		},
		beforeDestroy() {
			// 组件销毁时清除倒计时定时器
			this.clearCountdownTimer();
		},
		methods: {
			/**
			 * @description: 创建倒计时定时器
			 * 				 注意：这里用 setTimeout 实现的倒计时，因为用 setInterval 实现的倒计时会出现长时间从后台切换到前台后倒计时不准确的问题
			 * @param {Number} startTime 倒计时开始时间，秒
			 * @param {Number} endTime 倒计时结束时间，秒
			 * @return {*}
			 */
			createCountdownTimer(startTime, endTime) {
				// Debug
				// console.log("⏰", startTime, endTime);

				// 无效的开始时间  = 清空倒计时文本 = 清除倒计时定时器
				// 无效的结束时间 = 清空倒计时文本 = 清除倒计时定时器
				if (!startTime || !endTime) {
					this.countdownText = "";
					this.clearCountdownTimer();
					return;
				}

				// 计算时间差
				let diffTime = calcDateDiff(this.dayjs.unix(parseInt(startTime)), this.dayjs.unix(parseInt(endTime)));

				// 天数为 0 时，不显示天数
				if (diffTime.DD == "00") this.countdownText = `${diffTime.hh}时${diffTime.mm}分${diffTime.ss}秒`;
				// 否则，显示天数
				else this.countdownText = `${diffTime.DD}天${diffTime.hh}时${diffTime.mm}分${diffTime.ss}秒`;

				// Debug
				// console.log("⏰", this.countdownText);

				// 如果倒计时开始时间 >= 倒计时结束时间 = 倒计时已结束 = 清除倒计时定时器
				if (startTime >= endTime) return this.clearCountdownTimer();

				// 递归自身
				this.timer = setTimeout(() => this.createCountdownTimer(startTime + 1, endTime), 1000);
			},

			/**
			 * @description: 清除倒计时定时器
			 * @return {*}
			 */
			clearCountdownTimer() {
				clearTimeout(this.timer);
				this.timer = null;
			},
		},
	};
</script>

<style lang="scss" scoped>
	.auction-list-item-container {
		position: relative;
		overflow: hidden;
		background-color: #ffffff;
		cursor: pointer;
		transition: 0.2s;

		&::after {
			content: "";
			width: 100%;
			height: 100%;
			position: absolute;
			left: 0;
			top: 0;
			border: 2px solid transparent;
			z-index: 10;
			transition: 0.2s;
		}

		.head {
			height: 33px;
			display: flex;
			position: absolute;
			width: 100%;
			left: 0;
			top: 0;
			z-index: 3;
			font-size: 13px;

			.status {
				width: 80px;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				color: #ffffff;
				flex-shrink: 0;
			}

			.countdown {
				color: #ffffff;
				display: flex;
				align-items: center;
				padding-left: 10px;
				background-color: rgba($color: #000000, $alpha: 0.8);
				flex: 1;
			}
		}

		.head.status-30 {
			.status-box {
				background-color: var(--theme-color);
			}
		}

		.head.status-40 {
			.status-box {
				background-color: var(--theme-color2);
			}
		}

		.head.status-50 {
			.status-box {
				background-color: var(--theme-color6);
			}
		}

		.cover-box {
			width: 100%;
			padding-bottom: 56.55%;
			position: relative;
			overflow: hidden;

			.cover {
				position: absolute;
				width: 100%;
				height: 100%;
				left: 0;
				top: 0;
				object-fit: cover;
				z-index: 1;
			}
		}

		.badge {
			position: absolute;
			padding: 5px 6px 5px 12px;
			font-size: 12px;
			right: 0;
			bottom: 0;
			border-radius: 100px 0 0 100px;
			z-index: 4;

			&-10 {
				background: var(--auction-type-bg-10);
				color: var(--auction-type-text-color-10);
			}

			&-20 {
				background: var(--auction-type-bg-20);
				color: var(--auction-type-text-color-20);
			}
		}

		.texts {
			padding: 13px 30px 20px 20px;
			position: relative;

			.name {
				font-size: 18px;
				font-weight: normal;
				line-height: 24px;
				margin-bottom: 18px;
				height: 48px;
			}

			.text-row {
				color: #999999;
				line-height: 22px;
			}

			.theme-color {
				color: var(--theme-color);
			}

			.markpro {
				font-family: "MarkPro";
			}
		}

		.foot {
			padding: 14px 30px 14px 20px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			transition: 0.5s;
			position: relative;

			&::before {
				content: "";
				width: calc(100% - 50px);
				position: absolute;
				height: 1px;
				background-color: #e6e6e6;
				left: 20px;
				top: 0;
				transition: 0.5s;
			}

			.group {
				.label {
					color: #999999;
				}
			}
		}

		&:hover {
			transform: translateY(-5px);

			&::after {
				border-color: var(--theme-color);
			}

			.foot {
				background-color: #f7f7f7;

				&::before {
					transform: scale(0);
					opacity: 0;
				}
			}
		}
	}
</style>

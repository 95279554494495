<!-- 拍卖会状态标签 -->
<template>
	<div
		class="component-buy-lease-type-tag"
		:class="`component-buy-lease-type-tag--${type}`"
		:style="`background: var(--buy-lease-type-bg-${type}); color: var(--buy-lease-type-text-color-${type});`"
	>
		{{ buyLeaseTypes[type]?.text || "" }}
	</div>
</template>

<script>
	import { EnmuBuyLeaseTypes } from "@/const.js";
	export default {
		name: "BuyLeaseTypeTag",
		props: {
			type: {
				type: [String, Number],
				default: "",
			},
		},
		data() {
			return {
				buyLeaseTypes: EnmuBuyLeaseTypes,
			};
		},
		mounted() {},
		methods: {},
	};
</script>

<style lang="scss" scoped>
	.component-buy-lease-type-tag {
		background: var(--buy-lease-type-bg-1);
		color: var(--buy-lease-type-text-color-1);
	}
</style>

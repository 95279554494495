<!-- 登录 -->
<template>
	<div class="page-container page-login-container">
		<WebHeaderTwo class="header"></WebHeaderTwo>
		<div class="page-login-content layout-main">
			<div class="left">
				<img class="welcome" src="../../public/images/login-welcome.png" alt="" />
			</div>
			<div class="right">
				<div class="form-wrapper">
					<h1 class="title">请登录您的账号</h1>
					<el-form class="form" ref="form" :model="form" :rules="rules">
						<el-form-item prop="roleValue">
							<el-select v-model="form.roleValue" placeholder="请选择您的登录角色" @change="roleHandler">
								<el-option v-for="item in options" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item prop="phonenumber" v-if="roleValue == 0">
							<el-input class="input" v-model="form.phonenumber" maxlength="11"
								placeholder="+86  请输入您的手机号"></el-input>
						</el-form-item>
						<el-form-item prop="userName" v-if="roleValue == 1 ||roleValue == 2 || roleValue == 3">
							<el-input class="input" v-model="form.userName" maxlength="11"
								placeholder="请输入您的用户名"></el-input>
						</el-form-item>
						<el-form-item prop="pwd" v-if="loginType == 'pwd'">
							<el-input class="input" show-password v-model="form.pwd" maxlength="18"
								placeholder="请输入您的密码"></el-input>
						</el-form-item>
						<el-form-item prop="captcha" v-if="loginType == 'captcha'">
							<el-input class="input" v-model="form.captcha" maxlength="8" placeholder="请输入验证码"></el-input>
							<el-button type="primary" plain class="send-sms-button" @click="onClickSendSmsBtn"
								:disabled="isSendSmsBtnCountdown">{{ sendSmsBtnText }}</el-button>
						</el-form-item>
						<el-button class="submit-button" type="primary" @click="handleSubmit">登录</el-button>
						<div class="links" v-if="loginType == 'pwd'">
							<router-link class="link" :to="{ path: '/login', query: { by: 'captcha' } }"
								v-if="roleValue == 0" replace>验证码登录</router-link>
							<router-link class="link" :to="{ path: '/forgot-password' }" replace
								v-if="roleValue == 0">忘记密码</router-link>
							<router-link class="link" :to="{ path: '/register' }" replace>注册账号</router-link>
						</div>
						<div class="links" v-if="loginType == 'captcha'">
							<router-link class="link" :to="{ path: '/login', query: { by: 'pwd' } }"
								replace>账号密码登录</router-link>
						</div>
					</el-form>
				</div>
			</div>
		</div>
		<WebFooterTwo class="footer"></WebFooterTwo>
	</div>
</template>

<script>
import WebHeaderTwo from "@/components/WebHeaderTwo.vue"; // 页面Header
import WebFooterTwo from "@/components/WebFooterTwo.vue"; // 页面Footer
import { apiLoginByPwd, apiLoginByCaptcha, apiEnterpriseLogin, apiAuctioneerLogin,apiEnterpriseDisLogin } from "@/apis/user.js";
import { validatePhoneNumber } from '@/utils/func'
import { apiSendSmsCode } from "@/apis/common.js";

export default {
	name: "PageLogin",
	components: {
		WebHeaderTwo,
		WebFooterTwo,
	},
	computed: {
		// 动态验证规则
		rules() {
			if (this.loginType == "pwd") {
				return {
					userName: [{ required: true, message: "请输入您的用户名" }],
					phonenumber: [{ required: true, message: "请输入您的手机号" }, { validator: validatePhoneNumber }],
					pwd: [{ required: true, message: "请输入您的密码" }],
					roleValue: [{ required: true, message: '请选择您的登录角色', trigger: 'change' }],
				};
			}
			if (this.loginType == "captcha") {
				return {
					userName: [{ required: true, message: "请输入您的用户名" }],
					phonenumber: [{ required: true, message: "请输入您的手机号" }, { validator: validatePhoneNumber }],
					captcha: [{ required: true, message: "请输入验证码" }],
					roleValue: [{ required: true, message: '请选择您的登录角色', trigger: 'change' }],

				};
			}
			return {};
		},
	},
	data() {
		return {
			loginType: "pwd", // 登录方式. "pwd": 密码登录; "captcha": 验证码登录;
			form: {
				phonenumber: "", // 手机号
				pwd: "", // 密码
				captcha: "", // 验证码
				roleValue: "0",
				userName: ''
			},
			sendSmsBtnText: "发送验证码", // 发送验证码按钮上显示的文本
			isSendSmsBtnCountdown: false, // 发送验证码按钮是否正在倒计时
			sendSmsCountdownSeconds: 60, // 发送验证码按钮倒计时总秒数
			options: [{
				value: '0',
				label: '竞买人'
			},
			{
				value: '1',
				label: '物资处置企业'
			},
			{
				value: '2',
				label: '拍卖公司'
			},
			{
				value: '3',
				label: '拍卖师'
			}],
			roleValue: 0,
		};
	},
	watch: {
		//监听路由地址的改变
		$route: {
			immediate: true,
			handler() {
				if (this.$route.query.by) {
					// 更新登录方式
					this.loginType = this.$route.query.by || "pwd";
					// 清除表单的验证提醒
					if (this.$refs.form) {
						this.$refs.form.clearValidate(["phonenumber", "pwd", "captcha"]);
					}
				}
			},
			deep: true,
		},
	},
	methods: {
		// 角色下拉选择
		roleHandler(data) {
			console.log(data);
			this.roleValue = data
		},
		/**
		 * !点击了"发送验证码"按钮
		 */
		onClickSendSmsBtn() {
			// 验证手机号字段
			this.$refs.form.validateField("phonenumber", (errorMessage) => {
				// 验证通过
				if (!errorMessage) {
					// 手机号验证通过
					// 调用接口发送验证码
					apiSendSmsCode(
						{
							phone: this.form.phonenumber,
							mode:'login'
						},
						{
							loadingMsg: "正在发送验证码",
							successShow: true,
						},
					)
						.then((res) => {
							// 开始倒计时
							this.countdownStart();
						})
						.catch((err) => console.error(err));
				}
			});
		},

		/**
		 * !开始倒计时
		 */
		countdownStart() {
			// 倒计时总秒数
			let seconds = this.sendSmsCountdownSeconds;

			// 更新倒计时状态
			this.isSendSmsBtnCountdown = true;

			// 更新按钮文本
			this.sendSmsBtnText = seconds + "s后重新获取";

			// 设置定时器
			let timer = setInterval(() => {
				// 减秒
				seconds--;

				// 倒计时未结束
				// 更新按钮文本
				if (seconds >= 0) return (this.sendSmsBtnText = seconds + "s后重新获取");

				// 倒计时结束了
				// 更新倒计时文本
				this.sendSmsBtnText = " 重新发送";

				// 更新倒计时状态
				this.isSendSmsBtnCountdown = false;

				// 清除定时器
				clearInterval(timer);
			}, 1000);
		},

		/**
		 * !点击了"登录"按钮
		 */
		handleSubmit(formName) {
			this.$refs.form.validate((valid) => {
				

				// 表单验证通过
				if (valid) {
					// 调用接口进行登录
					if (this.roleValue == 0) {
						this.onLogin();
					}
					if (this.roleValue == 1) {
						this.enterpriseDisLogin()
					}
					if (this.roleValue == 2) {
						this.enterpriseLogin()
					}
					if (this.roleValue == 3) {
						this.auctioneerLogin()
					}

				} else {
					return false;
				}
			});
		},

		// 企业后台登录
		enterpriseLogin() {
			// if(!this.form.userName){
			// 	this.$message({
			// 		message: '请输入您的用户名', 
			// 		type: 'error'
			// 	})
			// 	return false
			// }
			// if(!this.form.pwd){
			// 	this.$message({
			// 		message: '请输入您的密码', 
			// 		type: 'error'
			// 	})
			// 	return false
			// }
			apiEnterpriseLogin({
				account: this.form.userName,
				password: this.form.pwd,
			},
			).then(res => {
				console.log('qiye', res);
				if (res.code === 200) {
					this.$message({
						message: '登录成功！', type: 'success', onClose: function () {
							localStorage.clear();
							sessionStorage.clear();
							window.location.href = '/enterprise'

						}
					});
					// Cookies.remove('9ca8e8d13b4369cddfdab26c63d69ce2');
					// Cookies.remove('f63dcfe3c89a14e80c9cdf60b4b94f4b');
					// localStorage.clear();
					// sessionStorage.clear();
				}
			}).catch(err => {
				console.error(err);
			})
		},
		enterpriseDisLogin() {
			apiEnterpriseDisLogin({
				account: this.form.userName,
				password: this.form.pwd,
			},
			).then(res => {
				console.log('qiye', res);
				if (res.code === 200) {
					this.$message({
						message: '登录成功！', type: 'success', onClose: function () {
							localStorage.clear();
							sessionStorage.clear();
							window.location.href = '/disposal'

						}
					});
				}
			}).catch(err => {
				console.error(err);
			})
		},
		// 拍卖师登录
		auctioneerLogin() {
			// if(!this.form.userName){
			// 	this.$message({
			// 		message: '请输入您的用户名', 
			// 		type: 'error'
			// 	})
			// 	return false
			// }
			// if(!this.form.pwd){
			// 	this.$message({
			// 		message: '请输入您的密码', 
			// 		type: 'error'
			// 	})
			// 	return false
			// }
			apiAuctioneerLogin({
					username: this.form.userName,
					password: this.form.pwd,
				},
				{
					loadingMsg: "登录中",
					successShow: true,
					successMsg: "登录成功",
					successCallback: () => {
						window.location.href = '/auctioneer/#/auction/list'
					},
				},).then(res => {
					if (res.code == 1000) {
						console.log('pms', res);
						localStorage.setItem('auction',res.finalData.name)
						localStorage.setItem('AUCTIONEER-AUTHORIZATION',res.tokens['auctioneer-authorization'])
						// this.$message({
						// 	message: '登录成功！', type: 'success', onClose: function () {
						// 		localStorage.clear();
						// 		sessionStorage.clear();
						// 		window.location.href = '/auctioneer/#/auction/list'

						// 	}
						// })
					}
				})
		},
		/**
		 * !调用接口进行登录
		 */
		onLogin() {
			if (this.roleValue == 0) {
				// 密码登录
				if (this.loginType == "pwd") {
					apiLoginByPwd(
						{
							phone: this.form.phonenumber,
							password: this.form.pwd,
						},
						{
							loadingMsg: "登录中",
							successShow: true,
							successMsg: "登录成功",
							successCallback: () => {
								if (sessionStorage["ZHONGNUO_FROM_PATH"]) this.$router.go(-1);
								else this.$router.replace({ path: "/" });
							},
						},
					)
						.then((res) => {
							// 更新用户信息
							this.$store.commit("updateUserinfo", res?.finalData);
						})
						.catch((err) => console.error(err));
					return false;
				}

				// 验证码登录
				if (this.loginType == "captcha") {
					apiLoginByCaptcha(
						{
							phone: this.form.phonenumber,
							code: this.form.captcha,
						},
						{
							loadingMsg: "登录中",
							successShow: true,
							successMsg: "登录成功",
							successCallback: () => {
								if (sessionStorage["ZHONGNUO_FROM_PATH"]) this.$router.go(-1);
								else this.$router.replace({ path: "/" });
							},
						},
					)
						.then((res) => {
							// 更新用户信息
							this.$store.commit("updateUserinfo", res?.finalData);
						})
						.catch((err) => console.error(err));
					return false;
				}
			}

		},
	},
};
</script>

<style scoped lang="scss">
.page-login-container {
	height: 100vh;
	display: flex;
	flex-direction: column;

	.header {
		flex-shrink: 0;
	}

	.page-login-content {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		padding: 30px 0;

		.left {
			flex-shrink: 0;
		}

		.welcome {
			display: block;
			width: 520px;
			height: auto;
		}

		.right {
			flex-shrink: 0;
		}

		.form-wrapper {
			width: 460px;
			background-color: #ffffff;
			padding: 87px 60px;

			.el-select {
				/* margin-bottom: 22px; */
				width: 100%;
			}

			.title {
				font-size: 24px;
				line-height: 1;
				font-weight: normal;
				text-align: center;
				padding-bottom: 27px;
				border-bottom: 1px solid #f4f4f4;
				margin-bottom: 38px;
			}

			.submit-button {
				width: 100%;
				height: 40px;
				border-radius: 0;
				padding: 0;
			}

			.links {
				display: flex;
				line-height: 1;
				margin-top: 21px;
				justify-content: center;

				.link {
					color: #333333;
					font-size: 12px;
					position: relative;
					text-decoration: none;
					padding: 0 12px;

					&:not(:first-child)::after {
						content: "";
						width: 1px;
						height: 8px;
						background-color: #333333;
						position: absolute;
						left: 0;
						top: 50%;
						margin-top: -4px;
					}

					&:hover {
						color: var(--theme-color);
					}
				}
			}

			.send-sms-button {
				position: absolute;
				height: 100%;
				border-radius: 0;
				right: 0;
				width: 126px;
				background-color: transparent;
				border: none;

				&:hover {
					opacity: 0.9;
					color: var(--theme-color);
				}
			}

			::v-deep {
				.form {
					.el-input__inner {
						height: 48px;
						border-radius: 0;
						background-color: #f5f5f5;
					}
				}
			}
		}
	}

	.footer {
		flex-shrink: 0;
	}
}
</style>

<style>
.page-login-container .el-input__inner {
	border-color: transparent;
}
</style>

<!-- 拍卖会状态标签 -->
<template>
	<div
		class="component-auction-status"
		:class="`component-auction-status--${status}`"
		:style="`--auction-status-bg: var(--auction-status-bg-${status}); --auction-status-text-color: var(--auction-status-text-color-${status});`"
	>
		{{ auctionStatus[status]?.name || "" }}
	</div>
</template>

<script>
	import { EnumAuctionStatus } from "@/const.js";
	export default {
		name: "AuctionStatus",
		props: {
			status: {
				type: [String, Number],
				default: "",
			},
		},
		data() {
			return {
				auctionStatus: EnumAuctionStatus,
			};
		},
		mounted() {},
		methods: {},
	};
</script>

<style lang="scss" scoped>
	.component-auction-status {
		background: var(--auction-status-bg);
		color: var(--auction-status-text-color);
	}
</style>

import { getUserinfo, getCompanyInfo, getPageIndexData } from "./func.js";

export default {
	// 当前Header中激活的导航菜单的名称
	activeHeaderNavName: "",
	// 公司信息
	companyInfo: getCompanyInfo(),
	// 用户信息
	userinfo: getUserinfo(),
	// 首页数据
	pageIndexData: getPageIndexData(),
};

<!-- 拍卖会列表 -->
<template>
	<div class="auction-list-container">
		<div class="wrapper">
			<div class="item" v-for="(item, index) in list" :key="index" @click="onClickItem(item)">
				<AuctionListItem :item="item"></AuctionListItem>
			</div>
		</div>
	</div>
</template>

<script>
import AuctionListItem from "@/components/AuctionListItem.vue";
export default {
	name: "AuctionList",
	components: {
		AuctionListItem,
	},
	props: {
		list: Array,
	},
	methods: {
		/**
		 * !item点击事件
		 * @param {Object} item item数据
		 */
		onClickItem(item) {
			// 跳转到拍卖会详情页
			this.$router.push({ name: "AuctionDetails", query: { no: item?.no } });
		},
	},
};
</script>

<style lang="scss" scoped>
.auction-list-container {
	.wrapper {
		margin: 0 -6px;
		display: flex;
		flex-wrap: wrap;
	}

	.item {
		width: 25%;
		padding: 0 6px;
		margin-bottom: 19px;
	}
}
</style>

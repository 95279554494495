<!-- 入住企业列表 -->
<template>
	<div class="settled-enterprise-list-container">
		<div class="wrapper">
			<div class="item" v-for="(item, index) in list" :key="index">
				<SettledEnterpriseListItem :item="item"></SettledEnterpriseListItem>
			</div>
		</div>
	</div>
</template>

<script>
import SettledEnterpriseListItem from "@/components/SettledEnterpriseListItem.vue";
export default {
	name: "SettledEnterpriseList",
	components: {
		SettledEnterpriseListItem,
	},
	props: {
		list: Array,
	},
};
</script>

<style lang="scss" scoped>
.settled-enterprise-list-container {
	.wrapper {
		margin: 0 -6px;
		display: flex;
		flex-wrap: wrap;
	}

	.item {
		width: 25%;
		padding: 0 6px;
		margin-bottom: 10px;
	}
}
</style>

/**
 * ****************************************************************************************************
 * 标的模块相关接口
 * ****************************************************************************************************
 */
import HiRequest from "@/request/index.js";
export const hr = new HiRequest();

// 获取搜索数据
export const apiGetSearchItems = (data = {}, options = {}) => hr.get("api/v1/auction_item/search_data", data, options);

// 获取标的列表
export const apiGetTargetList = (data = {}, options = {}) => hr.get("api/v1/auction_item/lists", data, options);

// 获取标的详情
export const apiGetTargetDetails = (data = {}, options = {}) => hr.get("api/v1/auction_item/detail", data, options);

// 添加收藏
export const apiCollection = (data = {}, options = {}) => hr.post("api/v1/collect/add", data, options);

// 取消收藏
export const apiUncollection = (data = {}, options = {}) => hr.post("api/v1/collect/cancel", data, options);

// 获取报名页数据
export const apiGetEnrollPageData = (data = {}, options = {}) => hr.get("api/v1/auction_item/enroll_view", data, options);

// 报名
export const apiOnEnroll = (data = {}, options = {}) => hr.post("api/v1/auction_item/enroll", data, options);

// 更多聊天消息
export const apiGetMoreChatMessage = (data = {}, options = {}) => hr.get("api/v1/auction_item/more_speech", data, options);

// 更多竞价记录
export const apiGetMoreOfferList = (data = {}, options = {}) => hr.get("api/v1/auction_item/more_quote", data, options);

// 用户保持在线心跳
export const apiUserOnlineBearheart = (data = {}, options = {}) => hr.post("api/v1/auction_item/enroll_online", data, options);

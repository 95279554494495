<!-- 求购租赁详情页 - 求购租赁介绍模块 -->
<template>
	<div class="component-buy-lease-detail-intro">
		<div class="left">
			<div class="image-preview-box">
				<img :src="intro?.images?.[swiperCurrent]" class="image" alt="" />
			</div>
			<div class="swiper">
				<i class="swiper-arrow swiper-arrow-prev zn-iconfont zn-iconfont-zuo"></i>
				<div class="swiper-container">
					<div class="swiper-wrapper">
						<div class="swiper-slide" v-for="item in intro?.images" :key="item">
							<div class="image-box">
								<img :src="item" class="image" alt="" />
							</div>
						</div>
					</div>
				</div>
				<i class="swiper-arrow swiper-arrow-next zn-iconfont zn-iconfont-you"></i>
			</div>
		</div>
		<div class="right">
			<!-- 标题 + 类别 -->
			<div class="title-box">
				<h1 class="title">{{ intro?.name || "" }}</h1>
				<span class="tag tag-1" v-if="intro?.mode_id == 1">求购</span>
				<span class="tag tag-2" v-else-if="intro?.mode_id == 2">租赁</span>
				<span class="tag tag-3" v-else-if="intro?.mode_id == 3">采购</span>
				<span class="tag tag-4" v-else-if="intro?.mode_id == 4">置换</span>
			</div>

			<!-- 价格 -->
			<div class="price-box" v-if="intro?.price_type == 1">
				<span class="symbol">¥</span>
				<span class="price">面议</span>
			</div>
			<div class="price-box" v-else-if="intro?.price_type == 2">
				<span class="symbol">¥</span>
				<span class="price">{{ formatPrice(intro?.price) }}</span>
				<span class="unit"> /{{ intro?.price_unit }}</span>
			</div>

			<!-- 字段 -->
			<div class="fileds">
				<div class="items">
					<div class="item left">
						<label class="label" v-if="intro?.mode_id == 1">求购企业：</label>
						<label class="label" v-else-if="intro?.mode_id == 2">发布企业：</label>
						<label class="label" v-else-if="intro?.mode_id == 3">发布企业：</label>
						<label class="label" v-else-if="intro?.mode_id == 4">发布企业：</label>
						<span class="value theme-color">{{ intro?.company_name }}</span>
					</div>
					<div class="item">
						<label class="label">物资类别：</label>
						<span class="value">{{ intro?.type_name.join(',') }}</span>
					</div>
				</div>
				<div class="items">
					<div class="item left">
						<label class="label">发布日期：</label>
						<span class="value">{{ dayjs.unix(intro?.create_time).format("YYYY-MM-DD HH:mm:ss") }}</span>
					</div>
					<div class="item">
						<label class="label" v-if="intro?.mode_id == 1">需求地区：</label>
						<label class="label" v-else-if="intro?.mode_id == 2">所在地区：</label>
						<label class="label" v-else-if="intro?.mode_id == 3">所在地区：</label>
						<label class="label" v-else-if="intro?.mode_id == 4">所在地区：</label>
						<span class="value">{{ intro?.region }}</span>
					</div>
				</div>
				<div class="items">
					<div class="item left" v-if="intro?.mode_id == 1">
						<label class="label">求购时间：</label>
						<span class="value">{{ dayjs.unix(intro?.show_time).format("YYYY-MM-DD HH:mm:ss") }}</span>
					</div>
					<div class="item">
						<label class="label">需求说明：</label>
						<span class="value">{{ intro?.supplement }}</span>
					</div>
				</div>
			</div>

			<el-button type="primary" class="bottom-button" @click="onClickBotBtn" v-if="intro?.mode_id == 1">参与报价</el-button>
			<el-button type="primary" class="bottom-button" @click="onClickBotBtn" v-else-if="intro?.mode_id == 2">我有需求</el-button>
			<el-button type="primary" class="bottom-button" @click="onClickBotBtn" v-else-if="intro?.mode_id == 3">我有需求</el-button>
			<el-button type="primary" class="bottom-button" @click="onClickBotBtn" v-else-if="intro?.mode_id == 4">我要置换</el-button>
		</div>
		<el-dialog
			:visible="dialogVisible"
			width="494px"
			modal-append-to-body
			append-to-body
			custom-class="component-buy-lease-detail-intro-dialog"
			:show-close="false"
			destroy-on-close
			close-on-click-modal
			@close="onCloseDialog"
		>
			<div class="dialog-content">
				<el-form ref="componentBuyLeaseDetailIntroDialogForm" :model="form" class="form" :rules="rules">
					<el-form-item style="margin-bottom: 0">
						<div class="input-group">
							<el-form-item prop="name">
								<el-input class="input" v-model="form.name" placeholder="请输入姓名" style="width: 202px"></el-input>
							</el-form-item>
							<el-form-item prop="tel">
								<el-input class="input" v-model="form.tel" placeholder="请输入联系方式" style="width: 202px"></el-input>
							</el-form-item>
						</div>
					</el-form-item>
					<el-form-item prop="company">
						<el-input class="input" v-model="form.company" placeholder="请输入您的单位名称"></el-input>
					</el-form-item>
					<el-form-item prop="desc">
						<el-input class="input" type="textarea" :rows="4" v-model="form.desc" placeholder="请输入报价/需求描述" resize="none"></el-input>
					</el-form-item>
				</el-form>
				<el-button type="primary" class="submit-button" @click="handleSubmit">提交</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import { validatePhoneNumber } from "@/utils/func.js";
	import { apiSubmitNeedForm } from "@/apis/buyLease.js";
	import { mapGetters } from "vuex";

	export default {
		name: "BuyLeaseDetailIntro",
		props: {
			intro: Object, // 简介,介绍
		},
		data() {
			return {
				swiperInstance: null, // swiper实例
				swiperCurrent: 0, // swiper下标
				dialogVisible: false, // 对话框显示状态
				form: {
					name: "", // 姓名
					tel: "", // 联系方式
					company: "", // 公司名称
					desc: "", // 需求描述
				},
				rules: {
					name: [{ required: true, message: "请输入姓名" }],
					tel: [{ required: true, message: "请输入联系方式" }, { validator: validatePhoneNumber }],
					company: [{ required: true, message: "请输入您的单位名称" }],
					desc: [{ required: true, message: "请输入报价/需求描述" }],
				},
			};
		},
		computed: {
			...mapGetters(["userinfo"]),
		},
		watch: {
			// 监听轮播图数据
			"intro.images": {
				handler() {
					this.$nextTick(() => {
						let timer = setTimeout(() => {
							clearTimeout(timer);
							if (this.swiperInstance) {
								// 更新Swiper
								this.swiperInstance.update();
							} else {
								// 实例化swiper
								this.initSwiper();
							}
						}, 300);
					});
				},
				deep: true,
				immediate: true,
			},
		},
		mounted() {},
		methods: {
			/**
			 * !实例化swiper
			 */
			initSwiper() {
				this.swiperInstance = new this.Swiper(".component-buy-lease-detail-intro .swiper-container", {
					slidesPerView: 4,
					spaceBetween: 7,
					loop: false,
					loopPreventsSlide: true,
					slideToClickedSlide: true,
					navigation: {
						nextEl: ".component-buy-lease-detail-intro .swiper-arrow-next",
						prevEl: ".component-buy-lease-detail-intro .swiper-arrow-prev",
					},
					on: {
						init: (swiper) => {
							this.swiperCurrent = this?.swiperInstance?.realIndex || 0;
						},
						transitionEnd: (swiper) => {
							this.swiperCurrent = this?.swiperInstance?.realIndex || 0;
						},
					},
				});
			},

			/**
			 * !点击了底部按钮
			 */
			onClickBotBtn() {
				// 未登录用户先去登录
				if (!this.userinfo) {
					this.$router.push({ name: "Login" });
					return;
				}

				// 显示对话框
				this.dialogVisible = true;
			},

			/**
			 * !关闭对话框的回调函数
			 */
			onCloseDialog() {
				this.dialogVisible = false;
			},

			/**
			 * !提交事件
			 */
			handleSubmit() {
				this.$refs.componentBuyLeaseDetailIntroDialogForm.validate((valid) => {
					// 表单验证通过
					if (valid) {
						// 提交表单
						this.submitForm();
					}
				});
			},

			/**
			 * !提交表单
			 */
			submitForm() {
				apiSubmitNeedForm(
					{
						id: this.intro?.id,
						phone: this.form?.tel,
						name: this.form?.name,
						company_name: this.form?.company,
						describe: this.form?.desc,
					},
					{
						loadingMsg: "正在提交",
						successShow: true,
					}
				)
					.then((res) => {
						// 关闭对话框
						this.dialogVisible = false;

						// 清空表单数据
						this.form.tel = "";
						this.form.name = "";
						this.form.company = "";
						this.form.desc = "";
					})
					.catch((err) => console.error(err));
			},
		},
	};
</script>

<style lang="scss" scoped>
	.component-buy-lease-detail-intro {
		background-color: #ffffff;
		padding: 25px;
		display: flex;

		.left {
			width: 316px;
			flex-shrink: 0;
			position: relative;

			.image-preview-box {
				padding-bottom: 100%;
				position: relative;
				background-color: #f5f5f5;
				overflow: hidden;
				margin-bottom: 16px;

				.image {
					position: absolute;
					width: 100%;
					height: 100%;
					display: block;
					left: 0;
					top: 0;
					object-fit: contain;
				}
			}

			.swiper {
				display: flex;
				align-items: center;
			}

			.swiper-arrow {
				flex-shrink: 0;
				color: #c7c7c7;
				font-size: 26px;
				cursor: pointer;
				transition: 0.2s;

				&.swiper-arrow-prev {
					margin-right: 6px;
				}

				&.swiper-arrow-next {
					margin-left: 6px;
				}

				&.swiper-button-disabled {
					color: #999999;
				}

				&:not(.swiper-button-disabled):hover {
					color: var(--theme-color);
				}
			}

			.swiper-container {
				flex: 1;
				overflow: hidden;

				.image-box {
					padding-bottom: 100%;
					overflow: hidden;
					position: relative;
					cursor: pointer;

					&::after {
						content: "";
						width: 100%;
						height: 100%;
						left: 0;
						top: 0;
						z-index: 2;
						position: absolute;
						border: 2px solid transparent;
						transition: 0.2s;
					}

					.image {
						position: absolute;
						width: 100%;
						height: 100%;
						display: block;
						left: 0;
						top: 0;
						object-fit: cover;
					}
				}

				.swiper-slide-active {
					.image-box {
						&::after {
							border-color: var(--theme-color);
						}
					}
				}
			}
		}

		.right {
			flex: 1;
			overflow: hidden;
			display: flex;
			flex-direction: column;
			position: relative;
			padding: 30px 88px 0 96px;

			.title-box {
				margin-bottom: 24px;

				.title {
					font-size: 22px;
					display: inline-block;
					line-height: 30px;
					vertical-align: middle;
				}

				.tag {
					display: inline-block;
					font-size: 14px;
					line-height: 1;
					padding: 5px 9px;
					background: var(--theme-color);
					vertical-align: middle;
					color: #ffffff;
					margin-left: 14px;
				}

				.tag-1 {
					background: var(--buy-lease-type-bg-1);
					color: var(--buy-lease-type-text-color-1);
				}

				.tag-2 {
					background: var(--buy-lease-type-bg-2);
					color: var(--buy-lease-type-text-color-2);
				}

				.tag-3 {
					background: var(--buy-lease-type-bg-3);
					color: var(--buy-lease-type-text-color-3);
				}

				.tag-3 {
					background: var(--buy-lease-type-bg-4);
					color: var(--buy-lease-type-text-color-4);
				}
			}

			.price-box {
				color: var(--theme-color);
				line-height: 1;
				padding-bottom: 23px;
				border-bottom: 1px solid #f3f3f3;
				margin-bottom: 20px;

				.symbol {
					font-size: 16px;
					display: inline-block;
				}

				.price {
					font-size: 42px;
					font-family: "MarkPro";
					margin-left: 9px;
					margin-right: 6px;
					line-height: 27px;
					display: inline-block;
					vertical-align: bottom;
				}

				.unit {
					font-size: 22px;
					display: inline-block;
				}
			}

			.items {
				display: flex;
				line-height: 1.6;
				padding: 8px 0;

				.item {
					display: flex;
					color: #999999;

					.label {
						flex-shrink: 0;
					}
				}

				.item.left {
					flex-shrink: 0;
					width: 42.26%;
				}

				.theme-color {
					color: var(--theme-color);
				}
			}

			.bottom-button {
				margin-top: 20px;
				width: 132px;
				height: 44px;
				padding: 0;
				font-size: 16px;
				border-radius: 0;
			}
		}
	}
</style>

<style lang="scss">
	.component-buy-lease-detail-intro-dialog {
		.el-dialog__header {
			display: none;
		}

		.el-dialog__body {
			padding: 32px 39px 39px 39px;
		}

		.input-group {
			display: flex;
			justify-content: space-between;
		}

		.el-form-item {
			margin-bottom: 10px;
		}

		.el-input__inner {
			border-radius: 0;
			height: 48px;
		}

		.el-textarea textarea {
			font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, Segoe UI, Arial, Roboto, "PingFang SC", miui, "Hiragino Sans GB",
				"Microsoft Yahei", sans-serif;
			padding-top: 14px;
			border-radius: 0;
		}

		.submit-button {
			height: 46px;
			padding: 0;
			font-weight: bold;
			width: 100%;
			border-radius: 0;
		}

		.el-form-item__error {
			bottom: 5px;
			top: auto;
			width: 100%;
			text-align: right;
			padding-right: 8px;
		}
	}
</style>

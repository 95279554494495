<!-- 通知、资讯列表项 -->
<template>
	<div class="notice-list-item-container">
		<div class="content ellipsis2">{{ text }}</div>
	</div>
</template>

<script>
	export default {
		name: "NoticeItem",
		props: {
			text: String,
		},
	};
</script>

<style lang="scss" scoped>
	.notice-list-item-container {
		color: #666666;
		line-height: 24px;
		height: 70px;
		display: flex;
		align-items: center;
		cursor: pointer;
		transition: color 0.2s;
		color: #666666;

		&:hover {
			color: var(--theme-color);
		}

		.content {
			height: 48px;
			position: relative;

			&::before {
				content: "●";
				border-radius: 50%;
				line-height: inherit;
				transform: scale(0.7);
				display: inline-block;
			}
		}
	}
</style>

<!-- 入驻企业 - 详情 -->
<template>
	<div class="page-container page-settled-enterprise-details-container">
		<WebHeader></WebHeader>
		<div class="page-settled-enterprise-details-content">
			<!-- 面包屑 -->
			<Breadcrumbs :list="breadcrumbs"></Breadcrumbs>

			<!-- 主内容区域 -->
			<div class="layout-main">
				<!-- 基础信息卡片 -->
				<SettledEnterpriseBlockListItem :item="info" :effect="false" class="info-card"></SettledEnterpriseBlockListItem>

				<!-- Tabs && Contents -->
				<div class="tabs-and-contents">
					<el-tabs type="border-card" v-model="tabsValue" @tab-click="handleTabClick">
						<!-- 标的列表 -->
						<el-tab-pane label="标的列表" name="targetList">
							<div class="tab-content">
								<TargetList :list="tabs.targetList.list" v-if="tabs.targetList.list.length"></TargetList>
								<el-empty description="数据为空" v-else></el-empty>
								<div class="common-pagination">
									<el-pagination
										background
										:page-size="tabs.targetList.pageSize"
										:total="tabs.targetList.total"
										:pager-count="5"
										:current-page="tabs.targetList.pageIndex"
										hide-on-single-page
										@current-change="onChangeTargetListPageIndex"
									></el-pagination>
								</div>
							</div>
						</el-tab-pane>

						<!-- 拍卖会列表 -->
						<el-tab-pane label="拍卖会列表" name="auctionList">
							<div class="tab-content">
								<AuctionBlockList :list="tabs.auctionList.list" v-if="tabs.auctionList.list.length"></AuctionBlockList>
								<el-empty description="数据为空" v-else></el-empty>
								<div class="common-pagination">
									<el-pagination
										background
										:page-size="tabs.auctionList.pageSize"
										:total="tabs.auctionList.total"
										:pager-count="5"
										:current-page="tabs.auctionList.pageIndex"
										hide-on-single-page
										@current-change="onChangeAuctionListPageIndex"
									></el-pagination>
								</div>
							</div>
						</el-tab-pane>

						<!-- 求购信息 -->
						<el-tab-pane label="求购信息" name="buyInfo">
							<div class="tab-content">
								<BuyLeaseList :list="tabs.buyInfo.list" v-if="tabs.buyInfo.list.length"></BuyLeaseList>
								<el-empty description="数据为空" v-else></el-empty>
								<div class="common-pagination">
									<el-pagination
										background
										:page-size="tabs.buyInfo.pageSize"
										:total="tabs.buyInfo.total"
										:pager-count="5"
										:current-page="tabs.buyInfo.pageIndex"
										hide-on-single-page
										@current-change="onChangeBuyInfoPageIndex"
									></el-pagination>
								</div>
							</div>
						</el-tab-pane>

						<!-- 租赁信息 -->
						<el-tab-pane label="租赁信息" name="leaseInfo">
							<div class="tab-content">
								<BuyLeaseList :list="tabs.leaseInfo.list" v-if="tabs.leaseInfo.list.length"></BuyLeaseList>
								<el-empty description="数据为空" v-else></el-empty>
								<div class="common-pagination">
									<el-pagination
										background
										:page-size="tabs.leaseInfo.pageSize"
										:total="tabs.leaseInfo.total"
										:pager-count="5"
										:current-page="tabs.leaseInfo.pageIndex"
										hide-on-single-page
										@current-change="onChangeLeaseInfoPageIndex"
									></el-pagination>
								</div>
							</div>
						</el-tab-pane>
					</el-tabs>
				</div>
			</div>
		</div>
		<WebFooter></WebFooter>
	</div>
</template>

<script>
import WebHeader from "@/components/WebHeader.vue"; // 页面Header
import WebFooter from "@/components/WebFooter.vue"; // 页面Footer
import Breadcrumbs from "@/components/Breadcrumbs.vue"; // 面包屑
import SettledEnterpriseBlockListItem from "@/components/SettledEnterpriseBlockListItem.vue"; // 信息卡片
import TargetList from "@/components/TargetList.vue"; // 标的列表
import AuctionBlockList from "@/components/AuctionBlockList.vue"; // 拍卖会列表
import BuyLeaseList from "@/components/BuyLeaseList.vue"; // 求购租赁列表
import { breadcrumbsSettledEnterpriseDetails } from "@/breadcrumbs/index.js"; // 面包屑数据
import { apiGetSettledEnterpriseDetails } from "@/apis/enterprise.js";
import { apiGetTargetList } from "@/apis/target.js";
import { apiGetAuctionList } from "@/apis/auction.js";
import { apiGetBuyLeaseList } from "@/apis/buyLease.js";

export default {
	name: "PageSettledEnterpriseDetails",
	components: {
		WebHeader,
		WebFooter,
		Breadcrumbs,
		SettledEnterpriseBlockListItem,
		TargetList,
		AuctionBlockList,
		BuyLeaseList,
	},
	data() {
		return {
			id: "", // 企业id
			breadcrumbs: breadcrumbsSettledEnterpriseDetails, // 面包屑数据
			info: {}, // 企业信息
			tabs: {
				// 标的列表
				targetList: {
					label: "标的列表",
					list: [],
					pageIndex: 1, // 当前页码
					pageSize: 20, // 每页数据长度
					total: 0, // 总数据条数
				},
				// 拍卖会列表
				auctionList: {
					label: "拍卖会列表",
					list: [],
					pageIndex: 1, // 当前页码
					pageSize: 20, // 每页数据长度
					total: 0, // 总数据条数
				},
				// 求购信息
				buyInfo: {
					label: "求购信息",
					list: [],
					pageIndex: 1, // 当前页码
					pageSize: 20, // 每页数据长度
					total: 0, // 总数据条数
				},
				// 租赁信息
				leaseInfo: {
					label: "租赁信息",
					list: [],
					pageIndex: 1, // 当前页码
					pageSize: 20, // 每页数据长度
					total: 0, // 总数据条数
				},
			}, // tabs
			tabsValue: "targetList", // 当前选中的tab名称
		};
	},
	mounted() {
		// 得到企业id
		this.id = this.$route.query?.id || "";

		// 查询详情数据
		this.queryDetails();

		// 查询列表数据
		this.queryList();
	},
	methods: {
		/**
		 * !查询详情数据
		 */
		queryDetails() {
			apiGetSettledEnterpriseDetails({
				id: this.id,
			})
				.then((res) => {
					// 企业详情数据
					this.info = res?.finalData || {};

					// 更新面包屑
					this.breadcrumbs = [{ name: "首页", to: { name: "Index" } },
	{ name: "入驻企业", to: { name: "SettledEnterpriseList" } },]
					this.breadcrumbs.push({
						name: this.info?.company_name,
						to: { name: "SettledEnterpriseDetails", query: { id: this.info?.id } },
					});
				})
				.catch((err) => console.error(err));
		},

		/**
		 * !查询列表数据
		 */
		queryList() {
			if (this.tabsValue == "targetList") return this.queryTargetList();
			if (this.tabsValue == "auctionList") return this.queryAuctionList();
			if (this.tabsValue == "buyInfo") return this.queryBuyList();
			if (this.tabsValue == "leaseInfo") return this.queryLeaseList();
		},

		/**
		 * !查询标的列表
		 */
		queryTargetList() {
			apiGetTargetList({
				page: this.tabs.targetList.pageIndex,
				limit: this.tabs.targetList.pageSize,
				need_count: this.tabs.targetList.pageIndex == 1 ? 1 : 2,
				enterprise_id: this.id,
			})
				.then((res) => {
					// 数据总条数
					if (res?.finalData?.count) this.tabs.targetList.total = res?.finalData?.count;

					// 列表
					res?.finalData?.lists?.forEach((item) => (item.now_time = res?.finalData?.now_time));
					this.tabs.targetList.list = res?.finalData?.lists || [];
				})
				.catch((err) => console.error(err));
		},

		/**
		 * !标的列表-页码切换事件
		 * @param {Number} current 当前页
		 */
		onChangeTargetListPageIndex(current) {
			// 清空现有列表
			this.tabs.targetList.list = [];

			// 更新页码
			this.tabs.targetList.pageIndex = current;

			// 查询新的列表数据
			this.queryList();
		},

		/**
		 * !查询拍卖会列表
		 */
		queryAuctionList() {
			apiGetAuctionList({
				page: this.tabs.auctionList.pageIndex,
				limit: this.tabs.auctionList.pageSize,
				need_count: this.tabs.auctionList.pageIndex == 1 ? 1 : 2,
				enterprise_id: this.id,
			})
				.then((res) => {
					// 数据总条数
					if (res?.finalData?.count) this.tabs.auctionList.total = res?.finalData?.count;

					// 列表
					res?.finalData?.lists?.forEach((item) => (item.now_time = res?.finalData?.now_time));
					this.tabs.auctionList.list = res?.finalData?.lists || [];
				})
				.catch((err) => console.error(err));
		},

		/**
		 * !拍卖会-页码切换事件
		 * @param {Number} current 当前页
		 */
		onChangeAuctionListPageIndex(current) {
			// 清空现有列表
			this.tabs.auctionList.list = [];

			// 更新页码
			this.tabs.auctionList.pageIndex = current;

			// 查询新的列表数据
			this.queryList();
		},

		/**
		 * !查询求购信息列表
		 */
		queryBuyList() {
			apiGetBuyLeaseList({
				page: this.tabs.buyInfo.pageIndex,
				limit: this.tabs.buyInfo.pageSize,
				need_count: this.tabs.buyInfo.pageIndex == 1 ? 1 : 2,
				enterprise_id: this.id,
				mode_id: 1,
			})
				.then((res) => {
					// 数据总条数
					if (res?.finalData?.count) this.tabs.buyInfo.total = res?.finalData?.count;

					// 列表
					this.tabs.buyInfo.list = res?.finalData?.lists || [];
				})
				.catch((err) => console.error(err));
		},

		/**
		 * !求购信息列表-页码切换事件
		 * @param {Number} current 当前页
		 */
		onChangeBuyInfoPageIndex(current) {
			// 清空现有列表
			this.tabs.buyInfo.list = [];

			// 更新页码
			this.tabs.buyInfo.pageIndex = current;

			// 查询新的列表数据
			this.queryList();
		},

		/**
		 * !查询租赁信息列表
		 */
		queryLeaseList() {
			apiGetBuyLeaseList({
				page: this.tabs.leaseInfo.pageIndex,
				limit: this.tabs.leaseInfo.pageSize,
				need_count: this.tabs.leaseInfo.pageIndex == 1 ? 1 : 2,
				enterprise_id: this.id,
				mode_id: 2,
			})
				.then((res) => {
					// 数据总条数
					if (res?.finalData?.count) this.tabs.leaseInfo.total = res?.finalData?.count;

					// 列表
					this.tabs.leaseInfo.list = res?.finalData?.lists || [];
				})
				.catch((err) => console.error(err));
		},

		/**
		 * !租赁信息列表-页码切换事件
		 * @param {Number} current 当前页
		 */
		onChangeLeaseInfoPageIndex(current) {
			// 清空现有列表
			this.tabs.leaseInfo.list = [];

			// 更新页码
			this.tabs.leaseInfo.pageIndex = current;

			// 查询新的列表数据
			this.queryList();
		},

		/**
		 * !tab 被选中时触发
		 * @param {Object} tab 被选中的标签 tab 实例
		 *
		 */
		handleTabClick(tab) {
			// 如果tab下的数据列表为空,就查询一下数据,否则就不用查询
			if (!this.tabs[this.tabsValue].list.length) this.queryList();
		},
	},
};
</script>

<style scoped lang="scss">
.page-settled-enterprise-details-content {
	.info-card {
		margin-bottom: 19px;
	}

	.tabs-and-contents {
		margin-bottom: 56px;
	}

	::v-deep {
		.el-tabs__content {
			background-color: #f5f5f5;
			padding: 16px 0;
		}
	}
}
</style>

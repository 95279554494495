<!-- 标的详情页中的顶部模块 -->
<template>
	<div class="component-target-detail-topic">
		<!-- #S 企业信息 -->
		<div class="component-target-detail-topic__left">
			<!-- 拍卖会标题、名称 -->
			<h1 class="component-target-detail-topic__left__title">{{ title }}</h1>

			<!-- 企业信息 -->
			<div class="component-target-detail-topic__left__infos">
				<!-- 企业名称 -->
				<p>{{ companyName }}</p>

				<!-- 联系电话 -->
				<p>联系电话：{{ contactsPhone }}</p>
			</div>
		</div>
		<!-- #E 企业信息 -->

		<!-- #S 标的列表 -->
		<div class="component-target-detail-topic__right" v-show="swiperInstance">
			<i class="component-target-detail-topic__right__swiper-arrow arrow-prev zn-iconfont zn-iconfont-zuo"></i>
			<div class="component-target-detail-topic__right__swiper">
				<div class="swiper-container component-target-detail-topic__right__swiper-container">
					<div class="swiper-wrapper">
						<div
							class="swiper-slide"
							:class="{ 'is-selected': index == current }"
							v-for="(item, index) in items"
							:key="item?.no"
							@click="onClickSwiperSlide(item)"
						>
							<div class="slide-content">
								<div class="status is-pause" v-if="item?.pause_status == 10">已暂停</div>
								<TargetStatus :status="item?.status"  class="status" v-else></TargetStatus>
								<div class="image-box">
									<img :src="item?.image" class="image" alt="" />
								</div>
								<div class="name-box">
									<h1 class="name ellipsis2">{{ item?.title }}</h1>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<i class="component-target-detail-topic__right__swiper-arrow arrow-next zn-iconfont zn-iconfont-you"></i>
		</div>
		<!-- #E 标的列表 -->

		<span class="badge badge-10" v-if="auctionType1 == 10">同步拍</span>
		<span class="badge badge-20" v-else-if="auctionType1 == 20">网络拍</span>
	</div>
</template>

<script>
	import TargetStatus from "@/components/TargetStatus.vue";

	export default {
		name: "TargetDetailTopic",
		components: {
			TargetStatus,
		},
		props: {
			items: { type: Array, default: () => [] }, // 标的列表
			title: [String, Number], // 拍卖会标题
			companyName: [String, Number],
			contactsPhone: [String, Number], // 联系电话
			current: { type: [String, Number], default: 0 }, // 默认选中的 slide 下标
			auctionType1: { type: [String, Number], default: "" }, // 拍卖会类型。同步拍|网络拍
		},
		data() {
			return {
				swiperInstance: null, // swiper实例
			};
		},
		watch: {
			// 数据变化后实时更新swiper
			info: {
				handler() {
					this.initOrUpdateSwiper();
				},
				deep: true,
				immediate: true,
			},
			// 数据变化后实时更新swiper
			current: {
				handler() {
					this.initOrUpdateSwiper();
				},
				deep: true,
				immediate: true,
			},
		},
		mounted() {
			// 初始化swiper
		},
		methods: {
			/**
			 * !初始化或更新轮播图
			 */
			initOrUpdateSwiper() {
				this.$nextTick(() => {
					let timer = setTimeout(() => {
						clearTimeout(timer);
						if (!this.swiperInstance) this.initSwiper();
						else this.swiperInstance?.update();
					}, 200);
				});
			},

			/**
			 * !实例化swiper
			 */
			initSwiper() {
				this.swiperInstance = new this.Swiper(".component-target-detail-topic__right__swiper-container", {
					initialSlide: this.current < 0 ? 0 : this.current,
					slidesPerView: 4,
					spaceBetween: 11,
					loop: false,
					loopPreventsSlide: true,
					slideToClickedSlide: true,
					navigation: {
						nextEl: ".component-target-detail-topic__right__swiper-arrow.arrow-next",
						prevEl: ".component-target-detail-topic__right__swiper-arrow.arrow-prev",
					},
				});
			},

			/**
			 * !swiper slide被点击事件
			 * @param {Object} item 被点击的数据对象
			 */
			onClickSwiperSlide(item) {
				// 刷新页面
				this.$router.push({
					name: "TargetDetails",
					query: { no: item?.no },
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	.component-target-detail-topic {
		background-color: #ffffff;
		display: flex;
		padding: 27px 9px 23px 33px;
		position: relative;

		&__left {
			flex-shrink: 0;
			border-right: 1px solid #e4e4e4;
			margin-right: -1px;
			width: 357px;
			padding: 0 16px;

			&__title {
				font-size: 22px;
				font-weight: bold;
				line-height: 30px;
				margin-bottom: 20px;
			}

			&__infos {
				color: #999999;
				line-height: 26px;
			}
		}

		&__right {
			flex: 1;
			border-left: 1px solid #e4e4e4;
			padding: 0 26px;
			overflow: hidden;
			display: flex;
			align-items: center;

			&__swiper-arrow {
				flex-shrink: 0;
				color: #c7c7c7;
				font-size: 32px;
				cursor: pointer;
				transition: 0.2s;

				&.arrow-prev {
					margin-right: 10px;
				}

				&.arrow-next {
					margin-left: 10px;
				}

				&.swiper-button-disabled {
					color: #999999;
				}

				&:not(.swiper-button-disabled):hover {
					color: var(--theme-color);
				}
			}

			&__swiper {
				flex: 1;
				overflow: hidden;
			}

			.slide-content {
				position: relative;
				overflow: hidden;
				background-color: #f5f5f5;
				cursor: pointer;
				height: 100%;

				&::after {
					content: "";
					width: 100%;
					height: 100%;
					top: 0;
					left: 0;
					z-index: 5;
					position: absolute;
					border: 2px solid transparent;
					transition: 0.2s;
				}

				.status {
					color: #ffffff;
					width: 81px;
					height: 33px;
					line-height: 33px;
					text-align: center;
					position: absolute;
					left: 0;
					top: 0;
					z-index: 2;

					&.is-pause {
						/* background: var(--theme-color6); */
						background: #ed8726;
						color: #ffffff;
					}
				}

				.image-box {
					position: relative;
					padding-bottom: 56.25%;
					overflow: hidden;

					.image {
						display: block;
						width: 100%;
						height: 100%;
						left: 0;
						top: 0;
						position: absolute;
						z-index: 0;
					}
				}

				.name-box {
					padding: 11px 18px 15px;
					line-height: 18px;

					.name {
						font-size: 14px;
						font-weight: normal;
					}
				}
			}

			.swiper-slide {
				overflow: hidden;
				max-width: 25%;
				height: auto;
			}

			.swiper-slide.is-selected {
				.slide-content {
					&::after {
						border-color: var(--theme-color);
					}
				}
			}
		}

		.badge {
			position: absolute;
			padding: 5px 12px 5px 6px;
			font-size: 12px;
			left: 0;
			top: 0;
			border-radius: 0 100px 100px 0;
			z-index: 10;

			&-10 {
				background: var(--auction-type-bg-10);
				color: var(--auction-type-text-color-10);
			}

			&-20 {
				background: var(--auction-type-bg-20);
				color: var(--auction-type-text-color-20);
			}
		}
	}
</style>

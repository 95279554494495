<!-- 废钢统计 - 区域统计 - 表格 -->
<template>
	<div class="component-average-price-area-table">
		<h1 class="title">区域平均价格</h1>
		<table class="table">
			<thead>
				<tr>
					<th>区域</th>
					<th>价格</th>
					<th>涨跌</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="row in list" :key="row.zone_id">
					<td>{{ row?.name }}</td>
					<td>
						<span class="price">{{ formatPrice(row?.price) }}</span
						><span class="price-unit">元</span>
					</td>
					<td>
						<span class="jia" v-if="row?.rise_fall > 0">+{{ formatPrice(row?.rise_fall) }}</span>
						<span class="jian" v-else-if="row?.rise_fall < 0">{{ formatPrice(row?.rise_fall) }}</span>
						<span class="ping" v-else-if="row?.rise_fall == 0">{{ formatPrice(row?.rise_fall) }}</span>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
	export default {
		name: "AveragePriceAreaTable",
		props: {
			list: Array,
		},
		data() {
			return {};
		},
		mounted() {},
		methods: {},
	};
</script>

<style lang="scss" scoped>
	.component-average-price-area-table {
		padding: 0 40px 37px 40px;

		.title {
			font-size: 22px;
			padding: 28px 0 24px;
			line-height: 40px;
			border-bottom: 2px dotted #c5c5c5;
			margin-bottom: 25px;
		}

		.table {
			width: 100%;
			border-collapse: collapse;
			border-spacing: 0;
			border-top: 1px solid #eaeaea;
			border-left: 1px solid #eaeaea;

			th {
				height: 41px;
				background-color: #f5f5f5;
				border-right: 1px solid #eaeaea;
				border-bottom: 1px solid #eaeaea;
			}

			td {
				height: 36px;
				border-right: 1px solid #eaeaea;
				border-bottom: 1px solid #eaeaea;
				text-align: center;
			}

			.price {
				font-family: "MarkPro";
			}

			.price-unit {
				font-size: 12px;
				margin-left: 2px;
				display: inline-block;
			}

			.jia {
				color: var(--theme-color5);
				font-family: "MarkPro";
			}

			.jian,
			.ping {
				color: var(--theme-color4);
				font-family: "MarkPro";
			}
		}
	}
</style>

<!-- 标的竞价记录模块 -->
<template>
	<div class="component-target-bidding-record">
		<table class="table" v-if="list.length">
			<thead>
				<tr>
					<th>状态</th>
					<th>竞买号</th>
					<th>价格</th>
					<th>时间</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(row, index) in list" :key="index">
					<td><span v-if="row?.nickname == info?.nickname">我的</span>{{ index == 0 ? "领先" : "落后" }}</td>
					<td>{{ row?.nickname }}</td>
					<td>{{ formatPrice(row?.price) }}</td>
					<td>{{ dayjs.unix(row?.create_time).format("YYYY-MM-DD HH:mm:ss") }}</td>
				</tr>
			</tbody>
		</table>
		<el-empty description="暂无竞价记录" v-else></el-empty>
		<div class="more-wrapper">
			<div v-if="loading" class="loading">
				<i class="el-icon-loading"></i>
				<span>加载中</span>
			</div>
			<span class="loadmore" v-else-if="list?.length < count" @click="$emit('more')">点击加载更多</span>
			<span v-else>已加载全部竞价记录</span>
		</div>
	</div>
</template>

<script>
export default {
	name: "TargetBiddingRecord",
	props: {
		// 列表数据
		list: {
			type: Array,
			default: () => [],
		},
		info: {
			type: Object,
			default: () => {},
		},

		// 总数量
		count: {
			type: [Number, String],
			default: 0,
		},
	},
	data() {
		return {
			loading: true, // 是否是加载状态
		};
	},
	methods: {
		/**
		 * !开启loading
		 */
		loadingOpen() {
			this.loading = true;
		},

		/**
		 * !关闭loading
		 */
		loadingClose() {
			this.loading = false;
		},
	},
};
</script>

<style lang="scss" scoped>
.component-target-bidding-record {
	.table {
		width: 100%;
		border-collapse: collapse;
		border-spacing: 0;
		border-left: 1px solid #f2f2f2;
		border-top: 1px solid #f2f2f2;

		th {
			height: 48px;
			background-color: #f5f5f5;
			font-weight: bold;
			border-right: 1px solid #f2f2f2;
			border-bottom: 1px solid #f2f2f2;
		}

		tbody {
			tr:hover {
				background-color: rgba(242, 242, 242, 0.3);
			}

			td {
				width: 25%;
				height: 48px;
				text-align: center;
				border-right: 1px solid #f2f2f2;
				border-bottom: 1px solid #f2f2f2;
				transition: 0.2s;
			}

			tr:first-child {
				color: var(--theme-color);
				font-weight: bold;
			}
		}
	}

	.more-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 20px;
		font-size: 14px;
		margin-bottom: 6px;
		color: #666666;

		.loadmore {
			cursor: pointer;
			user-select: none;
		}

		.loading {
			display: flex;
			align-items: center;
			justify-content: center;

			.el-icon-loading {
				margin-right: 3px;
			}
		}
	}
}
</style>
